import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getBasketItems, updateBasketItem } from '../api'
import { useAtom } from 'jotai'
import { cartItemsCount, userProfile } from '../utils/atom'

export const useUpdateBasket = () => {
  const [userprofile, setUserDetails] = useAtom(userProfile)
  const [, setCartItemCount] = useAtom(cartItemsCount)
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async (data) => {
      const payload = {
        email: data?.email || '',
        programmeId:
          data?.programDetails?.id ||
          data?.programDetails?.Id ||
          data?.programmeId ||
          '',
        programmeName:
          data?.programDetails?.name || data?.programDetails?.Name || '',
        applicationStatus: 'InProgress',
        brand: data?.programDetails?.brand || '',
        bucketId: data?.bucketId || '',
      }
      const response = updateBasketItem(payload)
      return response
    },
    {
      onSuccess: async (data, context) => {
        const basketResponse = await getBasketItems({
          email: userprofile?.email,
        })
        const newUserDetails = { ...userprofile }
        newUserDetails['bucketId'] = basketResponse?.[0]?.bucketId || ''
        setUserDetails(newUserDetails)
        setCartItemCount(basketResponse?.length)
        queryClient.setQueryData([`getBasketItems-${userprofile?.email}`], (prevData) => {
          return basketResponse
        })
      },
    },
  )
  return { mutation }
}
