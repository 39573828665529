import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@app-hero/native-icons'
import {
  Button,
  Link,
  LinkedInButton,
  Loader,
  MobileInput,
  Text,
  TextInput,
} from '@libs/components'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { CustomAuth, SecureStore } from '@libs/utils'
import SearchDropDown from '@libs/components/src/DropDown/SearchDropdown'
import { useAtom } from 'jotai'
import { LinkedInLogo } from '@apphero/assets'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { isWeb } from '@libs/utils/src/screenLayout'
import { getCurrentLocation } from '../../api'
import ModalComponent from '../modal'
import { toggleDropdown } from './helpers'
import { reset } from '../../navigation/RootNavigator'
import { userLanguage } from '../../utils/atom'
import { useTheme } from '@libs/theme'

const Signup = ({
  countryList,
  invalidUser,
  signUpDetails,
  setSignupDetails,
  isLoading,
  setIsLoading,
  handleSignUp,
  alertBoxDetails,
  setAlertBoxDetails,
  mobile,
  tablet,
  isCTAEnable,
  setIsCTAEnable,
  errorMessage,
  setErrorMessage,
  ...props
}) => {
  const { t } = useTranslation()
  const [emailErrorMessage, setEmailErrorMessage] = useState({
    error: '',
    errorKey: '',
  })
  const [countryErrorMessage, setCountryErrorMessage] = useState({
    error: '',
    errorKey: '',
  })

  const [defaultEmail, setDefaultEmail] = useState('')
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [canSetPassword, setCanSetPassword] = useState(false)
  const [mobileError, setMobileError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const countryDropdownRef = useRef()
  const { colors } = useTheme()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const [userSelectedLanguage] = useAtom(userLanguage)
  const route = useRoute()
  const { width } = useWindowDimensions()
  const routeName = route?.name
  const isDesktop = isWeb(width)

  const initialValue = {
    country: '',
    countryCode: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: {
      dialCode: '',
      mobileNumber: '',
    },
    password: '',
    repeatPassword: '',
  }

  useEffect(() => {
    if (!isFocused) return
    ;(() => {
      setEmailErrorMessage((prev) => ({ ...prev, error: t(prev.errorKey) }))
      setCountryErrorMessage((prev) => ({ ...prev, error: t(prev.errorKey) }))
      setErrorMessage('')
    })()
  }, [userSelectedLanguage, isFocused])

  useEffect(() => {
    setSignupDetails(initialValue)
    setErrorMessage('')
  }, [])

  useEffect(() => {
    if (!isFocused && !countryList) return
    ;(async () => {
      const response = await getCurrentLocation()
      const tempUser = JSON.parse(await SecureStore.getItemAsync('tempUser'))
      setDefaultEmail(tempUser?.email)
      const country = countryList?.find(
        (e) => e.Value === response.country_code,
      )
      setSignupDetails((previousData) => ({
        ...previousData,
        email: tempUser?.email || previousData?.email || '',
        mobile: {
          dialCode: response.country_calling_code,
          mobileNumber: previousData.mobile.mobileNumber,
        },
        country: country?.Label || '',
      }))
    })()
    return () => {}
  }, [isFocused, countryList])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      function isValidName(name) {
        const specialCharPattern =
          /[!@#$%`^&*~()_+\-=\[\]{};':"\\|,.<>\/?1234567890]+/

        return !specialCharPattern.test(name)
      }
      const emailPattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidFirstName = signUpDetails.firstName.length > 0
      const isValidEmail = emailPattern.test(signUpDetails.email)
      const isValidMobile = signUpDetails.mobile.mobileNumber?.length > 0

      const fullNumber = `${signUpDetails.mobile.dialCode}${signUpDetails.mobile.mobileNumber}`
      const isValidMobileNumber = isValidPhoneNumber(fullNumber)
      if (!isValidName(signUpDetails.firstName)) {
        setFirstNameError('Please enter a valid First Name')
      } else {
        setFirstNameError('')
      }
      if (!isValidName(signUpDetails.lastName)) {
        setLastNameError('Please enter a valid Last Name')
      } else {
        setLastNameError('')
      }
      if (!isValidMobileNumber && signUpDetails.mobile.mobileNumber) {
        setMobileError('Please enter a valid Phone Number')
      } else {
        setMobileError('')
      }

      if (
        isValidFirstName &&
        isValidEmail &&
        isValidMobile &&
        isValidMobileNumber &&
        isValidName(signUpDetails.lastName) &&
        isValidName(signUpDetails.firstName)
      ) {
        setCanSetPassword(true)
      } else {
        setCanSetPassword(false)
      }
      if (signUpDetails.country.length === 0) {
        setCountryErrorMessage({
          error: t('ERROR_MESSAGE.COUNTRY_NOT_SLEETED'),
          errorKey: 'ERROR_MESSAGE.COUNTRY_NOT_SLEETED',
        })
      }
      if (!isValidEmail && signUpDetails?.email?.length > 0) {
        const disallowedCharsPattern = /[!#$%&'*+\-/=?^`{|}~,\s[\]<>\\]/
        if (disallowedCharsPattern.test(signUpDetails?.email)) {
          setEmailErrorMessage({
            error: t('ERROR_MESSAGE.INVALID_EMAIL_2'),
            errorKey: 'ERROR_MESSAGE.INVALID_EMAIL_2',
          })
        } else {
          setEmailErrorMessage({
            error: t('ERROR_MESSAGE.INVALID_EMAIL_1'),
            errorKey: 'ERROR_MESSAGE.INVALID_EMAIL_1',
          })
        }
        setCanSetPassword(false)
      }
      if (isValidEmail || signUpDetails?.email?.length === 0) {
        setEmailErrorMessage({
          error: '',
          errorKey: '',
        })
      }
    })()
  }, [isFocused, signUpDetails])

  const handleChange = (field, selectedValue) => {
    setCountryErrorMessage({
      error: '',
      errorKey: '',
    })
    setErrorMessage('')

    if (field === 'mobile') {
      const numberPattern = /^\d+$/
      const isValidMobileNumber = numberPattern.test(selectedValue)

      const mobile = {
        dialCode: signUpDetails.mobile.dialCode,
        mobileNumber: selectedValue,
      }
      if (isValidMobileNumber || !selectedValue) {
        return setSignupDetails((previousData) => ({
          ...previousData,
          [field]: mobile,
        }))
      }
    }
    if (field === 'email') {
      const emailPattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidEmail = emailPattern.test(selectedValue)
      if (!isValidEmail) {
        setEmailErrorMessage({
          error: t('ERROR_MESSAGE.INVALID_EMAIL'),
          errorKey: 'ERROR_MESSAGE.INVALID_EMAIL',
        })
      } else {
        setEmailErrorMessage({
          error: '',
          errorKey: '',
        })
      }
    }
    if (field === 'country') {
      return setSignupDetails((previousData) => ({
        ...previousData,
        [field]: selectedValue.Label,
        countryCode: selectedValue.Value,
      }))
    }
    return setSignupDetails((previousData) => ({
      ...previousData,
      [field]: selectedValue,
    }))
  }

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={styles.signUpContainer(mobile, tablet, isDesktop)}
      scrollEnabled
    >
      {isDesktop ? (
        <Text
          color={colors.neutral}
          variant="heading3"
          style={{
            textAlign: 'center',
            fontWeight: 700,
            borderRadius: 12,
            textWrap: 'nowrap',
          }}
        >
          {t('AUTH.ACCOUNT_SIGNUP')}
        </Text>
      ) : (
        routeName !== 'privacyPolicy' &&
        routeName !== 'login' && (
          <View style={styles.profileLeftHeader}>
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === 'web') {
                  window.history.go(-1)
                } else {
                  navigation.goBack()
                }
              }}
            >
              <Icon
                name="ArrowNarrowLeft"
                height={24}
                width={24}
                color={colors.black}
              />
            </TouchableOpacity>
            <Text style={[styles.myProfileText]}>
              {t('AUTH.ACCOUNT_SIGNUP')}
            </Text>
          </View>
        )
      )}

      <View style={styles.SignUpFields}>
        <View style={{ marginTop: 12 }}>
          <TextInput
            value={signUpDetails?.firstName}
            onChangeText={(text) => handleChange('firstName', text)}
            placeholder={`${t('TEXT_INPUT.FIRST_NAME')} (${t(
              'TEXT_INPUT.AS_PER_PASSPORT',
            )})`}
            limit={35}
            style={{
              height: 56,
              marginBottom: 10,
              borderRadius: 12,
            }}
            iconName="User2"
          />
          {firstNameError && (
            <Text variant="display3" color={colors.onAlert} style={{}}>
              {firstNameError}
            </Text>
          )}
        </View>
        <View style={{ marginTop: 6 }}>
          <TextInput
            value={signUpDetails?.lastName}
            onChangeText={(text) => handleChange('lastName', text)}
            placeholder={`${t('TEXT_INPUT.LAST_NAME')} (${t(
              'TEXT_INPUT.AS_PER_PASSPORT',
            )})`}
            style={{ height: 56, marginBottom: 10, borderRadius: 12 }}
            limit={35}
            iconName="User2"
          />
          {lastNameError && (
            <Text variant="display3" color={colors.onAlert} style={{}}>
              {lastNameError}
            </Text>
          )}
        </View>
        {/* <View style={{ flex: 1, marginBottom: 10 }} ref={countryDropdownRef}>
          <DropDown
            style={{
              margin: 0,
              width: '100%',
            }}
            label={t('TEXT_INPUT.COUNTRY')}
            labelColor={colors.onNeutral}
            toggleDropdown={() =>
              toggleDropdown({
                ref: countryDropdownRef,
                setDropdownTop,
                setDropdownRight: setDropdownLeft,
                setDropDownWidth,
              })
            }
            onPress={(selectedCountry) =>
              handleChange('country', selectedCountry)
            }
            dropdownWidth={dropdownWidth}
            isCountryCode
            items={countryList || []}
            value={
              signUpDetails.country
                ? {
                    Label: signUpDetails.country,
                    Value: signUpDetails.countryCode,
                  }
                : {}
            }
            position={{ top: dropdownTop, left: dropdownLeft + 10 }}
            dropdownHeight={150}
          />
        </View> */}
        <View style={{ marginTop: 8 }}>
          <View
            style={{
              flex: 1,
              marginBottom: 10,
              borderRadius: 12,
            }}
            ref={countryDropdownRef}
          >
            <SearchDropDown
              style={{
                margin: 0,
                width: '100%',
                borderRadius: 12,
                height: 56,
              }}
              iconName="Map"
              label={t('TEXT_INPUT.COUNTRY')}
              labelColor={colors.onNeutral}
              toggleDropdown={() =>
                toggleDropdown({
                  ref: countryDropdownRef,
                  setDropdownTop,
                  setDropdownRight: setDropdownLeft,
                  setDropDownWidth,
                })
              }
              onPress={(selectedCountry) =>
                handleChange('country', selectedCountry)
              }
              dropdownWidth={dropdownWidth}
              isCountryCode
              items={countryList || []}
              value={
                signUpDetails.country
                  ? {
                      Label: signUpDetails.country,
                      Value: signUpDetails.countryCode,
                    }
                  : {}
              }
              position={{ top: dropdownTop, left: dropdownLeft + 10 }}
              dropdownHeight={150}
            />
          </View>
        </View>
        <View style={{ marginTop: 8 }}>
          <MobileInput
            countryBasedOn={signUpDetails?.country}
            value={signUpDetails.mobile.mobileNumber}
            onChangeText={(text) => handleChange('mobile', text)}
            placeholder={t('TEXT_INPUT.PHONE')}
            dialCode={signUpDetails.mobile.dialCode}
            style={{
              height: 56,
              marginBottom: 10,
              borderRadius: 12,
            }}
            handleCountrySelection={(selectedCountry) => {
              setSignupDetails((previousData) => ({
                ...previousData,
                mobile: {
                  dialCode: selectedCountry.dial_code,
                  mobileNumber: previousData.mobile.mobileNumber,
                },
                countryCode: selectedCountry.code,
              }))
            }}
            code={signUpDetails.countryCode}
          />
          {mobileError && (
            <Text variant="display3" color={colors.onAlert} style={{}}>
              {mobileError}
            </Text>
          )}
        </View>
        <View style={{ marginTop: 12 }}>
          <TextInput
            value={signUpDetails?.email}
            onChangeText={(text) => handleChange('email', text)}
            inputFieldStyle={{
              color: defaultEmail ? colors.primaryPlaceHolder : '',
            }}
            style={{
              height: 56,
              marginBottom: 10,
              borderRadius: 12,
            }}
            placeholder={t('TEXT_INPUT.EMAIL')}
            disableFieldColor="transparent"
            editable={!defaultEmail}
            iconName="Mail2"
          />
          {emailErrorMessage.error ? (
            <Text
              variant="display3"
              color={colors.onAlert}
              style={{ marginBottom: 20 }}
            >
              {emailErrorMessage.error}
            </Text>
          ) : null}
        </View>
      </View>
      {errorMessage && (
        <Text variant="display5" color={colors.onAlert}>
          {errorMessage}
        </Text>
      )}
      <View style={styles.buttonsContainer}>
        {isLoading ? (
          // <Loader size={15} hasFlexValue={false} />
          ''
        ) : (
          <Button
            label={t('BUTTON.SIGNUP')}
            buttonColor={colors.primary}
            labelColors={colors.white}
            onPress={() => {
              if (canSetPassword) {
                setSignupDetails((prevDetails) => ({
                  ...prevDetails,
                  password: '',
                  repeatPassword: '',
                }))
                navigation.push('setPassword')
              }
            }}
            buttonStyle={{ width: '100%', borderRadius: 12, height: 56 }}
            labelStyle={{ fontWeight: 700 }}
            disable={!canSetPassword}
          />
        )}
      </View>
      {isDesktop ? (
        <Text
          variant="display5"
          color={colors.onNeutral}
          style={{ marginBottom: 26 }}
        >
          Or continue with
        </Text>
      ) : (
        <View
          style={[
            styles.signUpContainer,
            {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginBottom: 32,
            },
          ]}
        >
          <View style={[styles.divider, { width: '30%' }]} />
          <Text
            color={colors.onNeutral}
            style={{
              paddingHorizontal: 16,
              fontSize: 12,
              textWrap: 'none',
              flexShrink: 0,
            }}
          >
            Or continue with
          </Text>
          <View style={[styles.divider, { width: '30%' }]} />
        </View>
      )}
      <View style={[styles.authLinks, { width: '100%' }]}>
        <LinkedInButton
          onPress={() => CustomAuth.federatedSignIn('linkedin')}
        />
      </View>
      <View style={styles.footer}>
        <Text variant="display4">{t('LINK.HAVE_ACCOUNT')} </Text>
        <Link
          label={t('BUTTON.SIGNIN')}
          labelVariant="display4"
          labelColor={colors.primary}
          handlePress={() => {
            navigation.navigate('login')
            setSignupDetails(initialValue)
          }}
          labelStyle={{ fontWeight: 700 }}
        />
      </View>
      <ModalComponent
        visible={alertBoxDetails.visible}
        title={alertBoxDetails.message}
        buttonLabel={t('BUTTON.OK')}
        handleCloseModal={() => {
          reset('signUp')
          setAlertBoxDetails({
            visible: false,
            message: '',
          })
        }}
        handleButton={() => {
          reset('signUp')
          setAlertBoxDetails({
            visible: false,
            message: '',
          })
        }}
        buttonColor={colors.primary}
        labelColors={colors.primaryVariant}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  authLinks: {
    alignSelf: 'center',
  },
  buttonsContainer: {
    width: '100%',
    marginTop: 20,
    marginBottom: 30,
  },
  signUpContainer: (mobile, tablet, isDesktop) => {
    return {
      flexDirection: 'column',
      alignItems: isDesktop && 'center',
      justifyContent: 'center',
      marginTop: !isDesktop ? '6vh' : '10vh',

      paddingLeft: isDesktop ? '16%' : '10%',
      paddingRight: isDesktop ? '16%' : '10%',
    }
  },
  SignUpFields: {
    marginTop: window.devicePixelRatio === 2 ? 30 : 15,
    width: '100%',
    // justifyContent: 'space-evenly',
    // flex: 1,
  },
  leftHeader: {
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: -35,
  },
  footer: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontWeight: 700,
    fontSize: 18,
    color: '#1E1E2D',
    textTransform: 'uppercase',
    flex: 1,
    textAlign: 'center',
  },
  profileLeftHeader: {
    flexDirection: 'row',
    columnGap: 20,
  },
  myProfileText: {
    fontWeight: 700,
    fontSize: 24,
    color: '#1E1E2D',
    flex: 1,
    textAlign: 'left',
    paddingRight: 10,
  },
  divider: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    height: 0,
  },
})

export default Signup
