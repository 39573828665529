import { StyleSheet, TextInput, View } from 'react-native'
import React, { useRef, useState } from 'react'
import { countryCodes } from '@libs/utils'
import SearchDropDown from '../DropDown/SearchDropdown'
import { useTheme } from '@libs/theme'

const MobileInput = ({
  inputFieldStyle,
  placeholder,
  placeholderColor,
  dialCode,
  value,
  style,
  code,
  onChangeText = () => {},
  handleCountrySelection = () => {},
  countryBasedOn,
}) => {
  const { colors } = useTheme()
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const dropDownRef = useRef()

  const toggleDropdown = (visible) => {
    if (visible) {
      return
    }
    if (!visible) {
      dropDownRef?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
        setDropdownTop(py + 50)
        setDropdownLeft(_px)
        setDropDownWidth(_w)
      })
    }
  }

  return (
    <View
      style={[
        styles.inputContainer,
        {
          borderColor: style?.borderColor || colors.primaryPlaceHolder,
          borderRadius: 12,
          height: style?.height,
        },
      ]}
      ref={dropDownRef}
    >
      <SearchDropDown
        items={countryCodes}
        hasNoBorder
        toggleDropdown={toggleDropdown}
        position={{ left: dropdownLeft, top: dropdownTop }}
        dropdownWidth={dropdownWidth}
        dropdownHeight={200}
        onPress={(selectedFilter) => handleCountrySelection(selectedFilter)}
        isCountryCode
        dialCode={dialCode}
        code={code}
        countryBasedOn={countryBasedOn}
      />
      <TextInput
        inputMode="tel"
        placeholder={placeholder}
        style={[
          styles.inputField,
          inputFieldStyle,
          { borderRadius: 12 },
          style,
        ]}
        placeholderTextColor={placeholderColor || colors.primaryPlaceHolder}
        onChangeText={(text: any) => {
          if (!isNaN(text)) onChangeText(text)
        }}
        keyboardType="numeric"
        value={value}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    borderRadius: 4,
    height: 56,
    marginBottom: 10,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  inputField: {
    flex: 1,
    marginLeft: 10,
  },
})

export default MobileInput
