import React, { useEffect, useState } from 'react'
import { isMobile, isTablet } from '@libs/utils/src/screenLayout'
import { Linking, useWindowDimensions } from 'react-native'
import queryString from 'query-string'
import DashBoard from '../screens/dashboard'
import Auth from '../screens/auth'
import MyApplication from '../screens/myApplication'
import CompareProgram from '../screens/compareProgrammes'
import ViewApplicationDetails from '../screens/viewApplicationDetails'
import Profile from '../screens/profile'
import ProgrammeDetails from '../screens/programmeDetails'
import ProgrammeApplication from '../screens/programmeApplication'
import ApplicationBasket from '../screens/applicationBasket'
import ChangePassword from '../screens/changePassword'
import ChangeProfilePicture from '../screens/changeProfilePicture'
import StudentDetail from '../screens/studentDetail'
import Notification from '../screens/notification'
import ProtectedRoute from './ProtectedRoute'

export const Stacks = (Stack) => {
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const tablet = isTablet(width)
  const [initialParams, setInitialParams] = useState({})

  useEffect(() => {
    const handleInitialURL = async () => {
      const url = await Linking.getInitialURL()
      if (url) {
        const queryParams = queryString.parseUrl(url)
        setInitialParams(queryParams.query)
      }
    }
    handleInitialURL()
  }, [])

  return (
    <Stack.Group
      initialRouteName="login"
      screenOptions={{
        title: '',
      }}
    >
      <Stack.Screen name="login" options={{ headerShown: false }}>
        {(screenProps) => <Auth {...screenProps} {...initialParams} />}
      </Stack.Screen>
      <Stack.Screen name="signUp" options={{ headerShown: false }}>
        {(screenProps) => <Auth {...screenProps} {...initialParams} />}
      </Stack.Screen>
      <Stack.Screen name="setNewPassword" options={{ headerShown: false }}>
        {(screenProps) => <Auth {...screenProps} />}
      </Stack.Screen>
      <Stack.Screen name="forgotPassword" options={{ headerShown: false }}>
        {(screenProps) => <Auth {...screenProps} />}
      </Stack.Screen>
      <Stack.Screen name="setPassword" options={{ headerShown: false }}>
        {(screenProps) => <Auth {...screenProps} />}
      </Stack.Screen>
      <Stack.Screen name="Otp" options={{ headerShown: false }}>
        {(screenProps) => <Auth {...screenProps} />}
      </Stack.Screen>
      <Stack.Screen name="privacyPolicy" options={{ headerShown: false }}>
        {(screenProps) => <Auth {...screenProps} />}
      </Stack.Screen>
      <Stack.Screen name="path-finder">
        {(screenProps) => (
          <ProtectedRoute>
            <DashBoard {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen name="my-application">
        {(screenProps) => (
          <ProtectedRoute>
            <MyApplication
              {...screenProps}
              {...initialParams}
              setInitialParams={setInitialParams}
            />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="notification"
        options={mobile || tablet ? { headerShown: false } : ''}
      >
        {(screenProps) => (
          <ProtectedRoute>
            <Notification {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen name="compare-programmes">
        {(screenProps) => (
          <ProtectedRoute>
            <CompareProgram {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen name="view-application-details">
        {(screenProps) => (
          <ProtectedRoute>
            <ViewApplicationDetails {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="profile"
        options={mobile || tablet ? { headerShown: false } : ''}
      >
        {(screenProps) => (
          <ProtectedRoute>
            <Profile {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen name="programme-detail">
        {(screenProps) => (
          <ProtectedRoute>
            <ProgrammeDetails {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen name="apply-programmes" options={{ headerShown: false }}>
        {(screenProps) => (
          <ProtectedRoute>
            <ProgrammeApplication {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen name="student-details">
        {(screenProps) => (
          <ProtectedRoute>
            <StudentDetail {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen name="application-basket">
        {(screenProps) => (
          <ProtectedRoute>
            <ApplicationBasket {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="change-password"
        options={mobile || tablet ? { headerShown: false } : ''}
      >
        {(screenProps) => (
          <ProtectedRoute>
            <ChangePassword {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
      <Stack.Screen name="profile-picture">
        {(screenProps) => (
          <ProtectedRoute>
            <ChangeProfilePicture {...screenProps} />
          </ProtectedRoute>
        )}
      </Stack.Screen>
    </Stack.Group>
  )
}
