import { Platform } from 'react-native'

const awsConfig = {
  aws_project_region: 'eu-west-1',
  aws_appsync_graphqlEndpoint: 'https://notification-gql.apphero.io/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id:
    'eu-west-1:b747d380-b700-4cb1-af0e-07704a5bf8b4',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_Q5t0ZyN4K',
  aws_user_pools_web_client_id: '7a9oe1juvo0c326b5j5e2c31gd',
  oauth: {
    domain: 'apphero-prod.auth.eu-west-1.amazoncognito.com',
    redirectSignIn:
      Platform.OS === 'web'
        ? `${window.location.origin}/my-application`
        : 'appHero://',
    redirectSignOut:
      Platform.OS === 'web' ? `${window.location.origin}/login` : 'appHero://',
    responseType: 'code',
    scope: [
      'email',
      'openid',
      'phone',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
  },
  API: {
    endpoints: [
      {
        name: 'graphql',
        endpoint: 'https://notification-gql.apphero.io/graphql',
        region: 'eu-west-1',
        service: 'appsync',
      },
    ],
  },
}

export default awsConfig
