export const countryCodes = [
  {
    name: {
      en: 'United States',
      ru: 'Соединенные Штаты',
      pl: 'Stany Zjednoczone',
      ua: 'Сполучені Штати',
      cz: 'Spojené státy',
      by: 'Злучаныя Штаты',
      pt: 'Estados Unidos',
      es: 'Estados Unidos',
      ro: 'Statele Unite',
      bg: 'Съединени щати',
      de: 'Vereinigte Staaten',
      fr: 'États-Unis',
      nl: 'Verenigde Staten',
      it: 'stati Uniti',
      cn: '美国',
      zh: '美國',
      ee: 'Ühendriigid',
      jp: 'アメリカ',
      he: 'ארצות הברית',
      el: 'Ηνωμένες Πολιτείες Αμερικής',
    },
    dial_code: '+1',
    code: 'US',
    flag: '🇺🇸',
  },
  {
    name: {
      en: 'France',
      ru: 'Франция',
      pl: 'Francja',
      ua: 'Франція',
      cz: 'Francie',
      by: 'Францыя',
      pt: 'França',
      es: 'Francia',
      ro: 'Franţa',
      bg: 'Франция',
      de: 'Frankreich',
      fr: 'France',
      nl: 'Frankrijk',
      it: 'Francia',
      cn: '法国',
      zh: '法國',
      ee: 'Prantsusmaa',
      jp: 'フランス',
      he: 'צרפת',
      el: 'Γαλλία',
    },
    dial_code: '+33',
    code: 'FR',
    flag: '🇫🇷',
  },
  {
    name: {
      en: 'Germany',
      ru: 'Германия',
      pl: 'Niemcy',
      ua: 'Німеччина',
      cz: 'Německo',
      by: 'Германія',
      pt: 'Alemanha',
      es: 'Alemania',
      ro: 'Germania',
      bg: 'Германия',
      de: 'Deutschland',
      fr: 'Allemagne',
      nl: 'Duitsland',
      it: 'Germania',
      cn: '德国',
      zh: '德國',
      ee: 'Saksamaa',
      jp: 'ドイツ',
      he: 'גרמניה',
      el: 'Γερμανία',
    },
    dial_code: '+49',
    code: 'DE',
    flag: '🇩🇪',
  },
  {
    name: {
      en: 'Afghanistan',
      ru: 'Афганистан',
      pl: 'Afganistan',
      ua: 'Афганістан',
      cz: 'Afghánistán',
      by: 'Афганістан',
      pt: 'Afeganistão',
      es: 'Afganistán',
      ro: 'Afganistan',
      bg: 'Афганистан',
      de: 'Afghanistan',
      fr: "L'Afghanistan",
      nl: 'Afghanistan',
      it: 'Afghanistan',
      cn: '阿富汗',
      zh: '阿富汗',
      ee: 'Afganistan',
      jp: 'アフガニスタン',
      he: 'אפגניסטן',
      el: 'Αφγανιστάν',
    },
    dial_code: '+93',
    code: 'AF',
    flag: '🇦🇫',
  },
  {
    name: {
      en: 'Albania',
      ru: 'Албания',
      pl: 'Albania',
      ua: 'Албанія',
      cz: 'Albánie',
      by: 'Албанія',
      pt: 'Albânia',
      es: 'Albania',
      ro: 'Albania',
      bg: 'Албания',
      de: 'Albanien',
      fr: 'Albanie',
      nl: 'Albanië',
      it: 'Albania',
      cn: '阿尔巴尼亚',
      zh: '阿爾巴尼亞',
      ee: 'Albaania',
      jp: 'アルバニア',
      he: 'אלבניה',
      el: 'Αλβανία',
    },
    dial_code: '+355',
    code: 'AL',
    flag: '🇦🇱',
  },
  {
    name: {
      en: 'Algeria',
      ru: 'Алжир',
      pl: 'Algieria',
      ua: 'Алжир',
      cz: 'Alžírsko',
      by: 'Алжыр',
      pt: 'Argélia',
      es: 'Argelia',
      ro: 'Algeria',
      bg: 'Алжир',
      de: 'Algerien',
      fr: 'Algérie',
      nl: 'Algerije',
      it: 'Algeria',
      cn: '阿尔及利亚',
      zh: '阿爾及利亞',
      ee: 'Alžeeria',
      jp: 'アルジェリア',
      he: "אלג'יריה",
      el: 'Αλγερία',
    },
    dial_code: '+213',
    code: 'DZ',
    flag: '🇩🇿',
  },
  {
    name: {
      en: 'American Samoa',
      ru: 'Американское Самоа',
      pl: 'Samoa Amerykańskie',
      ua: 'Американське Самоа',
      cz: 'Americká Samoa',
      by: 'Амерыканскае Самоа',
      pt: 'Samoa',
      es: 'Samoa Americana',
      ro: 'Samoa Americană',
      bg: 'Американска Самоа',
      de: 'Amerikanischen Samoa-Inseln',
      fr: 'Samoa américaines',
      nl: 'Amerikaans Samoa',
      it: 'Samoa americane',
      cn: '美属萨摩亚',
      zh: '美屬薩摩亞',
      ee: 'Ameerika Samoa',
      jp: 'アメリカ領サモア',
      he: 'סמואה האמריקנית',
      el: 'Αμερικανική Σαμόα',
    },
    dial_code: '+1684',
    code: 'AS',
    flag: '🇦🇸',
  },
  {
    name: {
      en: 'Andorra',
      ru: 'Андорра',
      pl: 'Andora',
      ua: 'Андорра',
      cz: 'Andorra',
      by: 'Андора',
      pt: 'Andorra',
      es: 'Andorra',
      ro: 'Andorra',
      bg: 'Андора',
      de: 'Andorra',
      fr: 'Andorre',
      nl: 'Andorra',
      it: 'Andorra',
      cn: '安道尔',
      zh: '安道爾',
      ee: 'Andorra',
      jp: 'アンドラ',
      he: 'אנדורה',
      el: 'Ανδόρρα',
    },
    dial_code: '+376',
    code: 'AD',
    flag: '🇦🇩',
  },
  {
    name: {
      en: 'Angola',
      ru: 'Ангола',
      pl: 'Angola',
      ua: 'Ангола',
      cz: 'Angola',
      by: 'Ангола',
      pt: 'Angola',
      es: 'Angola',
      ro: 'Angola',
      bg: 'Ангола',
      de: 'Angola',
      fr: "L'Angola",
      nl: 'Angola',
      it: 'Angola',
      cn: '安哥拉',
      zh: '安哥拉',
      ee: 'Angola',
      jp: 'アンゴラ',
      he: 'אנגולה',
      el: 'Ανγκόλα',
    },
    dial_code: '+244',
    code: 'AO',
    flag: '🇦🇴',
  },
  {
    name: {
      en: 'Anguilla',
      ru: 'Ангилья',
      pl: 'Anguilla',
      ua: 'Ангілья',
      cz: 'Anguilla',
      by: 'Ангілья',
      pt: 'Anguila',
      es: 'Anguila',
      ro: 'Anguilla',
      bg: 'Ангила',
      de: 'Anguilla',
      fr: 'Anguilla',
      nl: 'Anguilla',
      it: 'Anguilla',
      cn: '安圭拉岛',
      zh: '安圭拉島',
      ee: 'Anguilla',
      jp: 'アンギラ',
      he: 'אנגווילה',
      el: 'Ανγκουίλα',
    },
    dial_code: '+1264',
    code: 'AI',
    flag: '🇦🇮',
  },
  {
    name: {
      en: 'Antarctica',
      ru: 'Антарктида',
      pl: 'Antarktyda',
      ua: 'Антарктида',
      cz: 'Antarktida',
      by: 'Антарктыда',
      pt: 'Antarctica',
      es: 'Antártida',
      ro: 'Antarctica',
      bg: 'Антарктида',
      de: 'Antarktis',
      fr: 'Antarctique',
      nl: 'Antarctica',
      it: 'Antartide',
      cn: '南极洲',
      zh: '南極洲',
      ee: 'Antarktika',
      jp: '南極大陸',
      he: 'אנטארקטיקה',
      el: 'Ανταρκτική',
    },
    dial_code: '+672',
    code: 'AQ',
    flag: '🇦🇶',
  },
  {
    name: {
      en: 'Antigua and Barbuda',
      ru: 'Антигуа и Барбуда',
      pl: 'Antigua i Barbuda',
      ua: 'Антигуа та Барбуда',
      cz: 'Antigua a Barbuda',
      by: 'Антыгуа і Барбуда',
      pt: 'Antiga e Barbuda',
      es: 'Antigua y Barbuda',
      ro: 'Antigua si Barbuda',
      bg: 'Антигуа и Барбуда',
      de: 'Antigua und Barbuda',
      fr: 'Antigua-et-Barbuda',
      nl: 'Antigua en Barbuda',
      it: 'Antigua e Barbuda',
      cn: '安提瓜和巴布达',
      zh: '安提瓜和巴布達',
      ee: 'Antigua ja Barbuda',
      jp: 'アンティグアバーブーダ',
      he: 'אנטיגואה וברבודה',
      el: 'Αντίγκουα και Μπαρμπούντα',
    },
    dial_code: '+1268',
    code: 'AG',
    flag: '🇦🇬',
  },
  {
    name: {
      en: 'Argentina',
      ru: 'Аргентина',
      pl: 'Argentyna',
      ua: 'Аргентина',
      cz: 'Argentina',
      by: 'Аргентына',
      pt: 'Argentina',
      es: 'Argentina',
      ro: 'Argentina',
      bg: 'Аржентина',
      de: 'Argentinien',
      fr: 'Argentine',
      nl: 'Argentinië',
      it: 'Argentina',
      cn: '阿根廷',
      zh: '阿根廷',
      ee: 'Argentina',
      jp: 'アルゼンチン',
      he: 'ארגנטינה',
      el: 'Αργεντινή',
    },
    dial_code: '+54',
    code: 'AR',
    flag: '🇦🇷',
  },
  {
    name: {
      en: 'Armenia',
      ru: 'Армения',
      pl: 'Armenia',
      ua: 'Вірменія',
      cz: 'Arménie',
      by: 'Арменія',
      pt: 'Arménia',
      es: 'Armenia',
      ro: 'Armenia',
      bg: 'Армения',
      de: 'Armenien',
      fr: 'Arménie',
      nl: 'Armenië',
      it: 'Armenia',
      cn: '亚美尼亚',
      zh: '亞美尼亞',
      ee: 'Armeenia',
      jp: 'アルメニア',
      he: 'ארמניה',
      el: 'Αρμενία',
    },
    dial_code: '+374',
    code: 'AM',
    flag: '🇦🇲',
  },
  {
    name: {
      en: 'Aruba',
      ru: 'Аруба',
      pl: 'Aruba',
      ua: 'Аруба',
      cz: 'Aruba',
      by: 'Аруба',
      pt: 'Aruba',
      es: 'Aruba',
      ro: 'Aruba',
      bg: 'Аруба',
      de: 'Aruba',
      fr: 'Aruba',
      nl: 'Aruba',
      it: 'Aruba',
      cn: '阿鲁巴岛',
      zh: '阿魯巴島',
      ee: 'Aruba',
      jp: 'アルバ',
      he: 'ארובה',
      el: 'Αρούμπα',
    },
    dial_code: '+297',
    code: 'AW',
    flag: '🇦🇼',
  },
  {
    name: {
      en: 'Australia',
      ru: 'Австралия',
      pl: 'Australia',
      ua: 'Австралія',
      cz: 'Austrálie',
      by: 'Аўстралія',
      pt: 'Austrália',
      es: 'Australia',
      ro: 'Australia',
      bg: 'Австралия',
      de: 'Australien',
      fr: 'Australie',
      nl: 'Australië',
      it: 'Australia',
      cn: '澳大利亚',
      zh: '澳大利亞',
      ee: 'Austraalia',
      jp: 'オーストラリア',
      he: 'אוסטרליה',
      el: 'Αυστραλία',
    },
    dial_code: '+61',
    code: 'AU',
    flag: '🇦🇺',
  },
  {
    name: {
      en: 'Austria',
      ru: 'Австрия',
      pl: 'Austria',
      ua: 'Австрія',
      cz: 'Rakousko',
      by: 'Аўстрыя',
      pt: 'Áustria',
      es: 'Austria',
      ro: 'Austria',
      bg: 'Австрия',
      de: 'Österreich',
      fr: "L'Autriche",
      nl: 'Oostenrijk',
      it: 'Austria',
      cn: '奥地利',
      zh: '奧地利',
      ee: 'Austria',
      jp: 'オーストリア',
      he: 'אוסטריה',
      el: 'Αυστρία',
    },
    dial_code: '+43',
    code: 'AT',
    flag: '🇦🇹',
  },
  {
    name: {
      en: 'Azerbaijan',
      ru: 'Азербайджан',
      pl: 'Azerbejdżan',
      ua: 'Азербайджан',
      cz: 'Ázerbajdžán',
      by: 'Азербайджан',
      pt: 'Azerbaijão',
      es: 'Azerbaiyán',
      ro: 'Azerbaidjan',
      bg: 'Азербайджан',
      de: 'Aserbaidschan',
      fr: 'Azerbaïdjan',
      nl: 'Azerbeidzjan',
      it: 'Azerbaigian',
      cn: '阿塞拜疆',
      zh: '阿塞拜疆',
      ee: 'Aserbaidžaan',
      jp: 'アゼルバイジャン',
      he: "אזרבייג'ן",
      el: 'Αζερμπαϊτζάν',
    },
    dial_code: '+994',
    code: 'AZ',
    flag: '🇦🇿',
  },
  {
    name: {
      en: 'Bahamas',
      ru: 'Багамские Острова',
      pl: 'Bahamy',
      ua: 'Багамські острови',
      cz: 'Bahamy',
      by: 'Багамскія астравы',
      pt: 'Baamas',
      es: 'Bahamas',
      ro: 'Bahamas',
      bg: 'Бахамски острови',
      de: 'Bahamas',
      fr: 'Bahamas',
      nl: 'Bahamas',
      it: 'Bahamas',
      cn: '巴哈马',
      zh: '巴哈馬',
      ee: 'Bahama',
      jp: 'バハマ',
      he: 'איי בהאמה',
      el: 'Μπαχάμες',
    },
    dial_code: '+1242',
    code: 'BS',
    flag: '🇧🇸',
  },
  {
    name: {
      en: 'Bahrain',
      ru: 'Бахрейн',
      pl: 'Bahrajn',
      ua: 'Бахрейн',
      cz: 'Bahrajn',
      by: 'Бахрэйн',
      pt: 'Barém',
      es: 'Bahrein',
      ro: 'Bahrain',
      bg: 'Бахрейн',
      de: 'Bahrain',
      fr: 'Bahreïn',
      nl: 'Bahrein',
      it: 'Bahrein',
      cn: '巴林',
      zh: '巴林',
      ee: 'Bahrein',
      jp: 'バーレーン',
      he: 'בחריין',
      el: 'Μπαχρέιν',
    },
    dial_code: '+973',
    code: 'BH',
    flag: '🇧🇭',
  },
  {
    name: {
      en: 'Bangladesh',
      ru: 'Бангладеш',
      pl: 'Bangladesz',
      ua: 'Бангладеш',
      cz: 'Bangladéš',
      by: 'Бангладэш',
      pt: 'Bangladeche',
      es: 'Bangladesh',
      ro: 'Bangladesh',
      bg: 'Бангладеш',
      de: 'Bangladesch',
      fr: 'Bangladesh',
      nl: 'Bangladesh',
      it: 'Bangladesh',
      cn: '孟加拉国',
      zh: '孟加拉國',
      ee: 'Bangladesh',
      jp: 'バングラデシュ',
      he: 'בנגלדש',
      el: 'Μπανγκλαντές',
    },
    dial_code: '+880',
    code: 'BD',
    flag: '🇧🇩',
  },
  {
    name: {
      en: 'Barbados',
      ru: 'Барбадос',
      pl: 'Barbados',
      ua: 'Барбадос',
      cz: 'Barbados',
      by: 'Барбадас',
      pt: 'Barbados',
      es: 'Barbados',
      ro: 'Barbados',
      bg: 'Барбадос',
      de: 'Barbados',
      fr: 'Barbade',
      nl: 'Barbados',
      it: 'Barbados',
      cn: '巴巴多斯',
      zh: '巴巴多斯',
      ee: 'Barbados',
      jp: 'バルバドス',
      he: 'ברבדוס',
      el: 'Μπαρμπάντος',
    },
    dial_code: '+1246',
    code: 'BB',
    flag: '🇧🇧',
  },
  {
    name: {
      en: 'Belarus',
      ru: 'Белоруссия',
      pl: 'Białoruś',
      ua: 'Білорусь',
      cz: 'Bělorusko',
      by: 'Беларусь',
      pt: 'Bielorrússia',
      es: 'Bielorrusia',
      ro: 'Bielorusia',
      bg: 'Беларус',
      de: 'Weißrussland',
      fr: 'Biélorussie',
      nl: 'Wit-Rusland',
      it: 'Bielorussia',
      cn: '白俄罗斯',
      zh: '白俄羅斯',
      ee: 'Valgevene',
      jp: 'ベラルーシ',
      he: 'בלארוס',
      el: 'Λευκορωσία',
    },
    dial_code: '+375',
    code: 'BY',
    flag: '🇧🇾',
  },
  {
    name: {
      en: 'Belgium',
      ru: 'Бельгия',
      pl: 'Belgia',
      ua: 'Бельгія',
      cz: 'Belgie',
      by: 'Бельгія',
      pt: 'Bélgica',
      es: 'Bélgica',
      ro: 'Belgia',
      bg: 'Белгия',
      de: 'Belgien',
      fr: 'Belgique',
      nl: 'Belgie',
      it: 'Belgio',
      cn: '比利时',
      zh: '比利時',
      ee: 'Belgia',
      jp: 'ベルギー',
      he: 'בלגיה',
      el: 'Βέλγιο',
    },
    dial_code: '+32',
    code: 'BE',
    flag: '🇧🇪',
  },
  {
    name: {
      en: 'Belize',
      ru: 'Белиз',
      pl: 'Belize',
      ua: 'Беліз',
      cz: 'Belize',
      by: 'Беліз',
      pt: 'Belize',
      es: 'Belice',
      ro: 'Belize',
      bg: 'Белиз',
      de: 'Belize',
      fr: 'Belize',
      nl: 'Belize',
      it: 'Belize',
      cn: '伯利兹',
      zh: '伯利茲',
      ee: 'Belize',
      jp: 'ベリーズ',
      he: 'בליז',
      el: 'Μπελίζ',
    },
    dial_code: '+501',
    code: 'BZ',
    flag: '🇧🇿',
  },
  {
    name: {
      en: 'Benin',
      ru: 'Бенин',
      pl: 'Benin',
      ua: 'Бенін',
      cz: 'Benin',
      by: 'Бенін',
      pt: 'Benim',
      es: 'Benín',
      ro: 'Benin',
      bg: 'Бенин',
      de: 'Benin',
      fr: 'Bénin',
      nl: 'Benin',
      it: 'Benin',
      cn: '贝宁',
      zh: '貝寧',
      ee: 'Benin',
      jp: 'ベナン',
      he: 'בנין',
      el: 'Μπενίν',
    },
    dial_code: '+229',
    code: 'BJ',
    flag: '🇧🇯',
  },
  {
    name: {
      en: 'Bermuda',
      ru: 'Бермуды',
      pl: 'Bermudy',
      ua: 'Бермуди',
      cz: 'Bermudy',
      by: 'Бярмуды',
      pt: 'Bermudas',
      es: 'Bermudas',
      ro: 'Bermuda',
      bg: 'Бермуди',
      de: 'Bermuda',
      fr: 'Bermudes',
      nl: 'Bermuda',
      it: 'Bermuda',
      cn: '百慕大',
      zh: '百慕大',
      ee: 'Bermuda',
      jp: 'バミューダ',
      he: 'ברמודה',
      el: 'Βερμούδες',
    },
    dial_code: '+1441',
    code: 'BM',
    flag: '🇧🇲',
  },
  {
    name: {
      en: 'Bhutan',
      ru: 'Бутан',
      pl: 'Bhutan',
      ua: 'Бутан',
      cz: 'Bhútán',
      by: 'Бутан',
      pt: 'Butão',
      es: 'Bután',
      ro: 'Bhutan',
      bg: 'Бутан',
      de: 'Bhutan',
      fr: 'Bhoutan',
      nl: 'Bhutan',
      it: 'Bhutan',
      cn: '不丹',
      zh: '不丹',
      ee: 'Bhutan',
      jp: 'ブータン',
      he: 'בהוטן',
      el: 'Μπουτάν',
    },
    dial_code: '+975',
    code: 'BT',
    flag: '🇧🇹',
  },
  {
    name: {
      en: 'Bolivia',
      ru: 'Боливия',
      pl: 'Boliwia',
      ua: 'Болівія',
      cz: 'Bolívie',
      by: 'Балівія',
      pt: 'Bolívia',
      es: 'Bolivia',
      ro: 'Bolivia',
      bg: 'Боливия',
      de: 'Bolivien',
      fr: 'Bolivie',
      nl: 'Bolivia',
      it: 'Bolivia',
      cn: '玻利维亚',
      zh: '玻利維亞',
      ee: 'Boliivia',
      jp: 'ボリビア',
      he: 'בוליביה',
      el: 'Βολιβία',
    },
    dial_code: '+591',
    code: 'BO',
    flag: '🇧🇴',
  },
  {
    name: {
      en: 'Bosnia and Herzegovina',
      ru: 'Босния и Герцеговина',
      pl: 'Bośnia i Hercegowina',
      ua: 'Боснія і Герцеговина',
      cz: 'Bosna a Hercegovina',
      by: 'Боснія і Герцагавіна',
      pt: 'Bósnia e Herzegovina',
      es: 'Bosnia y Herzegovina',
      ro: 'Bosnia si Hertegovina',
      bg: 'Босна и Херцеговина',
      de: 'Bosnien und Herzegowina',
      fr: 'Bosnie Herzégovine',
      nl: 'Bosnië-Herzegovina',
      it: 'Bosnia Erzegovina',
      cn: '波斯尼亚和黑塞哥维那（简称：波黑',
      zh: '波斯尼亞和黑塞哥維那（簡稱：波黑',
      ee: 'Bosnia ja Hertsegoviina',
      jp: 'ボスニア・ヘルツェゴビナ',
      he: 'בוסניה והרצגובינה',
      el: 'Βοσνία και Ερζεγοβίνη',
    },
    dial_code: '+387',
    code: 'BA',
    flag: '🇧🇦',
  },
  {
    name: {
      en: 'Botswana',
      ru: 'Ботсвана',
      pl: 'Botswana',
      ua: 'Ботсвана',
      cz: 'Botswana',
      by: 'Батсвана',
      pt: 'Botsuana',
      es: 'Botsuana',
      ro: 'Botswana',
      bg: 'Ботсвана',
      de: 'Botswana',
      fr: 'Botswana',
      nl: 'Botswana',
      it: 'Botswana',
      cn: '博茨瓦纳',
      zh: '博茨瓦納',
      ee: 'Botswana',
      jp: 'ボツワナ',
      he: 'בוצואנה',
      el: 'Μποτσουάνα',
    },
    dial_code: '+267',
    code: 'BW',
    flag: '🇧🇼',
  },
  {
    name: {
      en: 'Brazil',
      ru: 'Бразилия',
      pl: 'Brazylia',
      ua: 'Бразилія',
      cz: 'Brazílie',
      by: 'Бразілія',
      pt: 'Brasil',
      es: 'Brasil',
      ro: 'Brazilia',
      bg: 'Бразилия',
      de: 'Brasilien',
      fr: 'Brésil',
      nl: 'Brazilië',
      it: 'Brasile',
      cn: '巴西',
      zh: '巴西',
      ee: 'Brasiilia',
      jp: 'ブラジル',
      he: 'ברזיל',
      el: 'Βραζιλία',
    },
    dial_code: '+55',
    code: 'BR',
    flag: '🇧🇷',
  },
  {
    name: {
      en: 'British Indian Ocean Territory',
      ru: 'Британская территория Индийского океана',
      pl: 'Brytyjskie Terytorium Oceanu Indyjskiego',
      ua: 'Британська територія в Індійському океані',
      cz: 'Britské území v Indickém oceánu',
      by: 'Брытанская тэрыторыя ў Індыйскім акіяне',
      pt: 'Território Britânico do Oceano Índico',
      es: 'Territorio Británico del Océano Índico',
      ro: 'Teritoriul Britanic al Oceanului Indian',
      bg: 'Британска територия в Индийския океан',
      de: 'Britisches Territorium des Indischen Ozeans',
      fr: "Territoire britannique de l'océan Indien",
      nl: 'Brits-Indisch oceaan gebied',
      it: "Territorio britannico dell'Oceano Indiano",
      cn: '英属印度洋领地',
      zh: '英屬印度洋領地',
      ee: 'Briti India ookeani territoorium',
      jp: 'イギリス領インド洋地域',
      he: 'טריטוריית האוקיינוס ​​ההודי הבריטי',
      el: 'Βρετανικά Εδάφη Ινδικού Ωκεανού',
    },
    dial_code: '+246',
    code: 'IO',
    flag: '🇮🇴',
  },
  {
    name: {
      en: 'Brunei Darussalam',
      ru: 'Бруней-Даруссалам',
      pl: 'Brunei Darussalam',
      ua: 'Бруней-Даруссалам',
      cz: 'Brunej Darussalam',
      by: 'Бруней-Дарусалам',
      pt: 'Brunei',
      es: 'Brunei Darussalam',
      ro: 'Brunei Darussalam',
      bg: 'Бруней Дарусалам',
      de: 'Brunei Darussalam',
      fr: 'Brunei Darussalam',
      nl: 'Brunei Darussalam',
      it: 'Brunei Darussalam',
      cn: '文莱达鲁萨兰国',
      zh: '文萊達魯薩蘭國',
      ee: 'Brunei Darussalam',
      jp: 'ブルネイダルサラーム',
      he: 'ברוניי דארוסלאם',
      el: 'Μπρουνέι',
    },
    dial_code: '+673',
    code: 'BN',
    flag: '🇧🇳',
  },
  {
    name: {
      en: 'Bulgaria',
      ru: 'Болгария',
      pl: 'Bułgaria',
      ua: 'Болгарія',
      cz: 'Bulharsko',
      by: 'Балгарыя',
      pt: 'Bulgária',
      es: 'Bulgaria',
      ro: 'Bulgaria',
      bg: 'България',
      de: 'Bulgarien',
      fr: 'Bulgarie',
      nl: 'Bulgarije',
      it: 'Bulgaria',
      cn: '保加利亚',
      zh: '保加利亞',
      ee: 'Bulgaaria',
      jp: 'ブルガリア',
      he: 'בולגריה',
      el: 'Βουλγαρία',
    },
    dial_code: '+359',
    code: 'BG',
    flag: '🇧🇬',
  },
  {
    name: {
      en: 'Burkina Faso',
      ru: 'Буркина-Фасо',
      pl: 'Burkina Faso',
      ua: 'Буркіна-Фасо',
      cz: 'Burkina Faso',
      by: 'Буркіна-Фасо',
      pt: 'Burquina Faso',
      es: 'Burkina Faso',
      ro: 'Burkina Faso',
      bg: 'Буркина Фасо',
      de: 'Burkina Faso',
      fr: 'Burkina Faso',
      nl: 'Burkina Faso',
      it: 'Burkina Faso',
      cn: '布基纳法索',
      zh: '布基納法索',
      ee: 'Burkina Faso',
      jp: 'ブルキナファソ',
      he: 'בורקינה פאסו',
      el: 'Μπουρκίνα Φάσο',
    },
    dial_code: '+226',
    code: 'BF',
    flag: '🇧🇫',
  },
  {
    name: {
      en: 'Burundi',
      ru: 'Бурунди',
      pl: 'Burundi',
      ua: 'Бурунді',
      cz: 'Burundi',
      by: 'Бурундзі',
      pt: 'Burúndi',
      es: 'Burundi',
      ro: 'Burundi',
      bg: 'Бурунди',
      de: 'Burundi',
      fr: 'Burundi',
      nl: 'Burundi',
      it: 'Burundi',
      cn: '布隆迪',
      zh: '布隆迪',
      ee: 'Burundi',
      jp: 'ブルンジ',
      he: 'בורונדי',
      el: 'Μπουρούντι',
    },
    dial_code: '+257',
    code: 'BI',
    flag: '🇧🇮',
  },
  {
    name: {
      en: 'Cambodia',
      ru: 'Камбоджа',
      pl: 'Kambodża',
      ua: 'Камбоджа',
      cz: 'Kambodža',
      by: 'Камбоджа',
      pt: 'Camboja',
      es: 'Camboya',
      ro: 'Cambodgia',
      bg: 'Камбоджа',
      de: 'Kambodscha',
      fr: 'Cambodge',
      nl: 'Cambodja',
      it: 'Cambogia',
      cn: '柬埔寨',
      zh: '柬埔寨',
      ee: 'Kambodža',
      jp: 'カンボジア',
      he: 'קמבודיה',
      el: 'Καμπότζη',
    },
    dial_code: '+855',
    code: 'KH',
    flag: '🇰🇭',
  },
  {
    name: {
      en: 'Cameroon',
      ru: 'Камерун',
      pl: 'Kamerun',
      ua: 'Камерун',
      cz: 'Kamerun',
      by: 'Камерун',
      pt: 'Camarões',
      es: 'Camerún',
      ro: 'Camerun',
      bg: 'Камерун',
      de: 'Kamerun',
      fr: 'Cameroun',
      nl: 'Kameroen',
      it: 'Camerun',
      cn: '喀麦隆',
      zh: '喀麥隆',
      ee: 'Kamerun',
      jp: 'カメルーン',
      he: 'קמרון',
      el: 'Καμερούν',
    },
    dial_code: '+237',
    code: 'CM',
    flag: '🇨🇲',
  },
  {
    name: {
      en: 'Canada',
      ru: 'Канада',
      pl: 'Kanada',
      ua: 'Канада',
      cz: 'Kanada',
      by: 'Канада',
      pt: 'Canadá',
      es: 'Canadá',
      ro: 'Canada',
      bg: 'Канада',
      de: 'Kanada',
      fr: 'Canada',
      nl: 'Canada',
      it: 'Canada',
      cn: '加拿大',
      zh: '加拿大',
      ee: 'Kanada',
      jp: 'カナダ',
      he: 'קנדה',
      el: 'Καναδάς',
    },
    dial_code: '+1',
    code: 'CA',
    flag: '🇨🇦',
  },
  {
    name: {
      en: 'Cape Verde',
      ru: 'Кабо-Верде',
      pl: 'Wyspy Zielonego Przylądka',
      ua: 'Кабо-Верде',
      cz: 'Kapverdy',
      by: 'Каба-Вэрдэ',
      pt: 'Cabo Verde',
      es: 'Cabo Verde',
      ro: 'capul Verde',
      bg: 'Кабо Верде',
      de: 'Kap Verde',
      fr: 'Cap-Vert',
      nl: 'Kaapverdië',
      it: 'capo Verde',
      cn: '佛得角',
      zh: '佛得角',
      ee: 'Roheneemesaared',
      jp: 'カーボベルデ',
      he: 'קייפ ורדה',
      el: 'Πράσινο Ακρωτήριο',
    },
    dial_code: '+238',
    code: 'CV',
    flag: '🇨🇻',
  },
  {
    name: {
      en: 'Cayman Islands',
      ru: 'Каймановы острова',
      pl: 'Kajmany',
      ua: 'Кайманові острови',
      cz: 'Kajmanské ostrovy',
      by: 'Кайманавы выспы',
      pt: 'Ilhas Caimão',
      es: 'Islas Caimán',
      ro: 'Insulele Cayman',
      bg: 'Кайманови острови',
      de: 'Cayman Inseln',
      fr: 'Îles Caïmans',
      nl: 'Kaaiman Eilanden',
      it: 'Isole Cayman',
      cn: '开曼群岛',
      zh: '開曼群島',
      ee: 'Kaimani saared',
      jp: 'ケイマン諸島',
      he: 'איי קיימן',
      el: 'Νήσοι Κέιμαν',
    },
    dial_code: '+345',
    code: 'KY',
    flag: '🇰🇾',
  },
  {
    name: {
      en: 'Central African Republic',
      ru: 'Центрально-Африканская Республика',
      pl: 'Republika Środkowoafrykańska',
      ua: 'Центральноафриканська Республіка',
      cz: 'Středoafrická republika',
      by: 'Цэнтральна-Афрыканская Рэспубліка',
      pt: 'República Centro-Africana',
      es: 'República Centroafricana',
      ro: 'Republica Centrafricană',
      bg: 'Централноафриканска република',
      de: 'Zentralafrikanische Republik',
      fr: 'République centrafricaine',
      nl: 'Centraal Afrikaanse Republiek',
      it: 'Repubblica Centrafricana',
      cn: '中非共和国',
      zh: '中非共和國',
      ee: 'Kesk-Aafrika Vabariik',
      jp: '中央アフリカ共和国',
      he: 'הרפובליקה המרכז - אפריקאית',
      el: 'Κεντροαφρικανική Δημοκρατία',
    },
    dial_code: '+236',
    code: 'CF',
    flag: '🇨🇫',
  },
  {
    name: {
      en: 'Chad',
      ru: 'Чад',
      pl: 'Czad',
      ua: 'Чад',
      cz: 'Čad',
      by: 'Чад',
      pt: 'Chade',
      es: 'Chad',
      ro: 'Ciad',
      bg: 'Чад',
      de: 'Tschad',
      fr: 'Tchad',
      nl: 'Tsjaad',
      it: 'Chad',
      cn: '乍得',
      zh: '乍得',
      ee: 'Tšaad',
      jp: 'チャド',
      he: "צ'אד",
      el: 'Τσαντ',
    },
    dial_code: '+235',
    code: 'TD',
    flag: '🇹🇩',
  },
  {
    name: {
      en: 'Chile',
      ru: 'Чили',
      pl: 'Chile',
      ua: 'Чилі',
      cz: 'Chile',
      by: 'Чылі',
      pt: 'Chile',
      es: 'Chile',
      ro: 'Chile',
      bg: 'Чили',
      de: 'Chile',
      fr: 'Chili',
      nl: 'Chili',
      it: 'Chile',
      cn: '智利',
      zh: '智利',
      ee: 'Tšiili',
      jp: 'チリ',
      he: "צ'ילה",
      el: 'Χιλή',
    },
    dial_code: '+56',
    code: 'CL',
    flag: '🇨🇱',
  },
  {
    name: {
      en: 'China',
      ru: 'Китай',
      pl: 'Chiny',
      ua: 'Китай',
      cz: 'Čína',
      by: 'Кітай',
      pt: 'China',
      es: 'China',
      ro: 'China',
      bg: 'Китай',
      de: 'China',
      fr: 'Chine',
      nl: 'China',
      it: 'Cina',
      cn: '中国',
      zh: '中國',
      ee: 'Hiina',
      jp: '中国',
      he: 'חרסינה',
      el: 'Κίνα',
    },
    dial_code: '+86',
    code: 'CN',
    flag: '🇨🇳',
  },
  {
    name: {
      en: 'Christmas Island',
      ru: 'Остров Рождества',
      pl: 'Wyspa Bożego Narodzenia',
      ua: 'Острів Різдва',
      cz: 'Vánoční ostrov',
      by: 'Востраў Каляд',
      pt: 'Ilha do Natal',
      es: 'Isla de Navidad',
      ro: 'Insula Craciunului',
      bg: 'Коледен остров',
      de: 'Weihnachtsinsel',
      fr: "L'île de noël",
      nl: 'Kersteiland',
      it: 'Isola di Natale',
      cn: '圣诞岛',
      zh: '聖誕島',
      ee: 'Jõulusaar',
      jp: 'クリスマス島',
      he: 'אי חג המולד',
      el: 'Νήσος των Χριστουγέννων',
    },
    dial_code: '+61',
    code: 'CX',
    flag: '🇨🇽',
  },
  {
    name: {
      en: 'Cocos (Keeling) Islands',
      ru: 'Кокосовые (Килинг) острова',
      pl: 'Wyspy Kokosowe (Keelinga)',
      ua: 'Кокосові (Кілінг) острови',
      cz: 'Kokosové (Keelingovy) ostrovy',
      by: 'Какосавыя (Кілінскія) астравы',
      pt: 'Ilhas dos Cocos',
      es: 'Islas Cocos (Keeling)',
      ro: 'Insulele Cocos (Keeling)',
      bg: 'Кокосови (Кийлинг) острови',
      de: 'Kokosinseln (Keelinginseln)',
      fr: 'Îles Cocos (Keeling)',
      nl: 'Cocos (Keeling) eilanden',
      it: 'Isole Cocos (Keeling)',
      cn: '科科斯（基林）群岛',
      zh: '科科斯（基林）群島',
      ee: 'Kookossaared (Keelingi) saared',
      jp: 'ココス（キーリング）諸島',
      he: 'איי קוקוס (קילינג).',
      el: 'Νησιά Κόκος',
    },
    dial_code: '+61',
    code: 'CC',
    flag: '🇨🇨',
  },
  {
    name: {
      en: 'Colombia',
      ru: 'Колумбия',
      pl: 'Kolumbia',
      ua: 'Колумбія',
      cz: 'Kolumbie',
      by: 'Калумбія',
      pt: 'Colômbia',
      es: 'Colombia',
      ro: 'Columbia',
      bg: 'Колумбия',
      de: 'Kolumbien',
      fr: 'Colombie',
      nl: 'Colombia',
      it: 'Colombia',
      cn: '哥伦比亚',
      zh: '哥倫比亞',
      ee: 'Kolumbia',
      jp: 'コロンビア',
      he: 'קולומביה',
      el: 'Κολομβία',
    },
    dial_code: '+57',
    code: 'CO',
    flag: '🇨🇴',
  },
  {
    name: {
      en: 'Comoros',
      ru: 'Коморские острова',
      pl: 'Komory',
      ua: 'Коморські острови',
      cz: 'Komory',
      by: 'Каморскія выспы',
      pt: 'Comores',
      es: 'Comoras',
      ro: 'Comore',
      bg: 'Коморски острови',
      de: 'Komoren',
      fr: 'Comores',
      nl: 'Comoren',
      it: 'Comore',
      cn: '科摩罗',
      zh: '科摩羅',
      ee: 'Komoorid',
      jp: 'コモロ',
      he: 'קומורו',
      el: 'Κομόρες',
    },
    dial_code: '+269',
    code: 'KM',
    flag: '🇰🇲',
  },
  {
    name: {
      en: 'Congo',
      ru: 'Конго',
      pl: 'Kongo',
      ua: 'Конго',
      cz: 'Kongo',
      by: 'Конга',
      pt: 'Congo-Brazzaville',
      es: 'Congo',
      ro: 'Congo',
      bg: 'Конго',
      de: 'Kongo',
      fr: 'Congo',
      nl: 'Congo',
      it: 'Congo',
      cn: '刚果',
      zh: '剛果',
      ee: 'Kongo',
      jp: 'コンゴ',
      he: 'קונגו',
      el: 'Δημοκρατία του Κονγκό',
    },
    dial_code: '+242',
    code: 'CG',
    flag: '🇨🇬',
  },
  {
    name: {
      en: 'Congo, The Democratic Republic of the',
      ru: 'Конго, Демократическая Республика',
      pl: 'Kongo, Republika Demokratyczna',
      ua: 'Конго, Демократична Республіка',
      cz: 'Kongo, Demokratická Republika',
      by: 'Конга, Дэмакратычная Рэспубліка Конга',
      pt: 'Congo-Kinshasa',
      es: 'Congo, República Democrática del',
      ro: 'Congo, Republica Democrată Congo',
      bg: 'Конго, Демократична република Конго',
      de: 'Kongo, Demokratische Republik Kongo',
      fr: 'Congo, République démocratique du Congo',
      nl: 'Congo, Democratische Republiek Congo',
      it: 'Congo, Repubblica Democratica del Congo',
      cn: '刚果民主共和国',
      zh: '剛果民主共和國',
      ee: 'Kongo, Kongo Demokraatlik Vabariik',
      jp: 'コンゴ、コンゴ民主共和国',
      he: 'קונגו',
      el: 'Λαϊκή Δημοκρατία του Κονγκό',
    },
    dial_code: '+243',
    code: 'CD',
    flag: '🇨🇩',
  },
  {
    name: {
      en: 'Cook Islands',
      ru: 'Острова Кука',
      pl: 'Wyspy Cooka',
      ua: 'Острови Кука',
      cz: 'Cookovy ostrovy',
      by: 'Астравы Кука',
      pt: 'Ilhas Cook',
      es: 'Islas Cook',
      ro: 'Insulele Cook',
      bg: 'Острови Кук',
      de: 'Cookinseln',
      fr: 'les Îles Cook',
      nl: 'Cook Eilanden',
      it: 'Isole Cook',
      cn: '库克群岛',
      zh: '庫克群島',
      ee: 'Cooki saared',
      jp: 'クック諸島',
      he: 'איי קוק',
      el: 'Νήσοι Κουκ',
    },
    dial_code: '+682',
    code: 'CK',
    flag: '🇨🇰',
  },
  {
    name: {
      en: 'Costa Rica',
      ru: 'Коста-Рика',
      pl: 'Kostaryka',
      ua: 'Коста-Ріка',
      cz: 'Kostarika',
      by: 'Коста-Рыка',
      pt: 'Costa Rica',
      es: 'Costa Rica',
      ro: 'Costa Rica',
      bg: 'Коста Рика',
      de: 'Costa Rica',
      fr: 'Costa Rica',
      nl: 'Costa Rica',
      it: 'Costa Rica',
      cn: '哥斯达黎加',
      zh: '哥斯達黎加',
      ee: 'Costa Rica',
      jp: 'コスタリカ',
      he: 'קוסטה ריקה',
      el: 'Κόστα Ρίκα',
    },
    dial_code: '+506',
    code: 'CR',
    flag: '🇨🇷',
  },
  {
    name: {
      en: "Cote d'Ivoire",
      ru: "Кот-д'Ивуар",
      pl: 'Wybrzeże Kości Słoniowej',
      ua: "Кот-д'Івуар",
      cz: 'Pobřeží slonoviny',
      by: "Кот-д'Івуар",
      pt: 'Costa do Marfim',
      es: 'Costa de Marfil',
      ro: 'Coasta de Fildeș',
      bg: "Кот д'Ивоар",
      de: 'Elfenbeinküste',
      fr: "Côte d'Ivoire",
      nl: 'Ivoorkust',
      it: "Costa d'Avorio",
      cn: '科特迪瓦',
      zh: '科特迪瓦',
      ee: 'Elevandiluurannik',
      jp: 'コートジボワール',
      he: 'חוף השנהב',
      el: 'Ακτή Ελεφαντοστού',
    },
    dial_code: '+225',
    code: 'CI',
    flag: '🇨🇮',
  },
  {
    name: {
      en: 'Croatia',
      ru: 'Хорватия',
      pl: 'Chorwacja',
      ua: 'Хорватія',
      cz: 'Chorvatsko',
      by: 'Харватыя',
      pt: 'Croácia',
      es: 'Croacia',
      ro: 'Croaţia',
      bg: 'Хърватия',
      de: 'Kroatien',
      fr: 'Croatie',
      nl: 'Kroatië',
      it: 'Croazia',
      cn: '克罗地亚',
      zh: '克羅地亞',
      ee: 'Horvaatia',
      jp: 'クロアチア',
      he: 'קרואטיה',
      el: 'Κροατία',
    },
    dial_code: '+385',
    code: 'HR',
    flag: '🇭🇷',
  },
  {
    name: {
      en: 'Cuba',
      ru: 'Куба',
      pl: 'Kuba',
      ua: 'Куба',
      cz: 'Kuba',
      by: 'Куба',
      pt: 'Cuba',
      es: 'Cuba',
      ro: 'Cuba',
      bg: 'Куба',
      de: 'Kuba',
      fr: 'Cuba',
      nl: 'Cuba',
      it: 'Cuba',
      cn: '古巴',
      zh: '古巴',
      ee: 'Kuuba',
      jp: 'キューバ',
      he: 'קובה',
      el: 'Κούβα',
    },
    dial_code: '+53',
    code: 'CU',
    flag: '🇨🇺',
  },
  {
    name: {
      en: 'Cyprus',
      ru: 'Кипр',
      pl: 'Cypr',
      ua: 'Кіпр',
      cz: 'Kypr',
      by: 'Кіпр',
      pt: 'Chipre',
      es: 'Chipre',
      ro: 'Cipru',
      bg: 'Кипър',
      de: 'Zypern',
      fr: 'Chypre',
      nl: 'Cyprus',
      it: 'Cipro',
      cn: '塞浦路斯',
      zh: '塞浦路斯',
      ee: 'Küpros',
      jp: 'キプロス',
      he: 'קפריסין',
      el: 'Κύπρος',
    },
    dial_code: '+357',
    code: 'CY',
    flag: '🇨🇾',
  },
  {
    name: {
      en: 'Czech Republic',
      ru: 'Чехия',
      pl: 'Republika Czeska',
      ua: 'Чеська Республіка',
      cz: 'Česká republika',
      by: 'Чэская Рэспубліка',
      pt: 'República Checa',
      es: 'República Checa',
      ro: 'Republica Cehă',
      bg: 'Чехия',
      de: 'Tschechien',
      fr: 'République Tchèque',
      nl: 'Tsjechië',
      it: 'Repubblica Ceca',
      cn: '捷克共和国',
      zh: '捷克共和國',
      ee: 'Tšehhi Vabariik',
      jp: 'チェコ共和国',
      he: "הרפובליקה הצ'כית",
      el: 'Τσεχία',
    },
    dial_code: '+420',
    code: 'CZ',
    flag: '🇨🇿',
  },
  {
    name: {
      en: 'Denmark',
      ru: 'Дания',
      pl: 'Dania',
      ua: 'Данія',
      cz: 'Dánsko',
      by: 'Данія',
      pt: 'Dinamarca',
      es: 'Dinamarca',
      ro: 'Danemarca',
      bg: 'Дания',
      de: 'Dänemark',
      fr: 'Danemark',
      nl: 'Denemarken',
      it: 'Danimarca',
      cn: '丹麦',
      zh: '丹麥',
      ee: 'Taani',
      jp: 'デンマーク',
      he: 'דנמרק',
      el: 'Δανία',
    },
    dial_code: '+45',
    code: 'DK',
    flag: '🇩🇰',
  },
  {
    name: {
      en: 'Djibouti',
      ru: 'Джибути',
      pl: 'Dżibuti',
      ua: 'Джибуті',
      cz: 'Džibuti',
      by: 'Джыбуці',
      pt: 'Jibuti',
      es: 'Yibuti',
      ro: 'Djibouti',
      bg: 'Джибути',
      de: 'Dschibuti',
      fr: 'Djibouti',
      nl: 'Djibouti',
      it: 'Gibuti',
      cn: '吉布地',
      zh: '吉布地',
      ee: 'Djibouti',
      jp: 'ジブチ',
      he: "ג'יבוטי",
      el: 'Τζιμπουτί',
    },
    dial_code: '+253',
    code: 'DJ',
    flag: '🇩🇯',
  },
  {
    name: {
      en: 'Dominica',
      ru: 'Доминика',
      pl: 'Dominika',
      ua: 'Домініка',
      cz: 'Dominika',
      by: 'Дамініка',
      pt: 'Domínica',
      es: 'Dominica',
      ro: 'Dominica',
      bg: 'Доминика',
      de: 'Dominica',
      fr: 'Dominique',
      nl: 'Dominica',
      it: 'Dominica',
      cn: '多米尼加',
      zh: '多米尼加',
      ee: 'Dominica',
      jp: 'ドミニカ',
      he: 'דומיניקה',
      el: 'Δομινίκα',
    },
    dial_code: '+1767',
    code: 'DM',
    flag: '🇩🇲',
  },
  {
    name: {
      en: 'Dominican Republic',
      ru: 'Доминиканская Респблика',
      pl: 'Republika Dominikany',
      ua: 'Домініканська республіка',
      cz: 'Dominikánská republika',
      by: 'Дамініканская Рэспубліка',
      pt: 'República Dominicana',
      es: 'República Dominicana',
      ro: 'Republica Dominicana',
      bg: 'Доминиканска република',
      de: 'Dominikanische Republik',
      fr: 'République dominicaine',
      nl: 'Dominicaanse Republiek',
      it: 'Repubblica Dominicana',
      cn: '多明尼加共和国',
      zh: '多明尼加共和國',
      ee: 'Dominikaani Vabariik',
      jp: 'ドミニカ共和国',
      he: 'הרפובליקה הדומיניקנית',
      el: 'Δομινικανή Δημοκρατία',
    },
    dial_code: '+1849',
    code: 'DO',
    flag: '🇩🇴',
  },
  {
    name: {
      en: 'Ecuador',
      ru: 'Эквадор',
      pl: 'Ekwador',
      ua: 'Еквадор',
      cz: 'Ekvádor',
      by: 'Эквадор',
      pt: 'Equador',
      es: 'Ecuador',
      ro: 'Ecuador',
      bg: 'Еквадор',
      de: 'Ecuador',
      fr: 'Equateur',
      nl: 'Ecuador',
      it: 'Ecuador',
      cn: '厄瓜多尔',
      zh: '厄瓜多爾',
      ee: 'Ecuador',
      jp: 'エクアドル',
      he: 'אקוודור',
      el: 'Ισημερινός',
    },
    dial_code: '+593',
    code: 'EC',
    flag: '🇪🇨',
  },
  {
    name: {
      en: 'Egypt',
      ru: 'Египет',
      pl: 'Egipt',
      ua: 'Єгипет',
      cz: 'Egypt',
      by: 'Егіпет',
      pt: 'Egipto',
      es: 'Egipto',
      ro: 'Egipt',
      bg: 'Египет',
      de: 'Ägypten',
      fr: 'Egypte',
      nl: 'Egypte',
      it: 'Egitto',
      cn: '埃及',
      zh: '埃及',
      ee: 'Egiptus',
      jp: 'エジプト',
      he: 'מצרים',
      el: 'Αίγυπτος',
    },
    dial_code: '+20',
    code: 'EG',
    flag: '🇪🇬',
  },
  {
    name: {
      en: 'El Salvador',
      ru: 'Сальвадор',
      pl: 'Salwador',
      ua: 'Сальвадор',
      cz: 'El Salvador',
      by: 'Сальвадор',
      pt: 'Salvador',
      es: 'El Salvador',
      ro: 'El Salvador',
      bg: 'Ел Салвадор',
      de: 'El Salvador',
      fr: 'Le Salvador',
      nl: 'El Salvador',
      it: 'El Salvador',
      cn: '萨尔瓦多',
      zh: '薩爾瓦多',
      ee: 'El Salvador',
      jp: 'エルサルバドル',
      he: 'אל סלבדור',
      el: 'Ελ Σαλβαδόρ',
    },
    dial_code: '+503',
    code: 'SV',
    flag: '🇸🇻',
  },
  {
    name: {
      en: 'Equatorial Guinea',
      ru: 'Экваториальная Гвинея',
      pl: 'Gwinea Równikowa',
      ua: 'Екваторіальна Гвінея',
      cz: 'Rovníková Guinea',
      by: 'Экватарыяльная Гвінея',
      pt: 'Guiné Equatorial',
      es: 'Guinea Ecuatorial',
      ro: 'Guineea Ecuatorială',
      bg: 'Екваториална Гвинея',
      de: 'Äquatorialguinea',
      fr: 'Guinée Équatoriale',
      nl: 'Equatoriaal-Guinea',
      it: 'Guinea Equatoriale',
      cn: '赤道几内亚',
      zh: '赤道幾內亞',
      ee: 'Ekvatoriaalne Guinea',
      jp: '赤道ギニア',
      he: 'גיניאה המשוונית',
      el: 'Ισημερινή Γουινέα',
    },
    dial_code: '+240',
    code: 'GQ',
    flag: '🇬🇶',
  },
  {
    name: {
      en: 'Eritrea',
      ru: 'Эритрея',
      pl: 'Erytrea',
      ua: 'Еритрея',
      cz: 'Eritrea',
      by: 'Эрытрэя',
      pt: 'Eritreia',
      es: 'Eritrea',
      ro: 'Eritreea',
      bg: 'Еритрея',
      de: 'Eritrea',
      fr: 'Érythrée',
      nl: 'Eritrea',
      it: 'Eritrea',
      cn: '厄立特里亚',
      zh: '厄立特里亞',
      ee: 'Eritrea',
      jp: 'エリトリア',
      he: 'אריתריאה',
      el: 'Ερυθραία',
    },
    dial_code: '+291',
    code: 'ER',
    flag: '🇪🇷',
  },
  {
    name: {
      en: 'Estonia',
      ru: 'Эстония',
      pl: 'Estonia',
      ua: 'Естонія',
      cz: 'Estonsko',
      by: 'Эстонія',
      pt: 'Estónia',
      es: 'Estonia',
      ro: 'Estonia',
      bg: 'Естония',
      de: 'Estland',
      fr: 'Estonie',
      nl: 'Estland',
      it: 'Estonia',
      cn: '爱沙尼亚',
      zh: '愛沙尼亞',
      ee: 'Eesti',
      jp: 'エストニア',
      he: 'אסטוניה',
      el: 'Εσθονία',
    },
    dial_code: '+372',
    code: 'EE',
    flag: '🇪🇪',
  },
  {
    name: {
      en: 'Ethiopia',
      ru: 'Эфиопия',
      pl: 'Etiopia',
      ua: 'Ефіопія',
      cz: 'Etiopie',
      by: 'Эфіопія',
      pt: 'Etiópia',
      es: 'Etiopía',
      ro: 'Etiopia',
      bg: 'Етиопия',
      de: 'Äthiopien',
      fr: 'Ethiopie',
      nl: 'Ethiopië',
      it: 'Etiopia',
      cn: '埃塞俄比亚',
      zh: '埃塞俄比亞',
      ee: 'Etioopia',
      jp: 'エチオピア',
      he: 'אתיופיה',
      el: 'Αιθιοπία',
    },
    dial_code: '+251',
    code: 'ET',
    flag: '🇪🇹',
  },
  {
    name: {
      en: 'Falkland Islands (Malvinas)',
      ru: 'Фолклендские острова (Malvinas)',
      pl: 'Falklandy (Malwiny)',
      ua: 'Фолклендські острови (Мальвінські острови)',
      cz: 'Falklandské ostrovy (Malvíny)',
      by: 'Фолклендскія астравы (Мальвіна)',
      pt: 'Ilhas Falkland',
      es: 'Islas Malvinas (Falkland Islands)',
      ro: 'Insulele Falkland (Malvinas)',
      bg: 'Фолкландски острови (Малвински острови)',
      de: 'Falklandinseln (Malvinas)',
      fr: 'Îles Falkland (Malvinas)',
      nl: 'Falklandeilanden (Malvinas)',
      it: 'Isole Falkland (Malvinas)',
      cn: '福克兰群岛（马尔维纳斯）',
      zh: '福克蘭群島（馬爾維納斯）',
      ee: 'Falklandi saared (Malvinas)',
      jp: 'フォークランド諸島（マルビナス）',
      he: 'איי פוקלנד (מלווינאס)',
      el: 'Νήσοι Φώκλαντ',
    },
    dial_code: '+500',
    code: 'FK',
    flag: '🇫🇰',
  },
  {
    name: {
      en: 'Faroe Islands',
      ru: 'Фарерские острова',
      pl: 'Wyspy Owcze',
      ua: 'Фарерські острови',
      cz: 'Faerské ostrovy',
      by: 'Фарэрскія астравы',
      pt: 'Faroé',
      es: 'Islas Feroe',
      ro: 'Insulele Feroe',
      bg: 'Фарьорските острови',
      de: 'Färöer Inseln',
      fr: 'Îles Féroé',
      nl: 'Faeröer',
      it: 'Isole Faroe',
      cn: '法罗群岛',
      zh: '法羅群島',
      ee: 'Fääri saared',
      jp: 'フェロー諸島',
      he: 'איי פרו',
      el: 'Νήσοι Φερόες',
    },
    dial_code: '+298',
    code: 'FO',
    flag: '🇫🇴',
  },
  {
    name: {
      en: 'Fiji',
      ru: 'Фиджи',
      pl: 'Fidżi',
      ua: 'Фіджі',
      cz: 'Fidži',
      by: 'Фіджы',
      pt: 'Fiji',
      es: 'Fiyi',
      ro: 'Fiji',
      bg: 'Фиджи',
      de: 'Fidschi',
      fr: 'Fidji',
      nl: 'Fiji',
      it: 'Figi',
      cn: '斐济',
      zh: '斐濟',
      ee: 'Fidži',
      jp: 'フィジー',
      he: "פיג'י",
      el: 'Φίτζι',
    },
    dial_code: '+679',
    code: 'FJ',
    flag: '🇫🇯',
  },
  {
    name: {
      en: 'Finland',
      ru: 'Финляндия',
      pl: 'Finlandia',
      ua: 'Фінляндія',
      cz: 'Finsko',
      by: 'Фінляндыя',
      pt: 'Finlândia',
      es: 'Finlandia',
      ro: 'Finlanda',
      bg: 'Финландия',
      de: 'Finnland',
      fr: 'Finlande',
      nl: 'Finland',
      it: 'Finlandia',
      cn: '芬兰',
      zh: '芬蘭',
      ee: 'Soome',
      jp: 'フィンランド',
      he: 'פינלנד',
      el: 'Φινλανδία',
    },
    dial_code: '+358',
    code: 'FI',
    flag: '🇫🇮',
  },
  {
    name: {
      en: 'French Guiana',
      ru: 'Французская Гвиана',
      pl: 'Gujana Francuska',
      ua: 'Французька Гвіана',
      cz: 'Francouzská Guyana',
      by: 'Французская Гвіяна',
      pt: 'Guiana Francesa',
      es: 'Guayana Francesa',
      ro: 'Guyana Franceză',
      bg: 'Френска Гвиана',
      de: 'Französisch-Guayana',
      fr: 'Guyane Française',
      nl: 'Frans Guyana',
      it: 'Guiana francese',
      cn: '法属圭亚那',
      zh: '法屬圭亞那',
      ee: 'Prantsuse Guajaana',
      jp: 'フランス領ギアナ',
      he: 'גיאנה הצרפתית',
      el: 'Γαλλική Γουιάνα',
    },
    dial_code: '+594',
    code: 'GF',
    flag: '🇬🇫',
  },
  {
    name: {
      en: 'French Polynesia',
      ru: 'Французская Полинезия',
      pl: 'Polinezja Francuska',
      ua: 'Французька Полінезія',
      cz: 'Francouzská Polynésie',
      by: 'Французская Палінезія',
      pt: 'Polinésia Francesa',
      es: 'Polinesia Francesa',
      ro: 'Polinezia Franceză',
      bg: 'Френска полинезия',
      de: 'Französisch Polynesien',
      fr: 'Polynésie française',
      nl: 'Frans-Polynesië',
      it: 'Polinesia francese',
      cn: '法属波利尼西亚',
      zh: '法屬波利尼西亞',
      ee: 'Prantsuse Polüneesia',
      jp: 'フランス領ポリネシア',
      he: 'פולינזיה הצרפתית',
      el: 'Γαλλική Πολυνησία',
    },
    dial_code: '+689',
    code: 'PF',
    flag: '🇵🇫',
  },
  {
    name: {
      en: 'Gabon',
      ru: 'Габон',
      pl: 'Gabon',
      ua: 'Габон',
      cz: 'Gabon',
      by: 'Габон',
      pt: 'Gabão',
      es: 'Gabón',
      ro: 'Gabon',
      bg: 'Габон',
      de: 'Gabun',
      fr: 'Gabon',
      nl: 'Gabon',
      it: 'Gabon',
      cn: '加蓬',
      zh: '加蓬',
      ee: 'Gabon',
      jp: 'ガボン',
      he: 'גאבון',
      el: 'Γκαμπόν',
    },
    dial_code: '+241',
    code: 'GA',
    flag: '🇬🇦',
  },
  {
    name: {
      en: 'Gambia',
      ru: 'Гамбия',
      pl: 'Gambia',
      ua: 'Гамбія',
      cz: 'Gambie',
      by: 'Гамбія',
      pt: 'Gâmbia',
      es: 'Gambia',
      ro: 'Gambia',
      bg: 'Гамбия',
      de: 'Gambia',
      fr: 'Gambie',
      nl: 'Gambia',
      it: 'Gambia',
      cn: '冈比亚',
      zh: '岡比亞',
      ee: 'Gambia',
      jp: 'ガンビア',
      he: 'גמביה',
      el: 'Γκάμπια',
    },
    dial_code: '+220',
    code: 'GM',
    flag: '🇬🇲',
  },
  {
    name: {
      en: 'Georgia',
      ru: 'Грузия',
      pl: 'Gruzja',
      ua: 'Грузія',
      cz: 'Gruzie',
      by: 'Грузія',
      pt: 'Geórgia',
      es: 'Georgia',
      ro: 'Georgia',
      bg: 'Джорджия',
      de: 'Georgia',
      fr: 'Géorgie',
      nl: 'Georgië',
      it: 'Georgia',
      cn: '乔治亚州',
      zh: '喬治亞州',
      ee: 'Gruusia',
      jp: 'ジョージア',
      he: 'גאורגיה',
      el: 'Γεωργία',
    },
    dial_code: '+995',
    code: 'GE',
    flag: '🇬🇪',
  },
  {
    name: {
      en: 'Ghana',
      ru: 'Гана',
      pl: 'Ghana',
      ua: 'Гана',
      cz: 'Ghana',
      by: 'Гана',
      pt: 'Gana',
      es: 'Ghana',
      ro: 'Ghana',
      bg: 'Гана',
      de: 'Ghana',
      fr: 'Ghana',
      nl: 'Ghana',
      it: 'Ghana',
      cn: '加纳',
      zh: '加納',
      ee: 'Ghana',
      jp: 'ガーナ',
      he: 'גאנה',
      el: 'Γκάνα',
    },
    dial_code: '+233',
    code: 'GH',
    flag: '🇬🇭',
  },
  {
    name: {
      en: 'Gibraltar',
      ru: 'Гибралтар',
      pl: 'Gibraltar',
      ua: 'Гібралтар',
      cz: 'Gibraltar',
      by: 'Гібралтар',
      pt: 'Gibraltar',
      es: 'Gibraltar',
      ro: 'Gibraltar',
      bg: 'Гибралтар',
      de: 'Gibraltar',
      fr: 'Gibraltar',
      nl: 'Gibraltar',
      it: 'Gibilterra',
      cn: '直布罗陀',
      zh: '直布羅陀',
      ee: 'Gibraltar',
      jp: 'ジブラルタル',
      he: 'גיברלטר',
      el: 'Γιβραλτάρ',
    },
    dial_code: '+350',
    code: 'GI',
    flag: '🇬🇮',
  },
  {
    name: {
      en: 'Greece',
      ru: 'Греция',
      pl: 'Grecja',
      ua: 'Греція',
      cz: 'Řecko',
      by: 'Грэцыя',
      pt: 'Grécia',
      es: 'Grecia',
      ro: 'Grecia',
      bg: 'Гърция',
      de: 'Griechenland',
      fr: 'Grèce',
      nl: 'Griekenland',
      it: 'Grecia',
      cn: '希腊',
      zh: '希臘',
      ee: 'Kreeka',
      jp: 'ギリシャ',
      he: 'יוון',
      el: 'Ελλάδα',
    },
    dial_code: '+30',
    code: 'GR',
    flag: '🇬🇷',
  },
  {
    name: {
      en: 'Greenland',
      ru: 'Гренландия',
      pl: 'Grenlandia',
      ua: 'Гренландія',
      cz: 'Grenlandia',
      by: 'Грэнландыя',
      pt: 'Gronelândia',
      es: 'Groenlandia',
      ro: 'Groenlanda',
      bg: 'Гренландия',
      de: 'Grönland',
      fr: 'Groenland',
      nl: 'Groenland',
      it: 'Groenlandia',
      cn: '格陵兰',
      zh: '格陵蘭',
      ee: 'Gröönimaa',
      jp: 'グリーンランド',
      he: 'גרינלנד',
      el: 'Γροιλανδία',
    },
    dial_code: '+299',
    code: 'GL',
    flag: '🇬🇱',
  },
  {
    name: {
      en: 'Grenada',
      ru: 'Гренада',
      pl: 'Grenada',
      ua: 'Гренада',
      cz: 'Grenada',
      by: 'Грэнада',
      pt: 'Granada',
      es: 'Granada',
      ro: 'Grenada',
      bg: 'Гренада',
      de: 'Grenada',
      fr: 'Grenade',
      nl: 'Grenada',
      it: 'Grenada',
      cn: '格林纳达',
      zh: '格林納達',
      ee: 'Grenada',
      jp: 'グレナダ',
      he: 'גרנדה',
      el: 'Γρενάδα',
    },
    dial_code: '+1473',
    code: 'GD',
    flag: '🇬🇩',
  },
  {
    name: {
      en: 'Guadeloupe',
      ru: 'Гваделупа',
      pl: 'Gwadelupa',
      ua: 'Гваделупа',
      cz: 'Guadeloupe',
      by: 'Гвадэлупа',
      pt: 'Guadalupe',
      es: 'Guadalupe',
      ro: 'Guadelupa',
      bg: 'Гваделупа',
      de: 'Guadeloupe',
      fr: 'Guadeloupe',
      nl: 'Guadeloupe',
      it: 'Guadalupa',
      cn: '瓜德罗普岛',
      zh: '瓜德羅普島',
      ee: 'Guadeloupe',
      jp: 'グアドループ',
      he: 'גוואדלופ',
      el: 'Γουαδελούπη',
    },
    dial_code: '+590',
    code: 'GP',
    flag: '🇬🇵',
  },
  {
    name: {
      en: 'Guam',
      ru: 'Гуам',
      pl: 'Guam',
      ua: 'Гуам',
      cz: 'Guam',
      by: 'Гуам',
      pt: 'Guame',
      es: 'Guam',
      ro: 'Guam',
      bg: 'Гуам',
      de: 'Guam',
      fr: 'Guam',
      nl: 'Guam',
      it: 'Guam',
      cn: '关岛',
      zh: '關島',
      ee: 'Guam',
      jp: 'グアム',
      he: 'גואם',
      el: 'Γκουάμ',
    },
    dial_code: '+1671',
    code: 'GU',
    flag: '🇬🇺',
  },
  {
    name: {
      en: 'Guatemala',
      ru: 'Гватемала',
      pl: 'Gwatemala',
      ua: 'Гватемала',
      cz: 'Guatemala',
      by: 'Гватэмала',
      pt: 'Guatemala',
      es: 'Guatemala',
      ro: 'Guatemala',
      bg: 'Гватемала',
      de: 'Guatemala',
      fr: 'Guatemala',
      nl: 'Guatemala',
      it: 'Guatemala',
      cn: '危地马拉',
      zh: '危地馬拉',
      ee: 'Guatemala',
      jp: 'グアテマラ',
      he: 'גואטמלה',
      el: 'Γουατεμάλα',
    },
    dial_code: '+502',
    code: 'GT',
    flag: '🇬🇹',
  },
  {
    name: {
      en: 'United Kingdom',
      ru: 'Объединенное Королевство',
      pl: 'Zjednoczone Królestwo',
      ua: "Об'єднане Королівство",
      cz: 'Spojené království',
      by: 'Злучанае Каралеўства',
      pt: 'Reino Unido',
      es: 'Reino Unido',
      ro: 'Regatul Unit',
      bg: 'Великобритания',
      de: 'Vereinigtes Königreich',
      fr: 'Royaume-Uni',
      nl: 'Verenigd Koningkrijk',
      it: 'Regno Unito',
      cn: '英国',
      zh: '英國',
      ee: 'Ühendkuningriik',
      jp: 'イギリス',
      he: 'הממלכה המאוחדת',
      el: 'Ηνωμένο Βασίλειο',
    },
    dial_code: '+44',
    code: 'GB',
    flag: '🇬🇧',
  },
  {
    name: {
      en: 'Guernsey',
      ru: 'Гернси',
      pl: 'Guernsey',
      ua: 'Гернсі',
      cz: 'Guernsey',
      by: 'Гернсі',
      pt: 'Guernsey',
      es: 'Guernsey',
      ro: 'Guernsey',
      bg: 'Гернси',
      de: 'Guernsey',
      fr: 'Guernesey',
      nl: 'Guernsey',
      it: 'Guernsey',
      cn: '根西岛',
      zh: '根西島',
      ee: 'Guernsey',
      jp: 'ガーンジー',
      he: 'גרנזי',
      el: 'Γκέρνσεϊ',
    },
    dial_code: '+44',
    code: 'GG',
    flag: '🇬🇬',
  },
  {
    name: {
      en: 'Guinea',
      ru: 'Гвинея',
      pl: 'Gwinea',
      ua: 'Гвінея',
      cz: 'Guinea',
      by: 'Гвінея',
      pt: 'Guiné',
      es: 'Guinea',
      ro: 'Guineea',
      bg: 'Гвинея',
      de: 'Guinea',
      fr: 'Guinée',
      nl: 'Guinea',
      it: 'Guinea',
      cn: '几内亚',
      zh: '幾內亞',
      ee: 'Guinea',
      jp: 'ギニア',
      he: 'גינאה',
      el: 'Γουινέα',
    },
    dial_code: '+224',
    code: 'GN',
    flag: '🇬🇳',
  },
  {
    name: {
      en: 'Guinea-Bissau',
      ru: 'Гвинея-Бисау',
      pl: 'Gwinea Bissau',
      ua: 'Гвінея-Бісау',
      cz: 'Guinea-Bissau',
      by: 'Гвінея-Бісаў',
      pt: 'Guiné-Bissau',
      es: 'Guinea-Bissau',
      ro: 'Guineea-Bissau',
      bg: 'Гвинея-Бисау',
      de: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      nl: 'Guinee-Bissau',
      it: 'Guinea-Bissau',
      cn: '几内亚比绍',
      zh: '幾內亞比紹',
      ee: 'Guinea-Bissau',
      jp: 'ギニアビサウ',
      he: 'גינאה-ביסאו',
      el: 'Γουινέα-Μπισσάου',
    },
    dial_code: '+245',
    code: 'GW',
    flag: '🇬🇼',
  },
  {
    name: {
      en: 'Guyana',
      ru: 'Гайана',
      pl: 'Gujana',
      ua: 'Гайана',
      cz: 'Guyana',
      by: 'Гаяна',
      pt: 'Guiana',
      es: 'Guyana',
      ro: 'Guyana',
      bg: 'Гвиана',
      de: 'Guyana',
      fr: 'Guyane',
      nl: 'Guyana',
      it: 'Guyana',
      cn: '圭亚那',
      zh: '圭亞那',
      ee: 'Guajaana',
      jp: 'ガイアナ',
      he: 'גיאנה',
      el: 'Γουιάνα',
    },
    dial_code: '+595',
    code: 'GY',
    flag: '🇬🇾',
  },
  {
    name: {
      en: 'Haiti',
      ru: 'Гаити',
      pl: 'Haiti',
      ua: 'Гаїті',
      cz: 'Haiti',
      by: 'Гаіці',
      pt: 'Haiti',
      es: 'Haití',
      ro: 'Haiti',
      bg: 'Хаити',
      de: 'Haiti',
      fr: 'Haïti',
      nl: 'Haïti',
      it: 'Haiti',
      cn: '海地',
      zh: '海地',
      ee: 'Haiti',
      jp: 'ハイチ',
      he: 'האיטי',
      el: 'Αϊτή',
    },
    dial_code: '+509',
    code: 'HT',
    flag: '🇭🇹',
  },
  {
    name: {
      en: 'Holy See (Vatican City State)',
      ru: 'Святой Престол (Vatican City State)',
      pl: 'Stolica Apostolska (Państwo Watykańskie)',
      ua: 'Святий Престол (Ватикан)',
      cz: 'Svatý stolec (Vatikánský městský stát)',
      by: 'Святы Пасад (дзяржава Ватыкан)',
      pt: 'Vaticano',
      es: 'Santa Sede (Estado de la Ciudad del Vaticano)',
      ro: 'Sfântul Scaun (Statul Vatican)',
      bg: 'Свети престол (Ватикан)',
      de: 'Heiliger Stuhl (Staat der Vatikanstadt)',
      fr: 'Saint-Siège (État de la Cité du Vatican)',
      nl: 'Heilige Stoel (Vaticaanstad)',
      it: 'Santa Sede (Stato della Città del Vaticano)',
      cn: '罗马教廷（梵蒂冈城国）',
      zh: '羅馬教廷（梵蒂岡城國）',
      ee: 'Püha Tool (Vatikani linnriik)',
      jp: 'ホーリーシー（バチカン市国）',
      he: 'הכס הקדוש (מדינת הוותיקן)',
      el: 'Βατικανό',
    },
    dial_code: '+379',
    code: 'VA',
    flag: '🇻🇦',
  },
  {
    name: {
      en: 'Honduras',
      ru: 'Гондурас',
      pl: 'Honduras',
      ua: 'Гондурас',
      cz: 'Honduras',
      by: 'Гандурас',
      pt: 'Honduras',
      es: 'Honduras',
      ro: 'Honduras',
      bg: 'Хондурас',
      de: 'Honduras',
      fr: 'Honduras',
      nl: 'Honduras',
      it: 'Honduras',
      cn: '洪都拉斯',
      zh: '洪都拉斯',
      ee: 'Honduras',
      jp: 'ホンジュラス',
      he: 'הונדורס',
      el: 'Ονδούρα',
    },
    dial_code: '+504',
    code: 'HN',
    flag: '🇭🇳',
  },
  {
    name: {
      en: 'Hong Kong',
      ru: 'Гонконг',
      pl: 'Hongkong',
      ua: 'Гонконг',
      cz: 'Hongkong',
      by: 'Ганконг',
      pt: 'Hong Kong',
      es: 'Hong Kong',
      ro: 'Hong Kong',
      bg: 'Хонг Конг',
      de: 'Hongkong',
      fr: 'Hong Kong',
      nl: 'Hong Kong',
      it: 'Hong Kong',
      cn: '香港',
      zh: '香港',
      ee: 'Hongkong',
      jp: '香港',
      he: 'הונג קונג',
      el: 'Χονγκ Κονγκ',
    },
    dial_code: '+852',
    code: 'HK',
    flag: '🇭🇰',
  },
  {
    name: {
      en: 'Hungary',
      ru: 'Венгрия',
      pl: 'Węgry',
      ua: 'Угорщина',
      cz: 'Maďarsko',
      by: 'Венгрыя',
      pt: 'Hungria',
      es: 'Hungría',
      ro: 'Ungaria',
      bg: 'Унгария',
      de: 'Ungarn',
      fr: 'Hongrie',
      nl: 'Hongarije',
      it: 'Ungheria',
      cn: '匈牙利',
      zh: '匈牙利',
      ee: 'Ungari',
      jp: 'ハンガリー',
      he: 'הונגריה',
      el: 'Ουγγαρία',
    },
    dial_code: '+36',
    code: 'HU',
    flag: '🇭🇺',
  },
  {
    name: {
      en: 'Iceland',
      ru: 'Исландия',
      pl: 'Islandia',
      ua: 'Ісландія',
      cz: 'Island',
      by: 'Ісландыя',
      pt: 'Islândia',
      es: 'Islandia',
      ro: 'Islanda',
      bg: 'Исландия',
      de: 'Island',
      fr: 'Islande',
      nl: 'IJsland',
      it: 'Islanda',
      cn: '冰岛',
      zh: '冰島',
      ee: 'Island',
      jp: 'アイスランド',
      he: 'איסלנד',
      el: 'Ισλανδία',
    },
    dial_code: '+354',
    code: 'IS',
    flag: '🇮🇸',
  },
  {
    name: {
      en: 'India',
      ru: 'Индия',
      pl: 'Indie',
      ua: 'Індія',
      cz: 'Indie',
      by: 'Індыя',
      pt: 'Índia',
      es: 'India',
      ro: 'India',
      bg: 'Индия',
      de: 'Indien',
      fr: 'Inde',
      nl: 'India',
      it: 'India',
      cn: '印度',
      zh: '印度',
      ee: 'India',
      jp: 'インド',
      he: 'הוֹדוּ',
      el: 'Ινδία',
    },
    dial_code: '+91',
    code: 'IN',
    flag: '🇮🇳',
  },
  {
    name: {
      en: 'Indonesia',
      ru: 'Индонезия',
      pl: 'Indonezja',
      ua: 'Індонезія',
      cz: 'Indonésie',
      by: 'Інданезія',
      pt: 'Indonésia',
      es: 'Indonesia',
      ro: 'Indonezia',
      bg: 'Индонезия',
      de: 'Indonesien',
      fr: 'Indonésie',
      nl: 'Indonesië',
      it: 'Indonesia',
      cn: '印度尼西亚',
      zh: '印度尼西亞',
      ee: 'Indoneesia',
      jp: 'インドネシア',
      he: 'אינדונזיה',
      el: 'Ινδονησία',
    },
    dial_code: '+62',
    code: 'ID',
    flag: '🇮🇩',
  },
  {
    name: {
      en: 'Iran, Islamic Republic of',
      ru: 'Иран, Исламская Республика',
      pl: 'Iran, Republika Islamska',
      ua: 'Іран, Ісламська Республіка',
      cz: 'Írán, Islámská Republika',
      by: 'Іран, Ісламская Рэспубліка',
      pt: 'Irão',
      es: 'Irán, República Islámica de',
      ro: 'Iran (Republica Islamica a',
      bg: 'Иран, Ислямска република',
      de: 'Iran, Islamische Republik',
      fr: 'Iran (République islamique d',
      nl: 'Iran, Islamitische Republiek',
      it: 'Iran (Repubblica Islamica del',
      cn: '伊朗伊斯兰共和国',
      zh: '伊朗伊斯蘭共和國',
      ee: 'Iraan, Iraagi Islamivabariik',
      jp: 'イラン、イスラム共和国',
      he: 'איראן',
      el: 'Ιράν',
    },
    dial_code: '+98',
    code: 'IR',
    flag: '🇮🇷',
  },
  {
    name: {
      en: 'Iraq',
      ru: 'Ирак',
      pl: 'Irak',
      ua: 'Ірак',
      cz: 'Irák',
      by: 'Ірак',
      pt: 'Iraque',
      es: 'Irak',
      ro: 'Irak',
      bg: 'Ирак',
      de: 'Irak',
      fr: 'Irak',
      nl: 'Irak',
      it: 'Iraq',
      cn: '伊拉克',
      zh: '伊拉克',
      ee: 'Iraak',
      jp: 'イラク',
      he: 'עיראק',
      el: 'Ιράκ',
    },
    dial_code: '+964',
    code: 'IQ',
    flag: '🇮🇶',
  },
  {
    name: {
      en: 'Ireland',
      ru: 'Ирландия',
      pl: 'Irlandia',
      ua: 'Ірландія',
      cz: 'Irsko',
      by: 'Ірландыя',
      pt: 'Irlanda',
      es: 'Irlanda',
      ro: 'Irlanda',
      bg: 'Ирландия',
      de: 'Irland',
      fr: 'Irlande',
      nl: 'Ierland',
      it: 'Irlanda',
      cn: '爱尔兰',
      zh: '愛爾蘭',
      ee: 'Iirimaa',
      jp: 'アイルランド',
      he: 'אירלנד',
      el: 'Ιρλανδία',
    },
    dial_code: '+353',
    code: 'IE',
    flag: '🇮🇪',
  },
  {
    name: {
      en: 'Isle of Man',
      ru: 'Остров Мэн',
      pl: 'Wyspa Man',
      ua: 'Острів Мен',
      cz: 'Isle of Man',
      by: 'Востраў Мэн',
      pt: 'Ilha de Man',
      es: 'Isla de Man',
      ro: 'insula Barbatului',
      bg: 'Остров Ман',
      de: 'Isle of Man',
      fr: 'île de Man',
      nl: 'Isle of Man',
      it: 'Isola di Man',
      cn: '马恩岛',
      zh: '馬恩島',
      ee: 'Mani saar',
      jp: 'マン島',
      he: 'האי מאן',
      el: 'Νήσος Μαν',
    },
    dial_code: '+44',
    code: 'IM',
    flag: '🇮🇲',
  },
  {
    name: {
      en: 'Israel',
      ru: 'Израиль',
      pl: 'Izrael',
      ua: 'Ізраїль',
      cz: 'Izrael',
      by: 'Ізраіль',
      pt: 'Israel',
      es: 'Israel',
      ro: 'Israel',
      bg: 'Израел',
      de: 'Israel',
      fr: 'Israël',
      nl: 'Israël',
      it: 'Israele',
      cn: '以色列',
      zh: '以色列',
      ee: 'Iisrael',
      jp: 'イスラエル',
      he: 'ישראל',
      el: 'Ισραήλ',
    },
    dial_code: '+972',
    code: 'IL',
    flag: '🇮🇱',
  },
  {
    name: {
      en: 'Italy',
      ru: 'Италия',
      pl: 'Włochy',
      ua: 'Італія',
      cz: 'Itálie',
      by: 'Італія',
      pt: 'Itália',
      es: 'Italia',
      ro: 'Italia',
      bg: 'Италия',
      de: 'Italien',
      fr: 'Italie',
      nl: 'Italië',
      it: 'Italia',
      cn: '意大利',
      zh: '意大利',
      ee: 'Itaalia',
      jp: 'イタリア',
      he: 'איטליה',
      el: 'Ιταλία',
    },
    dial_code: '+39',
    code: 'IT',
    flag: '🇮🇹',
  },
  {
    name: {
      en: 'Jamaica',
      ru: 'Ямайка',
      pl: 'Jamajka',
      ua: 'Ямайка',
      cz: 'Jamaica',
      by: 'Ямайка',
      pt: 'Jamaica',
      es: 'Jamaica',
      ro: 'Jamaica',
      bg: 'Ямайка',
      de: 'Jamaika',
      fr: 'Jamaïque',
      nl: 'Jamaica',
      it: 'Giamaica',
      cn: '的牙买加',
      zh: '的牙買加',
      ee: 'Jamaica',
      jp: 'ジャマイカ',
      he: "ג'מייקה",
      el: 'Τζαμάικα',
    },
    dial_code: '+1876',
    code: 'JM',
    flag: '🇯🇲',
  },
  {
    name: {
      en: 'Japan',
      ru: 'Япония',
      pl: 'Japonia',
      ua: 'Японія',
      cz: 'Japonsko',
      by: 'Японія',
      pt: 'Japão',
      es: 'Japón',
      ro: 'Japonia',
      bg: 'Япония',
      de: 'Japan',
      fr: 'Japon',
      nl: 'Japan',
      it: 'Giappone',
      cn: '日本',
      zh: '日本',
      ee: 'Jaapan',
      jp: '日本',
      he: 'יפן',
      el: 'Ιαπωνία',
    },
    dial_code: '+81',
    code: 'JP',
    flag: '🇯🇵',
  },
  {
    name: {
      en: 'Jersey',
      ru: 'Джерси',
      pl: 'Jersey',
      ua: 'Джерсі',
      cz: 'Trikot',
      by: 'Джэрсі',
      pt: 'Jersey',
      es: 'Jersey',
      ro: 'Jersey',
      bg: 'Джърси',
      de: 'Jersey',
      fr: 'Jersey',
      nl: 'Jersey',
      it: 'Jersey',
      cn: '球衣',
      zh: '球衣',
      ee: 'Jersey',
      jp: 'ジャージー',
      he: "ג'רזי",
      el: 'Τζέρσεϊ',
    },
    dial_code: '+44',
    code: 'JE',
    flag: '🇯🇪',
  },
  {
    name: {
      en: 'Jordan',
      ru: 'Иордания',
      pl: 'Jordania',
      ua: 'Йорданія',
      cz: 'Jordán',
      by: 'Іарданія',
      pt: 'Jordânia',
      es: 'Jordania',
      ro: 'Iordania',
      bg: 'Йордания',
      de: 'Jordanien',
      fr: 'Jordan',
      nl: 'Jordanië',
      it: 'Giordania',
      cn: '约旦',
      zh: '約旦',
      ee: 'Jordaania',
      jp: 'ヨルダン',
      he: 'ירדן',
      el: 'Ιορδανία',
    },
    dial_code: '+962',
    code: 'JO',
    flag: '🇯🇴',
  },
  {
    name: {
      en: 'Kazakhstan',
      ru: 'Казахстан',
      pl: 'Kazachstan',
      ua: 'Казахстан',
      cz: 'Kazachstán',
      by: 'Казахстан',
      pt: 'Cazaquistão',
      es: 'Kazajstán',
      ro: 'Kazahstan',
      bg: 'Казахстан',
      de: 'Kasachstan',
      fr: 'Kazakhstan',
      nl: 'Kazachstan',
      it: 'Kazakistan',
      cn: '哈萨克斯坦',
      zh: '哈薩克斯坦',
      ee: 'Kasahstan',
      jp: 'カザフスタン',
      he: 'קזחסטן',
      el: 'Καζακστάν',
    },
    dial_code: '+77',
    code: 'KZ',
    flag: '🇰🇿',
  },
  {
    name: {
      en: 'Kenya',
      ru: 'Кения',
      pl: 'Kenia',
      ua: 'Кенія',
      cz: 'Keňa',
      by: 'Кенія',
      pt: 'Quénia',
      es: 'Kenia',
      ro: 'Kenya',
      bg: 'Кения',
      de: 'Kenia',
      fr: 'Kenya',
      nl: 'Kenia',
      it: 'Kenya',
      cn: '肯尼亚',
      zh: '肯尼亞',
      ee: 'Keenia',
      jp: 'ケニア',
      he: 'קניה',
      el: 'Κένυα',
    },
    dial_code: '+254',
    code: 'KE',
    flag: '🇰🇪',
  },
  {
    name: {
      en: 'Kiribati',
      ru: 'Кирибати',
      pl: 'Kiribati',
      ua: 'Kiribati',
      cz: 'Kiribati',
      by: 'Кірыбаці',
      pt: 'Quiribáti',
      es: 'Kiribati',
      ro: 'Kiribati',
      bg: 'Кирибати',
      de: 'Kiribati',
      fr: 'Kiribati',
      nl: 'Kiribati',
      it: 'Kiribati',
      cn: '基里巴斯',
      zh: '基里巴斯',
      ee: 'Kiribati',
      jp: 'キリバス',
      he: 'קיריבטי',
      el: 'Κιριμπάτι',
    },
    dial_code: '+686',
    code: 'KI',
    flag: '🇰🇮',
  },
  {
    name: {
      en: "Korea, Democratic People's Republic of",
      ru: 'Корея, Народно-Демократическая Республика',
      pl: 'Korea, Republika Ludowo-Demokratyczna',
      ua: 'Корея, Народно-Демократична Республіка',
      cz: 'Korea, Demokratická lidová republika',
      by: 'Карэя, Народна-Дэмакратычная Рэспубліка',
      pt: 'Coreia do Norte',
      es: 'Corea, República Popular Democrática de',
      ro: 'Coreea, Republica Populară Democrată din',
      bg: 'Корея, Демократична народна република',
      de: 'Korea, Demokratische Volksrepublik',
      fr: 'République populaire démocratique de Corée',
      nl: 'Korea, Democratische Volksrepubliek',
      it: 'Corea, Repubblica Popolare Democratica di',
      cn: '韩国，朝鲜民主主义人民共和国',
      zh: '韓國，朝鮮民主主義人民共和國',
      ee: 'Korea, Korea Rahvademokraatlik Vabariik',
      jp: '韓国、朝鮮民主主義人民共和国',
      he: 'קוריאה הצפונית',
      el: 'Βόρεια Κορέα',
    },
    dial_code: '+850',
    code: 'KP',
    flag: '🇰🇵',
  },
  {
    name: {
      en: 'Korea, Republic of',
      ru: 'Республика Корея',
      pl: 'Republika Korei',
      ua: 'Корея, Республіка',
      cz: 'Korea, republika',
      by: 'Карэя, Рэспубліка',
      pt: 'Coreia do Sul',
      es: 'Corea, República de',
      ro: 'Republica Coreea',
      bg: 'Република Корея',
      de: 'Korea, Republik von',
      fr: 'Corée, République de',
      nl: 'Korea, republiek van',
      it: 'Corea, Repubblica di',
      cn: '韩国',
      zh: '韓國',
      ee: 'Korea Vabariik',
      jp: '大韓民国',
      he: 'קוריאה הדרומית',
      el: 'Νότια Κορέα',
    },
    dial_code: '+82',
    code: 'KR',
    flag: '🇰🇷',
  },
  {
    name: {
      en: 'Kuwait',
      ru: 'Кувейт',
      pl: 'Kuwejt',
      ua: 'Кувейт',
      cz: 'Kuvajt',
      by: 'Кувейт',
      pt: 'Kuwait',
      es: 'Kuwait',
      ro: 'Kuweit',
      bg: 'Кувейт',
      de: 'Kuwait',
      fr: 'Koweit',
      nl: 'Koeweit',
      it: 'Kuwait',
      cn: '科威特',
      zh: '科威特',
      ee: 'Kuveit',
      jp: 'クウェート',
      he: 'כווית',
      el: 'Κουβέιτ',
    },
    dial_code: '+965',
    code: 'KW',
    flag: '🇰🇼',
  },
  {
    name: {
      en: 'Kyrgyzstan',
      ru: 'Kyrgyzstan',
      pl: 'Kirgistan',
      ua: 'Киргизстан',
      cz: 'Kyrgyzstán',
      by: 'Кыргызстан',
      pt: 'Quirguizistão',
      es: 'Kirguistán',
      ro: 'Kârgâzstan',
      bg: 'Киргизстан',
      de: 'Kirgisistan',
      fr: 'Kirghizistan',
      nl: 'Kirgizië',
      it: 'Kirghizistan',
      cn: '吉尔吉斯斯坦',
      zh: '吉爾吉斯斯坦',
      ee: 'Kõrgõzstan',
      jp: 'キルギスタン',
      he: 'קירגיזסטן',
      el: 'Κιργιζία',
    },
    dial_code: '+996',
    code: 'KG',
    flag: '🇰🇬',
  },
  {
    name: {
      en: "Lao People's Democratic Republic",
      ru: 'Лаосская Народно-Демократическая Республика',
      pl: 'Laotańska Republika Ludowo-Demokratyczna',
      ua: 'Лаоська Народно-Демократична Республіка',
      cz: 'Laoská lidově demokratická republika',
      by: 'Лаоская Народна-Дэмакратычная Рэспубліка',
      pt: 'Laos',
      es: 'República Democrática Popular de Laos',
      ro: 'Republica Populară Democrată Laos',
      bg: 'Лаосска народнодемократична република',
      de: 'Demokratische Volksrepublik Laos',
      fr: 'République démocratique populaire lao',
      nl: 'Lao Democratische Volksrepubliek',
      it: 'Repubblica Democratica Popolare del Laos',
      cn: '老挝人民民主共和国',
      zh: '老撾人民民主共和國',
      ee: 'Laose Demokraatlik Rahvavabariik',
      jp: 'ラオス人民民主共和国',
      he: 'הרפובליקה הדמוקרטית העממית של לאו',
      el: 'Λάος',
    },
    dial_code: '+856',
    code: 'LA',
    flag: '🇱🇦',
  },
  {
    name: {
      en: 'Latvia',
      ru: 'Латвия',
      pl: 'Łotwa',
      ua: 'Латвія',
      cz: 'Lotyšsko',
      by: 'Латвія',
      pt: 'Letónia',
      es: 'Letonia',
      ro: 'Letonia',
      bg: 'Латвия',
      de: 'Lettland',
      fr: 'Lettonie',
      nl: 'Letland',
      it: 'Lettonia',
      cn: '拉脱维亚',
      zh: '拉脫維亞',
      ee: 'Läti',
      jp: 'ラトビア',
      he: 'לטביה',
      el: 'Λετονία',
    },
    dial_code: '+371',
    code: 'LV',
    flag: '🇱🇻',
  },
  {
    name: {
      en: 'Lebanon',
      ru: 'Ливан',
      pl: 'Liban',
      ua: 'Ліван',
      cz: 'Libanon',
      by: 'Ліван',
      pt: 'Líbano',
      es: 'Líbano',
      ro: 'Liban',
      bg: 'Ливан',
      de: 'Libanon',
      fr: 'Liban',
      nl: 'Libanon',
      it: 'Libano',
      cn: '黎巴嫩的',
      zh: '黎巴嫩的',
      ee: 'Liibanon',
      jp: 'レバノン',
      he: 'לבנון',
      el: 'Λίβανος',
    },
    dial_code: '+961',
    code: 'LB',
    flag: '🇱🇧',
  },
  {
    name: {
      en: 'Lesotho',
      ru: 'Лесото',
      pl: 'Lesoto',
      ua: 'Лесото',
      cz: 'Lesotho',
      by: 'Лесота',
      pt: 'Lesoto',
      es: 'Lesotho',
      ro: 'Lesotho',
      bg: 'Лесото',
      de: 'Lesotho',
      fr: 'Lesotho',
      nl: 'Lesotho',
      it: 'Lesotho',
      cn: '莱索托',
      zh: '萊索托',
      ee: 'Lesotho',
      jp: 'レソト',
      he: 'לסוטו',
      el: 'Λεσότο',
    },
    dial_code: '+266',
    code: 'LS',
    flag: '🇱🇸',
  },
  {
    name: {
      en: 'Liberia',
      ru: 'Либерия',
      pl: 'Liberia',
      ua: 'Ліберія',
      cz: 'Libérie',
      by: 'Ліберыя',
      pt: 'Libéria',
      es: 'Liberia',
      ro: 'Liberia',
      bg: 'Либерия',
      de: 'Liberia',
      fr: 'Libéria',
      nl: 'Liberia',
      it: 'Liberia',
      cn: '利比里亚',
      zh: '利比里亞',
      ee: 'Libeeria',
      jp: 'リベリア',
      he: 'ליבריה',
      el: 'Λιβερία',
    },
    dial_code: '+231',
    code: 'LR',
    flag: '🇱🇷',
  },
  {
    name: {
      en: 'Libyan Arab Jamahiriya',
      ru: 'Ливийская Арабская Джамахирия',
      pl: 'Libijska Arabska Dżamahirija',
      ua: 'Лівійська Арабська Джамахірія',
      cz: 'Libyjský Arab Jamahiriya',
      by: 'Лівійская Арабская Джамахірыя',
      pt: 'Líbia',
      es: 'Jamahiriya Árabe Libia',
      ro: 'Jamahiriya arabă libiană',
      bg: 'Либийска арабска Джамахирия',
      de: 'Libyscher arabischer Jamahiriya',
      fr: 'Jamahiriya arabe libyenne',
      nl: 'Libië',
      it: 'Giamahiria araba libica',
      cn: '阿拉伯利比亚民众国',
      zh: '阿拉伯利比亞民眾國',
      ee: 'Liibüa Araabia Jamahiriya',
      jp: 'リビアアラブジャマヒリヤ',
      he: "ג'מהיריה הערבית הלובית",
      el: 'Λιβύη',
    },
    dial_code: '+218',
    code: 'LY',
    flag: '🇱🇾',
  },
  {
    name: {
      en: 'Liechtenstein',
      ru: 'Лихтенштейн',
      pl: 'Liechtenstein',
      ua: 'Ліхтенштейн',
      cz: 'Lichtenštejnsko',
      by: 'Ліхтэнштэйн',
      pt: 'Listenstaine',
      es: 'Liechtenstein',
      ro: 'Liechtenstein',
      bg: 'Лихтенщайн',
      de: 'Liechtenstein',
      fr: 'Liechtenstein',
      nl: 'Liechtenstein',
      it: 'Liechtenstein',
      cn: '列支敦士登',
      zh: '列支敦士登',
      ee: 'Liechtenstein',
      jp: 'リヒテンシュタイン',
      he: 'ליכטנשטיין',
      el: 'Λίχτενσταϊν',
    },
    dial_code: '+423',
    code: 'LI',
    flag: '🇱🇮',
  },
  {
    name: {
      en: 'Lithuania',
      ru: 'Литва',
      pl: 'Litwa',
      ua: 'Литва',
      cz: 'Litva',
      by: 'Літва',
      pt: 'Lituânia',
      es: 'Lituania',
      ro: 'Lituania',
      bg: 'Литва',
      de: 'Litauen',
      fr: 'Lituanie',
      nl: 'Litouwen',
      it: 'Lituania',
      cn: '立陶宛',
      zh: '立陶宛',
      ee: 'Leedu',
      jp: 'リトアニア',
      he: 'ליטא',
      el: 'Λιθουανία',
    },
    dial_code: '+370',
    code: 'LT',
    flag: '🇱🇹',
  },
  {
    name: {
      en: 'Luxembourg',
      ru: 'Люксембург',
      pl: 'Luksemburg',
      ua: 'Люксембург',
      cz: 'Lucembursko',
      by: 'Люксембург',
      pt: 'Luxemburgo',
      es: 'Luxemburgo',
      ro: 'Luxemburg',
      bg: 'Люксембург',
      de: 'Luxemburg',
      fr: 'Luxembourg',
      nl: 'Luxemburg',
      it: 'Lussemburgo',
      cn: '卢森堡',
      zh: '盧森堡',
      ee: 'Luksemburg',
      jp: 'ルクセンブルク',
      he: 'לוקסמבורג',
      el: 'Λουξεμβούργο',
    },
    dial_code: '+352',
    code: 'LU',
    flag: '🇱🇺',
  },
  {
    name: {
      en: 'Macao',
      ru: 'Макао',
      pl: 'Makao',
      ua: 'Макао',
      cz: 'Macao',
      by: 'Макао',
      pt: 'Macau',
      es: 'Macao',
      ro: 'Macao',
      bg: 'Макао',
      de: 'Macao',
      fr: 'Macao',
      nl: 'Macao',
      it: 'Macao',
      cn: '澳门',
      zh: '澳門',
      ee: 'Aomen',
      jp: 'マカオ',
      he: 'מקאו',
      el: 'Μακάου',
    },
    dial_code: '+853',
    code: 'MO',
    flag: '🇲🇴',
  },
  {
    name: {
      en: 'Macedonia, The Former Yugoslav Republic of',
      ru: 'Македония, Бывшая Югославская Республика',
      pl: 'Macedonia, Była Jugosłowiańska Republika',
      ua: 'Македонія, колишня югославська республіка',
      cz: 'Makedonie, Bývalá jugoslávská republika',
      by: 'Македонія, былая югаслаўская рэспубліка',
      pt: 'Macedónia',
      es: 'Macedonia, Antigua República Yugoslava de',
      ro: 'Macedonia, Fosta Republică Iugoslavă a',
      bg: 'Македония, бившата югославска република',
      de: 'Mazedonien, die ehemalige jugoslawische Republik',
      fr: 'Macédoine, ancienne République yougoslave de',
      nl: 'Macedonië, de voormalige Joegoslavische Republiek',
      it: 'Macedonia, ex Repubblica iugoslava di',
      cn: '前南斯拉夫共和国马其顿',
      zh: '前南斯拉夫共和國馬其頓',
      ee: 'Makedoonia, endine Jugoslaavia Makedoonia Vabariik',
      jp: 'マケドニア、旧ユーゴスラビア共和国',
      he: 'מקדוניה',
      el: 'Βόρεια Μακεδονία',
    },
    dial_code: '+389',
    code: 'MK',
    flag: '🇲🇰',
  },
  {
    name: {
      en: 'Madagascar',
      ru: 'Мадагаскар',
      pl: 'Madagaskar',
      ua: 'Мадагаскар',
      cz: 'Madagaskar',
      by: 'Мадагаскар',
      pt: 'Madagáscar',
      es: 'Madagascar',
      ro: 'Madagascar',
      bg: 'Мадагаскар',
      de: 'Madagaskar',
      fr: 'Madagascar',
      nl: 'Madagascar',
      it: 'Madagascar',
      cn: '马达加斯加',
      zh: '馬達加斯加',
      ee: 'Madagaskar',
      jp: 'マダガスカル',
      he: 'מדגסקר',
      el: 'Μαδαγασκάρη',
    },
    dial_code: '+261',
    code: 'MG',
    flag: '🇲🇬',
  },
  {
    name: {
      en: 'Malawi',
      ru: 'Малави',
      pl: 'Malawi',
      ua: 'Малаві',
      cz: 'Malawi',
      by: 'Малаві',
      pt: 'Malávi',
      es: 'Malawi',
      ro: 'Malawi',
      bg: 'Малави',
      de: 'Malawi',
      fr: 'Malawi',
      nl: 'Malawi',
      it: 'Malawi',
      cn: '马拉维',
      zh: '馬拉維',
      ee: 'Malawi',
      jp: 'マラウイ',
      he: 'מלאווי',
      el: 'Μαλάουι',
    },
    dial_code: '+265',
    code: 'MW',
    flag: '🇲🇼',
  },
  {
    name: {
      en: 'Malaysia',
      ru: 'Малайзия',
      pl: 'Malezja',
      ua: 'Малайзія',
      cz: 'Malajsie',
      by: 'Малайзія',
      pt: 'Malásia',
      es: 'Malasia',
      ro: 'Malaezia',
      bg: 'Малайзия',
      de: 'Malaysia',
      fr: 'Malaisie',
      nl: 'Maleisië',
      it: 'Malaysia',
      cn: '马来西亚',
      zh: '馬來西亞',
      ee: 'Malaisia',
      jp: 'マレーシア',
      he: 'מלזיה',
      el: 'Μαλαισία',
    },
    dial_code: '+60',
    code: 'MY',
    flag: '🇲🇾',
  },
  {
    name: {
      en: 'Maldives',
      ru: 'Мальдивы',
      pl: 'Malediwy',
      ua: 'Мальдіви',
      cz: 'Maledivy',
      by: 'Мальдывы',
      pt: 'Maldivas',
      es: 'Maldivas',
      ro: 'Maldive',
      bg: 'Малдивите',
      de: 'Malediven',
      fr: 'Maldives',
      nl: 'Maldiven',
      it: 'Maldive',
      cn: '马尔代夫',
      zh: '馬爾代夫',
      ee: 'Maldiivid',
      jp: 'モルディブ',
      he: 'המלדיביים',
      el: 'Μαλδίβες',
    },
    dial_code: '+960',
    code: 'MV',
    flag: '🇲🇻',
  },
  {
    name: {
      en: 'Mali',
      ru: 'Мали',
      pl: 'Mali',
      ua: 'Малі',
      cz: 'Mali',
      by: 'Малі',
      pt: 'Mali',
      es: 'Malí',
      ro: 'Mali',
      bg: 'Мали',
      de: 'Mali',
      fr: 'Mali',
      nl: 'Mali',
      it: 'Mali',
      cn: '马里',
      zh: '馬里',
      ee: 'Mali',
      jp: 'マリ',
      he: 'מלי',
      el: 'Μάλι',
    },
    dial_code: '+223',
    code: 'ML',
    flag: '🇲🇱',
  },
  {
    name: {
      en: 'Malta',
      ru: 'Мальта',
      pl: 'Malta',
      ua: 'Мальта',
      cz: 'Malta',
      by: 'Мальта',
      pt: 'Malta',
      es: 'Malta',
      ro: 'Malta',
      bg: 'Малта',
      de: 'Malta',
      fr: 'Malte',
      nl: 'Malta',
      it: 'Malta',
      cn: '马耳他',
      zh: '馬耳他',
      ee: 'Malta',
      jp: 'マルタ',
      he: 'מלטה',
      el: 'Μάλτα',
    },
    dial_code: '+356',
    code: 'MT',
    flag: '🇲🇹',
  },
  {
    name: {
      en: 'Marshall Islands',
      ru: 'Маршалловы острова',
      pl: 'Wyspy Marshalla',
      ua: 'Маршаллові острови',
      cz: 'Marshallovy ostrovy',
      by: 'Маршалавы выспы',
      pt: 'Ilhas Marshall',
      es: 'Islas Marshall',
      ro: 'Insulele Marshall',
      bg: 'Маршалови острови',
      de: 'Marshallinseln',
      fr: 'Iles Marshall',
      nl: 'Marshall eilanden',
      it: 'Isole Marshall',
      cn: '马绍尔群岛',
      zh: '馬紹爾群島',
      ee: 'Marshalli saared',
      jp: 'マーシャル諸島',
      he: 'איי מרשל',
      el: 'Νήσοι Μάρσαλ',
    },
    dial_code: '+692',
    code: 'MH',
    flag: '🇲🇭',
  },
  {
    name: {
      en: 'Martinique',
      ru: 'Мартиника',
      pl: 'Martynika',
      ua: 'Мартініка',
      cz: 'Martinik',
      by: 'Марцініка',
      pt: 'Martinica',
      es: 'Martinica',
      ro: 'Martinica',
      bg: 'Мартиника',
      de: 'Martinique',
      fr: 'Martinique',
      nl: 'Martinique',
      it: 'Martinica',
      cn: '马提尼克岛',
      zh: '馬提尼克島',
      ee: 'Martinique',
      jp: 'マルティニーク',
      he: 'מרטיניק',
      el: 'Μαρτινίκα',
    },
    dial_code: '+596',
    code: 'MQ',
    flag: '🇲🇶',
  },
  {
    name: {
      en: 'Mauritania',
      ru: 'Мавритания',
      pl: 'Mauretania',
      ua: 'Мавританія',
      cz: 'Mauretánie',
      by: 'Маўрытанія',
      pt: 'Mauritânia',
      es: 'Mauritania',
      ro: 'Mauritania',
      bg: 'Мавритания',
      de: 'Mauretanien',
      fr: 'Mauritanie',
      nl: 'Mauritanië',
      it: 'Mauritania',
      cn: '毛里塔尼亚',
      zh: '毛里塔尼亞',
      ee: 'Mauritaania',
      jp: 'モーリタニア',
      he: 'מאוריטניה',
      el: 'Μαυριτανία',
    },
    dial_code: '+222',
    code: 'MR',
    flag: '🇲🇷',
  },
  {
    name: {
      en: 'Mauritius',
      ru: 'Маврикий',
      pl: 'Mauritius',
      ua: 'Маврикій',
      cz: 'Mauricius',
      by: 'Маўрыкій',
      pt: 'Maurícia',
      es: 'Mauricio',
      ro: 'Mauritius',
      bg: 'Мавриций',
      de: 'Mauritius',
      fr: 'Ile Maurice',
      nl: 'Mauritius',
      it: 'Maurizio',
      cn: '毛里求斯',
      zh: '毛里求斯',
      ee: 'Mauritius',
      jp: 'モーリシャス',
      he: 'מאוריציוס',
      el: 'Μαυρίκιος',
    },
    dial_code: '+230',
    code: 'MU',
    flag: '🇲🇺',
  },
  {
    name: {
      en: 'Mayotte',
      ru: 'Майотта',
      pl: 'Majotta',
      ua: 'Майотта',
      cz: 'Mayotte',
      by: 'Маёта',
      pt: 'Mayotte',
      es: 'Mayotte',
      ro: 'Mayotte',
      bg: 'Майот',
      de: 'Mayotte',
      fr: 'Mayotte',
      nl: 'Mayotte',
      it: 'Mayotte',
      cn: '马约特岛',
      zh: '馬約特島',
      ee: 'Mayotte',
      jp: 'マヨット',
      he: 'מיוט',
      el: 'Μαγιότ',
    },
    dial_code: '+262',
    code: 'YT',
    flag: '🇾🇹',
  },
  {
    name: {
      en: 'Mexico',
      ru: 'Мексика',
      pl: 'Meksyk',
      ua: 'Мексика',
      cz: 'Mexiko',
      by: 'Мексіка',
      pt: 'México',
      es: 'México',
      ro: 'Mexic',
      bg: 'Мексико',
      de: 'Mexiko',
      fr: 'Mexique',
      nl: 'Mexico',
      it: 'Messico',
      cn: '墨西哥',
      zh: '墨西哥',
      ee: 'Mehhiko',
      jp: 'メキシコ',
      he: 'מקסיקו',
      el: 'Μεξικό',
    },
    dial_code: '+52',
    code: 'MX',
    flag: '🇲🇽',
  },
  {
    name: {
      en: 'Micronesia, Federated States of',
      ru: 'Микронезия, Федеративные Штаты',
      pl: 'Mikronezja, Sfederowane Stany',
      ua: 'Мікронезія, Федеративні Штати',
      cz: 'Mikronésie, federativní státy',
      by: 'Мікранезія, Федэратыўныя Штаты',
      pt: 'Micronésia',
      es: 'Micronesia, Estados Federados de',
      ro: 'Micronezia, Statele Federate ale',
      bg: 'Микронезия, Федеративни щати',
      de: 'Mikronesien, Föderierte Staaten von',
      fr: 'Micronésie, États fédérés de',
      nl: 'Micronesië, Federale Staten van',
      it: 'Micronesia, Stati federati di',
      cn: '密克罗尼西亚联邦',
      zh: '密克羅尼西亞聯邦',
      ee: 'Mikroneesia, Makedoonia Liiduriigid',
      jp: 'ミクロネシア連邦',
      he: 'מיקרונזיה',
      el: 'Μικρονησία',
    },
    dial_code: '+691',
    code: 'FM',
    flag: '🇫🇲',
  },
  {
    name: {
      en: 'Moldova, Republic of',
      ru: 'Молдова, Республика',
      pl: 'Mołdawia, Republika',
      ua: 'Молдова, республіка',
      cz: 'Moldavsko, republika',
      by: 'Малдова, Рэспубліка',
      pt: 'Moldávia',
      es: 'Moldavia, República de',
      ro: 'Moldova, Republica',
      bg: 'Молдова, Република',
      de: 'Moldawien, Republik',
      fr: 'Moldova, République de',
      nl: 'Moldavië, Republiek',
      it: 'Moldova, Repubblica di',
      cn: '摩尔多瓦共和国',
      zh: '摩爾多瓦共和國',
      ee: 'Moldova Vabariik',
      jp: 'モルドバ共和国',
      he: 'מולדובה',
      el: 'Μολδαβία',
    },
    dial_code: '+373',
    code: 'MD',
    flag: '🇲🇩',
  },
  {
    name: {
      en: 'Monaco',
      ru: 'Монако',
      pl: 'Monako',
      ua: 'Монако',
      cz: 'Monako',
      by: 'Манака',
      pt: 'Mónaco',
      es: 'Mónaco',
      ro: 'Monaco',
      bg: 'Монако',
      de: 'Monaco',
      fr: 'Monaco',
      nl: 'Monaco',
      it: 'Monaco',
      cn: '摩纳哥',
      zh: '摩納哥',
      ee: 'Monaco',
      jp: 'モナコ',
      he: 'מונקו',
      el: 'Μονακό',
    },
    dial_code: '+377',
    code: 'MC',
    flag: '🇲🇨',
  },
  {
    name: {
      en: 'Mongolia',
      ru: 'Монголия',
      pl: 'Mongolia',
      ua: 'Монголія',
      cz: 'Mongolsko',
      by: 'Манголія',
      pt: 'Mongólia',
      es: 'Mongolia',
      ro: 'Mongolia',
      bg: 'Монголия',
      de: 'Mongolei',
      fr: 'Mongolie',
      nl: 'Mongolië',
      it: 'Mongolia',
      cn: '蒙古',
      zh: '蒙古',
      ee: 'Mongoolia',
      jp: 'モンゴル',
      he: 'מונגוליה',
      el: 'Μογγολία',
    },
    dial_code: '+976',
    code: 'MN',
    flag: '🇲🇳',
  },
  {
    name: {
      en: 'Montenegro',
      ru: 'Черногория',
      pl: 'Czarnogóra',
      ua: 'Чорногорія',
      cz: 'Černá Hora',
      by: 'Чарнагорыя',
      pt: 'Montenegro',
      es: 'Montenegro',
      ro: 'Muntenegru',
      bg: 'Черна гора',
      de: 'Montenegro',
      fr: 'Monténégro',
      nl: 'Montenegro',
      it: 'Montenegro',
      cn: '黑山共和国',
      zh: '黑山共和國',
      ee: 'Montenegro',
      jp: 'モンテネグロ',
      he: 'מונטנגרו',
      el: 'Μαυροβούνιο',
    },
    dial_code: '+382',
    code: 'ME',
    flag: '🇲🇪',
  },
  {
    name: {
      en: 'Montserrat',
      ru: 'Монтсеррат',
      pl: 'Montserrat',
      ua: 'Монтсеррат',
      cz: 'Montserrat',
      by: 'Мансерат',
      pt: 'Monserrate',
      es: 'Montserrat',
      ro: 'Montserrat',
      bg: 'Монсерат',
      de: 'Montserrat',
      fr: 'Montserrat',
      nl: 'Montserrat',
      it: 'Montserrat',
      cn: '蒙特塞拉特',
      zh: '蒙特塞拉特',
      ee: 'Montserrat',
      jp: 'モントセラト',
      he: 'מונטסראט',
      el: 'Μοντσερά',
    },
    dial_code: '+1664',
    code: 'MS',
    flag: '🇲🇸',
  },
  {
    name: {
      en: 'Morocco',
      ru: 'Марокко',
      pl: 'Maroko',
      ua: 'Марокко',
      cz: 'Maroko',
      by: 'Марока',
      pt: 'Marrocos',
      es: 'Marruecos',
      ro: 'Maroc',
      bg: 'Мароко',
      de: 'Marokko',
      fr: 'Maroc',
      nl: 'Marokko',
      it: 'Marocco',
      cn: '摩洛哥',
      zh: '摩洛哥',
      ee: 'Maroko',
      jp: 'モロッコ',
      he: 'מרוקו',
      el: 'Μαρόκο',
    },
    dial_code: '+212',
    code: 'MA',
    flag: '🇲🇦',
  },
  {
    name: {
      en: 'Mozambique',
      ru: 'Мозамбик',
      pl: 'Mozambik',
      ua: 'Мозамбік',
      cz: 'Mosambik',
      by: 'Мазамбік',
      pt: 'Moçambique',
      es: 'Mozambique',
      ro: 'Mozambic',
      bg: 'Мозамбик',
      de: 'Mosambik',
      fr: 'Mozambique',
      nl: 'Mozambique',
      it: 'Mozambico',
      cn: '莫桑比克',
      zh: '莫桑比克',
      ee: 'Mosambiik',
      jp: 'モザンビーク',
      he: 'מוזמביק',
      el: 'Μοζαμβίκη',
    },
    dial_code: '+258',
    code: 'MZ',
    flag: '🇲🇿',
  },
  {
    name: {
      en: 'Myanmar',
      ru: 'Мьянма',
      pl: 'Myanmar',
      ua: "М'янма",
      cz: 'Myanmar',
      by: "М'янма",
      pt: 'Birmânia',
      es: 'Myanmar',
      ro: 'Myanmar',
      bg: 'Мианмар',
      de: 'Myanmar',
      fr: 'Myanmar',
      nl: 'Myanmar',
      it: 'Myanmar',
      cn: '缅甸',
      zh: '緬甸',
      ee: 'Myanmar',
      jp: 'ミャンマー',
      he: 'מיאנמר',
      el: 'Μιανμάρ',
    },
    dial_code: '+95',
    code: 'MM',
    flag: '🇲🇲',
  },
  {
    name: {
      en: 'Namibia',
      ru: 'Намибия',
      pl: 'Namibia',
      ua: 'Намібія',
      cz: 'Namibie',
      by: 'Намібія',
      pt: 'Namíbia',
      es: 'Namibia',
      ro: 'Namibia',
      bg: 'Намибия',
      de: 'Namibia',
      fr: 'Namibie',
      nl: 'Namibië',
      it: 'Namibia',
      cn: '纳米比亚',
      zh: '納米比亞',
      ee: 'Namiibia',
      jp: 'ナミビア',
      he: 'נמיביה',
      el: 'Ναμίμπια',
    },
    dial_code: '+264',
    code: 'NA',
    flag: '🇳🇦',
  },
  {
    name: {
      en: 'Nauru',
      ru: 'Науру',
      pl: 'Nauru',
      ua: 'Науру',
      cz: 'Nauru',
      by: 'Науру',
      pt: 'Nauru',
      es: 'Nauru',
      ro: 'Nauru',
      bg: 'Науру',
      de: 'Nauru',
      fr: 'Nauru',
      nl: 'Nauru',
      it: 'Nauru',
      cn: '瑙鲁',
      zh: '瑙魯',
      ee: 'Nauru',
      jp: 'ナウル',
      he: 'נאורו',
      el: 'Ναουρού',
    },
    dial_code: '+674',
    code: 'NR',
    flag: '🇳🇷',
  },
  {
    name: {
      en: 'Nepal',
      ru: 'Непал',
      pl: 'Nepal',
      ua: 'Непал',
      cz: 'Nepál',
      by: 'Непал',
      pt: 'Nepal',
      es: 'Nepal',
      ro: 'Nepal',
      bg: 'Непал',
      de: 'Nepal',
      fr: 'Népal',
      nl: 'Nepal',
      it: 'Nepal',
      cn: '尼尼泊尔',
      zh: '尼尼泊爾',
      ee: 'Nepal',
      jp: 'ネパール',
      he: 'נפאל',
      el: 'Νεπάλ',
    },
    dial_code: '+977',
    code: 'NP',
    flag: '🇳🇵',
  },
  {
    name: {
      en: 'Netherlands',
      ru: 'Нидерланды',
      pl: 'Holandia',
      ua: 'Нідерланди',
      cz: 'Holandsko',
      by: 'Нідэрланды',
      pt: 'Países Baixos',
      es: 'Países Bajos',
      ro: 'Olanda',
      bg: 'Холандия',
      de: 'Niederlande',
      fr: 'Pays-Bas',
      nl: 'Nederland',
      it: 'Olanda',
      cn: '荷兰',
      zh: '荷蘭',
      ee: 'Holland',
      jp: 'オランダ',
      he: 'הולנד',
      el: 'Ολλανδία',
    },
    dial_code: '+31',
    code: 'NL',
    flag: '🇳🇱',
  },
  {
    name: {
      en: 'Netherlands Antilles',
      ru: 'Нидерландские Антильские острова',
      pl: 'Antyle Holenderskie',
      ua: 'Нідерландські Антильські острови',
      cz: 'Nizozemské Antily',
      by: 'Нідэрландскія Антыльскія астравы',
      pt: 'Antilhas Neerlandesas',
      es: 'Antillas Neerlandesas',
      ro: 'Antilele Olandeze',
      bg: 'Холандски Антили',
      de: 'Niederländische Antillen',
      fr: 'Antilles néerlandaises',
      nl: 'Nederlandse Antillen',
      it: 'Antille Olandesi',
      cn: '荷属安的列斯',
      zh: '荷屬安的列斯',
      ee: 'Hollandi Antillid',
      jp: 'オランダ領アンティル',
      he: 'האנטילים ההולנדיים',
      el: 'Ολλανδικές Αντίλλες',
    },
    dial_code: '+599',
    code: 'AN',
    flag: '🇦🇳',
  },
  {
    name: {
      en: 'New Caledonia',
      ru: 'Новая Каледония',
      pl: 'Nowa Kaledonia',
      ua: 'Нова Каледонія',
      cz: 'Nová Kaledonie',
      by: 'Новая Каледонія',
      pt: 'Nova Caledónia',
      es: 'Nueva Caledonia',
      ro: 'Noua Caledonie',
      bg: 'Нова Каледония',
      de: 'Neu-Kaledonien',
      fr: 'Nouvelle Calédonie',
      nl: 'Nieuw-Caledonië',
      it: 'Nuova Caledonia',
      cn: '新喀里多尼亚',
      zh: '新喀里多尼亞',
      ee: 'Uus-Kaledoonia',
      jp: 'ニューカレドニア',
      he: 'קלדוניה החדשה',
      el: 'Νέα Καληδονία',
    },
    dial_code: '+687',
    code: 'NC',
    flag: '🇳🇨',
  },
  {
    name: {
      en: 'New Zealand',
      ru: 'Новая Зеландия',
      pl: 'Nowa Zelandia',
      ua: 'Нова Зеландія',
      cz: 'Nový Zéland',
      by: 'Новая Зеландыя',
      pt: 'Nova Zelândia',
      es: 'Nueva Zelanda',
      ro: 'Noua Zeelanda',
      bg: 'Нова Зеландия',
      de: 'Neuseeland',
      fr: 'Nouvelle-Zélande',
      nl: 'Nieuw-Zeeland',
      it: 'Nuova Zelanda',
      cn: '新西兰',
      zh: '新西蘭',
      ee: 'Uus-Meremaa',
      jp: 'ニュージーランド',
      he: 'ניו זילנד',
      el: 'Νέα Ζηλανδία',
    },
    dial_code: '+64',
    code: 'NZ',
    flag: '🇳🇿',
  },
  {
    name: {
      en: 'Nicaragua',
      ru: 'Никарагуа',
      pl: 'Nikaragua',
      ua: 'Нікарагуа',
      cz: 'Nikaragua',
      by: 'Нікарагуа',
      pt: 'Nicarágua',
      es: 'Nicaragua',
      ro: 'Nicaragua',
      bg: 'Никарагуа',
      de: 'Nicaragua',
      fr: 'Nicaragua',
      nl: 'Nicaragua',
      it: 'Nicaragua',
      cn: '尼加拉瓜',
      zh: '尼加拉瓜',
      ee: 'Nicaragua',
      jp: 'ニカラグア',
      he: 'ניקרגואה',
      el: 'Νικαράγουα',
    },
    dial_code: '+505',
    code: 'NI',
    flag: '🇳🇮',
  },
  {
    name: {
      en: 'Niger',
      ru: 'Нигер',
      pl: 'Niger',
      ua: 'Нігер',
      cz: 'Niger',
      by: 'Нігер',
      pt: 'Níger',
      es: 'Níger',
      ro: 'Niger',
      bg: 'Нигер',
      de: 'Niger',
      fr: 'Niger',
      nl: 'Niger',
      it: 'Niger',
      cn: '尼日尔',
      zh: '尼日爾',
      ee: 'Niger',
      jp: 'ニジェール',
      he: "ניז'ר",
      el: 'Νίγηρας',
    },
    dial_code: '+227',
    code: 'NE',
    flag: '🇳🇪',
  },
  {
    name: {
      en: 'Nigeria',
      ru: 'Нигерия',
      pl: 'Nigeria',
      ua: 'Нігерія',
      cz: 'Nigérie',
      by: 'Нігерыя',
      pt: 'Nigéria',
      es: 'Nigeria',
      ro: 'Nigeria',
      bg: 'Нигерия',
      de: 'Nigeria',
      fr: 'Nigeria',
      nl: 'Nigeria',
      it: 'Nigeria',
      cn: '奈及利亚',
      zh: '奈及利亞',
      ee: 'Nigeeria',
      jp: 'ナイジェリア',
      he: 'ניגריה',
      el: 'Νιγηρία',
    },
    dial_code: '+234',
    code: 'NG',
    flag: '🇳🇬',
  },
  {
    name: {
      en: 'Niue',
      ru: 'Ниуэ',
      pl: 'Niue',
      ua: 'Ніуе',
      cz: 'Niue',
      by: 'Ніуе',
      pt: 'Niue',
      es: 'Niue',
      ro: 'Niue',
      bg: 'Ниуе',
      de: 'Niue',
      fr: 'Niue',
      nl: 'Niue',
      it: 'Niue',
      cn: '纽埃',
      zh: '紐埃',
      ee: 'Niue',
      jp: 'ニウエ',
      he: 'ניואה',
      el: 'Νιούε',
    },
    dial_code: '+683',
    code: 'NU',
    flag: '🇳🇺',
  },
  {
    name: {
      en: 'Norfolk Island',
      ru: 'Остров Норфолк',
      pl: 'Wyspa Norfolk',
      ua: 'Острів Норфолк',
      cz: 'Ostrov Norfolk',
      by: 'Востраў Норфолк',
      pt: 'Ilha Norfolk',
      es: 'Isla de Norfolk',
      ro: 'Insula Norfolk',
      bg: 'Остров Норфолк',
      de: 'Norfolkinsel',
      fr: "l'ile de Norfolk",
      nl: 'Norfolkeiland',
      it: 'Isola Norfolk',
      cn: '诺福克岛',
      zh: '諾福克島',
      ee: 'Norfolki saar',
      jp: 'ノーフォーク島',
      he: 'האי נורפוק',
      el: 'Νησί Νόρφολκ',
    },
    dial_code: '+672',
    code: 'NF',
    flag: '🇳🇫',
  },
  {
    name: {
      en: 'Northern Mariana Islands',
      ru: 'Северные Марианские острова',
      pl: 'Mariany Północne',
      ua: 'Північні Маріанські острови',
      cz: 'Severní Mariany',
      by: 'Паўночныя Марыянскія астравы',
      pt: 'Marianas do Norte',
      es: 'Islas Marianas del Norte',
      ro: 'Insulele Marianelor de Nord',
      bg: 'Северни Мариански острови',
      de: 'Nördliche Marianneninseln',
      fr: 'Îles Mariannes du Nord',
      nl: 'noordelijke Mariana eilanden',
      it: 'Isole Marianne settentrionali',
      cn: '北马里亚纳群岛',
      zh: '北馬里亞納群島',
      ee: 'Põhja-Mariaanid',
      jp: '北マリアナ諸島',
      he: 'איי מריאנה הצפוניים',
      el: 'Βόρειες Μαριάνες Νήσοι',
    },
    dial_code: '+1670',
    code: 'MP',
    flag: '🇲🇵',
  },
  {
    name: {
      en: 'Norway',
      ru: 'Норвегия',
      pl: 'Norwegia',
      ua: 'Норвегія',
      cz: 'Norsko',
      by: 'Нарвегія',
      pt: 'Noruega',
      es: 'Noruega',
      ro: 'Norvegia',
      bg: 'Норвегия',
      de: 'Norwegen',
      fr: 'Norvège',
      nl: 'Noorwegen',
      it: 'Norvegia',
      cn: '挪威',
      zh: '挪威',
      ee: 'Norra',
      jp: 'ノルウェー',
      he: 'נורווגיה',
      el: 'Νορβηγία',
    },
    dial_code: '+47',
    code: 'NO',
    flag: '🇳🇴',
  },
  {
    name: {
      en: 'Oman',
      ru: 'Оман',
      pl: 'Oman',
      ua: 'Оман',
      cz: 'Omán',
      by: 'Аман',
      pt: 'Omã',
      es: 'Omán',
      ro: 'Oman',
      bg: 'Оман',
      de: 'Oman',
      fr: 'Oman',
      nl: 'Oman',
      it: 'Oman',
      cn: '阿曼',
      zh: '阿曼',
      ee: 'Omaan',
      jp: 'オマーン',
      he: 'עומאן',
      el: 'Ομάν',
    },
    dial_code: '+968',
    code: 'OM',
    flag: '🇴🇲',
  },
  {
    name: {
      en: 'Pakistan',
      ru: 'Пакистан',
      pl: 'Pakistan',
      ua: 'Пакистан',
      cz: 'Pákistán',
      by: 'Пакістан',
      pt: 'Paquistão',
      es: 'Pakistán',
      ro: 'Pakistan',
      bg: 'Пакистан',
      de: 'Pakistan',
      fr: 'Pakistan',
      nl: 'Pakistan',
      it: 'Pakistan',
      cn: '巴基斯坦',
      zh: '巴基斯坦',
      ee: 'Pakistan',
      jp: 'パキスタン',
      he: 'פקיסטן',
      el: 'Πακιστάν',
    },
    dial_code: '+92',
    code: 'PK',
    flag: '🇵🇰',
  },
  {
    name: {
      en: 'Palau',
      ru: 'Палау',
      pl: 'Palau',
      ua: 'Палау',
      cz: 'Palau',
      by: 'Палаў',
      pt: 'Palau',
      es: 'Palau',
      ro: 'Palau',
      bg: 'Палау',
      de: 'Palau',
      fr: 'Palau',
      nl: 'Palau',
      it: 'Palau',
      cn: 'u琉',
      zh: 'u琉',
      ee: 'Palau',
      jp: 'パラオ',
      he: 'פלאו',
      el: 'Παλάου',
    },
    dial_code: '+680',
    code: 'PW',
    flag: '🇵🇼',
  },
  {
    name: {
      en: 'Palestinian Territory, Occupied',
      ru: 'Палестинская Территория, Оккупированная',
      pl: 'Teretorium Paleństynskie, Okupowane',
      ua: 'Палестинська територія, окупована',
      cz: 'Palestinské území, obsazené',
      by: 'Палестынская тэрыторыя, акупаваная',
      pt: 'Território Palestino, Ocupado',
      es: 'Territorio Palestino Ocupado',
      ro: 'Teritoriul Palestinian, Ocupat',
      bg: 'Палестинска територия, окупирана',
      de: 'Besetzte palästinensische Gebiete',
      fr: 'Territoire palestinien, occupé',
      nl: 'Palestijns gebied, bezet',
      it: 'Territori palestinesi occupati',
      cn: '被占领的巴勒斯坦领土',
      zh: '被占領的巴勒斯坦領土',
      ee: 'Palestiina territoorium, okupeeritud',
      jp: 'パレスチナ自治区、占領下',
      he: 'הרשות הפלסטינית',
      el: 'Κράτος της Παλαιστίνης',
    },
    dial_code: '+970',
    code: 'PS',
    flag: '🇵🇸',
  },
  {
    name: {
      en: 'Panama',
      ru: 'Панама',
      pl: 'Panama',
      ua: 'Панама',
      cz: 'Panama',
      by: 'Панама',
      pt: 'Panamá',
      es: 'Panamá',
      ro: 'Panama',
      bg: 'Панама',
      de: 'Panama',
      fr: 'Panama',
      nl: 'Panama',
      it: 'Panama',
      cn: '巴拿马',
      zh: '巴拿馬',
      ee: 'Panama',
      jp: 'パナマ',
      he: 'פנמה',
      el: 'Παναμάς',
    },
    dial_code: '+507',
    code: 'PA',
    flag: '🇵🇦',
  },
  {
    name: {
      en: 'Papua New Guinea',
      ru: 'Папуа - Новая Гвинея',
      pl: 'Papua Nowa Gwinea',
      ua: 'Папуа-Нова Гвінея',
      cz: 'Papua-Nová Guinea',
      by: 'Папуа-Новая Гвінея',
      pt: 'Papua-Nova Guiné',
      es: 'Papúa Nueva Guinea',
      ro: 'Papua Noua Guinee',
      bg: 'Папуа-Нова Гвинея',
      de: 'Papua Neu-Guinea',
      fr: 'Papouasie Nouvelle Guinée',
      nl: 'Papoea-Nieuw-Guinea',
      it: 'Papua Nuova Guinea',
      cn: '巴布亚新几内亚',
      zh: '巴布亞新幾內亞',
      ee: 'Paapua Uus-Guinea',
      jp: 'パプアニューギニア',
      he: 'פפואה גינאה החדשה',
      el: 'Παπούα Νέα Γουινέα',
    },
    dial_code: '+675',
    code: 'PG',
    flag: '🇵🇬',
  },
  {
    name: {
      en: 'Paraguay',
      ru: 'Парагвай',
      pl: 'Paragwaj',
      ua: 'Парагвай',
      cz: 'Paraguay',
      by: 'Парагвай',
      pt: 'Paraguai',
      es: 'Paraguay',
      ro: 'Paraguay',
      bg: 'Парагвай',
      de: 'Paraguay',
      fr: 'Paraguay',
      nl: 'Paraguay',
      it: 'Paraguay',
      cn: '巴拉圭',
      zh: '巴拉圭',
      ee: 'Paraguay',
      jp: 'パラグアイ',
      he: 'פרגוואי',
      el: 'Παραγουάη',
    },
    dial_code: '+595',
    code: 'PY',
    flag: '🇵🇾',
  },
  {
    name: {
      en: 'Peru',
      ru: 'Перу',
      pl: 'Peru',
      ua: 'Перу',
      cz: 'Peru',
      by: 'Перу',
      pt: 'Peru',
      es: 'Perú',
      ro: 'Peru',
      bg: 'Перу',
      de: 'Peru',
      fr: 'Pérou',
      nl: 'Peru',
      it: 'Perù',
      cn: '秘鲁',
      zh: '秘魯',
      ee: 'Peruu',
      jp: 'ペルー',
      he: 'פרו',
      el: 'Περού',
    },
    dial_code: '+51',
    code: 'PE',
    flag: '🇵🇪',
  },
  {
    name: {
      en: 'Philippines',
      ru: 'Филиппины',
      pl: 'Filipiny',
      ua: 'Філіппіни',
      cz: 'Filipíny',
      by: 'Філіпіны',
      pt: 'Filipinas',
      es: 'Filipinas',
      ro: 'Filipine',
      bg: 'Филипините',
      de: 'Philippinen',
      fr: 'Philippines',
      nl: 'Filippijnen',
      it: 'Filippine',
      cn: '菲律宾',
      zh: '菲律賓',
      ee: 'Filipiinid',
      jp: 'フィリピン',
      he: 'פיליפינים',
      el: 'Φιλιππίνες',
    },
    dial_code: '+63',
    code: 'PH',
    flag: '🇵🇭',
  },
  {
    name: {
      en: 'Pitcairn',
      ru: 'Питкэрн',
      pl: 'Pitcairn',
      ua: 'Піткерн',
      cz: 'Pitcairn',
      by: 'Піткэрн',
      pt: 'Pitcairn',
      es: 'Pitcairn',
      ro: 'Pitcairn',
      bg: 'Питкерн',
      de: 'Pitcairn',
      fr: 'Pitcairn',
      nl: 'Pitcairn',
      it: 'Pitcairn',
      cn: '皮特凯恩',
      zh: '皮特凱恩',
      ee: 'Pitcairn',
      jp: 'ピトケアン',
      he: 'פיטקרן',
      el: 'Πίτκαιρν',
    },
    dial_code: '+872',
    code: 'PN',
    flag: '🇵🇳',
  },
  {
    name: {
      en: 'Poland',
      ru: 'Польша',
      pl: 'Polska',
      ua: 'Польща',
      cz: 'Polsko',
      by: 'Польшча',
      pt: 'Polónia',
      es: 'Polonia',
      ro: 'Polonia',
      bg: 'Полша',
      de: 'Polen',
      fr: 'Pologne',
      nl: 'Polen',
      it: 'Polonia',
      cn: '波兰',
      zh: '波蘭',
      ee: 'Poola',
      jp: 'ポーランド',
      he: 'פולין',
      el: 'Πολωνία',
    },
    dial_code: '+48',
    code: 'PL',
    flag: '🇵🇱',
  },
  {
    name: {
      en: 'Portugal',
      ru: 'Португалия',
      pl: 'Portugalia',
      ua: 'Португалія',
      cz: 'Portugalsko',
      by: 'Партугалія',
      pt: 'Portugal',
      es: 'Portugal',
      ro: 'Portugalia',
      bg: 'Португалия',
      de: 'Portugal',
      fr: 'Le Portugal',
      nl: 'Portugal',
      it: 'Portogallo',
      cn: '葡萄牙',
      zh: '葡萄牙',
      ee: 'Portugal',
      jp: 'ポルトガル',
      he: 'פורטוגל',
      el: 'Πορτογαλία',
    },
    dial_code: '+351',
    code: 'PT',
    flag: '🇵🇹',
  },
  {
    name: {
      en: 'Puerto Rico',
      ru: 'Пуэрто-Рико',
      pl: 'Portoryko',
      ua: 'Пуерто Ріко',
      cz: 'Portoriko',
      by: 'Пуэрта-Рыка',
      pt: 'Porto Rico',
      es: 'Puerto Rico',
      ro: 'Puerto Rico',
      bg: 'Пуерто Рико',
      de: 'Puerto Rico',
      fr: 'Porto Rico',
      nl: 'Puerto Rico',
      it: 'Porto Rico',
      cn: '波多黎各',
      zh: '波多黎各',
      ee: 'Puerto Rico',
      jp: 'プエルトリコ',
      he: 'פוארטו ריקו',
      el: 'Πουέρτο Ρίκο',
    },
    dial_code: '+1939',
    code: 'PR',
    flag: '🇵🇷',
  },
  {
    name: {
      en: 'Qatar',
      ru: 'Катар',
      pl: 'Katar',
      ua: 'Катар',
      cz: 'Katar',
      by: 'Катар',
      pt: 'Catar',
      es: 'Qatar',
      ro: 'Qatar',
      bg: 'Катар',
      de: 'Katar',
      fr: 'Qatar',
      nl: 'Qatar',
      it: 'Qatar',
      cn: '卡塔尔',
      zh: '卡塔爾',
      ee: 'Katar',
      jp: 'カタール',
      he: 'קטאר',
      el: 'Κατάρ',
    },
    dial_code: '+974',
    code: 'QA',
    flag: '🇶🇦',
  },
  {
    name: {
      en: 'Romania',
      ru: 'Румыния',
      pl: 'Rumunia',
      ua: 'Румунія',
      cz: 'Rumunsko',
      by: 'Румынія',
      pt: 'Roménia',
      es: 'Rumanía',
      ro: 'România',
      bg: 'Румъния',
      de: 'Rumänien',
      fr: 'Roumanie',
      nl: 'Roemenië',
      it: 'Romania',
      cn: '罗马尼亚',
      zh: '羅馬尼亞',
      ee: 'Rumeenia',
      jp: 'ルーマニア',
      he: 'רומניה',
      el: 'Ρουμανία',
    },
    dial_code: '+40',
    code: 'RO',
    flag: '🇷🇴',
  },
  {
    name: {
      en: 'Russia',
      ru: 'Россия',
      pl: 'Rosja',
      ua: 'Росія',
      cz: 'Rusko',
      by: 'Расійская Федэрацыя',
      pt: 'Rússia',
      es: 'Rusia',
      ro: 'Federația Rusă',
      bg: 'Руска федерация',
      de: 'Russische Föderation',
      fr: 'Fédération Russe',
      nl: 'Russische Federatie',
      it: 'Federazione Russa',
      cn: '俄罗斯联邦',
      zh: '俄羅斯聯邦',
      ee: 'Venemaa Föderatsioon',
      jp: 'ロシア連邦',
      he: 'רוסיה',
      el: 'Ρωσία',
    },
    dial_code: '+7',
    code: 'RU',
    flag: '🇷🇺',
  },
  {
    name: {
      en: 'Rwanda',
      ru: 'Руанда',
      pl: 'Rwanda',
      ua: 'Руанда',
      cz: 'Rwanda',
      by: 'Руанда',
      pt: 'Ruanda',
      es: 'Ruanda',
      ro: 'Rwanda',
      bg: 'Руанда',
      de: 'Ruanda',
      fr: 'Rwanda',
      nl: 'Rwanda',
      it: 'Ruanda',
      cn: '卢旺达',
      zh: '盧旺達',
      ee: 'Rwanda',
      jp: 'ルワンダ',
      he: 'רואנדה',
      el: 'Ρουάντα',
    },
    dial_code: '+250',
    code: 'RW',
    flag: '🇷🇼',
  },
  {
    name: {
      en: 'Reunion',
      ru: 'Реюньон',
      pl: 'Réunion',
      ua: 'Реюньйон',
      cz: 'Réunion',
      by: "Уз'яднанне",
      pt: 'Reunião',
      es: 'Reunión',
      ro: 'Reuniune',
      bg: 'Съединение',
      de: 'Wiedervereinigung',
      fr: 'Réunion',
      nl: 'Bijeenkomst',
      it: 'Riunione',
      cn: '团圆',
      zh: '團圓',
      ee: 'Taaskohtumine',
      jp: '再会',
      he: 'איחוד',
      el: 'Ρεουνιόν',
    },
    dial_code: '+262',
    code: 'RE',
    flag: '🇷🇪',
  },
  {
    name: {
      en: 'Saint Barthélemy',
      ru: 'Святой Варфоломей',
      pl: 'Saint Barthelemy',
      ua: 'Сен-Бартелемі',
      cz: 'Svatý Bartoloměj',
      by: 'Святы Бартэлемі',
      pt: 'São Bartolomeu',
      es: 'San Bartolomé',
      ro: 'Sfântul Barthelemy',
      bg: 'Свети Бартелеми',
      de: 'Heiliger Barthelemy',
      fr: 'Saint Barthélemy',
      nl: 'Sint-Bartholomeus',
      it: 'Saint Barthelemy',
      cn: '圣巴托洛缪岛',
      zh: '聖巴托洛繆島',
      ee: 'Püha Barthelemy',
      jp: 'サンバルテルミー',
      he: "סנט ברת'למי",
      el: 'Άγιος Βαρθολομαίος',
    },
    dial_code: '+590',
    code: 'BL',
    flag: '🇧🇱',
  },
  {
    name: {
      en: 'Saint Helena, Ascension and Tristan Da Cunha',
      ru: 'Святой Елены, Вознесения и Тристан-да-Кунья',
      pl: 'Święta Helena, Wniebowstąpienie i Tristan Da Cunha',
      ua: 'Свята Олена, Вознесіння і Трістан Да Кунья',
      cz: 'Svatá Helena, Nanebevstoupení a Tristan Da Cunha',
      by: 'Святая Алена',
      pt: 'Santa Helena',
      es: 'Santa Elena, Ascensión y Tristán Da Cunha',
      ro: 'Sfânta Elena',
      bg: 'Света Елена',
      de: 'Heilige Helena',
      fr: 'Sainte-Hélène',
      nl: 'Sint-Helena',
      it: "Sant'Elena",
      cn: '圣海伦娜',
      zh: '聖海倫娜',
      ee: 'Püha Helena',
      jp: 'セントヘレナ',
      he: 'סנט הלנה',
      el: 'Αγία Ελένη',
    },
    dial_code: '+290',
    code: 'SH',
    flag: '🇸🇭',
  },
  {
    name: {
      en: 'Saint Kitts and Nevis',
      ru: 'Сент-Китс и Невис',
      pl: 'Saint Kitts i Nevis',
      ua: 'Сент-Кітс і Невіс',
      cz: 'Svatý Kryštof a Nevis',
      by: 'Сэнт-Кітс і Нэвіс',
      pt: 'São Cristóvão e Neves',
      es: 'San Cristóbal y Nieves',
      ro: 'Sfântul Kitts și Nevis',
      bg: 'Сейнт Китс и Невис',
      de: 'St. Kitts und Nevis',
      fr: 'Saint-Christophe-et-Niévès',
      nl: 'Saint Kitts en Nevis',
      it: 'Saint Kitts e Nevis',
      cn: '圣基茨和尼维斯',
      zh: '聖基茨和尼維斯',
      ee: 'Saint Kitts ja Nevis',
      jp: 'セントクリストファーネイビス',
      he: 'סנט קיטס ונוויס',
      el: 'Άγιος Χριστόφορος και Νέβις',
    },
    dial_code: '+1869',
    code: 'KN',
    flag: '🇰🇳',
  },
  {
    name: {
      en: 'Saint Lucia',
      ru: 'Санкт-Люсия',
      pl: 'święta Lucia',
      ua: 'Сент-Люсія',
      cz: 'Svatá Lucie',
      by: 'Святая Люсія',
      pt: 'Santa Lúcia',
      es: 'Santa Lucía',
      ro: 'Sfânta Lucia',
      bg: 'Сейнт Лусия',
      de: 'St. Lucia',
      fr: 'Sainte-Lucie',
      nl: 'Saint Lucia',
      it: 'Santa Lucia',
      cn: '圣卢西亚',
      zh: '聖盧西亞',
      ee: 'Saint Lucia',
      jp: 'セントルシア',
      he: 'סנט לוסיה',
      el: 'Αγία Λουκία',
    },
    dial_code: '+1758',
    code: 'LC',
    flag: '🇱🇨',
  },
  {
    name: {
      en: 'Saint Martin',
      ru: 'Сен-Мартен',
      pl: 'święty Marcin',
      ua: 'Святий Мартін',
      cz: 'Svatý Martin',
      by: 'Святы Марцін',
      pt: 'São Martinho',
      es: 'San Martín',
      ro: 'Sfântul Martin',
      bg: 'Свети Мартин',
      de: 'Sankt Martin',
      fr: 'Saint Martin',
      nl: 'Sint-Maarten',
      it: 'Saint Martin',
      cn: '圣马丁',
      zh: '聖馬丁',
      ee: 'Püha Martin',
      jp: 'サンマルタン',
      he: 'מרטין הקדוש',
      el: 'Άγιος Μαρτίνος',
    },
    dial_code: '+590',
    code: 'MF',
    flag: '🇲🇫',
  },
  {
    name: {
      en: 'Saint Pierre and Miquelon',
      ru: 'Сен-Пьер и Микелон',
      pl: 'Saint-Pierre i Miquelon',
      ua: "Сен-П'єр і Мікелон",
      cz: 'Svatý Pierre a Miquelon',
      by: "Сен-П'ер і Мікелон",
      pt: 'São Pedro e Miquelon',
      es: 'San Pedro y Miquelón',
      ro: 'Saint Pierre și Miquelon',
      bg: 'Сен Пиер и Микелон',
      de: 'Saint Pierre und Miquelon',
      fr: 'Saint-Pierre-et-Miquelon',
      nl: 'Saint Pierre en Miquelon',
      it: 'Saint Pierre e Miquelon',
      cn: '圣皮埃尔和密克隆群岛',
      zh: '聖皮埃爾和密克隆群島',
      ee: 'Saint Pierre ja Miquelon',
      jp: 'サンピエール島とミクロン島',
      he: 'סנט פייר ומיקלון',
      el: 'Σεντ Πιέρ και Μικελόν',
    },
    dial_code: '+508',
    code: 'PM',
    flag: '🇵🇲',
  },
  {
    name: {
      en: 'Saint Vincent and the Grenadines',
      ru: 'Святой Винсент и Гренадины',
      pl: 'Saint Vincent i Grenadyny',
      ua: 'Сент-Вінсент і Гренадини',
      cz: 'Svatý Vincenc a Grenadiny',
      by: 'Сэнт-Вінсэнт і Грэнадыны',
      pt: 'São Vicente e Granadinas',
      es: 'San Vicente y las Granadinas',
      ro: 'Sfântul Vincent și Grenadine',
      bg: 'Сейнт Винсент и Гренадини',
      de: 'St. Vincent und die Grenadinen',
      fr: 'Saint-Vincent-et-les-Grenadines',
      nl: 'Saint Vincent en de Grenadines',
      it: 'Saint Vincent e Grenadine',
      cn: '圣文森特和格林纳丁斯',
      zh: '聖文森特和格林納丁斯',
      ee: 'Saint Vincent ja Grenadiinid',
      jp: 'セントビンセントおよびグレナディーン諸島',
      he: 'סנט וינסנט והגרנדינים',
      el: 'Άγιος Βικέντιος και Γρεναδίνες',
    },
    dial_code: '+1784',
    code: 'VC',
    flag: '🇻🇨',
  },
  {
    name: {
      en: 'Samoa',
      ru: 'Самоа',
      pl: 'Samoa',
      ua: 'Самоа',
      cz: 'Samoa',
      by: 'Самоа',
      pt: 'Samoa',
      es: 'Samoa',
      ro: 'Samoa',
      bg: 'Самоа',
      de: 'Samoa',
      fr: 'Samoa',
      nl: 'Samoa',
      it: 'Samoa',
      cn: '萨摩亚',
      zh: '薩摩亞',
      ee: 'Samoa',
      jp: 'サモア',
      he: 'סמואה',
      el: 'Σαμόα',
    },
    dial_code: '+685',
    code: 'WS',
    flag: '🇼🇸',
  },
  {
    name: {
      en: 'San Marino',
      ru: 'Сан-Марино',
      pl: 'San Marino',
      ua: 'Сан-Марино',
      cz: 'San Marino',
      by: 'Сан-Марына',
      pt: 'São Marinho',
      es: 'San Marino',
      ro: 'San Marino',
      bg: 'Сан Марино',
      de: 'San Marino',
      fr: 'Saint Marin',
      nl: 'San Marino',
      it: 'San Marino',
      cn: '圣马力诺',
      zh: '聖馬力諾',
      ee: 'San Marino',
      jp: 'サンマリノ',
      he: 'סן מרינו',
      el: 'Άγιος Μαρίνος',
    },
    dial_code: '+378',
    code: 'SM',
    flag: '🇸🇲',
  },
  {
    name: {
      en: 'Sao Tome and Principe',
      ru: 'Сан-Томе и Принсипи',
      pl: 'Wyspy Świętego Tomasza i Książęca',
      ua: 'Сан-Томе і Принсіпі',
      cz: 'Svatý Tomáš a Princův ostrov',
      by: 'Сан-Томе і Прынсіпі',
      pt: 'São Tomé e Príncipe',
      es: 'Santo Tomé y Príncipe',
      ro: 'Sao Tome și Principe',
      bg: 'Сао Томе и Принсипи',
      de: 'Sao Tome und Principe',
      fr: 'Sao Tomé et Principe',
      nl: 'Sao Tomé en Principe',
      it: 'Sao Tome e Principe',
      cn: '圣多美和普林西比',
      zh: '聖多美和普林西比',
      ee: 'Sao Tome ja Principe',
      jp: 'サントメ・プリンシペ',
      he: 'סאו טומה ופרינסיפה',
      el: 'Σάο Τομέ και Πρίνσιπε',
    },
    dial_code: '+239',
    code: 'ST',
    flag: '🇸🇹',
  },
  {
    name: {
      en: 'Saudi Arabia',
      ru: 'Саудовская Аравия',
      pl: 'Arabia Saudyjska',
      ua: 'Саудівська Аравія',
      cz: 'Saudská arábie',
      by: 'Саўдаўская Аравія',
      pt: 'Arábia Saudita',
      es: 'San Vicente y las Granadinas',
      ro: 'Arabia Saudită',
      bg: 'Саудитска Арабия',
      de: 'Saudi-Arabien',
      fr: 'Arabie Saoudite',
      nl: 'Saoedi-Arabië',
      it: 'Arabia Saudita',
      cn: '沙特阿拉伯',
      zh: '沙特阿拉伯',
      ee: 'Saudi Araabia',
      jp: 'サウジアラビア',
      he: 'ערב הסעודית',
      el: 'Σαουδική Αραβία',
    },
    dial_code: '+966',
    code: 'SA',
    flag: '🇸🇦',
  },
  {
    name: {
      en: 'Senegal',
      ru: 'Сенегал',
      pl: 'Senegal',
      ua: 'Сенегал',
      cz: 'Senegal',
      by: 'Сенегал',
      pt: 'Senegal',
      es: 'Senegal',
      ro: 'Senegal',
      bg: 'Сенегал',
      de: 'Senegal',
      fr: 'Sénégal',
      nl: 'Senegal',
      it: 'Senegal',
      cn: '塞内加尔',
      zh: '塞內加爾',
      ee: 'Senegal',
      jp: 'セネガル',
      he: 'סנגל',
      el: 'Σενεγάλη',
    },
    dial_code: '+221',
    code: 'SN',
    flag: '🇸🇳',
  },
  {
    name: {
      en: 'Serbia',
      ru: 'Сербия',
      pl: 'Serbia',
      ua: 'Сербія',
      cz: 'Srbsko',
      by: 'Сербія',
      pt: 'Sérvia',
      es: 'Serbia',
      ro: 'Serbia',
      bg: 'Сърбия',
      de: 'Serbien',
      fr: 'Serbie',
      nl: 'Servië',
      it: 'Serbia',
      cn: '塞尔维亚',
      zh: '塞爾維亞',
      ee: 'Serbia',
      jp: 'セルビア',
      he: 'סרביה',
      el: 'Σερβία',
    },
    dial_code: '+381',
    code: 'RS',
    flag: '🇷🇸',
  },
  {
    name: {
      en: 'Seychelles',
      ru: 'Сейшельские острова',
      pl: 'Seszele',
      ua: 'Сейшельські острови',
      cz: 'Seychely',
      by: 'Сейшэльскія астравы',
      pt: 'Seicheles',
      es: 'Seychelles',
      ro: 'Seychelles',
      bg: 'Сейшелски острови',
      de: 'Seychellen',
      fr: 'les Seychelles',
      nl: 'Seychellen',
      it: 'Seychelles',
      cn: '塞舌尔',
      zh: '塞舌爾',
      ee: 'Seišellid',
      jp: 'セイシェル',
      he: 'איי סיישל',
      el: 'Σεϋχέλλες',
    },
    dial_code: '+248',
    code: 'SC',
    flag: '🇸🇨',
  },
  {
    name: {
      en: 'Sierra Leone',
      ru: 'Сьерра-Леоне',
      pl: 'Sierra Leone',
      ua: 'Сьєрра-Леоне',
      cz: 'Sierra Leone',
      by: 'Сьера-Леонэ',
      pt: 'Serra Leoa',
      es: 'Sierra Leona',
      ro: 'Sierra Leone',
      bg: 'Сиера Леоне',
      de: 'Sierra Leone',
      fr: 'Sierra Leone',
      nl: 'Sierra Leone',
      it: 'Sierra Leone',
      cn: '塞拉利昂',
      zh: '塞拉利昂',
      ee: 'Sierra Leone',
      jp: 'シエラレオネ',
      he: 'סיירה לאון',
      el: 'Σιέρα Λεόνε',
    },
    dial_code: '+232',
    code: 'SL',
    flag: '🇸🇱',
  },
  {
    name: {
      en: 'Singapore',
      ru: 'Сингапур',
      pl: 'Singapur',
      ua: 'Сінгапур',
      cz: 'Singapur',
      by: 'Сінгапур',
      pt: 'Singapura',
      es: 'Singapur',
      ro: 'Singapore',
      bg: 'Сингапур',
      de: 'Singapur',
      fr: 'Singapour',
      nl: 'Singapore',
      it: 'Singapore',
      cn: '新加坡',
      zh: '新加坡',
      ee: 'Singapur',
      jp: 'シンガポール',
      he: 'סינגפור',
      el: 'Σιγκαπούρη',
    },
    dial_code: '+65',
    code: 'SG',
    flag: '🇸🇬',
  },
  {
    name: {
      en: 'Slovakia',
      ru: 'Словакия',
      pl: 'Słowacja',
      ua: 'Словаччина',
      cz: 'Slovensko',
      by: 'Славакія',
      pt: 'Eslováquia',
      es: 'Eslovaquia',
      ro: 'Slovacia',
      bg: 'Словакия',
      de: 'Slowakei',
      fr: 'Slovaquie',
      nl: 'Slowakije',
      it: 'Slovacchia',
      cn: '斯洛伐克',
      zh: '斯洛伐克',
      ee: 'Slovakkia',
      jp: 'スロバキア',
      he: 'סלובקיה',
      el: 'Σλοβακία',
    },
    dial_code: '+421',
    code: 'SK',
    flag: '🇸🇰',
  },
  {
    name: {
      en: 'Slovenia',
      ru: 'Словения',
      pl: 'Słowenia',
      ua: 'Словенія',
      cz: 'Slovinsko',
      by: 'Славенія',
      pt: 'Eslovénia',
      es: 'Eslovenia',
      ro: 'Slovenia',
      bg: 'Словения',
      de: 'Slowenien',
      fr: 'Slovénie',
      nl: 'Slovenië',
      it: 'Slovenia',
      cn: '斯洛文尼亚',
      zh: '斯洛文尼亞',
      ee: 'Sloveenia',
      jp: 'スロベニア',
      he: 'סלובניה',
      el: 'Σλοβενία',
    },
    dial_code: '+386',
    code: 'SI',
    flag: '🇸🇮',
  },
  {
    name: {
      en: 'Solomon Islands',
      ru: 'Соломоновы острова',
      pl: 'Wyspy Salomona',
      ua: 'Соломонові острови',
      cz: 'Solomonovy ostrovy',
      by: 'Саламонавы астравы',
      pt: 'Ilhas Salomão',
      es: 'Islas Salomón',
      ro: 'Insulele Solomon',
      bg: 'Соломонови острови',
      de: 'Salomon-Inseln',
      fr: 'Les îles Salomon',
      nl: 'Solomon eilanden',
      it: 'Isole Salomone',
      cn: '所罗门群岛',
      zh: '所羅門群島',
      ee: 'Saalomoni Saared',
      jp: 'ソロモン諸島',
      he: 'איי שלמה',
      el: 'Νησιά Σολομώντα',
    },
    dial_code: '+677',
    code: 'SB',
    flag: '🇸🇧',
  },
  {
    name: {
      en: 'Somalia',
      ru: 'Сомали',
      pl: 'Somali',
      ua: 'Сомалі',
      cz: 'Somálsko',
      by: 'Самалі',
      pt: 'Somália',
      es: 'Somalia',
      ro: 'Somalia',
      bg: 'Сомалия',
      de: 'Somalia',
      fr: 'Somalie',
      nl: 'Somalië',
      it: 'Somalia',
      cn: '索马里',
      zh: '索馬里',
      ee: 'Somaalia',
      jp: 'ソマリア',
      he: 'סומליה',
      el: 'Σομαλία',
    },
    dial_code: '+252',
    code: 'SO',
    flag: '🇸🇴',
  },
  {
    name: {
      en: 'South Africa',
      ru: 'Южная Африка',
      pl: 'Afryka Południowa',
      ua: 'Південна Африка',
      cz: 'Jižní Afrika',
      by: 'Паўднёвая Афрыка',
      pt: 'África do Sul',
      es: 'Sudáfrica',
      ro: 'Africa de Sud',
      bg: 'Южна Африка',
      de: 'Südafrika',
      fr: 'Afrique du Sud',
      nl: 'Zuid-Afrika',
      it: 'Sud Africa',
      cn: '南非',
      zh: '南非',
      ee: 'Lõuna-Aafrika',
      jp: '南アフリカ',
      he: 'דרום אפריקה',
      el: 'Νότια Αφρική',
    },
    dial_code: '+27',
    code: 'ZA',
    flag: '🇿🇦',
  },
  {
    name: {
      en: 'South Georgia and the South Sandwich Islands',
      ru: 'Южная Георгия и Южные Сандвичевы острова',
      pl: 'Georgia Południowa i Sandwich Południowy',
      ua: 'Південна Джорджія та Південні Сандвічеві острови',
      cz: 'Jižní Georgie a Jižní Sandwichovy ostrovy',
      by: 'Паўднёвая Джорджыя і Паўднёвыя Сандвічавы астравы',
      pt: 'Geórgia do Sul e Sandwich do Sul',
      es: 'Georgia del Sur y las Islas Sandwich del Sur',
      ro: 'Georgia de Sud și Insulele Sandwich de Sud',
      bg: 'Южна Джорджия и Южните сандвичеви острови',
      de: 'Süd-Georgien und die südlichen Sandwich-Inseln',
      fr: 'Géorgie du Sud et îles Sandwich du Sud',
      nl: 'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
      it: 'Georgia del Sud e isole Sandwich meridionali',
      cn: '南乔治亚岛和南桑威奇群岛',
      zh: '南喬治亞島和南桑威奇群島',
      ee: 'Lõuna-Georgia ja Lõuna-Sandwichi saared',
      jp: 'サウスジョージア島とサウスサンドイッチ諸島',
      he: "דרום ג'ורג'יה ואיי סנדוויץ' הדרומיים",
      el: 'Νότιος Γεωργία και Νότιοι Νήσοι Σάντουιτς',
    },
    dial_code: '+500',
    code: 'GS',
    flag: '🇬🇸',
  },
  {
    name: {
      en: 'Spain',
      ru: 'Испания',
      pl: 'Hiszpania',
      ua: 'Іспанія',
      cz: 'Španělsko',
      by: 'Іспанія',
      pt: 'Espanha',
      es: 'España',
      ro: 'Spania',
      bg: 'Испания',
      de: 'Spanien',
      fr: 'Espagne',
      nl: 'Spanje',
      it: 'Spagna',
      cn: '西班牙',
      zh: '西班牙',
      ee: 'Hispaania',
      jp: 'スペイン',
      he: 'ספרד',
      el: 'Ισπανία',
    },
    dial_code: '+34',
    code: 'ES',
    flag: '🇪🇸',
  },
  {
    name: {
      en: 'Sri Lanka',
      ru: 'Шри-Ланка',
      pl: 'Sri Lanka',
      ua: 'Шрі Ланка',
      cz: 'Srí Lanka',
      by: 'Шры Ланка',
      pt: 'Sri Lanca',
      es: 'Sri Lanka',
      ro: 'Sri Lanka',
      bg: 'Шри Ланка',
      de: 'Sri Lanka',
      fr: 'Sri Lanka',
      nl: 'Sri Lanka',
      it: 'Sri Lanka',
      cn: '斯里兰卡',
      zh: '斯里蘭卡',
      ee: 'Sri Lanka',
      jp: 'スリランカ',
      he: 'סרי לנקה',
      el: 'Σρι Λάνκα',
    },
    dial_code: '+94',
    code: 'LK',
    flag: '🇱🇰',
  },
  {
    name: {
      en: 'Sudan',
      ru: 'Судан',
      pl: 'Sudan',
      ua: 'Судан',
      cz: 'Súdán',
      by: 'Судан',
      pt: 'Sudão',
      es: 'Sudán',
      ro: 'Sudan',
      bg: 'Судан',
      de: 'Sudan',
      fr: 'Soudan',
      nl: 'Soedan',
      it: 'Sudan',
      cn: '苏丹',
      zh: '蘇丹',
      ee: 'Sudaan',
      jp: 'スーダン',
      he: 'סודן',
      el: 'Σουδάν',
    },
    dial_code: '+249',
    code: 'SD',
    flag: '🇸🇩',
  },
  {
    name: {
      en: 'Suriname',
      ru: 'Суринам',
      pl: 'Surinam',
      ua: 'Суринам',
      cz: 'Surinam',
      by: 'Сурынам',
      pt: 'Suriname',
      es: 'Surinam',
      ro: 'Surinam',
      bg: 'Суринам',
      de: 'Suriname',
      fr: 'Suriname',
      nl: 'Suriname',
      it: 'Suriname',
      cn: '苏里南',
      zh: '蘇裡南',
      ee: 'Suriname',
      jp: 'スリナム',
      he: 'סורינאם',
      el: 'Σουρινάμ',
    },
    dial_code: '+597',
    code: 'SR',
    flag: '🇸🇷',
  },
  {
    name: {
      en: 'Svalbard and Jan Mayen',
      ru: 'Шпицберген и Ян Майен',
      pl: 'Svalbard i Jan Mayen',
      ua: 'Шпіцберген та Ян Маєн',
      cz: 'Špicberky a Jan Mayen',
      by: 'Шпіцберген і Ян Маен',
      pt: 'Svalbard e Jan Mayen',
      es: 'Svalbard y Jan Mayen',
      ro: 'Svalbard și Jan Mayen',
      bg: 'Шпицберген и Ян Майен',
      de: 'Spitzbergen und Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
      nl: 'Svalbard en Jan Mayen',
      it: 'Svalbard e Jan Mayen',
      cn: '斯瓦尔巴和扬·马延',
      zh: '斯瓦爾巴和揚·馬延',
      ee: 'Svalbard ja Jan Mayen',
      jp: 'スバールバル諸島およびヤンマイエン',
      he: 'סבאלברד ויאן מאין',
      el: 'Σβάλμπαρντ και Γιαν Μάγεν',
    },
    dial_code: '+47',
    code: 'SJ',
    flag: '🇸🇯',
  },
  {
    name: {
      en: 'Swaziland',
      ru: 'Свазиленд',
      pl: 'Suazi',
      ua: 'Свазіленд',
      cz: 'Svazijsko',
      by: 'Свазіленд',
      pt: 'Suazilândia',
      es: 'Suazilandia',
      ro: 'Swaziland',
      bg: 'Свазиленд',
      de: 'Swasiland',
      fr: 'Swaziland',
      nl: 'Swaziland',
      it: 'Swaziland',
      cn: '斯威士兰',
      zh: '斯威士蘭',
      ee: 'Svaasimaa',
      jp: 'スワジランド',
      he: 'סווזילנד',
      el: 'Σουαζιλάνδη',
    },
    dial_code: '+268',
    code: 'SZ',
    flag: '🇸🇿',
  },
  {
    name: {
      en: 'Sweden',
      ru: 'Швеция',
      pl: 'Szwecja',
      ua: 'Швеція',
      cz: 'Švédsko',
      by: 'Швецыя',
      pt: 'Suécia',
      es: 'Suecia',
      ro: 'Suedia',
      bg: 'Швеция',
      de: 'Schweden',
      fr: 'Suède',
      nl: 'Zweden',
      it: 'Svezia',
      cn: '瑞典',
      zh: '瑞典',
      ee: 'Rootsi',
      jp: 'スウェーデン',
      he: 'שבדיה',
      el: 'Σουηδία',
    },
    dial_code: '+46',
    code: 'SE',
    flag: '🇸🇪',
  },
  {
    name: {
      en: 'Switzerland',
      ru: 'Швейцария',
      pl: 'Szwajcaria',
      ua: 'Швейцарія',
      cz: 'Švýcarsko',
      by: 'Швейцарыя',
      pt: 'Suíça',
      es: 'Suiza',
      ro: 'Elveţia',
      bg: 'Швейцария',
      de: 'Schweiz',
      fr: 'la Suisse',
      nl: 'Zwitserland',
      it: 'Svizzera',
      cn: '瑞士',
      zh: '瑞士',
      ee: 'Šveits',
      jp: 'スイス',
      he: 'שוויץ',
      el: 'Ελβετία',
    },
    dial_code: '+41',
    code: 'CH',
    flag: '🇨🇭',
  },
  {
    name: {
      en: 'Syrian Arab Republic',
      ru: 'Сирийская Арабская Республика',
      pl: 'Republika Syryjsko-Arabska',
      ua: 'Сирійська Арабська Республіка',
      cz: 'Syrská Arabská republika',
      by: 'Сірыйская Арабская Рэспубліка',
      pt: 'Síria',
      es: 'República Árabe Siria',
      ro: 'Republica Araba Siriana',
      bg: 'Сирийска Арабска Република',
      de: 'Syrische Arabische Republik',
      fr: 'République arabe syrienne',
      nl: 'Syrische Arabische Republiek',
      it: 'Repubblica Araba Siriana',
      cn: '阿拉伯叙利亚共和国',
      zh: '阿拉伯敘利亞共和國',
      ee: 'Süüria Araabia Vabariik',
      jp: 'シリアアラブ共和国',
      he: 'סוריה',
      el: 'Συρία',
    },
    dial_code: '+963',
    code: 'SY',
    flag: '🇸🇾',
  },
  {
    name: {
      en: 'Taiwan, Province of China',
      ru: 'Тайвань, провинция Китая',
      pl: 'Tajwan, prowincja Chin',
      ua: 'Тайвань, провінція Китаю',
      cz: 'Tchaj-wan, provincie Číny',
      by: 'Тайвань, Кітайская правінцыя',
      pt: 'Taiwan',
      es: 'Taiwán, provincia de China',
      ro: 'Taiwan, provincia Chinei',
      bg: 'Тайван, провинция Китай',
      de: 'Taiwan, Provinz Chinas',
      fr: 'Taiwan, Province de Chine',
      nl: 'Taiwan, provincie China',
      it: 'Taiwan, provincia della Cina',
      cn: '中国台湾省',
      zh: '中國台灣省',
      ee: 'Taiwan, Hiina provints',
      jp: '台湾、中国省',
      he: 'טיוואן',
      el: 'Ταϊβάν',
    },
    dial_code: '+886',
    code: 'TW',
    flag: '🇹🇼',
  },
  {
    name: {
      en: 'Tajikistan',
      ru: 'Таджикистан',
      pl: 'Tadżykistan',
      ua: 'Таджикистан',
      cz: 'Tádžikistán',
      by: 'Таджыкістан',
      pt: 'Tajiquistão',
      es: 'Tayikistán',
      ro: 'Tadjikistan',
      bg: 'Таджикистан',
      de: 'Tadschikistan',
      fr: 'Tadjikistan',
      nl: 'Tadzjikistan',
      it: 'Tagikistan',
      cn: '塔吉克斯坦',
      zh: '塔吉克斯坦',
      ee: 'Tadžikistan',
      jp: 'タジキスタン',
      he: "טג'יקיסטן",
      el: 'Τατζικιστάν',
    },
    dial_code: '+992',
    code: 'TJ',
    flag: '🇹🇯',
  },
  {
    name: {
      en: 'Tanzania, United Republic of',
      ru: 'Танзания, Объединенная Республика',
      pl: 'Tanzania, Zjednoczona Republika',
      ua: "Танзанія, Об'єднана республіка",
      cz: 'Tanzanie, Velká republika',
      by: "Танзанія, Аб'яднаная Рэспубліка",
      pt: 'Tanzânia',
      es: 'Tanzania, República Unida de',
      ro: 'Tanzania, Republica Unită a',
      bg: 'Танзания, Обединена република',
      de: 'Tansania, Vereinigte Republik',
      fr: 'Tanzanie, République-Unie de',
      nl: 'Tanzania, Verenigde Republiek',
      it: 'Tanzania, Repubblica Unita di',
      cn: '坦桑尼亚联合共和国',
      zh: '坦桑尼亞聯合共和國',
      ee: 'Tansaania, Tšehhi Vabariik',
      jp: 'タンザニア、連合共和国',
      he: 'טנזניה',
      el: 'Τανζανία',
    },
    dial_code: '+255',
    code: 'TZ',
    flag: '🇹🇿',
  },
  {
    name: {
      en: 'Thailand',
      ru: 'Таиланд',
      pl: 'Tajlandia',
      ua: 'Таїланд',
      cz: 'Thajsko',
      by: 'Тайланд',
      pt: 'Tailândia',
      es: 'Tailandia',
      ro: 'Tailanda',
      bg: 'Тайланд',
      de: 'Thailand',
      fr: 'Thaïlande',
      nl: 'Thailand',
      it: 'Tailandia',
      cn: '泰国',
      zh: '泰國',
      ee: 'Tai',
      jp: 'タイ',
      he: 'תאילנד',
      el: 'Ταϊλάνδη',
    },
    dial_code: '+66',
    code: 'TH',
    flag: '🇹🇭',
  },
  {
    name: {
      en: 'Timor-Leste',
      ru: 'Восточный Тимор',
      pl: 'Timor Wschodni',
      ua: 'Восточний Тимор',
      cz: 'Východní Timor',
      by: 'Востраў Тымор',
      pt: 'Timor Leste',
      es: 'Timor-Leste',
      ro: 'Timor-Leste',
      bg: 'Тимор-Лешти',
      de: 'Timor-Leste',
      fr: 'Timor-Leste',
      nl: 'Oost-Timor',
      it: 'Timor-Leste',
      cn: '东帝汶',
      zh: '東帝汶',
      ee: 'Ida-Timor',
      jp: '東ティモール',
      he: 'טימור-לסטה',
      el: 'Ανατολικό Τιμόρ',
    },
    dial_code: '+670',
    code: 'TL',
    flag: '🇹🇱',
  },
  {
    name: {
      en: 'Togo',
      ru: 'Того',
      pl: 'Togo',
      ua: 'Того',
      cz: 'Togo',
      by: 'Ісці',
      pt: 'Togo',
      es: 'Togo',
      ro: 'A merge',
      bg: 'Да отида',
      de: 'Gehen',
      fr: 'Aller',
      nl: 'Gaan',
      it: 'Andare',
      cn: '多哥',
      zh: '多哥',
      ee: 'Minema',
      jp: 'トーゴ',
      he: 'טוגו',
      el: 'Τόγκο',
    },
    dial_code: '+228',
    code: 'TG',
    flag: '🇹🇬',
  },
  {
    name: {
      en: 'Tokelau',
      ru: 'Токелау',
      pl: 'Tokelau',
      ua: 'Токелау',
      cz: 'Tokelau',
      by: 'Такелаў',
      pt: 'Tokelau',
      es: 'Tokelau',
      ro: 'Tokelau',
      bg: 'Токелау',
      de: 'Tokelau',
      fr: 'Tokelau',
      nl: 'Tokelau',
      it: 'Tokelau',
      cn: '托克劳',
      zh: '托克勞',
      ee: 'Tokelau',
      jp: 'トケラウ',
      he: 'טוקלאו',
      el: 'Τοκελάου',
    },
    dial_code: '+690',
    code: 'TK',
    flag: '🇹🇰',
  },
  {
    name: {
      en: 'Tonga',
      ru: 'Тонга',
      pl: 'Tonga',
      ua: 'Тонга',
      cz: 'Tonga',
      by: 'Тонга',
      pt: 'Tonga',
      es: 'Tonga',
      ro: 'Tonga',
      bg: 'Тонга',
      de: 'Tonga',
      fr: 'Tonga',
      nl: 'Tonga',
      it: 'Tonga',
      cn: '汤加',
      zh: '湯加',
      ee: 'Tonga',
      jp: 'トンガ',
      he: 'טונגה',
      el: 'Τόνγκα',
    },
    dial_code: '+676',
    code: 'TO',
    flag: '🇹🇴',
  },
  {
    name: {
      en: 'Trinidad and Tobago',
      ru: 'Тринидад и Тобаго',
      pl: 'Trynidad i Tobago',
      ua: 'Тринідад і Тобаго',
      cz: 'Trinidad a Tobago',
      by: 'Трынідад і Табага',
      pt: 'Trindade e Tobago',
      es: 'Trinidad y Tobago',
      ro: 'Trinidad și Tobago',
      bg: 'Тринидад и Тобаго',
      de: 'Trinidad und Tobago',
      fr: 'Trinité-et-Tobago',
      nl: 'Trinidad en Tobago',
      it: 'Trinidad e Tobago',
      cn: '特立尼达和多巴哥',
      zh: '特立尼達和多巴哥',
      ee: 'Trinidad ja Tobago',
      jp: 'トリニダード・トバゴ',
      he: 'טרינידד וטובגו',
      el: 'Τρίνινταντ και Tομπάγκο',
    },
    dial_code: '+1868',
    code: 'TT',
    flag: '🇹🇹',
  },
  {
    name: {
      en: 'Tunisia',
      ru: 'Тунис',
      pl: 'Tunezja',
      ua: 'Туніс',
      cz: 'Tunisko',
      by: 'Туніс',
      pt: 'Tunísia',
      es: 'Túnez',
      ro: 'Tunisia',
      bg: 'Тунис',
      de: 'Tunesien',
      fr: 'Tunisie',
      nl: 'Tunesië',
      it: 'Tunisia',
      cn: '突尼斯',
      zh: '突尼斯',
      ee: 'Tuneesia',
      jp: 'チュニジア',
      he: 'תוניסיה',
      el: 'Τυνησία',
    },
    dial_code: '+216',
    code: 'TN',
    flag: '🇹🇳',
  },
  {
    name: {
      en: 'Turkey',
      ru: 'Турция',
      pl: 'Turcja',
      ua: 'Туреччина',
      cz: 'Turecko',
      by: 'Турцыя',
      pt: 'Turquia',
      es: 'Turquía',
      ro: 'Curcan',
      bg: 'Турция',
      de: 'Türkei',
      fr: 'dinde',
      nl: 'kalkoen',
      it: 'tacchino',
      cn: '火鸡',
      zh: '火雞',
      ee: 'Türgi',
      jp: '七面鳥',
      he: 'טורקיה',
      el: 'Τουρκία',
    },
    dial_code: '+90',
    code: 'TR',
    flag: '🇹🇷',
  },
  {
    name: {
      en: 'Turkmenistan',
      ru: 'Туркменистан',
      pl: 'Turkmenia',
      ua: 'Туркменістан',
      cz: 'Turkmenistán',
      by: 'Туркменістан',
      pt: 'Turquemenistão',
      es: 'Turkmenistán',
      ro: 'Turkmenistan',
      bg: 'Туркменистан',
      de: 'Turkmenistan',
      fr: 'Turkménistan',
      nl: 'Turkmenistan',
      it: 'Turkmenistan',
      cn: '土库曼斯坦',
      zh: '土庫曼斯坦',
      ee: 'Türkmenistan',
      jp: 'トルクメニスタン',
      he: 'טורקמניסטן',
      el: 'Τουρκμενιστάν',
    },
    dial_code: '+993',
    code: 'TM',
    flag: '🇹🇲',
  },
  {
    name: {
      en: 'Turks and Caicos Islands',
      ru: 'Острова Теркс и Кайкос',
      pl: 'Wyspy Turks i Caicos',
      ua: 'Острови Теркс і Кайкос',
      cz: 'ostrovy Turks a Cacois',
      by: 'Астравы Тэркс і Кайкас',
      pt: 'Ilhas Turcas e Caicos',
      es: 'Islas Turcas y Caicos',
      ro: 'Insulele Turks și Caicos',
      bg: 'Острови Търкс и Кайкос',
      de: 'Turks- und Caicosinseln',
      fr: 'îles Turques-et-Caïques',
      nl: 'Turks- en Caicoseilanden',
      it: 'Isole Turks e Caicos',
      cn: '特克斯和凯科斯群岛',
      zh: '特克斯和凱科斯群島',
      ee: 'Turksi ja Caicose saared',
      jp: 'タークス・カイコス諸島',
      he: 'איי טורקס וקאיקוס',
      el: 'Νήσοι Τουρκ και Κάικος',
    },
    dial_code: '+1649',
    code: 'TC',
    flag: '🇹🇨',
  },
  {
    name: {
      en: 'Tuvalu',
      ru: 'Тувалу',
      pl: 'Tuvalu',
      ua: 'Тувалу',
      cz: 'Tuvalu',
      by: 'Тувалу',
      pt: 'Tuvalu',
      es: 'Tuvalu',
      ro: 'Tuvalu',
      bg: 'Тувалу',
      de: 'Tuvalu',
      fr: 'Tuvalu',
      nl: 'Tuvalu',
      it: 'Tuvalu',
      cn: '图瓦卢',
      zh: '圖瓦盧',
      ee: 'Tuvalu',
      jp: 'ツバル',
      he: 'טובאלו',
      el: 'Τουβαλού',
    },
    dial_code: '+688',
    code: 'TV',
    flag: '🇹🇻',
  },
  {
    name: {
      en: 'Uganda',
      ru: 'Уганда',
      pl: 'Uganda',
      ua: 'Уганда',
      cz: 'Uganda',
      by: 'Уганда',
      pt: 'Uganda',
      es: 'Uganda',
      ro: 'Uganda',
      bg: 'Уганда',
      de: 'Uganda',
      fr: 'Ouganda',
      nl: 'Oeganda',
      it: 'Uganda',
      cn: '乌干达',
      zh: '烏干達',
      ee: 'Uganda',
      jp: 'ウガンダ',
      he: 'אוגנדה',
      el: 'Ουγκάντα',
    },
    dial_code: '+256',
    code: 'UG',
    flag: '🇺🇬',
  },
  {
    name: {
      en: 'Ukraine',
      ru: 'Украина',
      pl: 'Ukraina',
      ua: 'Україна',
      cz: 'Ukrajina',
      by: 'Украіна',
      pt: 'Ucrânia',
      es: 'Ucrania',
      ro: 'Ucraina',
      bg: 'Украйна',
      de: 'Ukraine',
      fr: 'Ukraine',
      nl: 'Oekraïne',
      it: 'Ucraina',
      cn: '乌克兰',
      zh: '烏克蘭',
      ee: 'Ukraina',
      jp: 'ウクライナ',
      he: 'אוקראינה',
      el: 'Ουκρανία',
    },
    dial_code: '+380',
    code: 'UA',
    flag: '🇺🇦',
  },
  {
    name: {
      en: 'United Arab Emirates',
      ru: 'Объединенные Арабские Эмираты',
      pl: 'Zjednoczone Emiraty Arabskie',
      ua: "Об'єднані Арабські Емірати",
      cz: 'Spojené arabské emiráty',
      by: 'Абяднаныя Арабскія Эміраты',
      pt: 'Emiratos Árabes Unidos',
      es: 'Emiratos Árabes Unidos',
      ro: 'Emiratele Arabe Unite',
      bg: 'Обединени арабски емирства',
      de: 'Vereinigte Arabische Emirate',
      fr: 'Emirats Arabes Unis',
      nl: 'Verenigde Arabische Emiraten',
      it: 'Emirati Arabi Uniti',
      cn: '阿拉伯联合酋长国',
      zh: '阿拉伯聯合酋長國',
      ee: 'Araabia Ühendemiraadid',
      jp: 'アラブ首長国連邦',
      he: 'איחוד האמירויות הערביות',
      el: 'Ηνωμένα Αραβικά Εμιράτα',
    },
    dial_code: '+971',
    code: 'AE',
    flag: '🇦🇪',
  },

  {
    name: {
      en: 'Uruguay',
      ru: 'Уругвай',
      pl: 'Urugwaj',
      ua: 'Уругвай',
      cz: 'Uruguay',
      by: 'Уругвай',
      pt: 'Uruguai',
      es: 'Uruguay',
      ro: 'Uruguay',
      bg: 'Уругвай',
      de: 'Uruguay',
      fr: 'Uruguay',
      nl: 'Uruguay',
      it: 'Uruguay',
      cn: '乌拉圭',
      zh: '烏拉圭',
      ee: 'Uruguay',
      jp: 'ウルグアイ',
      he: 'אורוגוואי',
      el: 'Ουρουγουάη',
    },
    dial_code: '+598',
    code: 'UY',
    flag: '🇺🇾',
  },
  {
    name: {
      en: 'Uzbekistan',
      ru: 'Узбекистан',
      pl: 'Uzbekistan',
      ua: 'Узбекистан',
      cz: 'Uzbekistán',
      by: 'Узбекістан',
      pt: 'Usbequistão',
      es: 'Uzbekistán',
      ro: 'Uzbekistan',
      bg: 'Узбекистан',
      de: 'Usbekistan',
      fr: 'Ouzbékistan',
      nl: 'Oezbekistan',
      it: 'Uzbekistan',
      cn: '乌兹别克斯坦',
      zh: '烏茲別克斯坦',
      ee: 'Usbekistan',
      jp: 'ウズベキスタン',
      he: 'אוזבקיסטן',
      el: 'Ουζμπεκιστάν',
    },
    dial_code: '+998',
    code: 'UZ',
    flag: '🇺🇿',
  },
  {
    name: {
      en: 'Vanuatu',
      ru: 'Вануату',
      pl: 'Vanuatu',
      ua: 'Вануату',
      cz: 'Vanuatu',
      by: 'Вануату',
      pt: 'Vanuatu',
      es: 'Vanuatu',
      ro: 'Vanuatu',
      bg: 'Вануату',
      de: 'Vanuatu',
      fr: 'Vanuatu',
      nl: 'Vanuatu',
      it: 'Vanuatu',
      cn: '瓦努阿图',
      zh: '瓦努阿圖',
      ee: 'Vanuatu',
      jp: 'バヌアツ',
      he: 'ונואטו',
      el: 'Βανουάτου',
    },
    dial_code: '+678',
    code: 'VU',
    flag: '🇻🇺',
  },
  {
    name: {
      en: 'Venezuela, Bolivarian Republic of',
      ru: 'Венесуэла, Боливарианская Республика',
      pl: 'Wenezuela, Republika Boliwariańska',
      ua: 'Венесуела, Боліваріанська Республіка',
      cz: 'Venezuela, Bolívarovská republika',
      by: 'Венесуэла',
      pt: 'Venezuela',
      es: 'Venezuela, República Bolivariana de',
      ro: 'Venezuela',
      bg: 'Венецуела',
      de: 'Venezuela',
      fr: 'Venezuela',
      nl: 'Venezuela',
      it: 'Venezuela',
      cn: '委内瑞拉',
      zh: '委內瑞拉',
      ee: 'Venezuela',
      jp: 'ベネズエラ',
      he: 'ונצואלה',
      el: 'Βενεζουέλα',
    },
    dial_code: '+58',
    code: 'VE',
    flag: '🇻🇪',
  },
  {
    name: {
      en: 'Viet Nam',
      ru: 'Вьетнам',
      pl: 'Wietnam',
      ua: "В'єтнам",
      cz: 'Vietnam',
      by: "В'етнам",
      pt: 'Vietname',
      es: 'Vietnam',
      ro: 'Vietnam',
      bg: 'Виетнам',
      de: 'Vietnam',
      fr: 'Viet Nam',
      nl: 'Vietnam',
      it: 'Viet Nam',
      cn: '越南',
      zh: '越南',
      ee: 'Vietnam',
      jp: 'ベトナム',
      he: 'וייטנאם',
      el: 'Βιετνάμ',
    },
    dial_code: '+84',
    code: 'VN',
    flag: '🇻🇳',
  },
  {
    name: {
      en: 'Virgin Islands, British',
      ru: 'Виргинские острова, Британские',
      pl: 'Wyspy Dziewicze, Brytyjskie',
      ua: 'Віргінські острови, Британські',
      cz: 'Panenské ostrovy, Britové',
      by: 'Віргінскія астравы, Брытанія',
      pt: 'Ilhas Virgens Britânicas',
      es: 'Islas Vírgenes Británicas',
      ro: 'Insulele Virgine, britanice',
      bg: 'Вирджински острови, британски',
      de: 'Virgin Inseln, Britisch',
      fr: 'Îles Vierges britanniques',
      nl: 'Britse Maagdeneilanden',
      it: 'Isole Vergini britanniche',
      cn: '英属维尔京群岛',
      zh: '英屬維爾京群島',
      ee: 'Neitsisaared, Suurbritannia',
      jp: '英領バージン諸島',
      he: 'איי הבתולה הבריטיים',
      el: 'Βρετανικές Παρθένοι Νήσοι',
    },
    dial_code: '+1284',
    code: 'VG',
    flag: '🇻🇬',
  },
  {
    name: {
      en: 'Virgin Islands, U.S.',
      ru: 'Американские Виргинские острова',
      pl: 'Wyspy Dziewicze Stanów Zjednoczonych',
      ua: 'Віргінські острови, США',
      cz: 'Panenské ostrovy, USA',
      by: 'Віргінскія астравы, ЗША',
      pt: 'Ilhas Virgens Americanas',
      es: 'Islas Vírgenes, EE.UU.',
      ro: 'Insulele Virgine, S.U.A.',
      bg: 'Вирджински острови, САЩ',
      de: 'Jungferninseln, USA',
      fr: 'Îles Vierges américaines, États-Unis',
      nl: 'Maagdeneilanden, U.s.',
      it: 'Isole Vergini, Stati Uniti',
      cn: '美国维尔京群岛',
      zh: '美國維爾京群島',
      ee: 'Neitsisaared, USA',
      jp: 'アメリカ領バージン諸島',
      he: 'איי הבתולה של ארצות הברית',
      el: 'Αμερικανικές Παρθένοι Νήσοι',
    },
    dial_code: '+1340',
    code: 'VI',
    flag: '🇻🇮',
  },
  {
    name: {
      en: 'Wallis and Futuna',
      ru: 'Уоллис и Футуна',
      pl: 'Wallis i Futuna',
      ua: 'Уолліс і Футуна',
      cz: 'Wallis a Futuna',
      by: 'Уоліс і Футуна',
      pt: 'Wallis e Futuna',
      es: 'Wallis y Futuna',
      ro: 'Wallis și Futuna',
      bg: 'Уолис и Футуна',
      de: 'Wallis und Futuna',
      fr: 'Wallis et Futuna',
      nl: 'Wallis en Futuna',
      it: 'Wallis e Futuna',
      cn: '瓦利斯和富图纳群岛',
      zh: '瓦利斯和富圖納群島',
      ee: 'Wallis ja Futuna',
      jp: 'ウォリス・フツナ',
      he: 'וואליס ופוטונה',
      el: 'Ουώλλις και Φουτούνα',
    },
    dial_code: '+681',
    code: 'WF',
    flag: '🇼🇫',
  },
  {
    name: {
      en: 'Yemen',
      ru: 'Йемен',
      pl: 'Jemen',
      ua: 'Ємен',
      cz: 'Jemen',
      by: 'Емен',
      pt: 'Iémen',
      es: 'Yemen',
      ro: 'Yemen',
      bg: 'Йемен',
      de: 'Jemen',
      fr: 'Yémen',
      nl: 'Jemen',
      it: 'Yemen',
      cn: '也门',
      zh: '也門',
      ee: 'Jeemen',
      jp: 'イエメン',
      he: 'תימן',
      el: 'Υεμένη',
    },
    dial_code: '+967',
    code: 'YE',
    flag: '🇾🇪',
  },
  {
    name: {
      en: 'Zambia',
      ru: 'Замбия',
      pl: 'Zambia',
      ua: 'Замбія',
      cz: 'Zambie',
      by: 'Замбія',
      pt: 'Zâmbia',
      es: 'Zambia',
      ro: 'Zambia',
      bg: 'Замбия',
      de: 'Sambia',
      fr: 'Zambie',
      nl: 'Zambia',
      it: 'Zambia',
      cn: '赞比亚',
      zh: '讚比亞',
      ee: 'Sambia',
      jp: 'ザンビア',
      he: 'זמביה',
      el: 'Ζάμπια',
    },
    dial_code: '+260',
    code: 'ZM',
    flag: '🇿🇲',
  },
  {
    name: {
      en: 'Zimbabwe',
      ru: 'Зимбабве',
      pl: 'Zimbabwe',
      ua: 'Зімбабве',
      cz: 'Zimbabwe',
      by: 'Зімбабвэ',
      pt: 'Zimbabué',
      es: 'Zimbabue',
      ro: 'Zimbabwe',
      bg: 'Зимбабве',
      de: 'Zimbabwe',
      fr: 'Zimbabwe',
      nl: 'Zimbabwe',
      it: 'Zimbabwe',
      cn: '津巴布韦',
      zh: '津巴布韋',
      ee: 'Zimbabwe',
      jp: 'ジンバブエ',
      he: 'זימבבואה',
      el: 'Ζιμπάμπουε',
    },
    dial_code: '+263',
    code: 'ZW',
    flag: '🇿🇼',
  },
  {
    name: {
      en: 'Åland Islands',
      ru: 'Аландские острова',
      pl: 'Wyspy Alandzkie',
      ua: 'Аландські острови',
      cz: 'Alandy',
      by: 'Аландскія астравы',
      pt: 'Ilhas Aland',
      es: 'Islas Åland',
      ro: 'Insulele Aland',
      bg: 'Аландски острови',
      de: 'Aland Islands',
      fr: 'Iles Aland',
      nl: 'Aland-eilanden',
      it: 'Isole Aland',
      cn: '奥兰群岛',
      zh: '奧蘭群島',
      ee: 'Alandi saared',
      jp: 'オーランド諸島',
      he: 'איי אלנד',
      el: 'Νήσοι Άλαντ',
    },
    dial_code: '+358',
    code: 'AX',
    flag: '🇦🇽',
  },
]
