import {
  View,
  StyleSheet,
  Image,
  ImageBackground,
  ScrollView,
  useWindowDimensions,
} from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FilePicker, Loader, Text } from '@libs/components'

import { isWeb } from '@libs/utils/src/screenLayout'
import ModalComponent from '../../components/modal'
import { useTheme } from '@libs/theme'

const DesktopView = ({
  ctaEnable,
  handleChange,
  successPopup,
  setSuccessPopup,
  isProfileUpdating,
  handleSave,
  getImageSource,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const web = isWeb(width)
  return (
    <ImageBackground
      // source={ProfileBg}
      style={{ flex: 1 }}
      imageStyle={{
        width: '60%',
        position: 'absolute',
        top: 0,
        left: '40%',
      }}
      // resizeMode="contain"
    >
      <ScrollView style={{ flex: 1 }}>
        <View style={styles.mainContainer(web)}>
          <View style={styles.profileContainer}>
            <View style={styles.profileHeader}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text
                  variant="heading3"
                  color={colors.white}
                  style={{
                    width: '100%',
                  }}
                >
                  Change Profile Picture
                </Text>
              </View>
            </View>
            <View style={styles.formContainer}>
              <View style={styles.imageContainer}>
                <View
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: 92.5,
                    overflow: 'hidden',
                  }}
                >
                  <Image
                    source={getImageSource()}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </View>
                <View style={styles.cameraIcon}>
                  <FilePicker
                    isProfile
                    handleFileSelect={(image) =>
                      handleChange('profilePictureLocation', image)
                    }
                  />
                </View>
              </View>
              {isProfileUpdating ? (
                <View style={{ marginBottom: 30 }}>
                  <Loader size={32} hasFlexValue={false} />
                </View>
              ) : (
                <Button
                  label={t('BUTTON.SAVE')}
                  buttonColor="#ECF2FC"
                  labelStyle={{ color: colors.primaryVariant3 }}
                  buttonStyle={{ marginVertical: 30 }}
                  onPress={() => handleSave()}
                  disable={!ctaEnable}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <ModalComponent
        visible={successPopup.visible}
        title={successPopup.message}
        buttonLabel={t('BUTTON.OK')}
        handleCloseModal={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        handleButton={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        buttonColor={colors.primaryContainer}
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  mainContainer: (web) => ({
    paddingVertical: 60,
    paddingHorizontal: web ? 100 : 30,
  }),
  profileContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    shadowColor: 'rgba(0, 15, 68, 0.15)',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 20,
  },
  profileHeader: {
    height: 115,
    backgroundColor: '#0B5CD7',
    paddingVertical: 25,
    paddingHorizontal: 56,
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'center',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  imageContainer: {
    height: 185,
    width: 185,
    borderRadius: 92.5,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    backgroundColor: '#FFFFFF',
  },
  cameraIcon: {
    height: 36,
    width: 36,
    borderRadius: 18,
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 140,
    left: 115,
  },
  formContainer: {
    alignSelf: 'center',
    flexDirection: 'column',
    flex: 1,
    paddingTop: 30,
  },
})

export default DesktopView
