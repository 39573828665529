export default {
  ACCORDION: {
    PROGRAMMES: 'Programmes',
  },
  ALERT: {
    INVALID_EMAIL:
      'Bitte versuchen Sie es mit einer anderen E-Mail-ID. Wir konnten in unserem System keine Anwendungen finden, die dieser E-Mail-Adresse entsprechen.',
    PASSWORD_CHANGE_SUCCESS: 'Das Passwort wurde erfolgreich geändert.',
    MULTI_SAME_PROGRAM:
      'Sie können derselben Marke nicht mehr als ein Programm hinzufügen.',
    PROGRAM_ALREADY_EXIST:
      'Dieses Programm ist bereits in Ihrem Warenkorb vorhanden.',
    PROGRAM_ID_ALREADY_EXIST:
      'Das Programm wurde zum Vergleich bereits hinzugefügt.',
  },
  APPLICATION: {
    CONGRATULATION: 'Glückwunsch!',
    DESCRIPTION: 'Beschreibung',
    HEADING: 'Programm Anwendung',
    LEVEL: 'Ebene',
    OFFER_DESCRIPTION: 'Sie haben ein Angebot zur Bewerbung erhalten',
    PROGRAMME_INFORMATION: 'Programminformationen',
    SUBJECT: 'Thema',
  },
  APPLICATION_BASKET: {
    HEADING1: 'Vielen Dank',
    HEADING2: 'Anwendungen in Bearbeitung',
    SUB_HEADING1: 'Die Schülerdetails wurden erfolgreich übermittelt!',
    SUB_HEADING2: 'Ihre Anfrage wurde erfolgreich übermittelt!',
    STUDENT_DETAIL_CARD_DESCRIPTION1: `Bitte geben Sie dem Zulassungsteam zusätzliche wesentliche persönliche Informationen, die den Antragsprozess erleichtern.`,
    STUDENT_DETAIL_CARD_DESCRIPTION2: `Diese Informationen werden automatisch in zukünftigen Anträgen angewendet und vorausgefüllt.`,
    TITLE: 'Schülerdetails',
    APPLICATION_CARD_DESCRIPTION: `Bitte geben Sie spezifische Informationen zur Marke an, die für dieses Programm wichtig sind.`,
    DESCRIPTION: `Sie können den Fortschritt Ihrer unten stehenden Anfrage(n) im Tab "Meine Anfragen" verfolgen. Während der Überprüfung der Zulassung können wir Sie für weitere Aktualisierungen zu Ihrer Anfrage kontaktieren. Sie können Ihr Konto hier überprüfen oder die mit Ihrer Anfrage verknüpfte E-Mail-Adresse auf Benachrichtigungen zum Fortschritt Ihrer Anfrage überprüfen.`,
  },
  APPLICATION_DETAIL: {
    ADMISSION_NOTES: 'Zulassungshinweise',
    ADMISSIONS_LETTER: 'Zulassungsbescheide',
    AGENT_ACCOUNT: 'Agenten konto',
    AGENT_CONTACT: 'Agentenkontakt',
    AGENT_EMAIL: 'E-Mail des Agenten',
    AGENT_PHONE: 'Telefon des Agenten',
    ANY_QUESTION: 'Habt ihr Fragen?',
    APPLICATION_NO: 'Antrags Nr:',
    APPLICATION_OWNER: 'Anwendungen Eigentümer',
    ATTACH_DOCUMENTS: 'Dokumente Anhängen',
    BUSINESS_DEVELOPER: 'Business Developer',
    COMMENTS: 'Kommentare',
    CONDITIONS: 'Bedingungen',
    CONTACT_TEXT: 'Kontaktieren',
    CREATED: 'Erstellt:',
    DOCUMENTS_LETTER: 'Unterlagen',
    EMAIL: 'Mit dieser Anwendung verknüpfte E-Mail-Adresse',
    FULL_NAME: 'Vollständiger Name (wie im Reisepass)',
    INFORMATION: 'Information',
    OFFERS: 'Bietet an',
    PHONE_NUMBER: 'Mit dieser Anwendung verknüpfte Telefonnummer',
    INTAKE: 'Aufnahme',
    DURATION: 'Dauer',
    LOCATION: 'Emplacement',
    CREATED_DATE: 'Erstellungsdatum',
    MEETING_LINK: 'Büchertreffen',
    BOOK_A_MEETING: 'Buchen Sie ein Treffen',
    STATUS: 'Status',
    ADVISOR: 'Berater',
    CREATED_BY: 'Erstellt von',
  },
  AUTH: {
    FOOTER_LINK: 'Global University Systems',
    FOOTER: 'Lerne mehr über ',
    FORGOT_PASSWORD: 'Haben Sie Ihr Passwort vergessen',
    LOGIN: 'Einloggen',
    OR: 'Oder melden Sie sich mit an',
    OTP_SEND:
      'Wir haben ein OTP an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang.',
    PASSWORD_RESET_SUCCESSFULLY:
      'Ihr Passwort wurde erfolgreich zurückgesetzt.',
    PRIVACY_POLICY: 'Datenschutzvereinbarung',
    GET_STARTED: 'Lass uns anfangen!',
    REGISTER: 'Registrieren',
    ACCOUNT_SIGNUP: 'Für einen Account anmelden',
    ACCOUNT_SIGNIN: 'Melden Sie sich bei Ihrem Konto an',
    SET_PASSWORD: 'Richten Sie Ihr Passwort ein!',
    WELCOME_DESCRIPTION:
      'Schöpfen Sie Ihr Potenzial mit karrierebereiten Programmen an internationalen Top-Destinationen aus',
    WELCOME_TITLE: `Entdecken Sie eine Welt voller Möglichkeiten`,
    WELCOME: 'Willkommen zurück! Bitte geben Sie Ihre Daten ein',
    BRAND_ITEM_1_TITLE: 'Branchenrelevante Qualifikationen',
    BRAND_ITEM_1_CONTENT:
      'Steigern Sie Ihr Leben und Ihre Karriere mit Programmen, die Sie auf die berufliche Zukunft vorbereiten sollen.',
    BRAND_ITEM_2_TITLE: 'Preisgekrönte Institutionen',
    BRAND_ITEM_2_CONTENT:
      'Studieren Sie an unseren mehrfach akkreditierten und mehrfach preisgekrönten Institutionen in den lebendigsten, wohlhabendsten und dynamischsten Städten der Welt.',
    OTP_VERIFICATION: 'OTP Verification',
    OTP_RESET: 'Verify and set a new password!',
  },
  BUTTON: {
    ACCEPT: 'Akzeptieren',
    ADD_TO_APPLICATION: 'Zur Bewerbung hinzufügen',
    ADD_TO_COMPARE: 'Zur Bewerbung hinzufügen',
    APPLY: 'Anwenden',
    ATTACH_DOCUMENT: 'Dokumente anhängen',
    CANCEL: 'Stornieren',
    CONFIRM: 'Bestätigen',
    CONTINUE_APPLICATION: 'Bewerbung fortsetzen',
    DECLINE: 'Abfall',
    DOWNLOAD_OFFER: 'Abfall',
    GO_TO_MY_APPLICATION: 'Gehen Sie zu Meine Bewerbungen.',
    GO_TO_PATH_FINDER: 'Zum Pfadfinder gehen',
    LOGIN: 'Einloggen',
    SIGNIN: 'Anmelden',
    LOGOUT: 'Abmelden',
    MORE_INFORMATION: `Mehr Informationen`,
    NEW_USER: 'Neuer Benutzer',
    OK: `In Ordnung`,
    PROCEED: 'Fortfahren',
    REMOVE_APPLICATION: 'Entfernen',
    SAVE: 'Zum Schutz',
    SIGNUP: `Registrieren`,
    SET_PASSWORD: 'Passwort festlegen',
    START_APPLICATION: `Starten Sie die Anwendung`,
    SUBMIT: 'Einreichen',
  },
  COMPARE_SCREEN: {
    CLEAR_COMPARE_PROGRAM: 'Klarer Vergleich',
    COMPARE_PROGRAM: 'Vergleichen Sie Programme',
    INSTITUTION: 'Institution',
    LEVEL: 'Ebene',
    DELIVERY_MODE: 'Liefer Modus',
    PROGRAM_DURATION: 'Programm Dauer',
    COURSE_PRICE: 'Kurspreis',
    FIELD_OF_STUDY: 'Feld Vom Studium',
    LOCATION: 'Standort',
  },
  DASHBOARD: {
    CLEAR_FILTER: 'Filter löschen',
    COUNTRY: 'Land',
    HERO_DESCRIPTION:
      'Steigern Sie Ihr Leben und Ihre Karriere mit höherer Bildung. Global University Systems ist stolz darauf, die innovativsten und zukunftsorientiertesten Institutionen der Erde zu besitzen und mit ihnen zusammenzuarbeiten, um branchenrelevante Qualifikationen mit Schwerpunkt auf angewandtem, praktischem Lernen in den führenden Volkswirtschaften der Welt zu vermitteln.',
    HERO_HEADER: 'Entdecken Sie eine Welt voller Möglichkeiten',
    HERO_SUB_HEADER:
      'Schöpfen Sie Ihr Potenzial mit karrierebereiten Programmen an internationalen Top-Destinationen aus',
    INSTITUTION: 'Institution',
    INTAKE: 'Aufnahme',
    LEVEL: 'Ebene',
    LOAD_MORE: 'Mehr laden',
    NO_PROGRAM: 'Derzeit ist kein Programm verfügbar.',
    SHOW_BY: 'Anzeigen von',
    SHOW_MORE: 'Zeig mehr',
    SUBJECT: 'Thema',
  },
  DROP_DOWN: {
    CHANGE_APPLICATION: 'Informationen ändern',
    DOWNLOAD_APPLICATION: 'Anwendung herunterladen',
    INSTITUTION: 'Institution',
    LABEL_SELECT: 'Wählen',
    PROGRAMMES: 'Programme',
  },
  ERROR_MESSAGE: {
    CAN_NOT_BE_EMPTY: 'Darf nicht leer sein',
    INVALID: 'Ungültig',
    NOT_MATCHING: 'stimmt nicht überein',
    NOT_BE_SAME: 'darf nicht dasselbe sein wie',
    INVALID_EMAIL: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    COUNTRY_NOT_SLEETED: 'Land darf nicht leer sein',
    PASSWORD_NOT_MATCH: 'Passwort stimmt nicht überein',
    DOCUMENT_NOT_AVAILABLE_TEXT1: 'Das Dokument',
    DOCUMENT_NOT_AVAILABLE_TEXT2:
      'zum Download angefordert ist nicht verfügbar.Bitte kontaktieren Sie den Administrator.',
  },
  FILE_UPLOAD: {
    ATTACH_DOCUMENT: 'Dokument anhängen',
    DOCUMENT_TYPE: 'Dokument Typ',
  },
  HEADER: {
    CART_TITLE: 'Bewerbungskorb',
    COMPARE_PROGRAM: 'Vergleichen Sie Programme',
    FIND_PROGRAM: 'Pfad Finder',
    MY_APPLICATION: 'Meine Anwendungen',
    START_APPLICATION: 'Anwendungen',
  },
  LABEL: {
    COMPLETED: 'Abgeschlossen',
  },
  LINK: {
    ACCOUNT_EXIST: 'Sie haben bereits ein Konto? anmelden',
    HAVE_ACCOUNT: 'Sie haben bereits ein Konto?',
    DONT_HAVE_ACCOUNT: 'Sie haben noch kein Konto?',
    FORGOT_PASSWORD: 'Haben Sie Ihr Passwort vergessen?',
    LINKED_IN: 'Weiter mit Linkedin',
    LINKED_TEXT: 'LinkedIn',
    MORE_INFO: 'Mehr Info',
  },
  MY_APPLICATION: {
    AGENT_DRAFT_ALERT: `Hier werden die Antragsentwürfe der Agenten platziert. Sie können keine Änderungen vornehmen oder den Antrag überprüfen, bis der Agent den Antrag ausgefüllt hat.`,
    ALL_APPLICATION: 'Alle Anwendungen',
    ADMISSIONS_REVIEW: 'Zulassungsprüfung',
    OFFERS: 'Bietet an',
    DRAFT: 'Entwürfe',
    AGENT_DRAFT: `Agentenentwurf`,
    NO_DATA: `Hier ist noch nichts!`,
    VISA_PENDING: `Visum ausstehend`,
    ACCEPTED: 'Akzeptiert',
    ENROLLED: 'Eingeschrieben',
    REJECTED: 'Abgelehnt/zurückgezogen',
  },
  OPPORTUNITIES_CARD: {
    DURATION: 'monate Programm',
    ESTIMATED_TUITION: 'Kostenvoranschlag',
  },
  PASSWORD_VALIDATION: {
    VALIDATION_1: 'Mindestens 6 Zeichen mit einem Großbuchstaben',
    VALIDATION_2: 'Mindestens ein Sonderzeichen',
    VALIDATION_3: 'Zumindest eine Nummer',
  },
  PRIVACY_POLICY: {
    CHECK_BOX_1_TEXT: `Durch die Registrierung und den Zugriff auf diese Website erkläre ich mich damit einverstanden, sich an die`,
    CHECK_BOX_1_LINK_TEXT: 'Nutzungsrichtlinien',
    CHECK_BOX_2_TEXT: `Durch die Registrierung und den Zugriff auf diese Website willige ich ein, Informationen (per E-Mail, Telefon oder Textnachricht/SMS) über die Kurse und Dienstleistungen sowie`,
    CHECK_BOX_2_LINK_TEXT:
      'von Institutionen, die mit oder innerhalb der GUS-Gruppe zusammenarbeiten',
    FIRST_PARAGRAPH_1: `AppHero ist ein Tool, das von den Unternehmen der Global University Systems-Gruppe (die`,
    FIRST_PARAGRAPH_2: `) und Partnerinstitutionen für den Datenaustausch im Zusammenhang mit den Bewerbungs- und Zulassungsprozessen dieser Institutionen verwendet wird. Apphero.io ist eine Website, die von Global University Systems B.V. (“Wir”) betrieben wird. Wir sind in den Niederlanden unter der Handelsregisternummer 55367453 registriert und haben unseren Sitz und unseren Hauptgeschäftssitz in Strawinskylaan 821, Tower A08, 1077XX, Amsterdam, Niederlande. Wir betreiben die Website für uns selbst und auch im Namen anderer Unternehmen, die unter den Markennamen GUS oder GUS betrieben werden oder die mit GUS oder GUS-Markennamen zusammenarbeiten, um Studenten zu werben.`,
    FIRST_PARAGRAPH_LINK_TEXT: 'GUS-Gruppe',
    SECOND_PARAGRAPH_1: `Die von Ihnen auf diesem Formular bereitgestellten Informationen werden gemäß unserer`,
    SECOND_PARAGRAPH_2: `verarbeitet. Wir werden die von Ihnen bereitgestellten Details verwenden, um Sie in Bezug auf Ihren Bewerbungs- und Zulassungsprozess bei der Institution oder den Institutionen, bei denen Sie sich beworben haben, zu kontaktieren.`,
    SECOND_PARAGRAPH_LINK_TEXT: 'Datenschutzrichtlinien',
  },
  PROFILE: {
    CHANGE_PASSWORD: 'Kennwort ändern',
    PERSONAL_INFORMATION: 'Persönliche Informationen',
  },
  PROGRAM_SCREEN: {
    ADD_TO_COMPARISON: 'Zum Vergleich hinzufügen',
    BRAND: 'Marke:',
    DELIVERY: 'Lieferung: ',
    DOWNTOWN_DESCRIPTION: `Zwei leicht zugängliche Campusstandorte in der pulsierenden Innenstadt von Vancouver, Kanada`,
    DOWNTOWN: 'Innenstadtlage',
    DURATION: 'Dauer:',
    LEVEL: 'Ebene:',
    LOCATION: 'Standort:',
    MEDAL_DESCRIPTION: `Vom Hochschulbewertungssystem QS Stars als Fünf-Sterne-Institution eingestuft.`,
    PROGRAM_PRICE_DESCRIPTION: `Die nachstehenden Preisprognosen können sich aufgrund von Werbeaktionen, Stipendien, regionalen Rabatten usw. ändern. Nachfolgend finden Sie einige Beispiele für mögliche Preise, die auf diesen Faktoren basieren. Die endgültige Berechnung Ihres Kurspreises entnehmen Sie bitte Ihrem Angebotsschreiben.`,
    PROGRAM_PRICE: 'Programm Preis',
    PROVEN_EMPLOYABILITY_DESCRIPTION: `100 % der MBA-Studenten haben innerhalb von sechs Monaten nach ihrem Abschluss eine Anstellung gefunden.`,
    PROVEN_EMPLOYABILITY: 'Nachgewiesene Beschäftigungsfähigkeit',
    RATING: 'Bewertung',
    RECOMMENDED_PROGRAM: 'Empfohlene Programme',
    SCHOOL: 'Schule:',
    STAR: ' 5-Sterne-QS',
    START_TO_APPLICATION: 'Anwendungen',
  },
  TEXT_INPUT: {
    AS_PER_PASSPORT: 'Laut Reisepass',
    CODE: 'OTP',
    CONFIRM_PASSWORD: 'Bestätigen Sie das Passwort',
    COUNTRY: 'Land',
    CURRENT_PASSWORD: 'Aktuelles Passwort',
    EMAIL: 'Email',
    FIRST_NAME: 'Prénom',
    LAST_NAME: 'Nachname',
    NEW_PASSWORD: 'Einloggen',
    PASSWORD: 'Passwort',
    PHONE: 'Telefonnummer',
    REPEAT_PASSWORD: 'Passwort wiederholen',
    USER_NAME: `Benutzername / EMail`,
  },
}
