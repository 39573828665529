import React, { useEffect, useState } from 'react'
import {
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { Link, Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native-web'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import LinearGradient from 'react-native-linear-gradient'
import { Icon } from '@app-hero/native-icons'
import { AuthLoader } from '@libs/components'
import { AuthLeftContainer } from '../../components'
// import { languageDropDown } from '../../constants'
import { gusUrl } from '../../utils/constants'
import { handleNavigation } from '../../utils/function'
import { loadingMessage } from '../../constants'
import { useAtom } from 'jotai'
import {
  OTPVerification,
  brandDetailsAtom,
  errorMessageAtom,
  signUpDetailsAtom,
} from '../../utils/atom'
import { useTheme } from '@libs/theme'
import { SecureStore } from '@libs/utils'

const DesktopView = ({
  dropdownLeft,
  dropdownTop,
  dropdownWidth,
  handleLanguageChange,
  renderModule,
  showVideo,
  toggleDropdown,
  userSelectedLanguage,
  routeName,
  isLoading,
  brandLogoUrl,
  ...props
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const navigation = useNavigation()
  const [brandDetails] = useAtom(brandDetailsAtom)
  const [showOTPVerification, setshowOTPVerification] = useAtom(OTPVerification)
  const [, setError] = useAtom(errorMessageAtom)
  const { width } = useWindowDimensions()
  const [signUpDetails, setSignupDetails] = useAtom(signUpDetailsAtom)

  const handleDeviceWidth = () => {
    if (routeName === 'privacyPolicy') return '100%'
    if (window.devicePixelRatio === 2) return '70%'
    return '100%'
  }

  if (isLoading)
    return (
      <AuthLoader
        label={loadingMessage?.[routeName]?.label}
        subText={loadingMessage?.[routeName]?.subText}
      />
    )

  return (
    <View style={styles.container(width)}>
      <View style={styles.leftContainer}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <LinearGradient
            colors={[
              brandDetails?.brand ? colors.white : '#0D1B2A',
              brandDetails?.brand ? colors.white : '#0D1B2A',
            ]}
            style={styles.mainContainer}
            start={{ x: 0.2, y: 0.8 }}
            end={{ x: 1, y: 0 }}
          >
            <AuthLeftContainer brandLogoUrl={brandLogoUrl} />
          </LinearGradient>
        </ScrollView>
      </View>
      <View
        style={[
          styles.rightContainer,
          { backgroundColor: showVideo ? 'rgba(0,0,0,0.2)' : '' },
        ]}
      >
        <View style={styles.header}>
          {/* <DropDown
            items={languageDropDown}
            value={userSelectedLanguage}
            toggleDropdown={toggleDropdown}
            position={{ left: dropdownLeft, top: dropdownTop }}
            dropdownWidth={dropdownWidth}
            labelColor={showVideo ? colors.white : ''}
            onPress={(selectedLanguage) =>
              handleLanguageChange(selectedLanguage)
            }
          /> */}
        </View>

        <ScrollView
          style={{
            flex: 1,
            backgroundColor: showVideo ? 'rgba(62, 137, 219, 0.3)' : '',
            borderRadius: 20,
          }}
        >
          {routeName !== 'privacyPolicy' &&
            routeName !== 'login' &&
            routeName !== 'Otp' && (
              <View style={styles.leftHeader}>
                <TouchableOpacity
                  onPress={() => {
                    setError('')
                    if (showOTPVerification) {
                      setshowOTPVerification(false)
                      return
                    }
                    if (navigation.canGoBack()) {
                      navigation.goBack()
                    } else navigation.navigate('login')
                  }}
                >
                  <Icon name="ArrowNarrowLeft" color={colors.neutral} />
                </TouchableOpacity>
              </View>
            )}
          {routeName === 'setPassword' && (
            <View style={styles.leftHeader}>
              <TouchableOpacity
                onPress={() => {
                  setError('')
                  setSignupDetails((prevDetails) => ({
                    ...prevDetails,
                    password: '',
                    repeatPassword: '',
                  }))
                  if (showOTPVerification) {
                    setshowOTPVerification(false)
                    return
                  }
                  if (navigation.canGoBack()) {
                    navigation.goBack()
                  } else navigation.navigate('login')
                }}
              >
                <Icon name="ArrowNarrowLeft" color={colors.neutral} />
              </TouchableOpacity>
            </View>
          )}

          <View
            style={[
              {
                flex: 1,
                overflow: 'hidden',
                alignSelf: 'center',
                width: handleDeviceWidth(),
              },
            ]}
          >
            {renderModule(props)}
          </View>
        </ScrollView>
        {/* <View
          style={[
            styles.footer,
            {
              marginTop: 40,
            },
          ]}
        >
          <Text variant="display5">{t('AUTH.FOOTER')}</Text>
          <Link
            label={t('AUTH.FOOTER_LINK')}
            labelVariant="display5"
            handlePress={() =>
              handleNavigation({ url: gusUrl, canValidate: false })
            }
            underline
          />
        </View> */}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: () => ({
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'white',
  }),
  leftContainer: { flex: 1, margin: '2rem' },
  mainContainer: {
    borderRadius: 16,
    height: window.innerWidth < 1280 ? '85vh' : '90vh',
  },
  rightContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 30,
    height: 24,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftHeader: {
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 10,
  },
})

export default DesktopView
