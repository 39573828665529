import { Text } from 'react-native'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { ScreenLayout, SecureStore } from '@libs/utils'
import { useAtom } from 'jotai'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import DesktopView from './DesktopView'
import { userProfile } from '../../utils/atom'
import { getUserDetails } from '../../api'

const StudentDetail = (props) => {
  const { routeName } = props?.route?.params
  const [url, setUrl] = useState()
  const [userData] = useAtom(userProfile)
  const isFocused = useIsFocused()
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, DesktopView, DesktopView),
    [],
  )

  const navigation = useNavigation()

  const { data, isFetching } = useQuery({
    queryKey: ['getUserDetailsAPI'],
    queryFn: () => getUserDetails({ email: userData?.email }),
    enabled: isFocused && !!userData?.email,
    initialData: [],
  })

  useEffect(() => {
    if (!isFocused || isFetching) return
      ; (async () => {
        const config = await SecureStore.getItemAsync('config')
        const configUrl = JSON.parse(config)
        setUrl(
          `${configUrl?.config?.studentDetailUrl}/${routeName}`,
        )
      })()
  }, [userData, isFocused, isFetching, data])


  useEffect(() => {
    const sendMessageToIframe = async () => {
      const iframe = document.getElementById('student-detail');
      const config = await SecureStore.getItemAsync('config')
      const configUrl = JSON.parse(config)


      if (url !== null && iframe && userData) {
        const iframeLoadHandler = () => {

          const message = JSON.stringify({
            userDetails: {
              email: userData?.email,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              phoneNumber: data?.phoneNumber
            }
          });

          if (iframe.contentWindow) {
            iframe.contentWindow.postMessage(message, configUrl?.config?.studentDetailUrl);
          }

          iframe.removeEventListener('load', iframeLoadHandler);
        };
        iframe.addEventListener('load', iframeLoadHandler);
      }
    };

    sendMessageToIframe();
  }, [url, userData]);


  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.pathName === '/thank-you') {
        navigation.navigate('application-basket')
      }
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const viewProps = {
    url,
    isFetching,
  }
  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default StudentDetail
