import React, { useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { Text } from '@libs/components'
import { BrandImage } from '@apphero/assets'
import { spacing, useTheme } from '@libs/theme'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useAtom } from 'jotai'
import { isMobile, isWeb } from '@libs/utils/src/screenLayout'
import { SecureStore } from '@libs/utils'
import { brandDetailsAtom, userProfile } from '../../utils/atom'

const HeaderLeft = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const { colors } = useTheme()
  const route = useRoute()
  const [userDetails] = useAtom(userProfile)
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const isDesktop = isWeb(width)

  const [brandDetails] = useAtom(brandDetailsAtom)
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const isFocused = useIsFocused()

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  let marginHorizontal

  if (userDetails && userDetails.canApply) {
    marginHorizontal = 0
  } else if (mobile) {
    marginHorizontal = 5
  } else {
    marginHorizontal = 30
  }

  return (
    <View style={styles.headerContainer(mobile)}>
      <TouchableOpacity onPress={() => navigation.navigate('my-application')}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image
            source={BrandImage}
            style={{ height: 38, width: mobile ? 116 : 146 }}
            resizeMode="contain"
          />
          {brandDetails.brand && brandLogoUrl && (
            <>
              <View
                style={{
                  height: '1.15rem',
                  width: 2,
                  backgroundColor: colors.neutral,
                  marginHorizontal: '0.75rem',
                }}
              />
              <Image
                source={`${brandLogoUrl}/${brandDetails.brand?.replaceAll(
                  ' ',
                  '_',
                )}.png`}
                style={{ height: 60, width: 146 }}
                resizeMode="contain"
                alt={brandDetails.brand}
              />
            </>
          )}
        </View>
      </TouchableOpacity>

      {isDesktop && userDetails?.canApply ? (
        <View style={styles.divider} />
      ) : (
        ''
      )}
      <View style={{ flexDirection: 'row', columnGap: '20%' }}>
        {isDesktop && (
          <TouchableOpacity
            onPress={() => navigation.navigate('my-application')}
            style={{
              marginHorizontal,
            }}
          >
            <Text
              color={
                route?.name === 'my-application'
                  ? colors.draftBlue
                  : colors.onNeutral
              }
              style={{
                fontWeight: route?.name === 'my-application' ? 700 : 400,
              }}
            >
              {t('HEADER.MY_APPLICATION')}
            </Text>
            {route?.name === 'my-application' ? (
              <View
                style={{
                  position: 'absolute',
                  bottom: -30,
                  left: 0,
                  width: '100%',
                  borderRadius: 20,
                  height: 5,
                  backgroundColor: colors.draftBlue,
                }}
              />
            ) : (
              ''
            )}
          </TouchableOpacity>
        )}
        {userDetails?.canApply && isDesktop && (
          <TouchableOpacity onPress={() => navigation.navigate('path-finder')}>
            <Text
              color={
                route?.name === 'path-finder'
                  ? colors.draftBlue
                  : colors.onNeutral
              }
              style={{
                fontWeight: route?.name === 'path-finder' ? 700 : 400,
              }}
            >
              {t('HEADER.EXPLORE_PATHWAYS')}
            </Text>
            {route?.name === 'path-finder' ? (
              <View
                style={{
                  position: 'absolute',
                  bottom: -30,
                  left: 0,
                  width: '100%',
                  borderRadius: 20,
                  height: 5,
                  backgroundColor: colors.draftBlue,
                }}
              />
            ) : (
              ''
            )}
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  headerContainer: (mobile) => ({
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    paddingLeft: mobile ? 24 : 90,
    paddingVertical: spacing.spacing6,
    // border: '1px solid red',
  }),

  divider: {
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 1)',
    borderStyle: 'solid',
    height: 20,
    opacity: '20%',
    marginLeft: '9.8%',
    marginRight: '10.4%',
  },
})

export default HeaderLeft
