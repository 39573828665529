/* eslint-disable no-nested-ternary */
import { View, StyleSheet, ScrollView } from 'react-native'
import React, { useState } from 'react'
import { DropDown, ShimmerPlaceholder, Text } from '@libs/components'

import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { OfferCard, OpportunitiesCard } from '../../components'
import EmptyContainer from '../../components/container/EmptyContainer'
import { sort } from '../../utils/sorting'
import WelcomeCard from '../../components/welcome/welcome'
import Drawer from '../../components/Drawer/drawer'

const MobileView = ({
  activeTab,
  data,
  brandLogoUrl,
  filterData,
  isLoading,
  offeredCards,
  onChangeDropdown,
  userName,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()

  const [selectedItem, setSelectedItem] = useState(filterData[0])

  const handleItemSelect = (item, index) => {
    setSelectedItem(item)
    onChangeDropdown(item, index)
  }

  return (
    <ScrollView style={[styles.mainContainer]}>
      <>
        {isLoading ? (
          <ShimmerPlaceholder
            style={{
              width: '100%',
              height: 160,
              borderRadius: spacing.spacing5,
            }}
          />
        ) : (
          <WelcomeCard name={userName} />
        )}
        <Text
          style={{
            marginVertical: 24,
            fontWeight: 700,
            color: '#0F172A',
            fontSize: 24,
          }}
        >
          {t('HEADER.MY_APPLICATION')}
        </Text>
        {/* <DropDown
          items={filterData}
          toggleDropdown={toggleDropdown}
          position={{ left: dropdownLeft, top: dropdownTop }}
          dropdownWidth={dropdownWidth}
          onPress={(selectedOption, index) =>
            onChangeDropdown(selectedOption, index)
          }
          dropdownHeight={300}
          defaultValue={filterData[1]}
          labelColor={colors.draftBlue}
          labelStyle={{ fontWeight: 600, fontSize: 15, paddingLeft: 5 }}
          iconColor={colors.draftBlue}
          iconSize={24}
          hasNoBorder={true}
          hasNoBorderStyle={{
            backgroundColor: 'white',
            borderRadius: 16,
            paddingHorizontal: 24,
          }}
        /> */}

        <Drawer
          items={filterData}
          onItemSelect={(item, index) => handleItemSelect(item, index)}
          selectedItem={selectedItem}
          labelColor={colors.draftBlue}
          iconColor={colors.draftBlue}
          hasNoBorder={true}
          labelStyle={{
            fontWeight: 600,
            fontSize: 15,
            paddingLeft: 5,
            color: colors.draftBlue,
          }}
          hasNoBorderStyle={{
            backgroundColor: 'white',
            borderRadius: 16,
            paddingHorizontal: 24,
          }}
          iconSize={24}
        />

        <View style={{ flex: 1 }}>
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            {/* {offeredCards?.length > 0 &&
            filterData[activeTab]?.fieldName === 'Offer' ? (
              <OfferCard data={offeredCards} />
            ) : null} */}
            {filterData[activeTab]?.fieldName === 'AgentDraft' ? (
              <View
                style={{
                  padding: 15,
                  flexDirection: 'row',
                  backgroundColor: colors.primaryVariant,
                  flex: 'wrap',
                  borderRadius: 15,
                }}
              >
                <Icon name="FeaturedIcon" height={34} width={34} />
                <View style={{ marginLeft: 15, flex: 'wrap', maxWidth: '90%' }}>
                  <Text variant="display4" color={colors.onNeutral}>
                    {t('MY_APPLICATION.AGENT_DRAFT_ALERT')}
                  </Text>
                </View>
              </View>
            ) : null}
            {isLoading ? (
              <SkeletonLoaderGrid />
            ) : (
              <View
                style={{
                  flexDirection: 'column',
                  rowGap: 16,
                }}
              >
                {data[filterData[activeTab]?.fieldName]?.length > 0 ? (
                  sort(
                    data[filterData[activeTab].fieldName],
                    'CreatedDate',
                  )?.map((item) => (
                    <OpportunitiesCard
                      data={item}
                      brandLogo={`${brandLogoUrl}/${item.BusinessUnitFilter__c?.replaceAll(
                        ' ',
                        '_',
                      )}.png`}
                      disable={
                        filterData[activeTab]?.fieldName === 'AgentDraft'
                      }
                      style={{
                        minWidth: '30%',
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                      width={150}
                      height={75}
                    />
                  ))
                ) : (
                  <EmptyContainer />
                )}
              </View>
            )}
          </ScrollView>
        </View>
      </>
    </ScrollView>
  )
}

const SkeletonLoaderGrid = () => (
  <View style={styles.loaderGrid}>
    {[1, 2, 3, 4].map(() => (
      <ShimmerPlaceholder
        style={{
          width: '100%',
          height: 250,
          borderRadius: spacing.spacing5,
        }}
      />
    ))}
  </View>
)

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 24,
    flexDirection: 'column',
    backgroundColor: 'rgba(244, 245, 251, 1)',
  },
  loaderGrid: {
    flexDirection: 'column',
    marginTop: 10,
    paddingHorizontal: 12,
    flexWrap: 'wrap',
    gap: 20,
  },
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
  },
  item: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  // blurContainer: {
  //   flex: 1,
  //   textAlign: 'center',
  //   justifyContent: 'center',
  //   overflow: 'hidden',
  //   borderRadius: 20,
  //   backgroundColor: ' rgba(22, 36, 71, 0.24)',
  // },
})

export default MobileView
