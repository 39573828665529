import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'

const EmptyNotificationContainer = () => {
  const { colors } = useTheme()
  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        rowGap: 20,
      }}
    >
      <Icon name="NotificationBell" width={44} height={44} />
      <Text
        style={{
          color: colors.primaryText,
          fontSize: 24,
          fontWeight: 700,
        }}
      >
        No Notifications Yet
      </Text>
      <View style={{ alignItems: 'center' }}>
        <Text style={styles.noNotification}>
          You have no notifications for now,
        </Text>
        <Text style={styles.noNotification}>please swing by later.</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  noNotification: {
    fontSize: 18,
    fontWeight: 400,
    opacity: 0.5,
  },
})

export default EmptyNotificationContainer
