import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from 'react-native'
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Badge, Text } from '@libs/components'
import { DateFormat } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'

const OpportunitiesCard = ({
  data,
  brandLogo,
  style,
  hasMaxwidth = true,
  disable = false,
  height,
  width,
}) => {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const [showBrandName, setShowBrandName] = useState(false)
  const { t } = useTranslation()
  const { width: windowWidth } = useWindowDimensions()

  const duration = data?.OpportunityLineItems?.records[0]?.Product2?.Duration__c
  const opportunitiesDetails = [
    {
      label: t('APPLICATION_DETAIL.INTAKE'),
      value: data?.OpportunityLineItems?.records?.[0]?.Intake_Date__c
        ? DateFormat(
            data?.OpportunityLineItems?.records?.[0]?.Intake_Date__c?.split(
              'T',
            )[0],
          )
        : '-',
      separate: false,
    },
    {
      value: duration || '-',
      label: t('APPLICATION_DETAIL.DURATION'),
      separate: false,
    },
    {
      label: t('APPLICATION_DETAIL.LOCATION'),
      value: data?.OpportunityLineItems?.records?.[0]?.Location__c || '-',
      separate: false,
    },
    {
      label: t('APPLICATION_DETAIL.CREATED_DATE'),
      value: DateFormat(data?.CreatedDate?.split('T')[0]) || '-',
      separate: false,
    },
  ]
  const badgeColor = {
    Offer: {
      color: '#065F46',
      backgroundColor: '#F0FFE4',
    },
    Accepted: {
      color: '#065F46',
      backgroundColor: '#F0FFE4',
    },
    Enrolled: {
      color: '#065F46',
      backgroundColor: '#F0FFE4',
    },
    Admission: {
      color: '#D4691B',
      backgroundColor: '#FFF4E0',
    },
    Draft: {
      backgroundColor: '#ECF2FC',
      color: '#044DBA',
    },
    Visa: {
      color: '#D4691B',
      backgroundColor: '#FFF4E0',
    },
    Rejected: {
      backgroundColor: '#FFD7D7',
      color: '#D72C2C',
    },
  }
  return (
    <TouchableOpacity
      style={[
        styles.mainContainer,
        style,
        hasMaxwidth
          ? {
              maxWidth: windowWidth < 1024 ? 'auto' : 450,
            }
          : {},
      ]}
      onPress={() =>
        navigation.navigate('view-application-details', { id: data.Id })
      }
      disabled={disable}
    >
      <View
        style={[
          styles.row,
          {
            flexDirection: 'row',
            alignItems: 'center',
          },
        ]}
      >
        <Image
          source={brandLogo}
          style={{
            height,
            width,
          }}
          alt={data?.brand}
          resizeMode="contain"
          onError={(error) => {
            setShowBrandName(true)
          }}
        />
        {/* <Icon name="DotsVertical" width={20} height={20} disable={true} /> */}
      </View>
      <Text
        style={{
          paddingTop: 15,
          paddingBottom: 20,
          fontWeight: 700,
          fontSize: 16,
          height: 110,
          color: colors.cardTitleColor,
        }}
      >
        {data?.Name?.split('_')[0]}
      </Text>
      <View>
        <DetailsCard opportunitiesDetails={opportunitiesDetails} />
      </View>
      <View style={styles.divider} />
      <Badge
        style={[
          badgeColor[data?.ApplicationStatus?.status.split(' ')[0]] ||
            badgeColor[data?.ApplicationStatus?.status.split('/')[0]],
          { marginVertical: 20 },
        ]}
      >
        {data?.ApplicationStatus?.status}
      </Badge>
    </TouchableOpacity>
  )
}

const DetailsCard = ({ opportunitiesDetails }) => (
  <FlatList
    data={opportunitiesDetails}
    renderItem={({ item }) => <Item item={item} />}
    keyExtractor={(_, index) => index}
    numColumns={1}
    contentContainerStyle={styles.flatListStyle}
  />
)

const Item = ({ item }) => {
  const { colors } = useTheme()
  return (
    <View style={styles.item(item?.separate)}>
      <Text
        variant="display3"
        color={colors.textSecondary}
        style={{ flex: 1, marginRight: 5, fontSize: 14 }}
      >
        {`${item?.label}:`}
      </Text>
      <Text style={{ fontWeight: 700, fontSize: 14, color: '#021547' }}>
        {item?.value}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  item: (separate) => ({
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: separate ? 30 : 3,
  }),
  divider: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginTop: 20,
  },
  flatListStyle: {
    flexDirection: 'column',
    alignItems: 'space-between',
    rowGap: 20,
  },
})

export default OpportunitiesCard
