import { useMutation } from '@tanstack/react-query'
import { updateCompareProgrammesIds } from '../api'
import { useAtom } from 'jotai'
import { selectedProgramIds, userProfile } from '../utils/atom'
import { useRef } from 'react'

export const useUpdateCompareProgrammesId = () => {
  const [userprofile] = useAtom(userProfile)
  const [program] = useAtom(selectedProgramIds)
  let newProgramIdList = [...program]
  const mutation = useMutation(async (data) => {
    if (data?.type === 'update') {
      newProgramIdList.push(data?.programId)
    }
    if (data?.type === 'delete') {
      const filteredData = newProgramIdList.filter(
        (currentProgramId) => currentProgramId !== data?.programId,
      )
      newProgramIdList = filteredData // Update the value in the ref
    }
    if (data?.type === 'clear') {
      newProgramIdList = []
    }

    const payload = {
      email: userprofile?.email || '',
      programmeIds: newProgramIdList,
    }
    await updateCompareProgrammesIds(payload)
    return newProgramIdList
  })

  return { mutation }
}
