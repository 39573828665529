import {
  View,
  StyleSheet,
  Image,
  ImageBackground,
  useWindowDimensions,
} from 'react-native'
import React from 'react'
import { Button, Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { OfferDownloadCardBackgroundImage, OfferMail } from '@apphero/assets'
import { DateFormat } from '@libs/utils'
import { Icon } from '@app-hero/native-icons'
import { isMobile } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'

const ApplicationDetailsOfferCard = ({ data, handleButton = () => {} }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)

  return (
    <View
      style={[styles.container, { flexDirection: mobile ? 'column' : 'row' }]}
    >
      <View
        style={{
          justifyContent: 'center',
          flex: 1,
          alignItems: mobile ? 'center' : 'flex-start',
        }}
      >
        <Text variant="heading2" color={colors.white}>
          {t('APPLICATION.CONGRATULATION')}
        </Text>
        <Text
          variant="display2"
          color={colors.white}
          style={{
            width: mobile ? '70%' : '80%',
            marginTop: mobile ? 12 : 5,
            marginBottom: 20,
          }}
        >
          {t('APPLICATION.OFFER_DESCRIPTION')}
        </Text>
        {/* <Text variant="display3" style={{ width: 95 }} color={colors.white}>
          {DateFormat(data?.CreatedDate?.split('T')[0])}
        </Text> */}
        <Text
          variant="display2"
          color={colors.white}
          style={{ width: '80%', marginTop: 5, marginBottom: 15 }}
        >
          {/* {t('APPLICATION.OFFER_DESCRIPTION')} */}
        </Text>
      </View>
      <View style={styles.subContainer}>
        <Image
          source={OfferMail}
          style={{ height: 77, width: 75, marginBottom: 30 }}
        />
        <Button
          label={t('BUTTON.DOWNLOAD_OFFER')}
          leftIcon={<Icon name="Download" color={colors.white} />}
          buttonColor={colors.successVariant1}
          labelVariant="display4"
          labelColors={colors.white}
          buttonStyle={{
            alignSelf: 'center',
            paddingVertical: 8,
            paddingHorizontal: 16,
          }}
          onPress={handleButton}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 40,
    paddingHorizontal: 40,
    justifyContent: 'space-between',
    backgroundColor: '#0B5CD7',
    borderRadius: 20,
    marginVertical: 40,
    alignItems: 'center',
  },
  subContainer: {
    maxWidth: 220,
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
    padding: 25,
  },
})

export default ApplicationDetailsOfferCard
