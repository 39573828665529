import React, { useCallback, Suspense, useState, useEffect } from 'react'
import { ScreenLayout } from '@libs/utils'
import { Text } from 'react-native'
import { useAtom } from 'jotai'
import { useIsFocused } from '@react-navigation/native'
import DesktopView from './DesktopView'
import { userProfile } from '../../utils/atom'
import { useUpdateProfile } from '../../hooks/useUpdateProfile'
import { ProfilePlaceHolder } from '@apphero/assets'
import { getUserDetails } from '../../api'
import { useQuery } from '@tanstack/react-query'

const ChangeProfilePicture = () => {
  const [userprofile, setUserDetails] = useAtom(userProfile)
  const [profileDetails, setProfileDetails] = useState({
    fullName: '',
    firstName: '',
    lastName: '',
    mobile: {
      dialCode: '',
      mobileNumber: '',
    },
    country: '',
    countryCode: '',
  })

  const [ctaEnable, setCTAEnable] = useState(true)
  const [isProfileUpdating, setIsProfileUpdating] = useState()
  const [successPopup, setSuccessPopup] = useState({
    visible: false,
    message: '',
  })

  const isFocused = useIsFocused()

  const handleChange = (field, selectedValue) => {
    if (field === 'profilePictureLocation') {
      setUserDetails((prev) => ({
        ...prev,
        selectedImage: selectedValue.selectedFile,
      }))
      return setProfileDetails((previousData) => ({
        ...previousData,
        base64Picture: selectedValue.file,
        fileName: selectedValue.fileName,
        selectedImage: selectedValue.selectedFile,
      }))
    }
  }

  const fetchUserDetails = async () => {
    let response = await getUserDetails({ email: userprofile.email })
    return response || {}
  }

  // fetch userProfile
  const { data: userDetails } = useQuery({
    queryKey: ['getUserProfile'],
    queryFn: fetchUserDetails,
    initialData: [],
    enabled: !!userprofile.email,
  })

  const { mutation } = useUpdateProfile()

  const handleSave = async () => {
    setIsProfileUpdating(true)

    await mutation.mutateAsync({
      updatedProfile: profileDetails,
    })

    setIsProfileUpdating(false)
  }
  useEffect(() => {
    if (!isFocused) return

    if (Object.keys(userDetails).length > 0) {
      const newUserDetails = {
        email: userprofile?.email,
        userID: userprofile.userID,
        accessToken: userprofile?.accessToken,
        bucketId: userprofile?.bucketId,
        ...userDetails,
      }
      setUserDetails(newUserDetails)
    }
  }, [userDetails, isFocused])

  useEffect(() => {
    if (!isFocused) return
    if (Object.keys(userprofile).length > 0) {
      let newUserDetails = { ...userprofile }

      setProfileDetails(newUserDetails)
    }
  }, [isFocused, userprofile])

  const getImageSource = () => {
    if (profileDetails?.selectedImage) {
      return profileDetails.selectedImage
    } else if (profileDetails?.profilePictureLocation) {
      return { uri: profileDetails?.profilePictureLocation }
    } else {
      return ProfilePlaceHolder
    }
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, DesktopView, DesktopView),
    [],
  )

  const viewProps = {
    ctaEnable,
    handleChange,
    successPopup,
    setSuccessPopup,
    isProfileUpdating,
    handleSave,
    getImageSource,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ChangeProfilePicture
