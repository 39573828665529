import {
  View,
  StyleSheet,
  Platform,
  useWindowDimensions,
  Image,
  TouchableOpacity,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Link,
  LinkedInButton,
  Loader,
  PasswordInput,
  Text,
  TextInput,
} from '@libs/components'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { CustomAuth, SecureStore } from '@libs/utils'
import { Auth } from 'aws-amplify'
import queryString from 'query-string'
import { isMobile, isTablet } from '@libs/utils/src/screenLayout'
import { LinkedInLogo } from '@apphero/assets'
import { isWeb } from '@libs/utils/src/screenLayout'
import {
  ResetPasswordAtom,
  errorMessageAtom,
  isChatbotVisible,
  userLanguage,
  userProfile,
} from '../../utils/atom'
import { getUserDetails } from '../../api'

const Login = ({ isLoading, setIsLoading, errorMessage, setErrorMessage }) => {
  const [, setCanShowChatbot] = useAtom(isChatbotVisible)
  const [isCTAEnable, setIsCTAEnable] = useState(false)
  const navigation = useNavigation()
  const [userDetails, setUserDetails] = useAtom(userProfile)
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
  })
  const [emailErrorMessage, setEmailErrorMessage] = useState({
    error: '',
    errorKey: '',
  })
  const { colors } = useTheme()
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const route = useRoute()

  const [userSelectedLanguage] = useAtom(userLanguage)
  const [, setForgotPasswordCredential] = useAtom(ResetPasswordAtom)
  const [, setError] = useAtom(errorMessageAtom)

  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const tablet = isTablet(width)
  const isDesktop = isWeb(width)

  useEffect(() => {
    if (!isFocused) return
    ;(() => {
      setEmailErrorMessage((prev) => ({ ...prev, error: t(prev.errorKey) }))
    })()
  }, [userSelectedLanguage, isFocused])

  useEffect(() => {
    if (!isFocused) return
    ;(() => {
      if (Platform.OS === 'web') {
        window.addEventListener('keypress', (event) => {
          if (event.code === 'Enter' || event.charCode === 13) {
            if (loginDetails?.email && loginDetails.password?.length >= 8) {
              handleSignIn()
            }
          }
        })
      }
    })()
  }, [isFocused, loginDetails])

  useEffect(() => {
    ;(async () => {
      try {
        await Auth.currentAuthenticatedUser()
      } catch (error) {
        setCanShowChatbot(false)
      }
    })()
  }, [isFocused])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const emailPattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidEmail = emailPattern.test(loginDetails?.email)
      if (isValidEmail && loginDetails?.password?.length >= 8) {
        setIsCTAEnable(true)
      } else {
        setIsCTAEnable(false)
      }
      if (!isValidEmail && loginDetails?.email?.length > 0) {
        const disallowedCharsPattern = /[!#$%&'*+\-/=?^`{|}~,\s[\]<>\\]/
        if (disallowedCharsPattern.test(loginDetails?.email)) {
          setEmailErrorMessage({
            error: t('ERROR_MESSAGE.INVALID_EMAIL_2'),
            errorKey: 'ERROR_MESSAGE.INVALID_EMAIL_2',
          })
        } else {
          setEmailErrorMessage({
            error: t('ERROR_MESSAGE.INVALID_EMAIL_1'),
            errorKey: 'ERROR_MESSAGE.INVALID_EMAIL_1',
          })
        }
        setIsCTAEnable(false)
      }
      if (isValidEmail || loginDetails?.email?.length === 0) {
        setEmailErrorMessage({
          error: '',
          errorKey: '',
        })
      }
    })()
  }, [isFocused, loginDetails])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const tempUser = JSON.parse(await SecureStore.getItemAsync('tempUser'))

      setLoginDetails((previousData) => ({
        ...previousData,
        email: tempUser?.email,
      }))
    })()
    return () => {
      setForgotPasswordCredential({
        email: '',
        code: '',
        newPassword: '',
        confirmPassword: '',
      })
      setError('')
    }
  }, [isFocused])

  const handleChange = (field, text) => {
    setEmailErrorMessage({
      error: '',
      errorKey: '',
    })
    setErrorMessage('')
    setLoginDetails((previousData) => ({ ...previousData, [field]: text }))
  }

  const handleSignIn = async () => {
    setIsLoading(true)
    try {
      const userData = await CustomAuth.emailSignIn(
        loginDetails.email.toLowerCase(),
        loginDetails.password,
      )

      if (userData?.message) {
        setErrorMessage(userData.message)
        console.log('errorMessage', errorMessage)
      } else {
        setUserDetails((prev) => ({ ...prev, ...userData }))
        if (userData?.accessToken) {
          const data = await getUserDetails({ email: userData.email })
          setUserDetails((prev) => ({ ...prev, ...data }))
          setCanShowChatbot(true)
          const redirectUrl = route.params?.redirect
          if (redirectUrl) {
            const queryParams = queryString.parseUrl(redirectUrl)
            navigation.navigate(queryParams.url.slice(1), queryParams.query)
            setIsLoading(false)
            return
          }
          if (data?.canApply) {
            navigation.navigate('path-finder')
          } else {
            navigation.navigate('my-application')
          }
        }
      }
    } catch (error) {
      console.error('SignIn error:', error)
      setErrorMessage('An unexpected error occurred. Please try again.')
    }
    setIsLoading(false)
  }

  return (
    <View style={[styles.loginContainer(mobile, tablet, isDesktop)]}>
      <Text
        color={colors.neutral}
        variant="heading3"
        style={{
          textAlign: 'center',
          fontWeight: 700,
          marginTop: 0,
          borderRadius: 12,
        }}
      >
        {t('AUTH.ACCOUNT_SIGNIN')}
      </Text>
      <Text
        color={colors.primaryPlaceHolder}
        variant="display4"
        style={{ marginTop: 12, textAlign: 'center' }}
      >
        {t('AUTH.WELCOME')}
      </Text>
      <View style={styles.loginFields}>
        <TextInput
          value={loginDetails.email}
          onChangeText={(text) => handleChange('email', text)}
          placeholder={t('TEXT_INPUT.EMAIL')}
          style={{
            height: 56,
            marginBottom: 16,
            borderRadius: 12,
          }}
          iconName="Mail2"
        />
        {emailErrorMessage.error ? (
          <Text
            variant="display3"
            color={colors.onAlert}
            style={{ marginBottom: 20 }}
          >
            {emailErrorMessage.error}
          </Text>
        ) : null}
        <PasswordInput
          value={loginDetails.password}
          onChangeText={(text) => handleChange('password', text)}
          placeholder={t('TEXT_INPUT.PASSWORD')}
          style={{
            height: 56,
            marginBottom: 16,
            borderRadius: 12,
          }}
          iconName="Lock"
        />
        <View style={styles.forgotLinkContainer}>
          <View />
          {/* <CheckBox
            renderLabel={
              <Text
                variant="display4"
                color={colors.neutral}
                style={{ marginLeft: 12, maxWidth: 500 }}
              >
                <Text
                  variant="display4"
                  underline
                  style={{ color: colors.neutral }}
                >
                  Remember me
                </Text>
              </Text>
            }
            value={rememberMe.save}
            handleCheck={handleCheck}
            isActive={rememberMe.save}
            field="save"
            style={{ paddingHorizontal: 12 }}
          /> */}
          <TouchableOpacity
            onPress={() => navigation.navigate('forgotPassword')}
          >
            <Text
              variant="display4"
              underline
              style={{
                marginTop: spacing.spacing3,
                color: colors.primary,
                fontWeight: 700,
              }}
            >
              {t('LINK.FORGOT_PASSWORD')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {errorMessage && (
        <Text variant="display3" color={colors.onAlert}>
          {errorMessage === 'User is not confirmed.'
            ? 'User does not exist'
            : errorMessage}
        </Text>
      )}
      <View style={styles.buttonsContainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <Button
            label={t('BUTTON.SIGNIN')}
            buttonColor={colors.primary}
            labelColors={colors.white}
            onPress={() => handleSignIn()}
            disable={!isCTAEnable}
            labelStyle={{ fontWeight: 700 }}
            buttonStyle={{ width: '100%', borderRadius: 12, height: 56 }}
          />
        )}
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              height: 1,
              width: '35%',
              borderStyle: 'solid',
              borderColor: '#E2E8F0',
              borderBottomWidth: 0.5,
            }}
          />
          <View>
            <Text
              variant="display5"
              textAlign="center"
              style={{ marginVertical: 24, marginHorizontal: 8 }}
            >
              {t('AUTH.OR')}
            </Text>
          </View>
          <View
            style={{
              height: 1,
              width: '35%',
              borderStyle: 'solid',
              borderColor: '#E2E8F0',
              borderBottomWidth: 0.5,
            }}
          />
        </View>

        <View style={[styles.authLinks, { width: '100%' }]}>
          <LinkedInButton
            onPress={() => CustomAuth.federatedSignIn('linkedin')}
          />
        </View>
      </View>
      <View style={styles.footer(mobile)}>
        <Text variant="display4">{t('LINK.DONT_HAVE_ACCOUNT')} </Text>
        <Link
          label={t('BUTTON.SIGNUP')}
          labelVariant="display4"
          labelColor={colors.primary}
          handlePress={() => navigation.navigate('privacyPolicy')}
          labelStyle={{ fontWeight: 700 }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  authLinks: {
    alignSelf: 'center',
  },
  buttonsContainer: {
    width: '100%',
    marginTop: 20,
    marginBottom: 30,
  },
  loginContainer: (mobile, tablet, isDesktop) => {
    let paddingHorizontalValue
    let marginTop

    return {
      flexDirection: 'column',
      alignItems: isDesktop && 'center',
      justifyContent: 'center',

      marginTop: !isDesktop ? '6vh' : '10vh',

      paddingLeft: isDesktop ? '16%' : '10%',
      paddingRight: isDesktop ? '16%' : '10%',
    }
  },
  loginFields: {
    marginTop: 30,
    width: '100%',
  },
  footer: (mobile) => ({
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  forgotLinkContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default Login
