import {
  View,
  StyleSheet,
  ImageBackground,
  ScrollView,
  Platform,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import React, { useState } from 'react'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import {
  Button,
  CheckBox,
  FilePicker,
  Loader,
  MobileInput,
  Text,
  TextInput,
} from '@libs/components'

import ModalComponent from '../../components/modal'
import SearchDropDown from '@libs/components/src/DropDown/SearchDropdown'
import { isMobile } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'

const MobileView = ({
  ctaEnable,
  handleChange,
  countryList,
  DropdownButton,
  dropdownRight,
  dropdownTop,
  dropdownWidth,
  successPopup,
  setSuccessPopup,
  isProfileUpdating,
  isLastNameAvailable,
  profileDetails,
  handleSave,
  getImageSource,
  setProfileDetails,
  toggleDropdown,
  setIsLastNameAvailable,
  setCTAEnable,
  navigation,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  return (
    <ImageBackground
      // source={ProfileBg}
      style={{ flex: 1 }}
      imageStyle={{
        width: '60%',
        position: 'absolute',
        top: 0,
        left: '40%',
      }}
      // resizeMode="contain"
    >
      <ScrollView style={{}}>
        <View style={styles.mainContainer}>
          <View style={styles.profileContainer}>
            <View style={styles.profileLeftHeader}>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('my-application')
                }}
              >
                <Icon
                  name="ArrowBack"
                  height={24}
                  width={24}
                  color={colors.black}
                />
              </TouchableOpacity>
              <Text style={[styles.myProfileText, { textAlign: 'center' }]}>
                {t('PROFILE.PERSONAL_INFO')}
              </Text>
            </View>

            <View style={styles.contentContainer}>
              <View
                style={{
                  flexDirection: 'column',
                  columnGap: 20,
                }}
              >
                <View style={styles.imageContainer}>
                  <View
                    style={{
                      height: '100%',
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      overflow: 'hidden',
                      paddingTop: 20,
                    }}
                  >
                    <FilePicker
                      isProfile
                      handleFileSelect={(image) =>
                        handleChange('profilePictureLocation', image)
                      }
                      profileStyle={{
                        width: 99,
                        height: 99,
                        borderRadius: 80,
                      }}
                      source={getImageSource()}
                    />
                  </View>
                </View>
                <View style={styles.formContainer}>
                  <View>
                    <TextInput
                      placeholder={t('TEXT_INPUT.FIRST_NAME')}
                      onChangeText={(text) => handleChange('firstName', text)}
                      value={profileDetails.firstName}
                      dropDownData=""
                      style={styles.inputField}
                      editable
                    />
                    <TextInput
                      placeholder={t('TEXT_INPUT.LAST_NAME')}
                      onChangeText={(text) => handleChange('lastName', text)}
                      value={profileDetails.lastName}
                      style={styles.inputField}
                      dropDownData=""
                      editable={!isLastNameAvailable}
                    />
                    <View
                      style={styles.checkboxContainer}
                      className="checkboxContainer"
                    >
                      <CheckBox
                        value={isLastNameAvailable}
                        handleCheck={() => {
                          setIsLastNameAvailable(!isLastNameAvailable)
                          setCTAEnable(true)
                        }}
                        checkboxColor={colors.white}
                      />
                      <Text variant="display3" color={'#0A0A0A'}>
                        I don’t have last name
                      </Text>
                    </View>
                    <View style={styles.divider} />
                    <MobileInput
                      value={profileDetails?.mobile?.mobileNumber || ''}
                      dialCode={profileDetails?.mobile.dialCode || ''}
                      placeholder={t('TEXT_INPUT.PHONE')}
                      onChangeText={(text) => handleChange('mobile', text)}
                      placeholderColor="#E0E0E0"
                      handleCountrySelection={(selectedCountry) => {
                        setProfileDetails((previousData) => ({
                          ...previousData,
                          mobile: {
                            dialCode: selectedCountry?.dial_code,
                            mobileNumber: previousData.mobile?.mobileNumber,
                          },
                        }))
                      }}
                      style={{
                        backgroundColor: 'white',
                        height: 56,
                        borderColor: '#E0E0E0',
                        borderRadius: 0,
                        paddingLeft: 24,
                        paddingRight: 14,
                        fontSize: 16,
                      }}
                    />
                  </View>
                  <View
                    style={{ flex: 1, marginVertical: 20 }}
                    ref={DropdownButton}
                  >
                    <SearchDropDown
                      style={{
                        margin: 0,
                        width: '100%',
                        backgroundColor: 'white',
                        borderRadius: 12,
                        borderColor: '#E0E0E0',
                        height: 56,
                      }}
                      modalStyle={{ right: 22, height: 56 }}
                      label={t('TEXT_INPUT.COUNTRY')}
                      labelColor={colors.onNeutral}
                      placeHolderColor="#E0E0E0"
                      toggleDropdown={toggleDropdown}
                      onPress={(selectedCountry) =>
                        handleChange('country', selectedCountry)
                      }
                      dropdownWidth={dropdownWidth}
                      isCountryCode
                      items={countryList || []}
                      value={
                        profileDetails.country
                          ? {
                              Label: profileDetails.country,
                              Value: profileDetails.countryCode,
                            }
                          : {}
                      }
                      position={{
                        top: dropdownTop,
                        right: dropdownRight - 205,
                      }}
                      dropdownHeight={150}
                    />
                  </View>
                  <TextInput
                    label="Email ID"
                    onChangeText={() => {}}
                    value={profileDetails?.email || ''}
                    editable={false}
                    dropDownData=""
                    style={styles.inputField}
                    inputFieldStyle={{ color: colors.primaryPlaceHolder }}
                  />
                </View>
              </View>

              {isProfileUpdating ? (
                <View style={{ marginBottom: 30, marginTop: 16 }}>
                  <Loader size={32} hasFlexValue={false} />
                </View>
              ) : (
                <Button
                  label={t('BUTTON.SAVE_CHANGES')}
                  labelStyle={{
                    color: colors.white,
                    flexDirection: 'row',
                    fontSize: 14,
                    fontWeight: 700,
                    fontWeight: 'bold',
                  }}
                  onPress={() => handleSave()}
                  disable={!ctaEnable}
                  appearance="filled"
                  buttonStyle={{
                    alignItems: 'center',
                    borderRadius: 8,
                    paddingHorizontal: 60,
                    justifyContent: 'center',
                    backgroundColor: '#2563EB',
                    marginTop: 16,
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <ModalComponent
        visible={successPopup.visible}
        title={successPopup.message}
        buttonLabel={t('BUTTON.OK')}
        handleCloseModal={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        handleButton={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        buttonColor={colors.primaryContainer}
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#F4F5FB',
  },
  profileContainer: {
    width: '100%',
  },
  profileLeftHeader: {
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingHorizontal: 24,
    paddingVertical: 29,
  },
  myProfileText: {
    fontWeight: 700,
    fontSize: 18,
    color: '#1E1E2D',
    textTransform: 'uppercase',
    flex: 1,
    textAlign: 'center',
  },

  imageContainer: {
    height: 132,
    width: 155,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 13,
    backgroundColor: '#ECF3FF',
    marginHorizontal: 'auto',
  },

  formContainer: {
    paddingTop: 28,
  },

  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    marginLeft: 2,
    marginTop: -10,
  },
  divider: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginTop: 12,
    marginBottom: 24,
  },

  contentContainer: {
    width: '100%',
    paddingHorizontal: 24,
    paddingVertical: 40,
  },
  inputField: {
    marginBottom: 16,
    backgroundColor: 'white',
    paddingLeft: 24,
    paddingRight: 14,
    paddingVertical: 16,
    borderRadius: 12,
    height: 56,
    fontSize: 16,
    borderColor: '#E0E0E0',
  },
})

export default MobileView
