import React, { useContext } from 'react'
import { ThemeContext } from '@libs/theme'
import { ActivityIndicator, View } from 'react-native'

const Loader = (props) => {
  const { colorVariant = 'primary', style, size, hasFlexValue = true } = props
  const theme = useContext(ThemeContext)

  return (
    <View
      style={[
        {
          alignItems: 'center',
          justifyContent: 'center',
        },
        hasFlexValue
          ? {
              flex: 1,
            }
          : {},
        style,
      ]}
    >
      <ActivityIndicator
        color={theme.colors[colorVariant]}
        size={size || 'large'}
      />
    </View>
  )
}
export default Loader
