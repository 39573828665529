import React, { useState } from 'react'
import {
  FlatList,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { Button } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import Text from '../Text/Text'

const Accordion = (props) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const {
    data = [],
    fetchProgramByInstitution,
    renderItem,
    activeTab,
    handleLoadMore,
    hasLoadMore = false,
  } = props
  const [onHover, setOnHover] = useState(false)
  const [defaultLogo, setDefaultLogo] = useState(false)
  const isOpen = activeTab?.Id === data?.Id
  return (
    <View
      style={[styles.container, { shadowOpacity: onHover ? 0.5 : 1 }]}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <View style={styles.header}>
        <View style={styles.headerRight}>
          {defaultLogo ? null : (
            <Image
              source={data?.brandLogo}
              style={{
                height: 38,
                width: 146,
              }}
              onError={() => setDefaultLogo(true)}
            />
          )}

          <View style={styles.institutionDetails}>
            <Text
              variant="heading4"
              color={activeTab || onHover ? colors.primary : colors.neutral}
            >
              {data?.Name}
            </Text>
            <Text variant="display3" color={colors.text}>
              {`${data?.total} ${t('ACCORDION.PROGRAMMES')}`}
            </Text>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => {
            fetchProgramByInstitution(data)
          }}
        >
          <Icon
            name={isOpen ? 'ArrowUp' : 'ArrowDown'}
            width={20}
            height={20}
          />
        </TouchableOpacity>
      </View>
      {isOpen && (
        <View style={{ flexDirection: 'column' }}>
          <View style={styles.body}>
            {data?.programDetails ? (
              <FlatList
                data={data?.programDetails}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                style={styles.body}
                numColumns={2}
                contentContainerStyle={{ justifyContent: 'center' }}
              />
            ) : null}
          </View>
          {hasLoadMore ? (
            <Button
              label={t('DASHBOARD.SHOW_MORE')}
              leftIcon={<Icon name="Refresh" />}
              buttonColor={colors.primaryVariant}
              labelColors={colors.primary}
              buttonStyle={{
                alignSelf: 'center',
                paddingVertical: 10,
                paddingHorizontal: 30,
                marginBottom: 30,
              }}
              onPress={handleLoadMore}
            />
          ) : null}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: 'white',
    shadowColor: 'rgba(3, 30, 125, 0.05)',
    shadowOffset: { width: 0, height: 2 },
    elevation: 5,
    shadowOpacity: 1,
    shadowRadius: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: spacing.spacing5,
    paddingHorizontal: spacing.spacing6,
    borderRadius: 6,
    height: 78,
    backgroundColor: 'white',
  },
  headerRight: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  institutionDetails: {
    marginLeft: 20,
  },
  body: {
    borderRadius: 6,
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    flex: 1,
    width: '100%',
    minHeight: 100,
    backfaceVisibility: 'red',
  },
})

export default Accordion
