import React from 'react'
import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'
import Button from './Button'

const LinkedInButton = ({ onPress }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    <Button
      onPress={onPress}
      appearance="filled"
      buttonColor={colors.linkedinBackground}
      hoverColor={colors.linkedinOnHover}
      imageIcon={
        <Icon name="LinkedinLogo" fill={colors.white} height={20} width={20} />
      }
      label={t('LINK.LINKED_TEXT')}
      labelVariant="display3"
      labelStyle={[styles.labelStyle, { color: colors.white }]}
      buttonStyle={styles.buttonStyle}
    />
  )
}

const styles = StyleSheet.create({
  buttonStyle: {
    borderRadius: 6,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  labelStyle: {
    fontWeight: '600',
    marginLeft: 6,
  },
})

export default LinkedInButton
