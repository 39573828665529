import * as ScreenLayout from './src/screenLayout'
import * as FileHandler from './src/fileHandler'
import * as CustomAuth from './src/auth'
import * as SecureStore from './src/secureStore'
import useDebounce from './src/debounce/useDebounce'
import testProps from './src/testingId/testProps'
import { findDateFormat, DateFormat, DateforComments } from './src/dateformat'
import { countryCodes } from './src/countryList/countryList'

function countRecentComments(data) {
  if (data) {
    let count = 0

    for (const item of data) {
      if (!item?.isRead) {
        count++
      }
    }

    return count
  }
}

function countRecentNotification(data) {
  if (data?.items.length > 0) {
    let count = 0

    for (const item of data?.items) {
      if (!item?.readStatus) {
        count++
      }
    }

    return count
  }
}

function countReadComments(data) {
  let count = 0

  for (const item of data) {
    if (item?.isRead) {
      count++
    }
  }

  return count
}
const getRandomImage = (imagesArray) => {
  const randomIndex = Math.floor(Math.random() * imagesArray.length)
  return imagesArray[randomIndex]
}

export {
  ScreenLayout,
  FileHandler,
  CustomAuth,
  SecureStore,
  countryCodes,
  DateFormat,
  findDateFormat,
  testProps,
  useDebounce,
  countRecentComments,
  countReadComments,
  DateforComments,
  countRecentNotification,
  getRandomImage,
}
