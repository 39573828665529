export const videoUrl =
  'https://storage.pardot.com/873531/1651055274Iu5NgSl3/video_5__2_.mp4'

export const gusUrl = 'https://www.globaluniversitysystems.com/'

export const privacyPolicyUrl =
  'https://www.globaluniversitysystems.com/privacy-policy'

export const termsAndConditionsUrl =
  'https://www.globaluniversitysystems.com/terms-and-conditions'

export const ourNetwork = 'https://www.globaluniversitysystems.com/our-network'

export const FW_CHAT_BOT_URL = '//fw-cdn.com/11572971/4224937.js'
