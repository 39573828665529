import {
  View,
  StyleSheet,
  ImageBackground,
  ScrollView,
  useWindowDimensions,
  Platform,
  TouchableOpacity,
} from 'react-native'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Button, Loader, PasswordInput, Text } from '@libs/components'
import { useIsFocused } from '@react-navigation/native'
import { isWeb } from '@libs/utils/src/screenLayout'
import ModalComponent from '../../components/modal'
import { Icon } from '@app-hero/native-icons'
import PasswordValidateBox from '@libs/components/src/PasswordInput/PasswordValidateBox'
import { useTheme } from '@libs/theme'

const MobileView = ({
  ctaEnable,
  errorMessage,
  handlePasswordFieldChange,
  changePasswordDetails,
  successPopup,
  setSuccessPopup,
  isProfileUpdating,
  handleSave,
  navigation,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [passNumValidate, setPassNumValidate] = useState(false)
  const [passSpecialValidate, setPassSpecialValidate] = useState(false)
  const [showValidateBox, setShowValidateBox] = useState(false)
  const [validateError, setValidateError] = useState('')
  const [passLenValidate, setPassLenValidate] = useState(false)
  const [passLetterValidate, setPassLetterValidate] = useState(false)
  const isFocused = useIsFocused()
  const onPassAllValid = () => {}

  const PassWordValidatorData = [
    {
      id: 1,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_1'),
      checkStatus: passLenValidate,
    },
    {
      id: 2,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_2'),
      checkStatus: passLetterValidate,
    },
    {
      id: 3,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_3'),
      checkStatus: passSpecialValidate,
    },
    {
      id: 4,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_4'),
      checkStatus: passNumValidate,
    },
  ]

  useEffect(() => {
    setPassSpecialValidate(false)
    setPassLetterValidate(false)
    setPassLenValidate(false)
    setPassNumValidate(false)
    onPassAllValid(false)

    const specialRegex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|._-]+/,
    )
    const letterLargeRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,}$/)
    const capitalLetterRegex = new RegExp(/^(?=.*[A-Z]).*$/)
    const numericRegex = new RegExp(/[0-9]/)

    if (specialRegex.test(changePasswordDetails.new_Password)) {
      setPassSpecialValidate(true)
    }

    if (capitalLetterRegex.test(changePasswordDetails.new_Password)) {
      setPassLetterValidate(true)
    }

    if (changePasswordDetails.new_Password.length >= 6) {
      setPassLenValidate(true)
    }
    if (numericRegex.test(changePasswordDetails.new_Password)) {
      setPassNumValidate(true)
    }
    if (
      changePasswordDetails.new_Password.length >= 6 &&
      specialRegex.test(changePasswordDetails.new_Password) &&
      letterLargeRegex.test(changePasswordDetails.new_Password) &&
      numericRegex.test(changePasswordDetails.new_Password)
    ) {
      onPassAllValid(true)
    }
  }, [changePasswordDetails.new_Password])

  useEffect(() => {
    if (!isFocused) return
    if (isFocused) {
      setValidateError('')
      setShowValidateBox(true)
    }
  }, [isFocused])

  return (
    <ImageBackground
      // source={ProfileBg}
      style={{ flex: 1 }}
      imageStyle={{
        width: '60%',
        position: 'absolute',
        top: 0,
        left: '40%',
      }}
      // resizeMode="contain"
    >
      <ScrollView>
        <View style={styles.mainContainer}>
          <View style={styles.profileContainer}>
            <View style={styles.profileLeftHeader}>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('my-application')
                }}
              >
                <Icon
                  name="ArrowBack"
                  height={24}
                  width={24}
                  color={colors.black}
                />
              </TouchableOpacity>
              <Text style={[styles.myProfileText, { textAlign: 'center' }]}>
                {t('PROFILE.CHANGE_PASSWORD')}
              </Text>
            </View>

            <View style={styles.formContainer}>
              <View style={styles.subContainer}>
                <PasswordInput
                  value={changePasswordDetails.current_Password}
                  onChangeText={(text) =>
                    handlePasswordFieldChange('current_Password', text)
                  }
                  placeholder={t('TEXT_INPUT.OLD_PASSWORD')}
                  style={styles.textInput}
                  iconSize={24}
                />
                <View
                  style={[styles.divider, { marginTop: 14, marginBottom: 24 }]}
                />
                <PasswordInput
                  value={changePasswordDetails.new_Password}
                  onChangeText={(text) =>
                    handlePasswordFieldChange('new_Password', text)
                  }
                  placeholder={t('TEXT_INPUT.NEW_Password')}
                  style={[
                    styles.textInput,
                    { columnGap: 12, marginBottom: 16 },
                  ]}
                  iconName="Lock"
                  iconSize={24}
                />
                <PasswordInput
                  value={changePasswordDetails.repeat_Password}
                  onChangeText={(text) =>
                    handlePasswordFieldChange('repeat_Password', text)
                  }
                  placeholder={t('TEXT_INPUT.RE_ENTER_NEW_PASSWORD')}
                  style={[styles.textInput, { columnGap: 12 }]}
                  iconName="Lock"
                  iconSize={24}
                />
                {changePasswordDetails.new_Password.length > 0 && (
                  <PasswordValidateBox
                    data={PassWordValidatorData}
                    errorLabel={validateError}
                    isFocused={!changePasswordDetails.new_Password?.length > 0}
                    iconSize={16}
                    labelStyle={{ fontSize: 12, fontWeight: 400 }}
                  />
                )}
                {errorMessage ? (
                  <Text
                    variant="display3"
                    color={colors.onAlert}
                    style={{ marginBottom: 20 }}
                  >
                    {errorMessage}
                  </Text>
                ) : null}
              </View>
              {isProfileUpdating ? (
                <View style={{ marginBottom: 30 }}>
                  <Loader size={32} hasFlexValue={false} />
                </View>
              ) : (
                <Button
                  label={t('BUTTON.UPDATE_PASSWORD')}
                  labelStyle={styles.updateLabelStyle}
                  buttonStyle={styles.updateButtonStyle}
                  onPress={() => handleSave()}
                  disable={!ctaEnable}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <ModalComponent
        visible={successPopup.visible}
        title={successPopup.message}
        buttonLabel={t('BUTTON.OK')}
        handleCloseModal={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        handleButton={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        buttonColor={colors.primaryContainer}
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#F4F5FB',
    minHeight: '100vh',
  },
  profileContainer: {
    width: '100%',
  },
  profileLeftHeader: {
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingHorizontal: 24,
    paddingVertical: 29,
  },
  myProfileText: {
    fontWeight: 700,
    fontSize: 18,
    color: '#1E1E2D',
    textTransform: 'uppercase',
    flex: 1,
    textAlign: 'center',
  },

  imageContainer: {
    height: 132,
    width: 155,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 13,
    backgroundColor: '#ECF3FF',
    marginHorizontal: 'auto',
  },

  formContainer: {
    paddingVertical: 40,
    paddingHorizontal: 24,
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '90vh',
  },

  divider: {
    borderWidth: 0.8,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginTop: 12,
    marginBottom: 24,
  },

  textInput: {
    backgroundColor: 'white',
    borderRadius: 12,
    borderColor: '#E0E0E0',
    paddingVertical: 10,
    height: 56,
  },
  updateLabelStyle: {
    color: 'white',
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  updateButtonStyle: {
    alignItems: 'center',
    borderRadius: 8,
    paddingHorizontal: 60,
    justifyContent: 'center',
    backgroundColor: '#2563EB',
  },
})

export default MobileView
