import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  inputRow: {
    alignSelf: 'stretch',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#9E9E9E',
    paddingHorizontal: 14,
    height: 38,
    marginBottom: 10,
  },

  inputField: {
    flex: 1,
    // outlineStyle: 'none',
  },
})
