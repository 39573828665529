import { View, Text } from 'react-native'
import React from 'react'

const WebView = ({ url, style = {}, id, type = '' }) => {
  const videoId =
    type === 'youtube' ? url.match(/youtu\.be\/([a-zA-Z0-9_-]+)/)?.[1] || url.match(/[?&]v=([^?&]+)/)?.[1] : 0

  const embedUrl = `https://www.youtube.com/embed/${videoId}`

  return (
    <iframe
      id={id}
      style={style}
      src={type === 'youtube' ? embedUrl : url}
      allowFullScreen={type === 'youtube'}
    />
  )
}

export default WebView
