import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { HeaderLeft, HeaderRight } from '../components'
import { Stacks } from './Stacks'
import { selectedProgramIds } from '../utils/atom'

const Stack = createNativeStackNavigator()

const ScreenOptions = {
  headerLeft: () => <HeaderLeft />,
  headerRight: () => <HeaderRight programIds={selectedProgramIds} />,
  headerStyle: {
    borderBottomWidth: 1,
    shadowColor: 'transparent',
    height: 80,
  },
}

const createNavigators = (initialRouteName) => (
  <Stack.Navigator
    initialRouteName={initialRouteName}
    screenOptions={ScreenOptions}
  >
    {(() => Stacks(Stack))()}
  </Stack.Navigator>
)

export const HomeStackNavigator = () => createNavigators('login')
