import React from 'react'
import { Modal, TouchableOpacity, View } from 'react-native'
import { Button, Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@libs/theme'

export const BasketAlertBox = ({
  isVisible,
  handleCloseModal = () => {},
  type,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const getTitleText = () => {
    if (type === 'compareProgrammes') {
      return t('ALERT.PROGRAM_ID_ALREADY_EXIST')
    } else if (type === 'brandAlreadyExist') {
      return t('ALERT.MULTI_SAME_PROGRAM')
    } else {
      return t('ALERT.PROGRAM_ALREADY_EXIST')
    }
  }

  return (
    <Modal
      visible={isVisible}
      transparent
      onBackdropPress={() => handleCloseModal()}
      onRequestClose={() => {
        handleCloseModal()
      }}
      style={{ flex: 1 }}
    >
      <TouchableOpacity
        style={{
          flex: 1,
          backgroundColor: 'rgba(107,106,106, 0.6)',
          position: 'relative',
          alignItems: 'center',
          paddingTop: '10%',
        }}
        onPress={() => handleCloseModal()}
      >
        <View
          style={{
            position: 'absolute',
            backgroundColor: colors.white,
            width: '30%',
            paddingBottom: 16,
            borderRadius: 15,
          }}
        >
          <View
            style={{
              flexDirection: 'column',
              padding: 14,
              position: 'relative',
              height: 200,
            }}
          >
            <Text
              variant="heading6"
              style={{ marginTop: 50, textAlign: 'center' }}
            >
              {getTitleText()}
            </Text>
            <Button
              label={t('BUTTON.OK')}
              buttonStyle={{ width: 100, alignSelf: 'center', marginTop: 50 }}
              onPress={() => handleCloseModal()}
            />
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  )
}
