import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useTheme } from '@libs/theme'
import Text from '../Text/Text'

const Link = (props) => {
  const { colors } = useTheme()
  const {
    label,
    underline,
    labelVariant = 'display3',
    labelStyle,
    labelColor,
    style,
    handlePress,
    disable,
  } = props
  return (
    <TouchableOpacity onPress={handlePress} style={style} disabled={disable}>
      <Text
        color={labelColor || colors.onNeutral}
        variant={labelVariant}
        style={[
          {
            textDecoration: underline ? 'underline' : 'none',
          },
          labelStyle,
        ]}
      >
        {label}
      </Text>
    </TouchableOpacity>
  )
}

export default Link
