import React from 'react'
import { StyleSheet, View } from 'react-native'
import LottieView from 'react-native-web-lottie'

const NotificationAnimation = (props) => (
  <View style={styles.animationContainer(props)}>
    <LottieView
      source={require('./loadingAnimation.json')}
      autoPlay
      loop
      speed={0.8}
    />
  </View>
)

const styles = StyleSheet.create({
  animationContainer: (props) => ({
    height: props?.height ?? 24,
    width: props?.width ?? 24,
    margin: props?.margin ?? '2rem',
  }),
})
export default NotificationAnimation
