import { Linking, Platform } from 'react-native'

export const isValidBasket = ({
  programDetails,
  basketItems,
  programmeId,
}) => {
  let cartDetails = { canAdd: true }
  basketItems?.forEach((item, index) => {
    if (!cartDetails.canAdd) {
      return
    }
    if (
      programDetails.id === item.programmeId ||
      programDetails.Id === item.programmeId ||
      programmeId === item.programmeId
    ) {
      return (cartDetails = { canAdd: false, type: 'programAlreadyExist' })
    } else if (programDetails.brand === item.brand) {
      return (cartDetails = { canAdd: false, type: 'brandAlreadyExist' })
    } else {
      cartDetails = { canAdd: true }
    }
  })

  return cartDetails
}

export const handleNavigation = async ({ url, fileName = '', translation, canValidate = true }) => {

  const isValidUrl = await isValidDocumentUrl({ url })
  if (isValidUrl || !canValidate) {
    Platform.OS === 'web' ? window.open(url, '_blank') : Linking.openURL(url).catch((err) =>
      console.error('Error opening URL: ', err),
    )
  }
  else {
    toast.show(`${translation('ERROR_MESSAGE.DOCUMENT_NOT_AVAILABLE_TEXT1')} ${fileName} ${translation('ERROR_MESSAGE.DOCUMENT_NOT_AVAILABLE_TEXT2')}`, {
      type: 'danger',
    })
  }
}

const isValidDocumentUrl = async ({ url }) => {
  try {
    const response = await fetch(url, { method: 'GET' })
    return response?.ok
  } catch (error) {
    return false
  }
}

export const getRecentData = ({ itemList }) => {
  let mostRecentDate = ''
  let uploadDocuments = {}

  for (const item of itemList) {
    const createdDate = new Date(item.CreatedDate)

    if (!mostRecentDate || createdDate > mostRecentDate) {
      mostRecentDate = createdDate
      uploadDocuments = item
    }
  }
  return uploadDocuments
}
