import React, { useRef } from 'react'
import { View, StyleSheet, ImageBackground } from 'react-native'
import { Icon } from '@app-hero/native-icons'
import { Text } from '@libs/components'

import { DomesticBg, InternationalBg } from '@apphero/assets'
import { DateFormat } from '@libs/utils'
import { useTheme } from '@libs/theme'

const PricingCard = ({ pricingData, key, index, style }) => {
  const { colors } = useTheme()
  const imageRef = useRef()
  return (
    <ImageBackground
      source={index === 0 ? DomesticBg : InternationalBg}
      imageStyle={{ resizeMode: 'contain', width: 200, height: 200 }}
      style={[styles.pricingContainer, style]}
      key={key}
      ref={imageRef}
    >
      <View style={styles.header}>
        <View style={styles.iconContainer}>
          <Icon
            name={index === 0 ? 'Domestic' : 'GlobeOutline'}
            color={colors.onNeutral}
          />
        </View>
        <View style={styles.headerText}>
          <Text variant="heading4" color={colors.onNeutral}>
            {pricingData?.[0]?.priceBookName}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 30 }}>
        {pricingData?.map((item, pricingIndex) => (
          <View style={{ flexDirection: 'column', paddingLeft: 62 }}>
            {pricingIndex === 0 ? (
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 15,
                }}
              >
                <Text
                  variant="display3"
                  style={{ fontWeight: 500, marginLeft: 70 }}
                >
                  Intake:
                </Text>
                <Text variant="display3" style={{ fontWeight: 500 }}>
                  Price:
                </Text>
              </View>
            ) : null}
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text variant="display3" style={{ width: 95 }}>
                {DateFormat(item?.intakeDate)}
              </Text>

              <View
                style={{
                  height: 2,
                  width: 15,
                  backgroundColor: colors.primaryPlaceHolder,
                  marginLeft: 6,
                }}
              />
              <Text variant="display3" style={{ paddingLeft: 10 }}>
                {item?.price}
              </Text>
              <Text
                variant="display3"
                color={colors.primaryPlaceHolder}
                style={{ paddingLeft: 10 }}
              >
                {item?.currencyCode}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  pricingContainer: {
    padding: 30,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: 'rgba(3, 30, 125, 0.05)',
    shadowOffset: { width: 0, height: 2 },
    elevation: 5,
    shadowOpacity: 1,
    shadowRadius: 10,
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    flex: 1,
    marginLeft: 20,
  },
  iconContainer: {
    backgroundColor: '#ECF2FC',
    width: 42,
    height: 42,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    color: '#043C8F',
  },
  price: {
    marginLeft: 40,
  },
})

export default PricingCard
