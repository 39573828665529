/* eslint-disable no-nested-ternary */
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native'
import React from 'react'
import { NotificationAnimation, Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import LinearGradient from 'react-native-linear-gradient'
import { Icon } from '@app-hero/native-icons'
import { formatNotificationTimestamp } from '@libs/utils/src/dateformat'
import { useTheme } from '@libs/theme'
import EmptyNotificationContainer from '../../components/container/EmptyNotificationContainer'

const DesktopView = ({ navigation, ...props }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const {
    inAppNotifications,
    handleUpdateNotification,
    loadMoreNotifications,
    handleEventNavigation,
    total,
    offset,
    notificationLoading,
  } = props

  return (
    <LinearGradient
      style={{
        justifyContent: 'center',
        paddingHorizontal: 25,
        minHeight: '90vh',
        paddingBottom: 80,
      }}
      start={{ x: 0, y: 0 }}
      end={{ x: -1, y: -1 }}
      colors={[colors.gradientStart, colors.gradientEnd]}
      locations={[0.2, 1.3]}
    >
      <View style={styles.mainView(colors)}>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 56,
            maxHeight: '2.5rem',
            marginBottom: 53,
            marginTop: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              fontWeight: 700,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('my-application')
              }}
            >
              <Icon style={{ marginRight: '1rem' }} name="ArrowNarrowLeft" />
            </TouchableOpacity>

            <View
              style={{
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              Notifications
            </View>
          </View>
          {/* <View style={{ flex: 1, flexDirection: 'row-reverse' }}>
            <Button
              label={t('BUTTON.SETTINGS')}
              buttonColor={colors.primary}
              labelColors={colors.white}
              onPress={() => {}}
              buttonStyle={{
                borderRadius: 8,
                height: 39,
              }}
              leftIcon={
                <Icon
                  style={{ height: '1rem', width: '1rem' }}
                  name="Setting"
                />
              }
              labelStyle={{ fontWeight: 700 }}
              disable
            />
          </View> */}
        </View>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'flex-start',
          }}
        >
          {inAppNotifications.length > 0 ? (
            inAppNotifications?.map((notificationItem, index) =>
              notificationItem !== null ? (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    if (!notificationItem?.readStatus) {
                      handleUpdateNotification(notificationItem)
                    }
                    handleEventNavigation(notificationItem)
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      flex: 1,
                      marginHorizontal: 56,
                      borderRadius: '0.8rem',
                      padding: 20,
                      marginVertical: '0.7rem',
                      backgroundColor: notificationItem?.readStatus
                        ? 'transparent'
                        : colors.notificationUnreadBackground,
                      borderWidth: notificationItem?.readStatus ? 1 : 0,
                      borderColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: colors.primary,
                        padding: 10,
                        borderRadius: 32,
                        marginRight: '1rem',
                        height: '3.375rem',
                        width: '3.375rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon name="ShieldIcon" color={colors.white} />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        variant="display3"
                        style={
                          notificationItem?.readStatus
                            ? styles.item(colors)
                            : [
                                styles.item(colors),
                                {
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.textPrimary,
                                },
                              ]
                        }
                      >
                        {notificationItem?.message}
                      </Text>

                      <Text
                        variant="display6"
                        style={{
                          color: colors.textPrimary,
                          opacity: notificationItem?.readStatus ? 0.5 : 1,
                          fontSize: 14,
                          fontWeight: notificationItem?.readStatus ? 400 : 700,
                          textAlign: 'right',
                          position: 'absolute',
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        {formatNotificationTimestamp(
                          notificationItem?.createdAt,
                        )}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.divider} />
                </TouchableOpacity>
              ) : null,
            )
          ) : (
            <EmptyNotificationContainer />
          )}
          {inAppNotifications.length > 0 &&
          total > offset &&
          !notificationLoading ? (
            <>
              <View style={[styles.divider, { marginBottom: 0 }]} />
              <TouchableOpacity
                onPress={() => loadMoreNotifications()}
                style={{ height: '48px', justifyContent: 'center' }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '100%' }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        alignItems: 'center',
                        flex: 1,
                      }}
                    >
                      <Text
                        variant="display3"
                        style={[
                          {
                            color: colors.textAccent,
                            fontWeight: 700,
                            lineHeight: 22.5,
                            textAlign: 'center',
                            flex: 1,
                            height: '100%',
                            width: '100%',
                          },
                        ]}
                        numberOfLines={2}
                        ellipsizeMode="tail"
                      >
                        See more
                      </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            </>
          ) : (
            total > offset && (
              <>
                <View style={styles.divider} />
                <View
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    height: '48px',
                  }}
                >
                  <NotificationAnimation height={48} width={48} margin={0} />
                </View>
              </>
            )
          )}
        </ScrollView>
      </View>
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  mainContainer: (colors) => ({}),
  mainView: (colors) => ({
    width: '100%',
    maxWidth: 903,
    height: '95%',
    maxHeight: '90vh',
    marginHorizontal: 'auto',
    paddingVertical: 30,
    backgroundColor: colors.white,
    borderRadius: 16,
    marginTop: '0.2rem',
  }),
  item: (colors) => ({
    color: colors.textPrimary,
    fontWeight: 400,
    width: '80%',
  }),
})

export default DesktopView
