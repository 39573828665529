import React, { useState } from 'react'
import {
  View,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import { Icon } from '@app-hero/native-icons'
import { Text } from '@libs/components'
import { spacing, useTheme } from '@libs/theme'
import CheckBox from '@libs/components/src/CheckBox/CheckBox'

import { useTranslation } from 'react-i18next'
import { isMobile } from '@libs/utils/src/screenLayout'

const Filter = (props) => {
  const {
    activeTab,
    filterData = [],
    filterHeading = '',
    filtersApplied,
    handleCheck = () => {},
    hasSubData,
    setActiveTab = () => {},
    setFiltersApplied = () => {},
  } = props
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  if (hasSubData) {
    return (
      <View style={styles.dropDownFilter}>
        {hasSubData &&
          Object.entries(filterData)?.map(([key, value], index) => (
            <Accordion
              itemIndex={index}
              field={key}
              value={value}
              filtersApplied={filtersApplied}
              setFiltersApplied={setFiltersApplied}
              handleCheck={handleCheck}
            />
          ))}
      </View>
    )
  }
  return (
    <View>
      <Text variant="heading3" color={'rgba(2, 21, 71, 1)'}>
        {filterHeading}
      </Text>
      <View style={styles.filterList}>
        {filterData?.map((item, index) => (
          <FilterItem
            item={item}
            setActiveTab={setActiveTab}
            isActive={index === activeTab}
            index={index}
            mobile={mobile}
          />
        ))}
      </View>
    </View>
  )
}

const FilterItem = ({ item, setActiveTab, isActive, index, mobile }) => {
  const { colors } = useTheme()
  return (
    <TouchableOpacity
      style={[
        styles.listItem(mobile),
        index === 0 ? { position: 'relative', right: 24 } : '',
        index === 0 ? { color: colors.draftBlue } : '',
      ]}
      key={index}
      onPress={() => setActiveTab(index)}
    >
      <Icon
        name={item.icon}
        height={20}
        width={20}
        color={isActive ? colors.draftBlue : ''}
      />
      <Text
        style={[
          styles.filterName,
          isActive ? styles.isActiveStyle : { fontSize: 15 },
        ]}
      >
        {item.label} {index !== 0 && item?.count > 0 ? `(${item.count})` : ''}
      </Text>
    </TouchableOpacity>
  )
}

const Accordion = ({
  field,
  value,
  itemIndex,
  filtersApplied,
  setFiltersApplied,
  handleCheck,
}) => {
  const [open, setOpen] = useState(false)
  const { colors } = useTheme()
  const { t } = useTranslation()
  const data = {
    institution: {
      icon: 'Parlament',
      name: t('DASHBOARD.INSTITUTION'),
    },
    country: {
      icon: 'LocationMarker',
      name: t('DASHBOARD.COUNTRY'),
    },
    level: {
      icon: 'AcademicCap',
      name: t('DASHBOARD.LEVEL'),
    },
    subject: {
      icon: 'SubjectOutline',
      name: t('DASHBOARD.SUBJECT'),
    },
    intake: {
      icon: 'CalendarOutline',
      name: t('DASHBOARD.INTAKE'),
    },
  }
  const accordionIcon = open ? 'ArrowUp' : 'ArrowDown'

  return (
    <View style={styles.accordionContainer} key={field}>
      <TouchableOpacity
        style={styles.accordionHeader}
        onPress={() => setOpen(!open)}
      >
        <View style={styles.headerRight}>
          <Icon
            name={data?.[field]?.icon}
            height={20}
            width={20}
            color={open ? colors.primary : colors.neutral}
          />
          <Text
            color={open ? colors.primary : colors.neutral}
            variant="heading5"
            style={styles.fieldName}
          >
            {data?.[field]?.name}
          </Text>
        </View>
        <Icon
          name={accordionIcon}
          height={20}
          width={20}
          color={open ? colors.primary : colors.neutral}
        />
      </TouchableOpacity>
      <View style={styles.accordionBody}>
        {open &&
          value?.options?.map((option, index) => (
            <CheckBox
              label={option.name}
              value={option.value}
              itemIndex={itemIndex}
              handleCheck={handleCheck}
              isActive={filtersApplied[field]}
              filtersApplied={filtersApplied}
              field={field}
              index={index}
              setFiltersApplied={setFiltersApplied}
            />
          ))}
      </View>
      <View style={[styles.line, { backgroundColor: colors.neutral }]} />
    </View>
  )
}

const styles = StyleSheet.create({
  accordionFilter: {
    padding: spacing.spacing4,
    borderRadius: 6,
    elevation: 5,
    backgroundColor: 'transparent',
  },
  accordionContainer: {
    flexDirection: 'column',
  },
  accordionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing.spacing4,
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionBody: {
    width: '100%',
    paddingHorizontal: spacing.spacing4,
  },
  fieldName: {
    marginLeft: spacing.spacing4,
  },
  line: {
    width: '100%',
    height: 1,
    marginVertical: 10,
  },
  filterList: {
    marginTop: 30,
  },
  listItem: (mobile) => ({
    flexDirection: 'row',
    paddingHorizontal: spacing.spacing6,
    paddingVertical: spacing.spacing4,
    alignItems: 'center',
    borderRadius: 100,
    maxWidth: mobile ? '75%' : '',
  }),
  filterName: {
    marginLeft: 10,
  },
  dropDownFilter: {
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  dropDownFilterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  seperator: {
    height: 30,
    width: 1,
    marginRight: 20,
  },

  isActiveStyle: {
    color: '#2563EB',
    fontWeight: 700,
    fontSize: 15,
  },
})

export default Filter
