import {
  View,
  StyleSheet,
  ImageBackground,
  ScrollView,
  Platform,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import React, { useState } from 'react'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import {
  Button,
  CheckBox,
  FilePicker,
  Loader,
  MobileInput,
  Text,
  TextInput,
} from '@libs/components'

import ModalComponent from '../../components/modal'
import SearchDropDown from '@libs/components/src/DropDown/SearchDropdown'
import { isMobile } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'

const DesktopView = ({
  ctaEnable,
  handleChange,
  countryList,
  DropdownButton,
  dropdownRight,
  dropdownTop,
  dropdownWidth,
  successPopup,
  setSuccessPopup,
  isProfileUpdating,
  isLastNameAvailable,
  profileDetails,
  handleSave,
  getImageSource,
  setProfileDetails,
  toggleDropdown,
  setIsLastNameAvailable,
  errorMessage,
  setCTAEnable,
  navigation,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  return (
    <ImageBackground
      // source={ProfileBg}
      style={{ flex: 1 }}
      imageStyle={{
        width: '60%',
        position: 'absolute',
        top: 0,
        left: '40%',
      }}
      // resizeMode="contain"
    >
      <ScrollView style={{ flex: 1 }}>
        <View style={styles.mainContainer}>
          <View style={styles.profileContainer}>
            <View style={styles.profileLeftHeader}>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('my-application')
                }}
              >
                <Icon
                  name="ArrowNarrowLeft"
                  height={24}
                  width={24}
                  color={colors.black}
                />
              </TouchableOpacity>

              <View>
                <Text style={styles.myProfileText(colors)}>
                  {t('PROFILE.PERSONAL_INFORMATION')}
                </Text>
              </View>
            </View>
            <View style={styles.divider} />

            <View style={styles.contentContainer}>
              <View
                style={{
                  flexDirection: 'row',
                  columnGap: 20,
                  marginTop: 10,
                  flexWrap: 'wrap',
                }}
              >
                <View style={styles.imageContainer}>
                  <View
                    style={{
                      height: '100%',
                      width: '100%',
                      overflow: 'hidden',
                      paddingHorizontal: 25,
                      paddingVertical: 20,
                    }}
                  >
                    <FilePicker
                      isProfile
                      handleFileSelect={(image) =>
                        handleChange('profilePictureLocation', image)
                      }
                      source={getImageSource()}
                      profileStyle={{
                        borderRadius: '80%',
                        width: 130,
                        height: 130,
                      }}
                    />
                  </View>
                </View>
                <View style={styles.formContainer}>
                  <View style={styles.username}>
                    <TextInput
                      placeholder={t('TEXT_INPUT.FIRST_NAME')}
                      onChangeText={(text) => handleChange('firstName', text)}
                      value={profileDetails.firstName}
                      dropDownData=""
                      style={{
                        marginBottom: 20,
                        backgroundColor: 'white',
                        paddingVertical: 10,
                        height: 45,
                        borderRadius: 12,
                        borderColor: '#E0E0E0',
                      }}
                      editable
                    />
                    <TextInput
                      placeholder={t('TEXT_INPUT.LAST_NAME')}
                      onChangeText={(text) => handleChange('lastName', text)}
                      value={profileDetails.lastName}
                      style={{
                        marginBottom: 20,
                        backgroundColor: 'white',
                        paddingVertical: 10,
                        height: 45,
                        borderRadius: 12,
                        borderColor: '#E0E0E0',
                      }}
                      dropDownData=""
                      editable={!isLastNameAvailable}
                    />
                    <View
                      style={styles.checkboxContainer}
                      className="checkboxContainer"
                    >
                      <CheckBox
                        value={isLastNameAvailable}
                        handleCheck={() => {
                          setIsLastNameAvailable(!isLastNameAvailable)
                          setCTAEnable(true)
                        }}
                      />
                      <Text className="heading">I don’t have last name</Text>
                    </View>
                    <MobileInput
                      value={profileDetails?.mobile?.mobileNumber || ''}
                      dialCode={profileDetails?.mobile.dialCode || ''}
                      placeholder={t('TEXT_INPUT.PHONE')}
                      onChangeText={(text) => handleChange('mobile', text)}
                      placeholderColor="#E0E0E0"
                      handleCountrySelection={(selectedCountry) => {
                        setProfileDetails((previousData) => ({
                          ...previousData,
                          mobile: {
                            dialCode: selectedCountry?.dial_code,
                            mobileNumber: previousData.mobile?.mobileNumber,
                          },
                        }))
                      }}
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 12,
                        height: 45,
                        borderColor: '#E0E0E0',
                      }}
                    />
                  </View>
                  <View
                    style={{ flex: 1, marginBottom: 20, marginTop: 20 }}
                    ref={DropdownButton}
                  >
                    <SearchDropDown
                      style={{
                        margin: 0,
                        width: '100%',
                        backgroundColor: 'white',
                        borderRadius: 12,
                        borderColor: '#E0E0E0',
                        height: 45,
                      }}
                      label={t('TEXT_INPUT.COUNTRY')}
                      labelColor={colors.onNeutral}
                      placeHolderColor="#E0E0E0"
                      toggleDropdown={toggleDropdown}
                      onPress={(selectedCountry) =>
                        handleChange('country', selectedCountry)
                      }
                      dropdownWidth={dropdownWidth}
                      isCountryCode
                      items={countryList || []}
                      value={
                        profileDetails.country
                          ? {
                              Label: profileDetails.country,
                              Value: profileDetails.countryCode,
                            }
                          : {}
                      }
                      position={{
                        top: dropdownTop,
                        right: dropdownRight - 205,
                      }}
                      dropdownHeight={150}
                    />
                  </View>
                  <TextInput
                    label="Email ID"
                    onChangeText={() => {}}
                    value={profileDetails?.email || ''}
                    editable={false}
                    dropDownData=""
                    style={{
                      marginBottom: 20,
                      backgroundColor: 'white',
                      paddingVertical: 10,
                      height: 45,
                      borderRadius: 12,
                      borderColor: '#E0E0E0',
                    }}
                    inputFieldStyle={{ color: colors.primaryPlaceHolder }}
                  />
                  {errorMessage ? (
                    <Text
                      variant="display4"
                      color={colors.onAlert}
                      style={{ marginBottom: 20 }}
                    >
                      {errorMessage}
                    </Text>
                  ) : null}
                </View>
              </View>

              {isProfileUpdating ? (
                <View style={{ marginBottom: 30 }}>
                  <Loader size={32} hasFlexValue={false} />
                </View>
              ) : (
                <Button
                  label={t('BUTTON.SAVE_CHANGES')}
                  labelStyle={{
                    color: colors.white,
                    flexDirection: 'row',
                    fontSize: 14,
                    fontWeight: 700,
                    fontWeight: 'bold',
                  }}
                  onPress={() => handleSave()}
                  disable={!ctaEnable}
                  appearance="filled"
                  buttonStyle={{
                    alignItems: 'center',
                    borderRadius: 8,
                    paddingHorizontal: 60,
                    justifyContent: 'center',
                    backgroundColor: '#2563EB',
                    marginLeft: 'auto',
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <ModalComponent
        visible={successPopup.visible}
        title={successPopup.message}
        buttonLabel={t('BUTTON.OK')}
        handleCloseModal={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        handleButton={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        buttonColor={colors.primaryContainer}
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
    maxWidth: 903,
    height: '100%',
    maxHeight: 692,
    marginHorizontal: 'auto',
    paddingVertical: 30,
  },
  profileContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    shadowColor: 'rgba(0, 15, 68, 0.15)',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 20,
    padding: 35,
  },
  profileHeader: {
    height: 70,
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    alignItems: 'center',
  },
  imageContainer: {
    height: 167,
    width: 196,
    paddingHorizontal: 10,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    backgroundColor: '#ECF3FF',
  },

  formContainer: {
    alignSelf: 'center',
    flex: 1,
    marginTop: 5,
  },
  subContainer: {
    // paddingTop: 30,
    width: 320,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    marginLeft: 2,
    marginTop: -10,
  },
  divider: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginTop: 20,
  },
  profileLeftHeader: {
    flexDirection: 'row',
    columnGap: 30,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  myProfileText: (colors) => ({
    fontWeight: 700,
    fontSize: 24,
    color: colors.primaryText,
    letterSpacing: 0.2,
  }),

  contentContainer: {
    borderRadius: 16,
    columnGap: 20,
    marginTop: 25,
  },
  username: {
    flexDirection: 'column',
    rowGap: 6,
  },
})

export default DesktopView
