import { View } from 'react-native'
import React, { useCallback, useEffect, useState } from 'react'
import DesktopView from './DesktopView'
import { ScreenLayout } from '@libs/utils'
import { useIsFocused } from '@react-navigation/native'
import { usePriceBookDetail, useProgramByID } from '../../hooks/usePrograms'
import { DateFormat } from '@libs/utils'

const ProgrammeApplication = (props) => {
  const [location, setLocation] = useState([])
  const [intake, selectedIntake] = useState([])
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const isFocused = useIsFocused()
  const programId = props?.route?.params?.id

  const { data: programmes, isFetching: isProgrammesFetching } = useProgramByID(
    {
      isFocused: isFocused,
      programId: programId,
      queryKey: 'getProgramByID',
    },
  )

  const { data: pricingData, isFetching: isPriceBookFetching } =
    usePriceBookDetail({
      isFocused: isFocused,
      programId: programId,
      brand: programmes?.brand,
      queryKey: 'getPricingDetails',
    })

  useEffect(() => {
    if (!isFocused || pricingData?.length === 0) return
    const uniqueLocations = Array.from(
      new Set(pricingData?.map((item) => item?.location)),
    )
    const finalLocationList = uniqueLocations?.map((location) => ({
      label: location,
      isSelected: false,
    }))
    setLocation(finalLocationList)
  }, [isFocused, pricingData])

  const toggleDropdown = (visible, ref) => {
    if (visible) {
      return
    }
    if (!visible) {
      ref?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
        setDropdownTop(py + 50)
        setDropdownLeft(_px)
        setDropDownWidth(_w)
      })
    }
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, DesktopView, DesktopView),
    [],
  )

  const handleLocationSelection = (selectedItem) => {
    const newLocation = location.map((item) => {
      if (item?.label === selectedItem?.label) {
        item.isSelected = true
      } else {
        item.isSelected = false
      }
      return item
    })
    const uniqueIntakeList = Array.from(
      new Set(
        pricingData?.map((item) => {
          if (item?.location === selectedItem.label) {
            const formattedDate = DateFormat(item?.intakeDate)
            return formattedDate
          }
        }),
      ),
    )
    const finalIntakeList = uniqueIntakeList.map((intake) => ({
      label: intake,
      isSelected: false,
    }))
    selectedIntake(finalIntakeList)
    setLocation(newLocation)
  }

  const handleIntakeSelection = (selectedItem) => {
    const newIntake = intake.map((item) => {
      if (item?.label === selectedItem?.label) {
        item.isSelected = true
      } else {
        item.isSelected = false
      }
      return item
    })
    selectedIntake(newIntake)
  }

  const viewProps = {
    location,
    dropdownTop,
    dropdownLeft,
    dropdownWidth,
    intake,
    isLoading: isPriceBookFetching || isProgrammesFetching,
    programmes,
    toggleDropdown,
    handleLocationSelection,
    handleIntakeSelection,
  }
  return (
    <View>
      <LayoutView {...viewProps} />
    </View>
  )
}

export default ProgrammeApplication
