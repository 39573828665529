import { ScrollView, View, StyleSheet, useWindowDimensions } from 'react-native'
import React from 'react'
import { isMobile } from '@libs/utils/src/screenLayout'

export const LayoutContainer = ({ scrollViewRef, children }) => {
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)

  if (scrollViewRef) {
    return (
      <ScrollView
        styles={{ backgroundColor: 'white', flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: 'center',
        }}
        ref={scrollViewRef}
      >
        <View style={styles.mainContainer(mobile)}>{children}</View>
      </ScrollView>
    )
  }
  return (
    <ScrollView
      styles={{ flex: 1, backgroundColor: '#fff' }}
      contentContainerStyle={{
        flexGrow: 1,
        alignItems: 'center',
      }}
    >
      <View style={styles.mainContainer(mobile)}>{children}</View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  mainContainer: (mobile) => ({
    paddingVertical: 20,
    flex: 1,
    backgroundColor: 'rgba(227, 231, 244, 0.4)',
    width: '100%',
    paddingHorizontal: mobile ? 12 : 20,
  }),
})
