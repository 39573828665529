import React, { useState } from 'react'
import {
  TouchableOpacity,
  View,
  Text,
  Image,
  useWindowDimensions,
} from 'react-native'
import { spacing, useTheme } from '@libs/theme'
import { Loader } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { isWeb } from '@libs/utils/src/screenLayout'

export const DocumentItem = ({
  index,
  item,
  finalDocumentDate,
  onPress,
  showDate,
  style,
  height,
}) => {
  const { colors } = useTheme()
  const [isFetching, setIsFetching] = useState(false)
  const isDesktop = isWeb(useWindowDimensions().width)
  const [show, setShow] = useState(false)

  return (
    <TouchableOpacity
      style={{
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: isDesktop ? 208 : '100%',
        shadowColor: 'rgba(3, 30, 125, 0.05)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 10,
        elevation: 5,
        borderRadius: 10,
        backgroundColor: 'white',
        height: isDesktop ? height : '',
      }}
      key={index}
      onPress={() => {
        setIsFetching(true)
        onPress()
        setIsFetching(false)
      }}
      onMouseEnter={(e) => {
        if (isDesktop) {
          setShow(true)
        }
      }}
      onMouseLeave={(e) => {
        if (isDesktop) {
          setShow(false)
        }
      }}
    >
      {show && (
        <TouchableOpacity
          onPress={() => {
            setIsFetching(true)
            onPress()
            setIsFetching(false)
          }}
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            zIndex: 2,
          }}
        >
          <Icon
            name="Download"
            style={{
              backgroundColor: colors.background,
              borderRadius: '50%',
              height: 32,
              width: 32,
            }}
          />
        </TouchableOpacity>
      )}
      <View
        style={{
          flexDirection: isDesktop ? 'column' : 'row',
          gap: 24,
          width: '100%',
          opacity: show ? 0.4 : 1,
          backgroundColor: show ? colors.backgroundPrimary : '',
          paddingHorizontal: 20,
          paddingVertical: showDate || !isDesktop ? 20 : 0,
          justifyContent: !showDate ? 'center' : 'space-around',
          height: '100%',
          alignItems: !isDesktop ? 'center' : '',
        }}
      >
        <Image
          source={item?.placeholderImage}
          style={{ height: 48, width: 40 }}
        />

        <Text style={style} numberOfLines={3}>
          {item?.Name}
        </Text>
        {showDate && isDesktop ? (
          <Text style={{ fontSize: 12, fontWeight: 400 }} color={'#9E9E9E'}>
            {finalDocumentDate}
          </Text>
        ) : null}

        {!isDesktop && (
          <TouchableOpacity
            onPress={() => {
              setIsFetching(true)
              onPress()
              setIsFetching(false)
            }}
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Icon name="Download" />
          </TouchableOpacity>
        )}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {isFetching ? <Loader size={10} style={{ marginLeft: 10 }} /> : null}
      </View>
    </TouchableOpacity>
  )
}
