export default {
  ACCORDION: {
    PROGRAMMES: 'Programmes',
  },
  ALERT: {
    INVALID_EMAIL:
      "Veuillez essayer une autre adresse e-mail, nous n'avons pas pu trouver d'applications correspondant à cette adresse e-mail dans notre système.",
    PASSWORD_CHANGE_SUCCESS: 'Le mot de passe a été modifié avec succès',
    MULTI_SAME_PROGRAM:
      "Vous ne pouvez pas ajouter plus d'un programme à la même marque.",
    PROGRAM_ALREADY_EXIST: 'Ce programme existe déjà dans votre panier.',
    PROGRAM_ID_ALREADY_EXIST:
      'Le programme a déjà été ajouté pour comparaison.',
  },
  APPLICATION: {
    CONGRATULATION: 'Félicitations!',
    DESCRIPTION: 'Description',
    HEADING: 'Demande de Programme',
    LEVEL: 'Niveau',
    OFFER_DESCRIPTION: `Vous avez reçu une offre pour l'application`,
    PROGRAMME_INFORMATION: 'Informations sur le Programme',
    SUBJECT: 'Sujet',
  },
  APPLICATION_BASKET: {
    HEADING1: 'Merci',
    HEADING2: "Demandes en cours d'examen",
    SUB_HEADING1: "Les détails de l'élève ont été soumis avec succès !",
    SUB_HEADING2: 'Votre demande a été soumise avec succès !',
    STUDENT_DETAIL_CARD_DESCRIPTION1: `Veuillez fournir à l'équipe des admissions des informations personnelles supplémentaires essentielles, qui faciliteront le processus de demande.`,
    STUDENT_DETAIL_CARD_DESCRIPTION2: `Ces informations seront appliquées et pré-remplies automatiquement dans les futures demandes.`,
    TITLE: "Détails de l'élève",
    APPLICATION_CARD_DESCRIPTION: `Veuillez fournir des informations spécifiques liées à la marque qui sont cruciales pour ce programme.`,
    DESCRIPTION: `Vous pouvez suivre la progression de votre ou de vos demandes ci-dessous dans l'onglet "Mes demandes". Pendant l'examen des admissions, nous pouvons vous contacter pour des mises à jour supplémentaires sur votre demande. Vous pouvez vérifier votre compte ici ou l'adresse e-mail associée à votre demande pour les alertes liées à la progression de votre demande.`,
  },
  APPLICATION_DETAIL: {
    ADMISSION_NOTES: `Notes d'admission`,
    ADMISSIONS_LETTER: `Lettres d'admission`,
    AGENT_ACCOUNT: `Compte d'agent`,
    AGENT_CONTACT: 'Agent de contact',
    AGENT_EMAIL: "E-mail de l'agent",
    AGENT_PHONE: "Téléphone de l'agent",
    ANY_QUESTION: 'Avez-vous des questions?',
    APPLICATION_NO: 'Numéro de demande:',
    APPLICATION_OWNER: 'Propriétaire de la demande',
    ATTACH_DOCUMENTS: 'Joindre des documents',
    BUSINESS_DEVELOPER: 'Développeur commercial',
    COMMENTS: 'Commentaires',
    CONDITIONS: 'Conditions',
    CONTACT_TEXT: 'Contactez',
    CREATED: 'Créé',
    DOCUMENTS_LETTER: 'Documents',
    EMAIL: 'Adresse e-mail associée à cette demande',
    FULL_NAME: 'Nom complet (comme sur le passeport)',
    INFORMATION: 'Information',
    OFFERS: 'Offres',
    PHONE_NUMBER: 'Numéro de téléphone associé à cette demande',
    INTAKE: 'Admission',
    DURATION: 'Durée',
    LOCATION: 'Emplacement',
    CREATED_DATE: 'Date de création',
    MEETING_LINK: 'Réunion du livre',
    BOOK_A_MEETING: 'Réserver une réunion',
    STATUS: 'Statut',
    ADVISOR: 'Conseiller',
    CREATED_BY: 'Créé par',
  },
  AUTH: {
    FOOTER_LINK: 'Systèmes universitaires mondiaux',
    FOOTER: 'En savoir plus sur ',
    FORGOT_PASSWORD: 'Mot de passe oublié',
    LOGIN: 'Se connecter',
    OR: 'Ou connectez-vous avec',
    OTP_SEND:
      'Nous avons envoyé un OTP à votre e-mail. Veuillez vérifier votre boîte de réception.',
    PASSWORD_RESET_SUCCESSFULLY:
      'Votre mot de passe a été réinitialisé avec succès.',
    PRIVACY_POLICY: 'Accord de politique de confidentialité',
    GET_STARTED: 'Commençons!',
    REGISTER: "S'inscrire",
    ACCOUNT_SIGNUP: 'Créer un compte',
    ACCOUNT_SIGNIN: 'Connectez-vous à votre compte',
    SET_PASSWORD: 'Configurez votre mot de passe !',
    WELCOME_DESCRIPTION:
      'Débloquez votre potentiel avec des programmes prêts pour la carrière dans les meilleures destinations internationales',
    WELCOME_TITLE: "Découvrez un monde d'opportunités",
    WELCOME: 'Content de te revoir! veuillez entrer vos coordonnées',
    BRAND_ITEM_1_TITLE: `Certifications pertinentes pour l'industrie`,
    BRAND_ITEM_1_CONTENT: `Montez en compétence dans votre vie et votre carrière avec des programmes conçus pour vous préparer à l'avenir du travail.`,
    BRAND_ITEM_2_TITLE: 'Institutions primées',
    BRAND_ITEM_2_CONTENT:
      'Étudiez dans nos institutions multi-accréditées et multi-primées dans les villes les plus dynamiques, prospères et dynamiques du monde.',
  },
  BUTTON: {
    ACCEPT: 'Accepter',
    ADD_TO_APPLICATION: 'Ajouter à la demande',
    ADD_TO_COMPARE: 'Ajouter à la comparaison',
    APPLY: 'Appliquer',
    ATTACH_DOCUMENT: 'Joindre des documents',
    CANCEL: 'Annuler',
    CONFIRM: 'Confirmer',
    CONTINUE_APPLICATION: 'Continuer la demande',
    DECLINE: 'Refuser',
    DOWNLOAD_OFFER: `Télécharger l'offre`,
    GO_TO_MY_APPLICATION: 'Aller à mes demandes',
    GO_TO_PATH_FINDER: 'Aller à Path Finder',
    LOGIN: 'Se connecter',
    SIGNIN: 'Se connecter',
    LOGOUT: 'Déconnexion',
    MORE_INFORMATION: `Plus d'informations`,
    NEW_USER: 'Nouvel utilisateur',
    OK: 'OK',
    PROCEED: 'Procéder',
    REMOVE_APPLICATION: 'Retirer',
    SAVE: 'Enregistrer',
    SIGNUP: "S'inscrire",
    SET_PASSWORD: 'Définir le mot de passe',
    START_APPLICATION: 'Commencer la demande',
    SUBMIT: 'Soumettre',
  },
  COMPARE_SCREEN: {
    CLEAR_COMPARE_PROGRAM: 'Effacer la comparaison',
    COMPARE_PROGRAM: 'Comparer les programmes',
    INSTITUTION: 'Institution',
    LEVEL: 'Niveau',
    DELIVERY_MODE: 'Mode de livraison',
    PROGRAM_DURATION: 'Durée du programme',
    COURSE_PRICE: 'Prix du cours',
    FIELD_OF_STUDY: `Domaine d'étude`,
    LOCATION: 'Emplacement',
  },
  DASHBOARD: {
    CLEAR_FILTER: 'Effacer le filtre',
    COUNTRY: 'Pays',
    HERO_DESCRIPTION:
      "Montez en compétence dans votre vie et votre carrière avec l'enseignement supérieur. Global University Systems possède et collabore fièrement avec les institutions les plus innovantes et les plus progressistes de la planète pour délivrer des certifications pertinentes pour l'industrie axées sur l'apprentissage appliqué et pratique dans les économies les plus performantes du monde.",
    HERO_HEADER: "Découvrez un monde d'opportunités",
    HERO_SUB_HEADER:
      'Débloquez votre potentiel avec des programmes prêts pour la carrière dans les meilleures destinations internationales.',
    INSTITUTION: 'Institution',
    INTAKE: 'Admission',
    LEVEL: 'Niveau',
    LOAD_MORE: 'Charger plus',
    NO_PROGRAM: `Actuellement, il n'y a pas de programme disponible.`,
    SHOW_BY: 'Afficher par',
    SHOW_MORE: 'Afficher plus',
    SUBJECT: 'Sujet',
  },
  DROP_DOWN: {
    LABEL_SELECT: 'Sélectionner',
    DOWNLOAD_APPLICATION: `Télécharger l'application`,
    CHANGE_APPLICATION: 'Changer les informations',
    PROGRAMMES: 'Programmes',
  },
  ERROR_MESSAGE: {
    CAN_NOT_BE_EMPTY: 'Ne peut pas être vide',
    INVALID: 'Invalide',
    NOT_MATCHING: 'ne correspond pas',
    NOT_BE_SAME: 'ne peut pas être le même que',
    INVALID_EMAIL: 'Veuillez entrer une adresse e-mail valide',
    COUNTRY_NOT_SLEETED: 'Le pays ne doit pas être vide',
    PASSWORD_NOT_MATCH: 'Le mot de passe ne correspond pas',
    DOCUMENT_NOT_AVAILABLE_TEXT1: 'Le document',
    DOCUMENT_NOT_AVAILABLE_TEXT2:
      "demandé pour le téléchargement n'est pas disponible.Veuillez contacter l'administrateur.",
  },
  FILE_UPLOAD: {
    ATTACH_DOCUMENT: 'Joindre un document',
    DOCUMENT_TYPE: 'Type de document',
  },
  HEADER: {
    CART_TITLE: 'Panier de demandes',
    COMPARE_PROGRAM: 'Comparer les programmes',
    FIND_PROGRAM: 'Chercheur de chemins',
    MY_APPLICATION: 'Mes demandes',
    START_APPLICATION: 'Demandes',
  },
  LABEL: {
    COMPLETED: 'Terminé',
  },
  LINK: {
    ACCOUNT_EXIST: 'Vous avez déjà un compte? Se connecter',
    HAVE_ACCOUNT: 'Vous avez déjà un compte? ',
    DONT_HAVE_ACCOUNT: "Vous n'avez pas de compte ?",
    FORGOT_PASSWORD: 'Mot de passe oublié?',
    LINKED_IN: 'Continuer avec Linkedin',
    LINKED_TEXT: 'LinkedIn',
    MORE_INFO: "Plus d'informations",
  },
  MY_APPLICATION: {
    AGENT_DRAFT_ALERT:
      "Les demandes en cours de l'agent sont placées ici. Vous ne pouvez pas apporter de modifications ni examiner la demande tant que l'agent n'a pas finalisé la demande.",
    ALL_APPLICATION: 'Toutes les demandes',
    ADMISSIONS_REVIEW: "Examen d'admission",
    OFFERS: 'Offres',
    DRAFT: 'Brouillons',
    AGENT_DRAFT: "Brouillons de l'agent",
    NO_DATA: `Rien ici pour l'instant !`,
    VISA_PENDING: `Visa en attente`,
    ACCEPTED: 'Accepté',
    ENROLLED: 'Inscrite',
    REJECTED: 'Rejeté/Retiré',
  },
  OPPORTUNITIES_CARD: {
    DURATION: 'mois de programme',
    ESTIMATED_TUITION: 'frais de scolarité estimés',
  },
  PASSWORD_VALIDATION: {
    VALIDATION_1: 'Le minimum 6 caractères avec une lettre majuscule',
    VALIDATION_2: 'Au moins un personnage spécial',
    VALIDATION_3: 'Au moins un numéro',
  },
  PRIVACY_POLICY: {
    CHECK_BOX_1_TEXT: `En m'inscrivant et en accédant à ce site, j'accepte de respecter la`,
    CHECK_BOX_1_LINK_TEXT: `Politique d'utilisation acceptable`,
    CHECK_BOX_2_TEXT:
      "En m'inscrivant et en accédant à ce site, je consens à recevoir des informations (par e-mail, téléphone ou SMS) sur les cours et services ainsi que",
    CHECK_BOX_2_LINK_TEXT: 'des institutions partenaires du Groupe GUS',
    FIRST_PARAGRAPH_1:
      'AppHero est un outil utilisé par le groupe Global University Systems (le',
    FIRST_PARAGRAPH_2:
      ") et les institutions partenaires aux fins d'échange de données relatives aux processus de candidature et d'admission de ces institutions. Apphero.io est un site exploité par Global University Systems B.V. (« Nous »). Nous sommes enregistrés aux Pays-Bas sous le numéro d'entreprise 55367453 et avons notre siège social et notre adresse de commerce principale à Strawinskylaan 821, Tour A08, 1077XX, Amsterdam, aux Pays-Bas. Nous exploitons le site pour nous-mêmes et au nom d'autres sociétés qui opèrent sous les marques GUS ou détenues par GUS, ou qui collaborent avec les marques détenues par GUS aux fins du recrutement d'étudiants.",
    FIRST_PARAGRAPH_LINK_TEXT: 'Groupe GUS',
    SECOND_PARAGRAPH_1:
      'Les informations que vous fournissez sur ce formulaire seront traitées conformément à notre',
    SECOND_PARAGRAPH_2:
      ". Nous utiliserons les détails que vous nous avez fournis pour vous contacter au sujet de votre candidature et des processus d'admission auprès de l'institution ou des institutions auxquelles vous avez postulé.",
    SECOND_PARAGRAPH_LINK_TEXT: 'Politique de confidentialité',
  },

  PROFILE: {
    CHANGE_PASSWORD: 'Changer le mot de passe',
    PERSONAL_INFORMATION: 'Informations personnelles',
  },
  PROGRAM_SCREEN: {
    ADD_TO_COMPARISON: 'Ajouter à la comparaison',
    BRAND: 'Marque:',
    DELIVERY: 'Livraison: ',
    DOWNTOWN_DESCRIPTION:
      'Deux campus facilement accessibles situés dans le dynamique centre-ville de Vancouver, Canada',
    DOWNTOWN: 'Emplacement en centre-ville',
    DURATION: 'Durée:',
    LEVEL: 'Niveau:',
    LOCATION: 'Emplacement:',
    MEDAL_DESCRIPTION: `Évalué comme une institution cinq étoiles par le système de notation de l'enseignement supérieur QS Stars.`,
    PROGRAM_PRICE_DESCRIPTION: `Les projections de prix ci-dessous peuvent être sujettes à des modifications en raison de promotions, de bourses, de remises régionales, etc. Voici quelques exemples de tarification possible en fonction de ces facteurs. Veuillez vous référer à votre lettre d'offre pour le calcul final du prix de votre cours.`,
    PROGRAM_PRICE: 'Prix du programme',
    PROVEN_EMPLOYABILITY_DESCRIPTION: `100 % des étudiants en MBA ont trouvé un emploi dans les six mois suivant l'obtention de leur diplôme`,
    PROVEN_EMPLOYABILITY: 'Employabilité prouvée',
    RATING: 'Évaluation',
    RECOMMENDED_PROGRAM: 'Programmes recommandés',
    SCHOOL: 'École:',
    STAR: '5 étoiles QS',
    START_TO_APPLICATION: 'Demandes',
  },
  TEXT_INPUT: {
    AS_PER_PASSPORT: 'comme sur le passeport',
    CODE: 'OTP',
    CONFIRM_PASSWORD: 'Confirmer le mot de passe',
    COUNTRY: 'Pays',
    CURRENT_PASSWORD: 'Mot de passe actuel',
    EMAIL: 'E-mail',
    FIRST_NAME: 'Prénom',
    LAST_NAME: 'Nom de famille',
    NEW_PASSWORD: 'Nouveau mot de passe',
    PASSWORD: 'Mot de passe',
    PHONE: 'Numéro de téléphone',
    REPEAT_PASSWORD: 'Répéter le mot de passe',
    USER_NAME: `Nom d'utilisateur / E-mail`,
  },
}
