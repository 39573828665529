import React, { useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
  Linking,
} from 'react-native'
import { Text, Badge, Button, ShimmerPlaceholder } from '@libs/components'
import { Icon } from '@app-hero/native-icons'

import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { countRecentComments, countReadComments } from '@libs/utils'
import { LayoutContainer } from 'shared-layout'
import { handleNavigation } from '../../utils/function'
import LinearGradient from 'react-native-linear-gradient'
import { sort } from '../../utils/sorting'
import { FileUploadModal } from '../../components'
import EmptyContainer from '../../components/container/EmptyContainer'
import Accordion from '../../components/CommentAccordion/index.mobile'
import { DocumentItem } from '../../components/viewApplication/documentItem'
import Drawer from '../../components/Drawer/drawer'
import { signedurl } from '../../api'

const MobileView = ({
  setTabData,
  canShowMenu,
  brandLogoUrl,
  admissionsNotesTabData,
  applicationDocuments,
  applicationLetterDocuments,
  documentType,
  dropdownTop,
  dropdownLeft,
  dropdownWidth,
  dropDownRef,
  handleFileUpload,
  informationList,
  isDocumentFetching,
  moreIconDropDownDetails,
  handleMoreIcon,
  isLoading,
  modalVisible,
  opportunities,
  setModalVisible,
  tabData,
  toggleDropdown,
  handleScrollComment,
  scrollViewRef,
  childRef,
  handleUpdateComment,
  isActiveComment,
}) => {
  const { t } = useTranslation()

  const { colors } = useTheme()

  if (isLoading) {
    return (
      <View
        style={{
          backgroundColor: '#E3E7F466',
          paddingHorizontal: 30,
          minHeight: '100vh',
        }}
      >
        <ShimmerPlaceholder
          style={{
            height: 250,
            width: '100%',
            marginTop: spacing.spacing5,
            borderRadius: spacing.spacing5,
          }}
        />
        <ShimmerPlaceholder
          style={{
            height: 50,
            width: '100%',
            marginTop: spacing.spacing5,
            borderRadius: spacing.spacing5,
          }}
        />
        <ShimmerPlaceholder
          style={{
            height: 500,
            width: '100%',
            marginTop: spacing.spacing5,
            borderRadius: spacing.spacing5,
          }}
        />

        <ShimmerPlaceholder
          style={{
            height: 286,
            width: '100%',
            marginTop: spacing.spacing5,
            borderRadius: spacing.spacing5,
          }}
        />
      </View>
    )
  }

  return (
    <LayoutContainer scrollViewRef={scrollViewRef}>
      <View>
        <LeftContainer
          admissionsNotesTabData={admissionsNotesTabData}
          applicationDocuments={applicationDocuments}
          applicationLetterDocuments={applicationLetterDocuments}
          brandLogoUrl={brandLogoUrl}
          canShowMenu={canShowMenu}
          childRef={childRef}
          data={opportunities}
          handleMoreIcon={handleMoreIcon}
          handleScrollComment={handleScrollComment}
          informationList={informationList}
          isDocumentFetching={isDocumentFetching}
          moreIconDropDownDetails={moreIconDropDownDetails}
          setModalVisible={setModalVisible}
          setTabData={setTabData}
          tabData={tabData}
          handleUpdateComment={handleUpdateComment}
          isActiveComment={isActiveComment}
        />
        <View
          style={{
            paddingHorizontal: 12,
            marginVertical: 20,
          }}
        >
          <View style={styles.rightContainer}>
            <Text
              variant="display1"
              style={{
                paddingBottom: 20,
                color: colors.textPrimary,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {t('APPLICATION_DETAIL.ANY_QUESTION')}
            </Text>
            <View style={[styles.divider]} />
            <Text
              variant="display3"
              color={colors.neutral}
              style={styles.rightSideTextContainer}
            >
              Please contact your{' '}
              {!opportunities?.isDirectSales ? 'agent, ' : 'advisor, '}
              <b>
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Name
                    ? opportunities?.Agent_Contact__r?.Name + ', '
                    : ''
                  : opportunities?.OwnerName__c
                  ? opportunities?.OwnerName__c + ', '
                  : ''}
              </b>
              at
            </Text>
            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 5,
                paddingTop: 16,
              }}
            >
              <Icon name="Mail" width={15} height={18} color={colors.black} />
              <Text
                variant="display3"
                color={colors.neutral}
                numberOfLines={2}
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                  fontWeight: 700,
                  borderBottomWidth: 1.5,
                  color: colors.textPrimary,
                }}
              >
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Email
                  : opportunities?.OwnerEmail__c}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Icon name="Phone" width={15} height={18} color={colors.black} />
              <Text
                variant="display3"
                color={colors.neutral}
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                  fontWeight: 700,
                  borderBottomWidth: 1.5,
                  color: colors.textPrimary,
                }}
              >
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Phone
                  : opportunities?.Owner?.Phone}
              </Text>
            </View>

            {opportunities?.isDirectSales && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingTop: 10,
                }}
              >
                <Icon name="CalenderIcon" width={15} height={18} />
                <TouchableOpacity
                  onPress={() => {
                    const url =
                      opportunities?.Owner?.Appointment_Booking_Link__c
                    if (url) {
                      Linking.openURL(url).catch((err) =>
                        console.error('Failed to open URL:', err),
                      )
                    }
                  }}
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <Text
                    variant="display3"
                    color={colors.neutral}
                    style={{
                      marginLeft: 10,
                      fontSize: 15,
                      fontWeight: 700,
                      borderBottomWidth: 1.5,
                      color: colors.textPrimary,
                    }}
                  >
                    {t('APPLICATION_DETAIL.BOOK_A_MEETING')}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            <Text
              variant="display3"
              color={colors.neutral}
              style={styles.rightSideTextContainer}
            >
              Or get in touch with our support team by clicking on the chat
              window below.
            </Text>
          </View>
        </View>
      </View>

      <FileUploadModal
        documentType={documentType}
        dropDownPosition={{ left: dropdownLeft + 10, top: dropdownTop + 10 }}
        dropDownRef={dropDownRef}
        dropdownWidth={dropdownWidth - 20}
        handleClose={() => setModalVisible(false)}
        handleSave={(documentDetails) => handleFileUpload(documentDetails)}
        title={t('APPLICATION_DETAIL.ATTACH_DOCUMENT')}
        toggleDropdown={toggleDropdown}
        visible={modalVisible}
      />
    </LayoutContainer>
  )
}

const LeftContainer = ({
  admissionsNotesTabData,
  applicationDocuments,
  applicationLetterDocuments,
  brandLogoUrl,
  canShowMenu,
  childRef,
  data,
  handleMoreIcon,
  handleScrollComment,
  informationList,
  isDocumentFetching,
  moreIconDropDownDetails,
  setModalVisible,
  setTabData,
  tabData,
  handleUpdateComment,
  isActiveComment,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [showBrandName, setShowBrandName] = useState(true)

  const badgeStyle = {
    Offer: {
      color: '#065F46',
      borderColor: '#075F46',
      backgroundColor: '#F0FFE4',
      borderWidth: 1,
    },
    Admission: {
      color: '#D4691B',
      backgroundColor: '#FFF4E0',
      borderWidth: 1,
      borderColor: '#D4691B',
    },
    Enrolled: {
      color: '#065F46',
      borderColor: '#075F46',
      backgroundColor: '#F0FFE4',
      borderWidth: 1,
    },
    Accepted: {
      color: '#065F46',
      borderColor: '#075F46',
      backgroundColor: '#F0FFE4',
      borderWidth: 1,
    },
    Draft: {
      backgroundColor: '#ECF2FC',
      borderWidth: 1,
      color: '#044DBA',
      borderColor: colors.draftBlue,
    },
    Visa: {
      color: '#D4691B',
      backgroundColor: '#FFF4E0',
      borderWidth: 1,
      borderColor: '#D4691B',
    },
    Rejected: {
      backgroundColor: '#FFD7D7',
      borderWidth: 1,
      color: '#D72C2C',
      borderColor: '#B42638',
    },
  }

  const badgeColor = {
    Offer: {
      color: '#065F46',
    },
    Admission: {
      color: '#D4691B',
    },
    Enrolled: {
      color: '#065F46',
    },
    Accepted: {
      color: '#065F46',
    },
    Draft: {
      color: '#044DBA',
    },
    Visa: {
      color: '#D4691B',
    },
    Rejected: {
      color: '#D72C2C',
    },
  }

  return (
    <View style={[styles.leftSession]}>
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            backgroundColor: colors.white,
            paddingHorizontal: 20,
            paddingVertical: 10,
            borderRadius: 16,
          }}
        >
          <View style={{ flex: 1 }}>
            <View>
              {showBrandName && (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: 5,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        if (Platform.OS === 'web') {
                          window.history.go(-1)
                        } else {
                          // navigation.goBack()
                        }
                      }}
                      style={{
                        marginRight: 10,
                        marginBottom: spacing.spacing8,
                        marginTop: 10,
                      }}
                    >
                      <Icon name="ArrowNarrowLeft" height={24} width={24} />
                    </TouchableOpacity>

                    <Image
                      source={`${brandLogoUrl}/${data.BusinessUnitFilter__c?.replaceAll(
                        ' ',
                        '_',
                      )}.png`}
                      style={{ height: 75, width: 150 }}
                      alt={data?.brand}
                      resizeMode="contain"
                      onError={(error) => {
                        setShowBrandName(true)
                      }}
                    />
                    {/* <Text
                          variant="display5"
                          color={colors.neutral}
                          numberOfLines={3}
                        >
                          {data?.Institution}
                        </Text> */}
                  </View>
                  {/* <View>
                    <Icon name="DotsVertical" width={20} height={20} />
                  </View> */}
                </View>
              )}
            </View>

            <View style={{ width: '100%' }}>
              <Text
                variant="display1"
                style={{
                  paddingTop: 10,
                  paddingBottom: 24,
                  fontWeight: 700,
                  fontSize: 24,
                  paddingLeft: 5,
                }}
              >
                {data?.Name?.split('_')[0]}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 30,
                marginRight: 10,
                alignItems: 'center',
                marginBottom:
                  data?.ApplicationStatus.status === 'Draft' ? 0 : 10,
              }}
            >
              <Badge
                style={[
                  badgeStyle[data?.ApplicationStatus?.status.split(' ')[0]] ||
                    badgeStyle[data?.ApplicationStatus?.status.split('/')[0]],
                  { flexDirection: 'row', fontSize: 14 },
                ]}
              >
                <Text
                  style={[
                    { fontWeight: 700, fontSize: 14 },
                    badgeColor[data?.ApplicationStatus?.status.split(' ')[0]] ||
                      badgeColor[data?.ApplicationStatus?.status.split('/')[0]],
                  ]}
                >
                  {' '}
                  {data?.ApplicationStatus?.status}
                </Text>
              </Badge>
            </View>
            {data?.ApplicationStatus.status === 'Draft' ? (
              <View style={styles.notes}>
                <Icon
                  name="AlertCircle"
                  height={22}
                  width={22}
                  color={'rgba(212, 105, 27, 1)'}
                  style={{ paddingHorizontal: 10 }}
                />
                <Text
                  style={{
                    color: '#D4691B',
                    fontSize: 16,
                    flex: 1,
                  }}
                >
                  <b>{t('APPLICATION_DETAIL.NOTE')}</b>{' '}
                  {t('APPLICATION_DETAIL.STATUS_HEADER_NOTE')}
                </Text>
              </View>
            ) : null}

            {/* {applicationLetterDocuments?.hasOffer &&
                data?.ApplicationStatus?.status === 'Offer' ? (
                  <ApplicationDetailsOfferCard
                    handleButton={async () => {
                      applicationLetterDocuments?.uploadDocuments?.FilePath
                        ? await handleNavigation({
                            url: applicationLetterDocuments?.uploadDocuments
                              ?.FilePath,
                            fileName: applicationLetterDocuments?.Name,
                            translation: t,
                          })
                        : null
                    }}
                    data={applicationLetterDocuments?.uploadDocuments}
                  />
                ) : null} */}
          </View>
        </View>
        {/* <View>
          <View
            style={{
              paddingVertical: 15,
              paddingHorizontal: 20,
              borderRadius: 15,
              background: colors.primaryVariant,
              boxShadow:
                '0px 2px 30px 0px rgba(3, 30, 125, 0.05), 0px 2px 20px 0px rgba(3, 24, 98, 0.05)',
              marginTop: spacing.spacing6,
              width: '100%',
            }}
          >
            <Text
              variant="bodyBold2"
              color={colors.primary}
              style={{ paddingBottom: 12, fontSize: 18 }}
            >
              {t('APPLICATION_DETAIL.ANY_QUESTION')}
            </Text>
            <Text
              variant="bodyBold2"
              color={colors.neutral}
              style={{ paddingBottom: 10 }}
            >
              {t('APPLICATION_DETAIL.CONTACT_TEXT')}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 5,
              }}
            >
              <Icon name="Mail" />
              <Text
                variant="display3"
                color={colors.neutral}
                numberOfLines={2}
                style={{ marginLeft: 10 }}
              >
                {!data?.isDirectSales
                  ? data?.Agent_Contact__r?.Email
                  : data?.OwnerEmail__c || '-'}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Icon name="Phone" />
              <Text
                variant="display3"
                color={colors.neutral}
                style={{ marginLeft: 10 }}
              >
                {!data?.isDirectSales
                  ? data?.Agent_Contact__r?.Phone
                  : data?.Owner?.Phone || '-'}
              </Text>
            </View>
          </View>
        </View> */}
      </View>
      <DrawerContainer
        informationList={informationList}
        applicationDocuments={applicationDocuments}
        applicationLetterDocuments={applicationLetterDocuments?.documents || []}
        setTabData={setTabData}
        isDocumentFetching={isDocumentFetching}
        tabData={tabData}
        setModalVisible={setModalVisible}
        data={data}
        admissionsNotesTabData={admissionsNotesTabData}
        childRef={childRef}
        handleScrollComment={handleScrollComment}
        handleUpdateComment={handleUpdateComment}
        isActiveComment={isActiveComment}
      />
    </View>
  )
}

const DrawerContainer = ({
  setTabData,
  applicationDocuments,
  applicationLetterDocuments,
  informationList,
  setModalVisible,
  tabData,
  data,
  admissionsNotesTabData,
  childRef,
  handleUpdateComment,
  isDocumentFetching,
  isActiveComment,
}) => {
  const [activeTab, setActiveTab] = useState(
    tabData.findIndex((item) => item.isActive === true),
  )
  const { colors } = useTheme()
  const { t } = useTranslation()

  const comments = data?.commentsObj?.map(
    ({ details, date, whatId, id, isRead }) => ({
      title: <Text>{details?.split(/\s+/)?.slice(0, 48)?.join(' ')}</Text>,
      content: <Text>{details}</Text>,
      date,
      whatId,
      id,
      isRead,
    }),
  )

  const messageCounts = [0, 0, 0, 0]

  messageCounts[3] = countRecentComments(data?.commentsObj)

  return (
    <View>
      <DrawerSection
        activeTab={activeTab}
        tabData={tabData}
        messageCounts={messageCounts}
        admissionsNotesTabData={admissionsNotesTabData}
        conditionsCount={
          !!data?.Admissions_Condition__c &&
          !Object.keys(applicationLetterDocuments)?.length > 0
        }
        setActiveTab={(selectedTab) => {
          const tabDataCopy = [...tabData]
          tabDataCopy?.map((tabItem, index) => {
            if (index === selectedTab) {
              tabDataCopy[index] = { ...tabDataCopy[index], isActive: true }
            } else {
              tabDataCopy[index] = { ...tabDataCopy[index], isActive: false }
            }
          })
          setActiveTab(selectedTab)
          setTabData(tabDataCopy)
        }}
      />
      {activeTab === 0
        ? activeTab === 0 && (
            <View>
              {informationList?.length > 0 ? (
                informationList?.map((item, index) => (
                  <React.Fragment key={index}>
                    <View
                      style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingVertical: 20,
                        paddingHorizontal: 16,
                      }}
                    >
                      <View style={{ flexBasis: 'auto', flexShrink: 1 }}>
                        <Text
                          variant="display5"
                          style={{ color: '#545B61', fontSize: 16 }}
                        >
                          {item?.displayName ? `${item?.displayName}:` : ''}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexGrow: 1,
                          flexShrink: 1,
                          flexBasis: 'auto',
                          alignItems: 'flex-end',
                        }}
                      >
                        {item?.field === 'MeetLink' ? (
                          <TouchableOpacity
                            onPress={() => {
                              const url = item?.fieldName
                              if (url && url !== '-') {
                                Linking.openURL(url).catch((err) =>
                                  console.error('Failed to open URL:', err),
                                )
                              }
                            }}
                          >
                            <Text
                              style={{
                                color: '#021547',
                                fontSize: 16,
                                fontWeight: '700',
                                textAlign: 'right',
                                textDecorationLine: 'underline', // Optional: underline the text
                              }}
                              variant="display5"
                            >
                              {t('APPLICATION_DETAIL.BOOK_A_MEETING')}
                            </Text>
                          </TouchableOpacity>
                        ) : (
                          <Text
                            style={{
                              color: '#021547',
                              fontSize: 16,
                              fontWeight: 700,
                              textAlign: 'right',
                            }}
                            variant="display5"
                          >
                            {item?.fieldName !== '-' ? item?.fieldName : 'NIL'}
                          </Text>
                        )}
                      </View>
                    </View>

                    {index !== informationList.length - 1 && (
                      <View
                        style={[styles.divider, { marginHorizontal: 20 }]}
                      />
                    )}
                  </React.Fragment>
                ))
              ) : (
                <EmptyContainer hasFixedHight />
              )}
            </View>
          )
        : null}
      {/* {countRecentComments(data?.commentsObj) > 0 && (
            <TouchableOpacity
              style={{
                width: '100%',
                alignItems: 'center',
                backgroundColor: '#0b5cd7',
                borderRadius: 4,
                marginBottom: 20,
              }}
              onPress={handleScrollComment}
            >
              <View
                style={{
                  flex: 1,
                  color: 'white',
                  alignItems: 'center',
                  paddingVertical: 6,
                }}
              >
                <Text variant="display6" color="#ffff">
                  {`You have: ${countRecentComments(
                    data?.commentsObj,
                  )} new comment${
                    countRecentComments(data?.commentsObj) > 1 ? 's' : ''
                  } `}
                </Text>
                <Icon name="ArrowDown" color="#ffff" width={18} height={18} />
              </View>
            </TouchableOpacity>
          )} */}
      <View>
        {activeTab === 1 ? (
          <View
            style={{
              flexDirection: 'column',
              paddingBottom: 10,
              paddingTop: 24,
            }}
          >
            {data?.ApplicationStatus?.status == 'Draft' ? null : (
              <LinearGradient
                colors={[colors.draftBlue, colors.textPrimary]}
                start={{ x: 0.2, y: 0.8 }}
                end={{ x: 1, y: 0 }}
                style={styles.attachDocument}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: 'white',
                    padding: 3,
                  }}
                >
                  {t('APPLICATION_DETAIL.ATTACH_DOCUMENT_TEXT')}
                </Text>
                <Button
                  label={t('BUTTON.ATTACH_DOCUMENT')}
                  buttonStyle={{
                    paddingHorizontal: 25,
                    borderRadius: 8,
                    marginTop: 20,
                    marginRight: 98,
                    paddingVertical: 11,
                  }}
                  labelStyle={{
                    color: colors.draftBlue,
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                  labelVariant="display7"
                  buttonColor={'white'}
                  onPress={() => setModalVisible(true)}
                  disable={data?.ApplicationStatus?.status === 'Draft'}
                />
              </LinearGradient>
            )}

            {applicationDocuments === undefined ? (
              isDocumentFetching ? (
                [...Array(3)].map(() => (
                  <>
                    <View style={[styles.divider, { marginTop: 10 }]} />
                    <ShimmerPlaceholder
                      style={{
                        height: 250,
                        width: '100%',
                        marginTop: spacing.spacing5,
                        borderRadius: spacing.spacing5,
                      }}
                    />
                  </>
                ))
              ) : (
                <EmptyContainer hasFixedHight />
              )
            ) : (
              Object?.entries(applicationDocuments || {})?.map(
                ([keys, value], index) => (
                  <View style={{ marginTop: 30, paddingHorizontal: 8 }}>
                    <DocumentAccordion
                      label={keys}
                      data={value}
                      index={index}
                      activeTab={activeTab}
                      showDate={false}
                      style={{
                        flexWrap: 'wrap',
                        width: '100%',
                        marginBottom: 2,
                        color: '#162447',
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    />
                  </View>
                ),
              )
            )}
          </View>
        ) : null}
      </View>
      <View>
        {activeTab === 2 ? (
          <View
            style={{
              paddingVertical: 30,
            }}
          >
            {isDocumentFetching ? (
              [...Array(1)].map(() => (
                <>
                  <ShimmerPlaceholder
                    style={{
                      height: 250,
                      width: '100%',
                      marginTop: spacing.spacing5,
                      borderRadius: spacing.spacing5,
                    }}
                  />
                </>
              ))
            ) : Object.keys(applicationLetterDocuments)?.length > 0 ||
              data?.Admissions_Condition__c ? (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                {data?.Admissions_Condition__c ? (
                  <AdmissionComponent data={data} />
                ) : null}

                {Object.keys(applicationLetterDocuments)?.length > 0 ? (
                  Object?.entries(applicationLetterDocuments || {})?.map(
                    ([keys, value], index) => (
                      <DocumentAccordion
                        label={keys}
                        data={value}
                        index={index}
                        showDate={true}
                        style={{
                          flexWrap: 'wrap',
                          width: '100%',
                          marginBottom: 2,
                          color: '#162447',
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      />
                    ),
                  )
                ) : (
                  <>
                    <Text
                      variant="display2"
                      color={colors.neutral}
                      style={{ marginBottom: spacing.spacing5 }}
                    >
                      {t('APPLICATION_DETAIL.OFFER_LETTER')}
                    </Text>
                    <EmptyContainer hasFixedHight />
                  </>
                )}
              </View>
            ) : (
              <EmptyContainer hasFixedHight />
            )}
          </View>
        ) : null}
      </View>
      <View>
        {activeTab === 3 ? (
          <View
            style={{
              borderRadius: 16,
              paddingBottom: 30,
            }}
          >
            {data?.commentsObj?.length > 0 ? (
              <Accordion
                data={comments}
                noOfRecentComments={countRecentComments(data?.commentsObj)}
                noOfReadComments={countReadComments(data?.commentsObj)}
                childRef={childRef}
                handleUpdateComment={handleUpdateComment}
                isActiveComment={isActiveComment}
              />
            ) : (
              <View style={{ marginTop: 30 }}>
                <EmptyContainer hasFixedHight />
              </View>
            )}
          </View>
        ) : null}
      </View>
    </View>
  )
}

const DrawerSection = ({ tabData, activeTab, setActiveTab, messageCounts }) => {
  const { colors } = useTheme()
  const handleItemSelect = (item, index) => {
    setActiveTab(index)
  }
  return (
    <View style={{ flex: 1, width: '100%', paddingTop: 20 }}>
      <Drawer
        items={tabData}
        onItemSelect={(item, index) => handleItemSelect(item, index)}
        selectedItem={tabData[activeTab]}
        labelColor={colors.draftBlue}
        iconColor={colors.draftBlue}
        hasNoBorder={true}
        labelStyle={{
          fontWeight: 600,
          fontSize: 15,
          marginLeft: 13,
          color: colors.draftBlue,
        }}
        hasNoBorderStyle={{
          backgroundColor: 'white',
          borderRadius: 16,
          paddingHorizontal: 24,
        }}
        iconSize={24}
        isViewApplication={true}
        messageCounts={messageCounts}
      />{' '}
    </View>
  )
}

const DocumentAccordion = ({
  label,
  data,
  index,
  activeTab,
  style,
  showDate,
}) => {
  const { colors } = useTheme()
  const [isExpanded, setIsExpanded] = useState(
    activeTab === 1 ? (index === 0 ? true : false) : true,
  )
  const { t } = useTranslation()

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <View style={{ width: '100%' }}>
      <View style={styles.divider} />
      {activeTab === 1 && data.length > 0 ? (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            backgroundColor: colors.accent,
            borderRadius: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 30,
          }}
          onPress={toggleAccordion}
        >
          <Text
            variant="display3"
            color={colors.textPrimary}
            style={{ marginRight: 10, fontWeight: '700' }}
          >
            {label}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 20,
              alignItems: 'center',
            }}
          >
            <Text style={{ color: colors.textPrimary }}>
              {data.length !== 1
                ? `(${data.length} ${t('APPLICATION_DETAIL.DOCS')})`
                : `(${data.length} ${t('APPLICATION_DETAIL.DOC')})`}
            </Text>

            <Icon
              name={isExpanded ? 'ArrowUp' : 'ArrowDown'}
              color={'black'}
              width={24}
              height={24}
            />
          </View>
        </TouchableOpacity>
      ) : (
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: colors.accent,
            borderRadius: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 30,
          }}
        >
          <Text
            color={colors.textPrimary}
            style={{ marginRight: 10, fontWeight: 700, fontSize: 16 }}
          >
            {label.replaceAll('-', ' ')}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 20,
              alignItems: 'center',
            }}
          >
            <Text style={{ color: colors.textPrimary }}>
              {data.length !== 1
                ? `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENTS')})`
                : `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENT')})`}
            </Text>
          </View>
        </View>
      )}
      {isExpanded && (
        <View
          style={{
            marginVertical: 20,
            flexDirection: 'row',
            gap: 20,
            flexWrap: 'wrap',
          }}
        >
          {sort(data, 'CreatedDate')?.map((item) => {
            const documentData = item?.CreatedDate?.split('T')[0]
            const finalDocumentDate = documentData
              ?.split('-')
              .reverse()
              .join('/')
            return (
              <DocumentItem
                index={index}
                item={item}
                finalDocumentDate={finalDocumentDate}
                onPress={async () => {
                  const resp = await signedurl(item)
                  if (resp.FilePath) {
                    await handleNavigation({
                      url: resp.FilePath,
                      fileName: item?.Name,
                      translation: t,
                    })
                  }
                }}
                style={style}
                showDate={showDate}
              />
            )
          })}
        </View>
      )}
    </View>
  )
}

const AdmissionComponent = ({ data }) => {
  const { colors } = useTheme()
  const [showFullCondition, setShowFullCondition] = useState(false)

  const toggleCondition = () => {
    setShowFullCondition(!showFullCondition)
  }

  const renderConditionText = () => {
    const condition = data.Admissions_Condition__c
    if (showFullCondition) {
      return condition
    } else {
      return condition.length > 41 ? condition.slice(0, 200) + '...' : condition
    }
  }

  return (
    <ScrollView
      style={{ paddingBottom: 20 }}
      showsVerticalScrollIndicator={false}
    >
      <Text
        color={colors.textPrimary}
        style={{ fontWeight: 700, fontSize: 16 }}
      >
        Condition
      </Text>
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: showFullCondition ? 'flex-start' : 'center',
            paddingLeft: 20,
            paddingTop: 10,
            columnGap: 10,
          }}
        >
          {' '}
          <Text style={{ color: colors.textPrimary, fontWeight: 700 }}>
            &#8226;
          </Text>
          <Text
            numberOfLines={showFullCondition ? undefined : 1}
            style={{ fontSize: 14, lineHeight: 20 }}
          >
            {renderConditionText()}
          </Text>
        </View>

        <View>
          {!showFullCondition && renderConditionText().endsWith('...') && (
            <TouchableOpacity
              onPress={toggleCondition}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingHorizontal: 38,
                paddingVertical: 3,
              }}
            >
              <Text
                style={{
                  color: colors.draftBlue,
                  paddingRight: 4,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                See more
              </Text>
            </TouchableOpacity>
          )}
          {showFullCondition && (
            <TouchableOpacity
              onPress={toggleCondition}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 38,
                paddingVertical: 6,
              }}
            >
              <Text
                style={{
                  color: colors.draftBlue,
                  paddingRight: 4,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                See less
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    paddingVertical: 20,
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    paddingHorizontal: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  leftSession: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 12,
  },
  activeTab: {
    flexDirection: 'column',
    borderBottomWidth: 2,
  },
  unActiveTab: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  notes: {
    marginBottom: 10,
    marginTop: 23,
    backgroundColor: '#FFF4E0',
    borderColor: 'rgba(255, 233, 192, 1)',
    borderRadius: 5,
    borderWidth: 0.2,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  divider: {
    borderWidth: 0.2,
    borderColor: '#000000',
    borderStyle: 'solid',
    opacity: 0.2,
  },
  attachDocument: {
    flexDirection: 'column',
    paddingLeft: 24.5,
    paddingVertical: 32,
    columnGap: 3,
    borderRadius: 16,
  },
  rightContainer: {
    paddingHorizontal: 24,
    paddingVertical: 32,
    borderRadius: 13,
    background: 'transparent',
    borderWidth: 0.2,
    borderColor: '#000000',
  },

  rightSideTextContainer: {
    marginTop: 20,
    fontSize: 15,
    lineHeight: 21,
  },
})

export default MobileView
