import React, { useRef, useEffect } from 'react'
import { View, StyleSheet, Animated, useWindowDimensions } from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import { ShimmerPlaceholder } from '../Skeleton/Skeleton'
import { isWeb } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'

const NotificationLoader = () => {
  const { colors } = useTheme()
  const fadeAnim = useRef(new Animated.Value(0.3)).current

  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0.3,
          duration: 1000,
          useNativeDriver: true,
        }),
      ]),
    ).start()
  }, [fadeAnim])

  return (
    <LinearGradient
      style={{
        justifyContent: isDesktop ? 'center' : 'flex-start',
        paddingHorizontal: isDesktop ? 25 : 0,
        minHeight: isDesktop ? '90vh' : '100vh',
        paddingBottom: 80,
        flexDirection: 'column',
        rowGap: !isDesktop ? 20 : 0,
        paddingVertical: isDesktop ? 0 : 10,
      }}
      start={{ x: 0, y: 0 }}
      end={{ x: -1, y: -1 }}
      colors={
        isDesktop ? [colors.gradientStart, colors.gradientEnd] : [colors.white]
      }
      locations={[0.2, 1.3]}
    >
      {isDesktop ? (
        <View style={[styles.mainView(colors)]}>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 30,
              alignItems: 'center',
              marginHorizontal: 56,
              marginBottom: 40,
              marginTop: 30,
            }}
          >
            <ShimmerPlaceholder
              style={[styles.headerIcon, { opacity: fadeAnim }]}
            />
            <ShimmerPlaceholder style={styles.headerText} />
          </View>

          {[...Array(5)].map((_, index) => (
            <View key={index} style={styles.notificationItem}>
              <ShimmerPlaceholder
                style={[styles.iconPlaceholder, { opacity: fadeAnim }]}
              />
              <View style={styles.textContainer}>
                <ShimmerPlaceholder style={styles.textPlaceholder} />
                <ShimmerPlaceholder style={styles.timestampPlaceholder} />
              </View>
            </View>
          ))}
        </View>
      ) : (
        <>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 30,
              paddingHorizontal: 30,
              alignItems: 'center',
              marginVertical: 10,
            }}
          >
            <ShimmerPlaceholder
              style={[styles.headerIcon, { opacity: fadeAnim }]}
            />
            <ShimmerPlaceholder style={styles.headerText} />
          </View>
          <View
            style={{
              backgroundColor: '#F4F5FB',
              rowGap: 20,
              paddingHorizontal: 20,
              paddingVertical: 10,
              minHeight: '100vh',
            }}
          >
            {[...Array(8)].map((_, index) => (
              <>
                <View
                  key={index}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: 5,
                  }}
                >
                  <ShimmerPlaceholder
                    style={[styles.iconPlaceholder, { opacity: fadeAnim }]}
                  />
                  <View style={styles.textContainerMobile}>
                    <ShimmerPlaceholder
                      style={styles.timestampPlaceholderMobile}
                    />
                    <ShimmerPlaceholder style={styles.textPlaceholderMobile} />
                  </View>
                </View>
                {index !== 7 ? <View style={styles.divider} /> : ''}
              </>
            ))}
          </View>
        </>
      )}
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  mainView: (colors) => ({
    width: '100%',
    maxWidth: 903,
    height: '100%',
    maxHeight: 652,
    marginHorizontal: 'auto',
    paddingVertical: 30,
    backgroundColor: colors.white,
    borderRadius: 16,
    marginTop: 25,
  }),
  notificationItem: {
    flexDirection: 'row',
    flex: 1,
    marginHorizontal: 56,
    borderRadius: 8,
    borderWidth: 1,
    marginVertical: 7,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    padding: 15,
  },
  iconPlaceholder: {
    backgroundColor: '#E0E0E0',
    borderRadius: 32,
    marginRight: '1rem',
    height: 54,
    width: 54,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  textPlaceholder: {
    backgroundColor: '#F3F5F7',
    height: 20,
    borderRadius: 4,
    marginBottom: 8,
    width: '70%',
  },
  timestampPlaceholder: {
    backgroundColor: '#F3F5F7',
    height: 14,
    borderRadius: 4,
    width: '20%',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  headerIcon: {
    width: 40,
    height: 40,
    borderRadius: 40 / 2,
    backgroundColor: '#E0E0E0',
  },
  headerText: {
    backgroundColor: '#F3F5F7',
    height: 20,
    borderRadius: 4,
    width: '30%',
  },
  textContainerMobile: {
    flex: 1,
    justifyContent: 'center',
    rowGap: 10,
  },
  textPlaceholderMobile: {
    backgroundColor: '#F3F5F7',
    height: 16,
    borderRadius: 4,
    marginBottom: 8,
    width: '80%',
  },
  timestampPlaceholderMobile: {
    backgroundColor: '#F3F5F7',
    height: 14,
    borderRadius: 4,
    width: '40%',
  },
  divider: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
  },
})

export default NotificationLoader
