import React, {
  useCallback,
  Suspense,
  useRef,
  useState,
  useEffect,
} from 'react'
import { ScreenLayout, SecureStore } from '@libs/utils'
import { Text } from 'react-native'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useIsFocused } from '@react-navigation/native'
import { useAtom } from 'jotai'
import DesktopView from './DesktopView'
import { compareProgramsByIds } from '../../api'
import { selectedProgramIds, userProfile } from '../../utils/atom'
import { isValidBasket } from '../../utils/function'
import { useUpdateBasket } from '../../hooks/useUpdateBasket'
import { useUpdateCompareProgrammesId } from '../../hooks/useUpdateCompareprogrammesId'
import { useTranslation } from 'react-i18next'

const CompareProgram = () => {
  const isFocused = useIsFocused()
  const [userprofile] = useAtom(userProfile)
  const programCardRef = useRef()
  const { t } = useTranslation()
  const [programDetails, setProgramDetails] = useState()
  const [isClearingProgram, setIsClearingProgram] = useState(false)
  const [alertDetails, setAlertDetails] = useState({
    isVisible: false,
    type: '',
  })
  const [isBasketLoading, setIsBasketLoading] = useState(false)
  const [programIds, setSelectedProgramId] = useAtom(selectedProgramIds)
  const [contentHeight, setContentHeight] = useState([])
  const queryClient = useQueryClient()
  const basketItems = queryClient.getQueryData([
    `getBasketItems-${userprofile?.email}`,
  ])
  const [brandLogoUrl, setBrandLogoUrl] = useState('')

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, DesktopView, DesktopView),
    [],
  )

  const { mutation: updateProgramId } = useUpdateCompareProgrammesId()

  const fetchProgramDetails = async () => {
    const data = await compareProgramsByIds({ ids: programIds })
    setProgramDetails(data)
    return data?.programs
  }

  const handleRemove = async (removeItem) => {
    if (removeItem === 'clear') {
      setIsClearingProgram(true)
      await updateProgramId.mutateAsync({
        programId: removeItem?.Id,
        type: 'clear',
      })
      setProgramDetails([])
      setSelectedProgramId([])
      setIsClearingProgram(false)
    } else {
      const mappedId = `${removeItem?.brand}_${removeItem?.Id}`
      const response = await updateProgramId.mutateAsync({
        programId: mappedId,
        type: 'delete',
      })
      const programDetailsCopy = [...programDetails]
      const newProgramDetail = programDetailsCopy.filter(
        (programmesData) => programmesData?.Id !== removeItem?.Id,
      )
      setSelectedProgramId(response)
      setProgramDetails(newProgramDetail)
    }
  }

  const { isFetching } = useQuery({
    queryKey: ['getProgramDetails'],
    queryFn: fetchProgramDetails,
    enabled: isFocused && programIds?.length > 0,
    initialData: [],
  })

  const { mutation } = useUpdateBasket()

  // updateCart
  const updateCartItems = async ({ programDetails }) => {
    const validBasket = isValidBasket({ programDetails, basketItems })

    setIsBasketLoading(true)
    if (validBasket?.canAdd) {
      await mutation.mutateAsync({
        email: userprofile?.email,
        bucketId: userprofile?.bucketId,
        programDetails,
      })
      setIsBasketLoading(false)
    } else {
      setAlertDetails({ isVisible: true, type: validBasket?.type })
      setIsBasketLoading(false)
    }
  }

  const categoryData = {
    Institution: {
      title: t('COMPARE_SCREEN.INSTITUTION'),
      fieldName: 'institution',
    },
    Level: {
      title: t('COMPARE_SCREEN.LEVEL'),
      fieldName: 'level',
    },
    Delivery_Mode: {
      title: t('COMPARE_SCREEN.DELIVERY_MODE'),
      fieldName: 'delivery',
    },
    Program_Duration: {
      title: t('COMPARE_SCREEN.PROGRAM_DURATION'),
      fieldName: 'maxDuration',
      added: 'months',
    },
    Course_Price: {
      title: t('COMPARE_SCREEN.COURSE_PRICE'),
      fieldName: 'maxFees',
    },
    FIELD_OF_STUDY: {
      title: t('COMPARE_SCREEN.FIELD_OF_STUDY'),
      fieldName: 'subject',
    },
    Location: {
      title: t('COMPARE_SCREEN.LOCATION'),
      fieldName: 'country',
    },
  }

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      await queryClient.invalidateQueries([
        `getBasketItems-${userprofile?.email}`,
      ])
    })()
  }, [basketItems?.length, isFocused])

  const viewProps = {
    alertDetails,
    brandLogoUrl,
    categoryData,
    contentHeight,
    isBasketLoading,
    isClearingProgram,
    isLoading: isFetching,
    programCardRef,
    programDetails,
    handleRemove,
    setAlertDetails,
    setContentHeight,
    updateCartItems,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default CompareProgram
