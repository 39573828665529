import React from 'react'
import { Loader, WebView } from '@libs/components'
import { View } from 'react-native'

const DesktopView = ({ url, isFetching }) => {
  if (!url || isFetching) {
    return <Loader />
  }
  return (
    <View style={{ height: '100%' }}>
      <WebView
        url={url}
        style={{ flex: 1, border: 'none' }}
        id="student-detail"
      />
    </View>
  )
}

export default DesktopView
