import React, {
  useCallback,
  Suspense,
  useRef,
  useState,
  useEffect,
} from 'react'
import {
  CustomAuth,
  ScreenLayout,
  SecureStore,
  countryCodes,
} from '@libs/utils'
import { Text } from 'react-native'
import { useAtom } from 'jotai'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import DesktopView from './DesktopView'
import { userProfile, isChatbotVisible } from '../../utils/atom'
import { useUpdateProfile } from '../../hooks/useUpdateProfile'
import { ProfilePlaceHolder } from '@apphero/assets'
import { getUserDetails } from '../../api'
import { useQuery } from '@tanstack/react-query'
import { getCountryList } from '../../api'
import { useTranslation } from 'react-i18next'
import MobileView from './MobileView'
import { userChatBotDestroy } from '../../utils/addScript'

const Profile = () => {
  const [userprofile, setUserDetails] = useAtom(userProfile)
  const [profileDetails, setProfileDetails] = useState({
    fullName: '',
    firstName: '',
    lastName: '',
    mobile: {
      dialCode: '',
      mobileNumber: '',
    },
    country: '',
    countryCode: '',
  })
  const [canShowChatbot, setCanShowChatbot] = useAtom(isChatbotVisible)

  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownRight, setDropdownRight] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [isLastNameAvailable, setIsLastNameAvailable] = useState()
  const [ctaEnable, setCTAEnable] = useState(false)
  const [countryDropdownData, setCountryDropDownData] = useState({
    countryData: [],
    selectedCountry: {},
  })
  const [isLoading, setIsLoading] = useState()
  const [isProfileUpdating, setIsProfileUpdating] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [successPopup, setSuccessPopup] = useState({
    visible: false,
    message: '',
  })
  const { t } = useTranslation()
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const DropdownButton = useRef()

  const toggleDropdown = (visible, ref) => {
    if (visible) {
      return
    }
    if (!visible) {
      ref?.current?.measure((_fx, _fy, _w, h, _px, py) => {
        setDropdownTop(py + 50)
        setDropdownRight(_px - 10)
        setDropDownWidth(_w)
      })
    }
  }

  const handleLogout = async () => {
    if (canShowChatbot) {
      await userChatBotDestroy()
    }
    setIsLoading(true)
    await CustomAuth.logOut()
    await SecureStore.deleteItemAsync('userProfile')
    await SecureStore.deleteItemAsync('config')
    await SecureStore.deleteItemAsync('selectedProgramId')
    await SecureStore.deleteItemAsync('userLanguage')
    await SecureStore.deleteItemAsync('filters')

    navigation.navigate('login')
    setIsLoading(false)
  }

  const handleChange = (field, selectedValue) => {
    if (selectedValue?.error) {
      return setErrorMessage(
        'Please upload a file in one of the allowed file formats(JPG/PNG/JPEG)',
      )
    }
    if (field === 'profilePictureLocation') {
      setErrorMessage('')
      setUserDetails((prev) => ({
        ...prev,
        selectedImage: selectedValue.selectedFile,
      }))
      setCTAEnable(true)
      return setProfileDetails((previousData) => ({
        ...previousData,
        base64Picture: selectedValue.file,
        fileName: selectedValue.fileName,
        selectedImage: selectedValue.selectedFile,
      }))
    }
    if (field === 'mobile') {
      const mobile = {
        dialCode: profileDetails?.mobile?.dialCode || `+1`,
        mobileNumber: selectedValue,
      }
      setCTAEnable(true)
      return setProfileDetails((previousData) => ({
        ...previousData,
        [field]: mobile,
      }))
    }
    if (field === 'country') {
      setCTAEnable(true)

      return setProfileDetails((previousData) => ({
        ...previousData,
        [field]: selectedValue.Label,
        countryCode: selectedValue.Value,
      }))
    } else {
      setCTAEnable(true)

      return setProfileDetails((previousData) => ({
        ...previousData,
        [field]: selectedValue,
      }))
    }
  }

  const fetchUserDetails = async () => {
    let response = await getUserDetails({ email: userprofile.email })
    return response || {}
  }

  // fetch userProfile
  const { data: userDetails } = useQuery({
    queryKey: ['getUserProfile'],
    queryFn: fetchUserDetails,
    initialData: [],
    enabled: !!userprofile.email,
  })

  const { mutation } = useUpdateProfile()

  const handleSave = async () => {
    let passwordChangeResponse = { isSuccess: false }
    setIsProfileUpdating(true)
    await mutation.mutateAsync({
      updatedProfile: profileDetails,
      isLastNameAvailable: isLastNameAvailable,
    })

    if (passwordChangeResponse?.isSuccess) {
      setSuccessPopup({
        visible: true,
        message: t('ALERT.PASSWORD_CHANGE_SUCCESS'),
      })
    }
    setCTAEnable(false)
    setIsProfileUpdating(false)
  }
  useEffect(() => {
    if (!isFocused) return

    if (Object.keys(userDetails).length > 0) {
      const newUserDetails = {
        email: userprofile?.email,
        userID: userprofile.userID,
        accessToken: userprofile?.accessToken,
        bucketId: userprofile?.bucketId,
        ...userDetails,
      }
      setUserDetails(newUserDetails)
    }
  }, [userDetails, isFocused])

  useEffect(() => {
    if (!isFocused) return
    if (Object.keys(userprofile).length > 0) {
      let selectedCountry = {}
      let newUserDetails = { ...userprofile }

      const countryData = Object.entries(countryCodes)?.map(([key, item]) => {
        if (item?.dial_code === userprofile?.phoneNumber?.split('-')[0]) {
          selectedCountry = {
            countryNames: item.name,
            dial_code: item?.dial_code,
          }
        }
        return { countryNames: item.name, dial_code: item?.dial_code }
      })
      const firstName =
        userprofile?.firstName?.charAt(0).toUpperCase() +
        userprofile?.firstName?.slice(1)
      const lastName = !!userprofile?.lastName
        ? userprofile?.lastName?.charAt(0).toUpperCase() +
          userprofile?.lastName?.slice(1)
        : ''
      newUserDetails = {
        ...newUserDetails,
        fullName: `${firstName} ${lastName}`,
        mobile: {
          dialCode: userprofile?.phoneNumber?.split('-')[0],
          mobileNumber: userprofile?.phoneNumber?.split('-')[1],
        },
      }
      delete newUserDetails['phoneNumber']
      setCountryDropDownData({ countryData, selectedCountry })
      setProfileDetails(newUserDetails)
    }
  }, [isFocused, userprofile])

  const getImageSource = () => {
    if (profileDetails?.selectedImage) {
      return profileDetails.selectedImage
    } else if (profileDetails?.profilePictureLocation) {
      return { uri: profileDetails?.profilePictureLocation }
    } else {
      return ProfilePlaceHolder
    }
  }

  const { data: countryList } = useQuery({
    queryKey: ['getCountryList'],
    queryFn: getCountryList,
    enabled: isFocused,
  })

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const viewProps = {
    countryDropdownData,
    ctaEnable,
    countryList,
    DropdownButton,
    dropdownRight,
    dropdownTop,
    dropdownWidth,
    errorMessage,
    isLoading,
    isProfileUpdating,
    isLastNameAvailable,
    profileDetails,
    successPopup,
    getImageSource,
    handleChange,
    handleLogout,
    handleSave,
    setProfileDetails,
    setIsLastNameAvailable,
    setUserDetails,
    setSuccessPopup,
    setCTAEnable,
    toggleDropdown,
    ProfilePlaceHolder,
    navigation,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Profile
