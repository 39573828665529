import { Image, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Badge, Button, Loader, Text } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { SecureStore } from '@libs/utils'
import { useTheme } from '@libs/theme'

const ApplicationBasketCard = ({
  item,
  index,
  handleDeleteCardItem,
  deletingCartItemId,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const [brandLogoUrl, setBrandLogoUrl] = useState('')

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const handleDeleteCart = async () => {
    await handleDeleteCardItem({ externalId: item?.SK })
  }

  return (
    <TouchableOpacity
      key={index}
      style={[
        styles.container,
        item.progress === 100 ? { backgroundColor: colors.fieldBorder } : {},
      ]}
      onPress={() => {
        item?.routeName
          ? navigation.navigate('student-details', {
              routeName: item?.routeName,
              ...item.queryParams,
            })
          : {}
      }}
    >
      <View
        style={[
          styles.header,
          {
            backgroundColor:
              item.progress === 100 ? '' : colors.primaryBackground,
          },
        ]}
      >
        <Image
          source={
            item.imageURl ||
            `${brandLogoUrl}/${item.brand?.replaceAll(' ', '_')}.png`
          }
          style={{ height: 80, width: 80 }}
          resizeMode="contain"
        />
        {item.progress === 100 ? (
          <Badge theme="DarkGreen">
            {
              <View style={{ flexDirection: 'row' }}>
                <Icon name="Check" color={colors.white} />
                <Text
                  color={colors.white}
                  variant="body2"
                  style={{ marginTop: 2 }}
                >
                  {t('LABEL.COMPLETED')}
                </Text>
              </View>
            }
          </Badge>
        ) : (
          <Badge>{`${item.progress || 0}% ${t('LABEL.COMPLETED')}`}</Badge>
        )}
      </View>
      <View style={styles.subContainer}>
        <Text
          variant="heading4"
          style={[{ maxWidth: '80%' }, styles.textStyle]}
        >
          {item.title || item.programmeName}
        </Text>
        {item.description1 ? (
          <Text variant="display3" style={styles.textStyle}>
            {item.description1}
          </Text>
        ) : null}
        {item.description2 ? (
          <Text variant="display3" style={styles.textStyle}>
            {item.description2}
          </Text>
        ) : null}
      </View>
      {index > 0 && item.progress < 100 ? (
        <View style={styles.buttonContainer}>
          {deletingCartItemId === item?.SK ? (
            <Loader size={20} />
          ) : (
            <Button
              label={t('BUTTON.REMOVE_APPLICATION')}
              leftIcon={<Icon name="Delete" color={colors.onAlert} />}
              buttonColor="transparent"
              labelColors={colors.onAlert}
              buttonStyle={{
                paddingVertical: 10,
                paddingHorizontal: 30,
                marginBottom: 30,
              }}
              onPress={handleDeleteCart}
            />
          )}
        </View>
      ) : null}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'column-reverse',
    flex: 1,
    alignItems: 'center',
  },
  container: {
    flexDirection: 'column',
    width: '25%',
    marginHorizontal: 20,
    borderRadius: 10,
    overflow: 'hidden',
    shadowColor: 'rgba(3, 30, 125, 0.05)',
    shadowOffset: { width: 0, height: 2 },
    elevation: 5,
    shadowOpacity: 1,
    shadowRadius: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  subContainer: {
    padding: 20,
  },
  textStyle: {
    marginBottom: 20,
  },
})

export default ApplicationBasketCard
