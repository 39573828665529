export default {
  ACCORDION: {
    PROGRAMMES: 'Programmes',
    MARK_ALL_READ: 'Mark all as read',
  },
  ALERT: {
    INVALID_EMAIL: `Please try another email address; we couldn't find any completed applications linked to this one.`,
    PASSWORD_CHANGE_SUCCESS: 'Password has been changed successfully',
    MULTI_SAME_PROGRAM:
      'You cannot add more than one program to the same brand.',
    PROGRAM_ALREADY_EXIST: 'This program already exists in your basket.',
    PROGRAM_ID_ALREADY_EXIST:
      'The program has already been added for comparison.',
  },
  APPLICATION: {
    CONGRATULATION: 'Congratulations!',
    DESCRIPTION: 'Description',
    HEADING: 'Programme Application',
    LEVEL: 'Level',
    OFFER_DESCRIPTION: 'You have got an offer to the application',
    PROGRAMME_INFORMATION: 'Programme Information',
    SUBJECT: 'Subject',
  },
  APPLICATION_BASKET: {
    HEADING1: 'Thank You',
    HEADING2: 'Applications In Progress',
    SUB_HEADING1: 'Student Detail has been successfully submitted!',
    SUB_HEADING2: 'Your Application has been successfully submitted!',
    STUDENT_DETAIL_CARD_DESCRIPTION1: `Please provide the admissions team with additional essential personal information, which will aid in a seamless application process.`,
    STUDENT_DETAIL_CARD_DESCRIPTION2: `This information will be applied and pe-filled automatically in future applications.`,
    TITLE: 'Student Detail',
    APPLICATION_CARD_DESCRIPTION: `Please provide a specific information related to the brand that is crucial for this program.`,
    DESCRIPTION: `You can track the progression of your below application(s) in the My Application tab.During Admissions review, we may contact you for further updates to and about your application. You can check your account here or the email address associated with your application for alerts related to your application's progress.`,
  },
  APPLICATION_DETAIL: {
    ADMISSION_NOTES: ' Admissions notes',
    ADMISSIONS_LETTER: 'Admissions Letters',
    AGENT_ACCOUNT: 'Agent Account',
    AGENT_CONTACT: 'Agent Contact',
    AGENT_EMAIL: 'Agent Email',
    AGENT_PHONE: 'Agent Phone',
    ANY_QUESTION: 'Need Support?',
    APPLICATION_NO: 'Application No:',
    APPLICATION_OWNER: 'Application Owner',
    ATTACH_DOCUMENT: 'Click here to upload',
    BUSINESS_DEVELOPER: 'Business Developer',
    COMMENTS: 'Comments',
    CONDITIONS: 'Conditions',
    CONTACT_TEXT: 'Contact',
    CREATED: 'Created:',
    DOCUMENTS_LETTER: 'Documents',
    EMAIL: 'Email Address associated with this application',
    FULL_NAME: 'Full Name (as on passport)',
    INFORMATION: 'Information',
    OFFERS: 'Admission Letters',
    PHONE_NUMBER: 'Phone Number associated with this application',
    INTAKE: 'Intake',
    DURATION: 'Duration',
    LOCATION: 'Location',
    CREATED_DATE: 'Created Date',
    MEETING_LINK: 'Book Meeting',
    BOOK_A_MEETING: 'Book a meeting',
    STATUS: 'Status',
    ADVISOR: 'Advisor Account',
    ADVISOR_EMAIL: 'Advisor Email',
    ADVISOR_PHONE: 'Advisor Phone',
    CREATED_BY: 'Created by',
    OFFER_LETTER: 'Letters',
    ADMISSIONS_COMMENTS: 'Comments',
    ATTACH_DOCUMENT_TEXT:
      'Please upload all your application-related documents here:',
    STATUS_HEADER_NOTE: 'Please ask your agent to finalise your application',
    NOTE: 'Note:',
    DOCUMENTS: 'documents',
    DOCUMENT: 'document',
    DOCS: 'docs',
    DOC: 'doc',
  },
  AUTH: {
    FOOTER_LINK: 'Global University Systems',
    FOOTER: 'Learn more about ',
    FORGOT_PASSWORD: 'Forgot Password',
    LOGIN: 'Log in',
    OR: 'Or sign in with',
    OTP_SEND: 'We have sent an OTP to your email. Please check your inbox.',
    PASSWORD_RESET_SUCCESSFULLY: 'Your password has been successfully reset.',
    PRIVACY_POLICY: 'Privacy Policy Agreement',
    GET_STARTED: 'Let’s Get Started!',
    REGISTER: 'Register',
    ACCOUNT_SIGNUP: 'Sign Up for an Account',
    ACCOUNT_SIGNIN: 'Sign In to Your Account',
    SET_PASSWORD: 'Set Your Password!',
    WELCOME_DESCRIPTION:
      'Unlock you potential with career-ready programs in top international destinations',
    WELCOME_TITLE: 'Discover a \nWorld of \nOpportunity',
    WELCOME_TITLE_2: 'Empowering the \nleaders of tomorrow!',
    WELCOME: 'Welcome to AppHero! Please enter your details below.',
    BRAND_ITEM_1_TITLE: 'Industry-Relevant Credentials',
    BRAND_ITEM_1_CONTENT:
      'Level up in your life and career with programmes designed to prepare you for the future of work.',
    BRAND_ITEM_2_TITLE: 'Award-Winning Institutions',
    BRAND_ITEM_2_CONTENT:
      'Study at our multi-accredited, multi-award-winning institutions in the world’s most vibrant, prosperous, and dynamic cities.',
    RESET_PASSWORD: 'Reset Password',
    VALIDATE_EMAIL: 'Validate Your Email Address',
    VALIDATE_EMAIL_DESC:
      'To proceed, kindly enter the one-time verification code that was sent to your email address,',
    RESET_PASSWORD_DESC: 'Enter your email address to reset your password',
    BACK_TO_SIGNIN: 'Back to Sign In',
    DONT_HAVE_ACCOUNT: 'Don’t have an account?',
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    SIGN_UP: 'Sign Up Now',
    SIGN_IN: 'Sign in',
    RESET_PASSWORD_BUTTON: 'Reset password',
    OTP_VERIFICATION: 'OTP Verification',
    OTP_VERIFICATION_DESC:
      'To reset the password, enter the one-time password that was sent to your email address, ',
    OTP_RESET: 'Verify and set a new password!',
    OTP_RESET_DES_1: 'Please enter the one-time verification code sent to ',
    OTP_RESET_DES_2:
      'to reset your password. Note that the verification code is valid for 60 minutes only.',
  },
  BUTTON: {
    ACCEPT: 'Accept',
    ADD_TO_APPLICATION: 'Add to Application',
    ADD_TO_COMPARE: 'Add to Compare',
    APPLY: 'Apply',
    ATTACH_DOCUMENT: 'Click here to upload',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    CONTINUE_APPLICATION: 'Continue Application',
    DECLINE: 'Decline',
    DOWNLOAD_OFFER: 'Download offer',
    GO_TO_MY_APPLICATION: 'Go to My Applications',
    GO_TO_PATH_FINDER: 'Go to Path Finder',
    LOGIN: 'Log in',
    SIGNIN: 'Sign In',
    LOGOUT: 'Logout',
    MORE_INFORMATION: 'More Information',
    NEW_USER: 'New user',
    OK: 'OK',
    PROCEED: 'Proceed',
    REMOVE_APPLICATION: 'Remove',
    SAVE: 'Save',
    SIGNUP: 'Sign Up Now',
    SET_PASSWORD: 'Set password',
    VERIFY: 'Verify',
    START_APPLICATION: 'Start Application',
    SUBMIT: 'Submit',
    SAVE_CHANGES: 'SAVE CHANGES',
    UPDATE_PASSWORD: 'update password',
    CONTINUE: 'Continue',
    SETTINGS: 'settings',
  },
  COMPARE_SCREEN: {
    CLEAR_COMPARE_PROGRAM: 'Clear comparison',
    COMPARE_PROGRAM: 'Compare programmes',
    INSTITUTION: 'Institution',
    LEVEL: 'Level',
    DELIVERY_MODE: 'Delivery Mode',
    PROGRAM_DURATION: 'Program Duration',
    COURSE_PRICE: 'Course Price',
    FIELD_OF_STUDY: 'Field Of study',
    LOCATION: 'Location',
  },
  DASHBOARD: {
    CLEAR_FILTER: 'Clear Filter',
    COUNTRY: 'Country',
    HERO_DESCRIPTION:
      'Level up in your life and career with higher education. Global University Systems proudly owns and partners with Planet Earth’s most innovative, forward-thinking institutions to deliver industry-relevant credentials with a focus on applied, practical learning in the world’s leading economies.',
    HERO_HEADER: 'Discover a World of Opportunities',
    HERO_SUB_HEADER:
      'Unlock your potential with career-ready programs in top international destinations.',
    INSTITUTION: 'Institution',
    INTAKE: 'Intake',
    LEVEL: 'Level',
    LOAD_MORE: 'Load more',
    NO_PROGRAM: 'Currently, There is no Program Available.',
    SHOW_BY: 'Show by',
    SHOW_MORE: 'Show more',
    SUBJECT: 'Subject',
  },
  DROP_DOWN: {
    CHANGE_APPLICATION: 'Change Information',
    DOWNLOAD_APPLICATION: 'Download Application',
    INSTITUTION: 'Institution',
    LABEL_SELECT: 'Select',
    PROGRAMMES: 'Programmes',
    TEXT: 'Text',
  },
  ERROR_MESSAGE: {
    CAN_NOT_BE_EMPTY: 'Can Not be Empty',
    INVALID: 'Invalid',
    NOT_MATCHING: 'does not match',
    NOT_BE_SAME: 'cannot be the same as the',
    INVALID_EMAIL_1: 'Please enter a valid email',
    INVALID_EMAIL_2:
      'Please use only letters, numbers, full stops, and underscores',
    COUNTRY_NOT_SLEETED: 'Country Should not be Empty',
    PASSWORD_NOT_MATCH: 'Password does not match',
    DOCUMENT_NOT_AVAILABLE_TEXT1: 'The document',
    DOCUMENT_NOT_AVAILABLE_TEXT2:
      'requested for download is not available.Please contact the administrator.',
  },
  FILE_UPLOAD: {
    ATTACH_DOCUMENT: 'Attach document',
    DOCUMENT_TYPE: 'Document type',
  },
  HEADER: {
    CART_TITLE: 'Application Basket',
    COMPARE_PROGRAM: 'Compare programmes',
    FIND_PROGRAM: 'Path Finder',
    MY_APPLICATION: 'My Applications',
    START_APPLICATION: 'Applications',
    EXPLORE_PATHWAYS: 'Explore Pathways',
  },
  LABEL: {
    COMPLETED: 'Completed',
  },
  LINK: {
    ACCOUNT_EXIST: 'Already have an account? Sign in',
    HAVE_ACCOUNT: 'Already have an account?',
    DONT_HAVE_ACCOUNT: 'Don’t have an account?',
    FORGOT_PASSWORD: 'Forgot your password?',
    LINKED_IN: 'Continue with Linkedin',
    LINKED_TEXT: 'LinkedIn',
    MORE_INFO: 'More info',
  },
  MY_APPLICATION: {
    AGENT_DRAFT_ALERT: `Agent's draft applications are placed here. You cannot make changes or review the application until the agent completes the application.`,
    ALL_APPLICATION: 'All Applications',
    ADMISSIONS_REVIEW: 'Admissions Review',
    OFFERS: 'Offers',
    DRAFT: 'Drafts',
    NO_DATA: 'Nothing here yet!',
    VISA_PENDING: `Visa / Funding`,
    ACCEPTED: 'Accepted',
    ENROLLED: 'Enrolment',
    REJECTED: 'Rejected / Withdrawn',
  },
  OPPORTUNITIES_CARD: {
    DURATION: 'months programme',
    ESTIMATED_TUITION: 'estimation tuition',
  },
  PASSWORD_VALIDATION: {
    VALIDATION_1: 'Minimum of 6 characters',
    VALIDATION_2: 'Must include at least one capital letter',
    VALIDATION_3: 'Must contain at least one special character',
    VALIDATION_4: 'Must include at least one number',
  },
  PRIVACY_POLICY: {
    CHECK_BOX_1_TEXT: `By signing up and using the site, I agree to the`,
    CHECK_BOX_1_LINK_TEXT: 'Acceptable Use Policy',
    CHECK_BOX_2_TEXT: `By signing up and using the site, I consent to receiving information (by email, phone, or text) about the programmes and services offered from`,
    CHECK_BOX_2_LINK_TEXT:
      'institutions partnered with or within the GUS Group',
    FIRST_PARAGRAPH_1: `AppHero is a tool used by the Global University Systems group of companies (the`,
    FIRST_PARAGRAPH_2: `) and partner institutions for the purposes of data exchange pertaining to the application and admission processes of those institutions. Apphero.io is a site operated by Global University Systems B.V. (“We”). We are registered in The Netherlands under company number 55367453 and have our registered office and our main trading address at Strawinskylaan 821, Tower A08, 1077XX, Amsterdam,The Netherlands. We operate the site for ourselves and also on behalf of other companies who trade under the GUS or GUS owned brand names or who partner with GUS or GUS owned brand names for the purposes of student recruitment.`,
    FIRST_PARAGRAPH_LINK_TEXT: 'GUS Group',
    SECOND_PARAGRAPH_1: `The information you provide on this form will be processed in accordance with our`,
    SECOND_PARAGRAPH_2: `.We will use the details provided by you to get in touch with you about your application and admissions processes with the institution or institutions to which you have applied.`,
    SECOND_PARAGRAPH_LINK_TEXT: 'Privacy Policy',
  },
  PROFILE: {
    CHANGE_PASSWORD: 'Change Password',
    PERSONAL_INFORMATION: 'Personal Information',
    MY_PROFILE: 'My Profile',
    CHANGE_PASSWORD: 'Change Password',
    PERSONAL_INFO: 'Personal Info',
    NOTIFICATIONS: 'NOTIFICATIONS',
  },
  PROGRAM_SCREEN: {
    ADD_TO_COMPARISON: 'Add to comparison',
    BRAND: 'Brand:',
    DELIVERY: 'Delivery: ',
    DOWNTOWN_DESCRIPTION: ` Two easily-accessible campuses located in vibrant downtown Vancouver, Canada`,
    DOWNTOWN: 'Downtown Location',
    DURATION: 'Duration:',
    LEVEL: 'Level:',
    LOCATION: 'Location:',
    MEDAL_DESCRIPTION: `Rated as a Five Star institution by the QS Stars higher education rating system.`,
    PROGRAM_PRICE_DESCRIPTION: `Below price projections can be subject to change due to promotions, scholarships, regional discounts, and more. Below are some examples of possible pricing based on these factors. Please refer to your Offer Letter for the final calculation of your course price.`,
    PROGRAM_PRICE: 'Programme Price',
    PROVEN_EMPLOYABILITY_DESCRIPTION: `100% of MBA students have secured employment within six months of graduation`,
    PROVEN_EMPLOYABILITY: 'Proven Employability',
    RATING: 'Rating',
    RECOMMENDED_PROGRAM: 'Recommended Programmes',
    SCHOOL: 'School:',
    STAR: ' 5 Star QS',
    START_TO_APPLICATION: 'Applications',
  },
  TEXT_INPUT: {
    AS_PER_PASSPORT: 'as per Passport',
    CODE: 'OTP',
    CONFIRM_PASSWORD: 'Confirm Password',
    COUNTRY: 'Country',
    CURRENT_PASSWORD: 'Current Password',
    EMAIL: 'Email',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    NEW_PASSWORD: 'New Password',
    PASSWORD: 'Enter Password',
    PHONE: 'Phone Number',
    REPEAT_PASSWORD: 'Repeat Password',
    RE_ENTER_PASSWORD: 'Re-enter Password',
    USER_NAME: 'UserName / Email',
    OLD_PASSWORD: 'Old password',
    RE_ENTER_NEW_PASSWORD: 'Re-enter new password',
    NEW_Password: 'New password',
  },
}
