import { Icon } from '@app-hero/native-icons'
import { PhotoId } from '@apphero/assets'
import { Text } from '@libs/components'
import { useTheme } from '@libs/theme'
import { FileHandler } from '@libs/utils'
import { isMobile } from '@libs/utils/src/screenLayout'
import React, { useRef, useState } from 'react'
import {
  Image,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'

const FilePicker = ({
  handleFileSelect = () => {},
  style = {},
  isProfile,
  source,
  profileStyle,
}) => {
  const { colors } = useTheme()
  const [file, setFile] = useState([])
  const documentRef = useRef()
  const fileTypes = isProfile
    ? ['JPG', 'JPEG', 'PNG']
    : ['JPG', 'JPEG', 'PDF', 'PNG']
  const [show, setShow] = useState(false)
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = async (event) => {
    event.preventDefault()
    await FileHandler.handleFilePicker({
      event,
      file,
      fileTypes,
      handleFileSelect,
      setFile,
    })
  }

  return (
    <View style={[{ marginBottom: 30 }, style]}>
      <form
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onSubmit={(e) => {
          FileHandler.handleFilePicker({
            event: e,
            handleFileSelect,
            fileTypes,
          })
        }}
      >
        {isProfile ? (
          <TouchableOpacity
            onMouseEnter={(e) => {
              setShow(true)
            }}
            onMouseLeave={(e) => {
              setShow(false)
            }}
          >
            {show && (
              <TouchableOpacity
                onPress={() => documentRef.current.click()}
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  borderRadius: '80%',
                  width: mobile ? 98 : 130,
                  height: mobile ? 98 : 130,
                  backgroundColor: show ? 'rgba(59,68,75,0.6)' : '',
                }}
              >
                <Icon
                  name="Camera"
                  style={{
                    borderRadius: '50%',
                    height: 24,
                    width: 24,
                  }}
                  color={colors.white}
                />

                <Text
                  style={{
                    color: colors.white,
                    marginLeft: 10,
                    textAlign: 'center',
                    fontSize: 14,
                  }}
                >
                  Change Profile Picture
                </Text>
              </TouchableOpacity>
            )}
            <Image source={source} style={profileStyle} />
          </TouchableOpacity>
        ) : (
          <>
            {Object.keys(file).length > 0 ? (
              <View style={{ paddingHorizontal: 20 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderColor: colors.border,
                    borderRadius: 15,
                    borderWidth: 2,
                    padding: 20,
                    flexWrap: 'wrap',
                    marginTop: 20,
                  }}
                >
                  <View style={{ flexDirection: 'row', flex: 1 }}>
                    <Image source={PhotoId} style={{ height: 14, width: 14 }} />
                    <Text
                      variant="display4"
                      style={{ marginHorizontal: 10, maxWidth: 250 }}
                    >
                      {file.name}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => {
                      setFile({})
                    }}
                  >
                    <Icon name="Close" height={12} width={12} />
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <View
                style={{
                  height: 81,
                  borderRadius: 4,
                  borderWidth: 2,
                  padding: 5,
                  marginTop: 10,
                  borderColor: colors.fieldBorder,
                  borderStyle: 'dashed',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon name="AddFile" height={41} width={41} />
                <View style={{ marginLeft: 10 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity
                      style={{
                        backgroundColor: colors.backgroundOnNeutral,
                        borderRadius: 3,
                        marginRight: 3,
                        paddingHorizontal: 3,
                      }}
                      onPress={() => documentRef.current.click()}
                    >
                      <Text
                        variant="display4"
                        color={colors.primary}
                        style={{
                          backgroundColor: colors.primaryContainer,
                          paddingHorizontal: 3,
                          borderRadius: 3,
                        }}
                      >
                        Upload File
                      </Text>
                    </TouchableOpacity>
                    <Text variant="display4">or drag and drop</Text>
                  </View>
                  <Text variant="display4" color={colors.primaryIconColor}>
                    {fileTypes.map((item) => item).join(', ')}
                  </Text>
                </View>
              </View>
            )}
          </>
        )}

        <input
          type="file"
          ref={documentRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            FileHandler.handleFilePicker({
              event: e,
              fileTypes,
              handleFileSelect,
            })
          }}
          accept={
            isProfile ? '.jpg,.jpeg,.png' : "'.jpg', '.jpeg', '.pdf', '.png'"
          }
        />
      </form>
    </View>
  )
}

export default FilePicker
