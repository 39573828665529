import { Auth } from 'aws-amplify'
import { countryCodes } from '../countryList/countryList'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const forgotPasswordAsync = async (userName) => {
  try {
    const res = await Auth.forgotPassword(userName)
    return res
    console.log({ res })
  } catch (error) {
    return error
  }
}

export const resetPasswordAsync = async (username, code, newPassword) => {
  try {
    const res = await Auth.forgotPasswordSubmit(username, code, newPassword)
    console.log({ res })
    return res
  } catch (error) {
    return error
  }
}

export const changePasswordAsync = async (currentPassword, newPassword) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const res = await Auth.changePassword(user, currentPassword, newPassword)
    console.log(res)
    return res
  } catch (error) {
    return error
  }
}

export const completeNewPasswordAsync = async (user, newPassword) => {
  try {
    const res = await Auth.completeNewPassword(user, newPassword)
    if (res) {
      console.log(res)
    }
  } catch (error) {
    console.log({ error })
  }
}

export const emailSignUp = async (email, password, userDetails) => {
  try {
    const response = await Auth.signUp({
      username: email,
      password: password,
      attributes: userDetails,
    })
  } catch (error) {
    return error
  }
}
export const confirmSignUp = async (username, code, password) => {
  console.log('username', username)
  try {
    const res = await Auth.confirmSignUp(username, code)
    console.log('res', res)
    if (res) {
      try {
        const result = await emailSignIn(username, password)
        return result
      } catch (error) {
        return error
      }
    }
  } catch (error) {
    console.log('error', error)
    return error
  }
}

export const emailSignIn = async (email, password) => {
  try {
    const signInResponse = await Auth.signIn({
      username: email,
      password: password,
    })
    if (signInResponse?.attributes) {
      const contact = countryCodes
        .filter((item) =>
          signInResponse.attributes?.phone_number?.includes(item.dial_code),
        )
        .map((item) => ({
          phoneNo: signInResponse.attributes?.phone_number.replace(
            item.dial_code,
            '',
          ),
          dialCode: item.dial_code,
        }))

      const userData = {
        email: signInResponse.attributes?.email || '',
        userID: signInResponse.username || '',
        accessToken: signInResponse.signInUserSession.accessToken.jwtToken,
      }
      return userData
    }
  } catch (error) {
    return error
  }
}

export const federatedSignIn = async (providerType) => {
  await Auth.federatedSignIn({ provider: providerType }).catch((err) => {})
}

export const logOut = async () => {
  await AsyncStorage.removeItem('userProfile')
  await Auth.signOut({ global: true }).catch((err) => {
    return { error: err.message }
  })
}
