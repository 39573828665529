import React, { useEffect, useRef, useState } from 'react'
import { Platform, View, useWindowDimensions } from 'react-native'
import * as Sentry from '@sentry/react-native'
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context'
import 'react-native-gesture-handler'
import { Provider as JotaiProvider, useAtom } from 'jotai'
import Toast from 'react-native-toast-notifications'
import { ThemeProvider } from '@libs/theme'
import { SecureStore } from '@libs/utils'
import { navigationRef } from './navigation/RootNavigator'
import AppNavigator from './navigation'
import awsConfig from './awsConfig'
import { sentryUrl } from './config'
import './translations/i18n'
import { notificationSubscriber } from './graphql/subscription'
import { userProfile } from './utils/atom'

Sentry.init({
  dsn: sentryUrl,
  tracesSampleRate: 1.0,
})

const App = () => {
  const routeNameRef = useRef()
  Amplify.configure(awsConfig)
  const { height } = useWindowDimensions()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 600000,
      },
    },
  })

  // const customLightTheme = {
  //   ...DefaultTheme.colors,
  //   ...LightThemeColors,
  //   colors: {
  //     ...DefaultTheme.colors,
  //     ...LightThemeColors.colors,
  //   },
  //   spacing,
  // }

  // const customDarkTheme = {
  //   ...DefaultTheme.colors,
  //   ...DarkThemeColors,
  //   colors: {
  //     ...DefaultTheme.colors,
  //     ...DarkThemeColors.colors,
  //   },
  //   spacing,
  // }

  // const getThemeColor = (theme) => {
  //   if (theme === 'dark') {
  //     return customDarkTheme
  //   }
  //   if (theme === 'light') {
  //     return customLightTheme
  //   }
  //   return customDarkTheme
  // }

  // const theme = getThemeColor('light')

  useEffect(() => {
    ;(async () => {
      const gusApiUrl = window.location.origin
      const response = await fetch(`${gusApiUrl}/config.json`)
      const result = await response.json()
      await SecureStore.setItemAsync('config', JSON.stringify(result))
    })()
  }, [])

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <QueryClientProvider client={queryClient}>
        <NavigationContainer
          ref={navigationRef}
          onReady={() => {
            routeNameRef.current = navigationRef.current.getCurrentRoute().name
          }}
          onStateChange={async () => {
            const currentRouteName =
              navigationRef.current.getCurrentRoute().name
            routeNameRef.current = currentRouteName
          }}
          linking={{ enabled: true }}
        >
          <ThemeProvider>
            <JotaiProvider>
              <View style={{ minHeight: height }}>
                <AppNavigator />
              </View>
            </JotaiProvider>
          </ThemeProvider>
          <Toast
            ref={(ref) => (global.toast = ref)}
            duration={5000}
            animationType="zoom-in"
            animationDuration={0}
            textStyle={{ fontSize: 16 }}
            renderType={{
              error: (toast) => <ErrorToast toast={toast} />,
            }}
          />
        </NavigationContainer>
      </QueryClientProvider>
    </SafeAreaProvider>
  )
}

Sentry.setTag('Platform:', Platform.OS)

export default Sentry.withProfiler(App)
