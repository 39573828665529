import { Text } from 'react-native'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { ScreenLayout } from '@libs/utils'
import { useIsFocused } from '@react-navigation/native'
import { useProgramsInfiniteData } from '../../hooks/usePrograms'
import DesktopView from './DesktopView'

const ProgramAccordion = ({
  activeTab,
  brandLogoUrl,
  institutionData,
  filters,
  filterKey,
  setActiveTab,
  renderItem,
  ListEmptyComponent,
}) => {
  const [onHover, setOnHover] = useState(false)
  const [defaultLogo, setDefaultLogo] = useState(false)
  const [showBrandName, setShowBrandName] = useState(false)
  const isFocused = useIsFocused()
  const { Institution, ...newFilterData } = filters
  const isOpen = activeTab?.BusinessUnitFilter__c === institutionData?.BusinessUnitFilter__c
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, DesktopView, DesktopView),
    [],
  )

  // fetch program details
  const {
    allData: program,
    isFetching: isProgramFetching,
    hasNextPage: programHasNextPage,
    isFetchingNextPage: isProgramFetchNextPage,
    fetchNextPage: programFetchNextPage,
  } = useProgramsInfiniteData({
    queryKey: `getInstitutionProgram-${institutionData?.BusinessUnitFilter__c}-${filterKey?.currentKey}`,
    limit: 4,
    enabled: isOpen && isFocused,
    filters: {
      Institution: {
        values: [institutionData?.BusinessUnitFilter__c],
      },
      ...newFilterData,
    },
  })

  const viewProps = {
    brandLogoUrl,
    defaultLogo,
    institutionData,
    isLoading: isProgramFetching,
    isOpen,
    isProgramFetching,
    isProgramFetchNextPage,
    ListEmptyComponent,
    onHover,
    program,
    programHasNextPage,
    renderItem,
    setActiveTab,
    showBrandName,
    setDefaultLogo,
    setOnHover,
    setShowBrandName,
    programFetchNextPage,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ProgramAccordion
