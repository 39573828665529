import React, { useRef, useState } from 'react'
import {
  View,
  Modal,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from 'react-native'
import { Icon } from '@app-hero/native-icons'

import { Loader, Text } from '@libs/components'
import { useTheme } from '@libs/theme'

const Menu = ({ items, onPress = async () => {}, leftMargin }) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const menuRef = useRef()
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const { width: screenWidth } = useWindowDimensions()

  const toggleDropdown = (visible) => {
    if (visible) {
      return
    }
    if (!visible) {
      menuRef?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
        if (_px + 130 > screenWidth) {
          setDropdownLeft(_px - (_w + py))
        } else {
          setDropdownLeft(_px)
        }
        setDropdownTop(py + 30)
      })
    }
  }

  const handleModalClose = () => {
    setMenuVisible(false)
  }

  const handleOnPress = async (selectedOption) => {
    setIsFetching(true)
    await onPress(selectedOption)
    setMenuVisible(false)
    setIsFetching(false)
  }
  return (
    <View>
      <TouchableOpacity
        style={{
          height: 28,
          width: 28,
          borderWidth: 1,
          borderRadius: 6,
          borderColor: '#E4E9ED',
          padding: 4,
          alignItems: 'center',
          marginLeft: leftMargin || 32,
        }}
        onPress={() => {
          setMenuVisible(true)
          toggleDropdown()
        }}
        ref={menuRef}
      >
        <Icon name="DotsVertical" />
      </TouchableOpacity>
      <Modal
        visible={menuVisible}
        transparent
        onBackdropPress={() => setMenuVisible(false)}
        onRequestClose={() => {
          setMenuVisible(false)
        }}
      >
        <TouchableWithoutFeedback onPress={handleModalClose}>
          <View style={styles.modalOverlay} />
        </TouchableWithoutFeedback>
        <View
          style={[
            styles.dropDownList,
            {
              position: 'absolute',
              top: dropdownTop,
              left: dropdownLeft,
            },
          ]}
        >
          <FlatList
            data={items}
            renderItem={({ item, index }) => (
              <DropDownItem
                item={item}
                handleDropDownClose={() => setMenuVisible(false)}
                onPress={handleOnPress}
                isFetching={isFetching}
              />
            )}
            keyExtractor={(_, index) => index}
          />
        </View>
      </Modal>
    </View>
  )
}

const DropDownItem = ({ item, onPress, handleDropDownClose, isFetching }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { colors } = useTheme()

  const handleOptionSelected = (selectedOption) => {
    onPress(selectedOption)
    handleDropDownClose()
  }

  return (
    <TouchableOpacity
      key={item.label}
      style={[
        styles.dropDownListContainer,
        { backgroundColor: isHovered ? colors.primaryVariant : '' },
      ]}
      onPress={() => handleOptionSelected(item)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <View style={styles.item}>
        <Text
          variant="display3"
          color={colors.onNeutral}
          style={{ marginRight: 10 }}
        >
          {item?.label}
        </Text>
        {isFetching ? <Loader size={10} style={{ marginTop: 2 }} /> : null}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  dropDownList: {
    paddingVertical: 10,
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    shadowColor: 'rgba(0, 15, 68, 0.15)',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 5,
    flex: 1,
  },
  dropDownListContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.01)',
  },
})

export default Menu
