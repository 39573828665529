const INTER = 'Inter'
export const typography = {
  heading1: {
    fontFamily: `${INTER}`,
    fontSize: 45,
    fontWeight: 800,
  },
  heading2: {
    fontFamily: `${INTER}`,
    fontSize: 28,
    fontWeight: 700,
  },
  heading3: {
    fontFamily: `${INTER}`,
    fontSize: 24,
    fontWeight: 700,
  },
  heading4: {
    fontFamily: `${INTER}`,
    fontSize: 18,
    fontWeight: 700,
  },
  heading5: {
    fontFamily: `${INTER}`,
    fontSize: 16,
    fontWeight: 700,
  },
  heading6: {
    fontFamily: `${INTER}`,
    fontSize: 14,
    fontWeight: 700,
  },
  bodyBold1: { fontFamily: `${INTER}`, fontSize: 22, fontWeight: 500 },
  bodyBold2: { fontFamily: `${INTER}`, fontSize: 16, fontWeight: 500 },
  bodyBold3: { fontFamily: `${INTER}`, fontSize: 14, fontWeight: 500 },
  display1: {
    fontFamily: `${INTER}`,
    fontSize: 22,
    fontWeight: 400,
  },
  display2: {
    fontFamily: `${INTER}`,
    fontSize: 18,
    fontWeight: 400,
  },
  display3: {
    fontFamily: `${INTER}`,
    fontSize: 16,
    fontWeight: 400,
  },
  display4: {
    fontFamily: `${INTER}`,
    fontSize: 14,
    fontWeight: 400,
  },
  display5: {
    fontFamily: `${INTER}`,
    fontSize: 12,
    fontWeight: 400,
  },
  body1: {
    fontFamily: `${INTER}`,
    fontSize: 16,
    fontWeight: 300,
  },
  body2: {
    fontFamily: `${INTER}`,
    fontSize: 15,
    fontWeight: 300,
  },
}
