import React, { useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
  useWindowDimensions,
  Linking,
} from 'react-native'
import { Text, Badge, Button, ShimmerPlaceholder } from '@libs/components'
import { Icon } from '@app-hero/native-icons'

import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { isTablet, isWeb } from '@libs/utils/src/screenLayout'
import { countRecentComments, countReadComments } from '@libs/utils'
import { handleNavigation } from '../../utils/function'
import { FileUploadModal, Menu } from '../../components'
import EmptyContainer from '../../components/container/EmptyContainer'
import Accordion from '../../components/CommentAccordion'
import { DocumentItem } from '../../components/viewApplication/documentItem'
import LinearGradient from 'react-native-linear-gradient'
import { sort } from '../../utils/sorting'
import { signedurl } from '../../api'

const DesktopView = ({
  setTabData,
  canShowMenu,
  brandLogoUrl,
  admissionsNotesTabData,
  applicationDocuments,
  applicationLetterDocuments,
  documentType,
  dropdownTop,
  dropdownLeft,
  dropdownWidth,
  dropDownRef,
  handleFileUpload,
  informationList,
  isDocumentFetching,
  moreIconDropDownDetails,
  handleMoreIcon,
  isLoading,
  modalVisible,
  opportunities,
  setModalVisible,
  tabData,
  toggleDropdown,
  scrollViewRef,
  childRef,
  handleScrollComment,
  handleUpdateComment,
  isActiveComment,
}) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const web = isWeb(width)
  const tablet = isTablet(width)

  const findPaddingHorizontal = (width) => {
    let paddingHorizontal
    if (width >= 1024 && width <= 1240) {
      paddingHorizontal = 30
    } else if (width >= 1241 && width <= 1440) {
      paddingHorizontal = 90
    } else if (width >= 1441 && width <= 1670) {
      paddingHorizontal = 180
    } else if (width >= 1671 && width <= 1870) {
      paddingHorizontal = 270
    } else if (width >= 1871 && width <= 2160) {
      paddingHorizontal = 380
    } else {
      paddingHorizontal = 420
    }
    return paddingHorizontal
  }

  if (isLoading) {
    return (
      <View
        style={{
          backgroundColor: '#fff',
          flexDirection: 'row',
          paddingHorizontal: findPaddingHorizontal(width),
          minHeight: '100vh',
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <ShimmerPlaceholder
            style={{
              height: 200,
              width: '95%',
              marginTop: spacing.spacing5,
              borderRadius: spacing.spacing5,
            }}
          />
          <ShimmerPlaceholder
            style={{
              height: 600,
              width: '95%',
              marginTop: spacing.spacing5,
              borderRadius: spacing.spacing5,
            }}
          />
        </View>
        <View>
          <ShimmerPlaceholder
            style={{
              height: 286,
              width: 400,
              marginTop: spacing.spacing5,
              borderRadius: spacing.spacing5,
            }}
          />
        </View>
      </View>
    )
  }

  const { colors } = useTheme()
  return (
    <ScrollView
      style={{
        backgroundColor: colors.backgroundPrimary,
        flex: 1,
        // eslint-disable-next-line no-nested-ternar
        paddingHorizontal: findPaddingHorizontal(width),
      }}
      contentContainerStyle={{
        flexGrow: 1,
      }}
      ref={scrollViewRef}
    >
      <View style={styles.mainContainer(tablet, width)}>
        <LeftContainer
          informationList={informationList}
          brandLogoUrl={brandLogoUrl}
          data={opportunities}
          applicationDocuments={applicationDocuments}
          applicationLetterDocuments={applicationLetterDocuments}
          isDocumentFetching={isDocumentFetching}
          tabData={tabData}
          setModalVisible={setModalVisible}
          moreIconDropDownDetails={moreIconDropDownDetails}
          canShowMenu={canShowMenu}
          handleMoreIcon={handleMoreIcon}
          isLoading={isLoading}
          setTabData={setTabData}
          web={web}
          tablet={tablet}
          childRef={childRef}
          handleUpdateComment={handleUpdateComment}
          handleScrollComment={handleScrollComment}
          admissionsNotesTabData={admissionsNotesTabData}
          isActiveComment={isActiveComment}
        />
        <View
          style={{
            gap: spacing.spacing3,
            alignItems: 'flex-start',
          }}
        >
          <View style={styles.rightContainer(width)}>
            <Text
              variant="display1"
              style={{
                paddingBottom: 20,
                color: colors.textPrimary,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {t('APPLICATION_DETAIL.ANY_QUESTION')}
            </Text>
            <View style={[styles.divider]} />
            <Text
              variant="display3"
              color={colors.neutral}
              style={[styles.rightSideTextContainer, { width: 350 }]}
            >
              Please contact your{' '}
              {!opportunities?.isDirectSales ? 'agent, ' : 'advisor, '}
              <b>
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Name
                    ? opportunities?.Agent_Contact__r?.Name + ', '
                    : ''
                  : opportunities?.OwnerName__c
                  ? opportunities?.OwnerName__c + ', '
                  : ''}
              </b>
              at
            </Text>

            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 5,
                // paddingTop: 40,
              }}
            >
              <Icon name="Mail" width={18} height={18} />
              <Text
                variant="display3"
                color={colors.neutral}
                numberOfLines={2}
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                  fontWeight: 700,
                  borderBottomWidth: 1.5,
                  color: colors.textPrimary,
                }}
              >
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Email
                  : opportunities?.OwnerEmail__c}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Icon name="Phone" width={18} height={18} />
              <Text
                variant="display3"
                color={colors.neutral}
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                  fontWeight: 700,
                  borderBottomWidth: 1.5,
                  color: colors.textPrimary,
                }}
              >
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Phone
                  : opportunities?.Owner?.Phone}
              </Text>
            </View>

            {opportunities?.isDirectSales && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingTop: 10,
                }}
              >
                <Icon name="CalenderIcon" width={18} height={18} />
                <TouchableOpacity
                  onPress={() => {
                    const url =
                      opportunities?.Owner?.Appointment_Booking_Link__c
                    if (url) {
                      Linking.openURL(url).catch((err) =>
                        console.error('Failed to open URL:', err),
                      )
                    }
                  }}
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <Text
                    variant="display3"
                    color={colors.neutral}
                    style={{
                      marginLeft: 10,
                      fontSize: 15,
                      fontWeight: 700,
                      borderBottomWidth: 1.5,
                      color: colors.textPrimary,
                    }}
                  >
                    {t('APPLICATION_DETAIL.BOOK_A_MEETING')}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            <Text
              variant="display3"
              color={colors.neutral}
              style={[
                styles.rightSideTextContainer,
                { width: 350, marginTop: 10 },
              ]}
            >
              Or get in touch with our support team by clicking on the chat
              window below.
            </Text>
          </View>
        </View>
      </View>

      <FileUploadModal
        visible={modalVisible}
        title={t('APPLICATION_DETAIL.ATTACH_DOCUMENT')}
        toggleDropdown={toggleDropdown}
        handleSave={(documentDetails) => handleFileUpload(documentDetails)}
        handleClose={() => setModalVisible(false)}
        dropDownPosition={{ left: dropdownLeft + 10, top: dropdownTop + 10 }}
        dropdownWidth={dropdownWidth - 20}
        documentType={documentType}
        dropDownRef={dropDownRef}
      />
    </ScrollView>
  )
}

const LeftContainer = ({
  applicationDocuments,
  applicationLetterDocuments,
  brandLogoUrl,
  canShowMenu,
  data,
  handleMoreIcon,
  informationList,
  isDocumentFetching,
  moreIconDropDownDetails,
  setModalVisible,
  setTabData,
  tabData,
  isLoading,
  admissionsNotesTabData,
  web,
  tablet,
  childRef,
  handleScrollComment,
  handleUpdateComment,
  isActiveComment,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [showBrandName, setShowBrandName] = useState(true)

  const badgeStyle = {
    Offer: {
      color: '#065F46',
      borderColor: '#075F46',
      backgroundColor: '#F0FFE4',
      borderWidth: 1,
    },
    Admission: {
      color: '#D4691B',
      backgroundColor: '#FFF4E0',
      borderWidth: 1,
      borderColor: '#D4691B',
    },
    Enrolled: {
      color: '#065F46',
      borderColor: '#075F46',
      backgroundColor: '#F0FFE4',
      borderWidth: 1,
    },
    Accepted: {
      color: '#065F46',
      borderColor: '#075F46',
      backgroundColor: '#F0FFE4',
      borderWidth: 1,
    },
    Draft: {
      backgroundColor: '#ECF2FC',
      borderWidth: 1,
      color: '#044DBA',
      borderColor: colors.draftBlue,
    },
    Visa: {
      color: '#D4691B',
      backgroundColor: '#FFF4E0',
      borderWidth: 1,
      borderColor: '#D4691B',
    },
    Rejected: {
      backgroundColor: '#FFD7D7',
      borderWidth: 1,
      color: '#D72C2C',
      borderColor: '#B42638',
    },
  }

  const badgeColor = {
    Offer: {
      color: '#065F46',
    },
    Admission: {
      color: '#D4691B',
    },
    Enrolled: {
      color: '#065F46',
    },
    Accepted: {
      color: '#065F46',
    },
    Draft: {
      color: '#044DBA',
    },
    Visa: {
      color: '#D4691B',
    },
    Rejected: {
      color: '#D72C2C',
    },
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
      }}
    >
      {isLoading ? (
        <ShimmerPlaceholder
          style={{
            width: '100%',
            height: 400,
            borderRadius: spacing.spacing5,
          }}
        />
      ) : (
        <View
          style={{
            flexDirection: 'column',
            width: '100%',
            rowGap: 20,
          }}
        >
          <View
            style={{
              columnGap: 20,
            }}
          >
            <View
              style={{
                flexDirection: tablet ? 'column' : 'row',
                alignItems: !tablet && 'flex-start',
                backgroundColor: 'white',
                paddingHorizontal: 20,
                paddingVertical: 10,
                borderRadius: 16,
              }}
            >
              <View style={{ flex: 1, width: '100%' }}>
                <View>
                  {showBrandName && web && (
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: 5,
                        }}
                      >
                        {web && (
                          <TouchableOpacity
                            onPress={() => {
                              if (Platform.OS === 'web') {
                                window.history.go(-1)
                              } else {
                                // navigation.goBack()
                              }
                            }}
                            style={{
                              marginRight: 10,
                              marginBottom: !tablet && spacing.spacing8,
                              marginTop: 10,
                            }}
                          >
                            <Icon
                              name="ArrowNarrowLeft"
                              height={24}
                              width={24}
                            />
                          </TouchableOpacity>
                        )}

                        <Image
                          source={`${brandLogoUrl}/${data.BusinessUnitFilter__c?.replaceAll(
                            ' ',
                            '_',
                          )}.png`}
                          style={{ height: 75, width: 150 }}
                          alt={data?.brand}
                          resizeMode="contain"
                          onError={(error) => {
                            setShowBrandName(true)
                          }}
                        />
                        {/* <Text
                          variant="display5"
                          color={colors.neutral}
                          numberOfLines={3}
                        >
                          {data?.Institution}
                        </Text> */}
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          marginBottom: 20,
                          columnGap: 30,
                          marginRight: 10,
                          alignItems: 'center',
                        }}
                      >
                        <Badge
                          style={[
                            badgeStyle[
                              data?.ApplicationStatus?.status.split(' ')[0]
                            ] ||
                              badgeStyle[
                                data?.ApplicationStatus?.status.split('/')[0]
                              ],
                            { flexDirection: 'row' },
                          ]}
                        >
                          <Text
                            style={[
                              { fontWeight: 700, fontSize: 14 },
                              badgeColor[
                                data?.ApplicationStatus?.status.split(' ')[0]
                              ] ||
                                badgeColor[
                                  data?.ApplicationStatus?.status.split('/')[0]
                                ],
                            ]}
                          >
                            {data?.ApplicationStatus?.status}
                          </Text>
                        </Badge>
                        {/* <View>
                          <Icon name="DotsVertical" width={20} height={20} />
                        </View> */}
                      </View>
                    </View>
                  )}

                  {!web && (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <View
                        style={{ flexDirection: 'row', alignItems: 'center' }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            if (Platform.OS === 'web') {
                              window.history.go(-1)
                            } else {
                              // navigation.goBack()
                            }
                          }}
                          style={{ marginRight: 10 }}
                        >
                          <Icon name="ArrowNarrowLeft" height={24} width={24} />
                        </TouchableOpacity>

                        {showBrandName && (
                          <View>
                            <Image
                              source={`${brandLogoUrl}/${data.BusinessUnitFilter__c?.replaceAll(
                                ' ',
                                '_',
                              )}.png`}
                              style={{ height: 50, width: 100 }}
                              alt={data?.brand}
                              resizeMode="contain"
                              onError={(error) => {
                                setShowBrandName(true)
                              }}
                            />
                            {/* <Text
                          variant="display5"
                          color={colors.neutral}
                          numberOfLines={3}
                        >
                          {data?.Institution}
                        </Text> */}
                          </View>
                        )}
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Badge
                          style={[
                            badgeStyle[
                              data?.ApplicationStatus?.status.split(' ')[0]
                            ] ||
                              badgeStyle[
                                data?.ApplicationStatus?.status.split('/')[0]
                              ],
                            { flexDirection: 'row' },
                          ]}
                        >
                          Status -
                          <Text
                            style={[
                              { fontWeight: 700 },
                              badgeColor[
                                data?.ApplicationStatus?.status.split(' ')[0]
                              ] ||
                                badgeColor[
                                  data?.ApplicationStatus?.status.split('/')[0]
                                ],
                            ]}
                          >
                            {' '}
                            {data?.ApplicationStatus?.status}
                          </Text>
                        </Badge>
                        <View>
                          <Icon name="DotsVertical" width={20} height={20} />
                        </View>
                        {/* {canShowMenu ? (
                          <Menu
                            items={moreIconDropDownDetails}
                            onPress={async (selectedItem) =>
                              await handleMoreIcon({ selectedItem })
                            }
                          />
                        ) : null} */}
                      </View>
                    </View>
                  )}
                </View>

                <View style={{ width: !tablet && '100%' }}>
                  <Text
                    variant="display1"
                    style={{
                      paddingTop: 20,
                      paddingBottom: 8,
                      paddingHorizontal: 20,
                      fontWeight: 700,
                      paddingLeft: 40,
                    }}
                  >
                    {data?.Name?.split('_')[0]}
                  </Text>
                </View>
                {data?.ApplicationStatus?.status === 'Draft' ? (
                  <View style={styles.notes}>
                    <Icon
                      name="AlertCircle"
                      height={20}
                      width={20}
                      color={'rgba(212, 105, 27, 1)'}
                      style={{ paddingHorizontal: 10 }}
                    />
                    <Text
                      style={{
                        color: '#D4691B',
                        fontSize: 16,
                      }}
                    >
                      <b>{t('APPLICATION_DETAIL.NOTE')}</b>{' '}
                      {t('APPLICATION_DETAIL.STATUS_HEADER_NOTE')}
                    </Text>
                  </View>
                ) : null}

                {/* {applicationLetterDocuments?.hasOffer &&
                data?.ApplicationStatus?.status === 'Offer' ? (
                  <ApplicationDetailsOfferCard
                    handleButton={async () => {
                      applicationLetterDocuments?.uploadDocuments?.FilePath
                        ? await handleNavigation({
                            url: applicationLetterDocuments?.uploadDocuments
                              ?.FilePath,
                            fileName: applicationLetterDocuments?.Name,
                            translation: t,
                          })
                        : null
                    }}
                    data={applicationLetterDocuments?.uploadDocuments}
                  />
                ) : null} */}
              </View>
            </View>
          </View>
          {/* <View>
            {web && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginBottom: 16,
                }}
              >
                {canShowMenu ? (
                  <Menu
                    items={moreIconDropDownDetails}
                    onPress={async (selectedItem) =>
                      await handleMoreIcon({ selectedItem })
                    }
                  />
                ) : null}
              </View>
            )}
          </View> */}
          <TabSection
            informationList={informationList}
            applicationDocuments={applicationDocuments}
            applicationLetterDocuments={
              applicationLetterDocuments?.documents || []
            }
            setTabData={setTabData}
            isDocumentFetching={isDocumentFetching}
            tabData={tabData}
            setModalVisible={setModalVisible}
            data={data}
            childRef={childRef}
            handleScrollComment={handleScrollComment}
            admissionsNotesTabData={admissionsNotesTabData}
            handleUpdateComment={handleUpdateComment}
            isActiveComment={isActiveComment}
          />
        </View>
      )}
    </View>
  )
}

const TabSection = ({
  setTabData,
  applicationDocuments,
  applicationLetterDocuments,
  informationList,
  setModalVisible,
  tabData,
  data,
  admissionsNotesTabData,
  childRef,
  handleUpdateComment,
  isDocumentFetching,
  isActiveComment,
}) => {
  const [activeTab, setActiveTab] = useState(
    tabData.findIndex((item) => item.isActive === true),
  )
  const { colors } = useTheme()
  const { t } = useTranslation()

  const comments = data?.commentsObj?.map(
    ({ details, date, whatId, id, isRead }) => ({
      title: <Text>{details?.split(/\s+/)?.slice(0, 48)?.join(' ')}</Text>,
      content: <Text>{details}</Text>,
      date,
      whatId,
      id,
      isRead,
    }),
  )

  const messageCounts = [0, 0, 0, 0]

  messageCounts[3] = countRecentComments(data?.commentsObj)

  return (
    <View
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: 16,
        paddingHorizontal: 19,
        width: '100%',
        paddingTop: 20,
        paddingBottom: 35,
      }}
    >
      <Tab
        activeTab={activeTab}
        tabData={tabData}
        messageCounts={messageCounts}
        conditionsCount={
          !!data?.Admissions_Condition__c &&
          !Object.keys(applicationLetterDocuments)?.length > 0
        }
        style={{
          textTransform: 'uppercase',
        }}
        labelStyle={{ fontSize: 13 }}
        admissionsNotesTabData={admissionsNotesTabData}
        setActiveTab={(selectedTab) => {
          const tabDataCopy = [...tabData]
          tabDataCopy?.map((tabItem, index) => {
            if (index === selectedTab) {
              tabDataCopy[index] = { ...tabDataCopy[index], isActive: true }
            } else {
              tabDataCopy[index] = { ...tabDataCopy[index], isActive: false }
            }
          })
          setActiveTab(selectedTab)
          setTabData(tabDataCopy)
        }}
      />

      {activeTab === 0 ? (
        <View
          style={{
            paddingHorizontal: 20,
          }}
        >
          {activeTab === 0 ? (
            <View
              style={{
                backgroundColor: colors.backgroundPrimary,
                borderRadius: 16,
                paddingHorizontal: 20,
                paddingVertical: 10,
              }}
            >
              {informationList?.length > 0 ? (
                informationList?.map((item, index) => (
                  <React.Fragment key={index}>
                    <View
                      style={{
                        flexDirection: 'row',
                        marginVertical: 7,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 20,
                      }}
                    >
                      <View
                        style={{
                          flexWrap: 'wrap',
                        }}
                      >
                        <Text
                          variant="display5"
                          style={{ color: '#545B61', fontSize: 16 }}
                        >
                          {item?.displayName ? `${item?.displayName}:` : ''}
                        </Text>
                      </View>
                      <View style={{ flexWrap: 'wrap' }}>
                        {item?.field === 'MeetLink' ? (
                          <TouchableOpacity
                            onPress={() => {
                              const url = item?.fieldName
                              if (url && url !== '-') {
                                Linking.openURL(url).catch((err) =>
                                  console.error('Failed to open URL:', err),
                                )
                              }
                            }}
                          >
                            <Text
                              style={{
                                color: '#021547',
                                fontSize: 16,
                                fontWeight: '700',
                                textDecorationLine: 'underline', // Optional: underline the text
                              }}
                              variant="display5"
                            >
                              {t('APPLICATION_DETAIL.BOOK_A_MEETING')}
                            </Text>
                          </TouchableOpacity>
                        ) : (
                          <Text
                            style={{
                              color: '#021547',
                              fontSize: 16,
                              fontWeight: 700,
                              flex: 1,
                            }}
                            variant="display5"
                          >
                            {item?.fieldName !== '-' ? item?.fieldName : 'NIL'}
                          </Text>
                        )}
                      </View>
                    </View>
                    {index !== informationList.length - 1 && (
                      <View
                        style={[styles.divider, { marginHorizontal: 20 }]}
                      />
                    )}
                  </React.Fragment>
                ))
              ) : (
                <EmptyContainer hasFixedHight />
              )}
            </View>
          ) : null}
        </View>
      ) : null}

      <View style={{ paddingHorizontal: 20 }}>
        {activeTab === 1 ? (
          <View
            style={{
              flexDirection: 'column',
              backgroundColor: colors.backgroundPrimary,
              borderRadius: 16,
              paddingHorizontal: 40,
              paddingVertical: 30,
              width: '100%',
            }}
          >
            {data?.ApplicationStatus?.status == 'Draft' ? null : (
              <LinearGradient
                colors={[colors.draftBlue, colors.textPrimary]}
                start={{ x: 0.2, y: 0.8 }}
                end={{ x: 1, y: 0 }}
                style={[
                  styles.attachDocument,
                  applicationDocuments === undefined
                    ? { marginBottom: 30 }
                    : {},
                ]}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: 'white',
                    padding: 3,
                  }}
                >
                  {t('APPLICATION_DETAIL.ATTACH_DOCUMENT_TEXT')}
                </Text>
                <Button
                  label={t('BUTTON.ATTACH_DOCUMENT')}
                  buttonStyle={{
                    paddingHorizontal: 25,
                    borderRadius: 8,
                  }}
                  labelStyle={{
                    color: colors.draftBlue,
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                  labelVariant="display7"
                  buttonColor={'white'}
                  onPress={() => setModalVisible(true)}
                  disable={data?.ApplicationStatus?.status === 'Draft'}
                />
              </LinearGradient>
            )}
            {applicationDocuments === undefined ? (
              isDocumentFetching ? (
                [...Array(4)].map(() => (
                  <>
                    <View style={[styles.divider, { marginTop: 10 }]} />
                    <ShimmerPlaceholder
                      style={{
                        height: 250,
                        width: '100%',
                        marginTop: spacing.spacing5,
                        borderRadius: spacing.spacing5,
                      }}
                    />
                  </>
                ))
              ) : (
                <EmptyContainer hasFixedHight />
              )
            ) : (
              Object?.entries(applicationDocuments || {})?.map(
                ([keys, value], index) => (
                  <View style={{ marginTop: 30, paddingHorizontal: 8 }}>
                    <DocumentAccordion
                      label={keys}
                      data={value}
                      index={index}
                      activeTab={activeTab}
                      showDate={false}
                      style={{
                        flexWrap: 'wrap',
                        width: '100%',
                        marginBottom: 2,
                        color: '#162447',
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                      height={180}
                    />
                  </View>
                ),
              )
            )}
          </View>
        ) : null}
      </View>

      <View style={{ paddingHorizontal: 20 }}>
        {activeTab === 2 ? (
          <View
            style={{
              backgroundColor: colors.backgroundPrimary,
              borderRadius: 16,
              paddingVertical: 30,
              paddingHorizontal: 40,
            }}
          >
            {isDocumentFetching ? (
              [...Array(1)].map((_, i) => (
                <>
                  <ShimmerPlaceholder
                    style={{
                      height: 250,
                      width: '100%',
                      marginTop: spacing.spacing5,
                      borderRadius: spacing.spacing5,
                    }}
                  />
                </>
              ))
            ) : Object.keys(applicationLetterDocuments)?.length > 0 ||
              data?.Admissions_Condition__c ? (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                {data?.Admissions_Condition__c ? (
                  <AdmissionComponent data={data} />
                ) : null}

                {Object.keys(applicationLetterDocuments)?.length > 0 ? (
                  Object?.entries(applicationLetterDocuments || {})?.map(
                    ([keys, value], index) => (
                      <DocumentAccordion
                        label={keys}
                        data={value}
                        index={index}
                        showDate={true}
                        style={{
                          flexWrap: 'wrap',
                          width: '100%',
                          marginBottom: 2,
                          height: 50,
                          color: '#162447',
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                        height={209}
                      />
                    ),
                  )
                ) : (
                  <>
                    <Text
                      variant="display2"
                      color={colors.neutral}
                      style={{ marginBottom: spacing.spacing5 }}
                    >
                      {t('APPLICATION_DETAIL.OFFER_LETTER')}
                    </Text>
                    <EmptyContainer hasFixedHight />
                  </>
                )}
              </View>
            ) : (
              <EmptyContainer hasFixedHight />
            )}
          </View>
        ) : null}
      </View>

      <View style={{ paddingHorizontal: 20 }}>
        {activeTab === 3 ? (
          <View
            style={{
              backgroundColor: colors.backgroundPrimary,
              borderRadius: 16,
              paddingVertical: 30,
              paddingHorizontal: 40,
            }}
          >
            {data?.commentsObj?.length > 0 ? (
              <Accordion
                data={comments}
                noOfRecentComments={countRecentComments(data?.commentsObj)}
                noOfReadComments={countReadComments(data?.commentsObj)}
                childRef={childRef}
                handleUpdateComment={handleUpdateComment}
                isActiveComment={isActiveComment}
              />
            ) : (
              <EmptyContainer hasFixedHight />
            )}
          </View>
        ) : null}
      </View>
    </View>
  )
}

const Tab = ({
  tabData,
  activeTab,
  setActiveTab,
  marginTop = 10,
  disabledFieldIndex,
  messageCounts,
  conditionsCount,
  labelStyle,
  style,
}) => {
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  return (
    <View style={{ marginTop, padding: 5 }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {tabData?.map((item, index) => (
          <TouchableOpacity
            style={[
              activeTab === index ? styles.activeTab : styles.unActiveTab,
              {
                paddingBottom: 10,
                alignItems: 'center',
                marginLeft: width >= 1140 ? '4%' : '2%',
              },
              style,
            ]}
            disabled={disabledFieldIndex === index}
            onPress={() => index !== disabledFieldIndex && setActiveTab(index)}
            key={index}
          >
            <View>
              <Icon
                name={item.iconName}
                color={
                  activeTab === index ? colors.primary : colors.textSecondary
                }
              />
            </View>
            <Text
              variant="display3"
              color={activeTab === index ? colors.primary : colors.textPrimary}
              style={[
                {
                  fontWeight: activeTab === index ? 700 : 400,
                  marginLeft: 5,
                  borderBottomWidth: 1,
                  borderColor:
                    activeTab === index ? colors.primary : 'transparent',
                },
                labelStyle,
              ]}
            >
              {item.displayText}
            </Text>
            {item.displayText === 'Offers' && conditionsCount && (
              <View
                style={{
                  backgroundColor: 'red',
                  marginLeft: 6,
                  width: 15,
                  height: 15,
                  borderRadius: 15 / 2,
                  alignItems: 'center',
                }}
              >
                <Text
                  variant="display6"
                  style={{ color: 'white', fontSize: 12 }}
                >
                  {' • '}
                </Text>
              </View>
            )}
            {messageCounts[index] > 0 && (
              <View
                style={{
                  backgroundColor: 'red',
                  marginLeft: 6,
                  width: 15,
                  height: 15,
                  borderRadius: 15 / 2,
                  alignItems: 'center',
                }}
              >
                <Text
                  variant="display6"
                  style={{ color: 'white', fontSize: 12 }}
                >
                  {messageCounts[index]}
                </Text>
              </View>
            )}
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

// const DocumentList = ({ label, data, index }) => {
//   return (
//     <View style={{ paddingTop: data?.length > 0 ? 30 : 0 }}>
//       <View style={[styles.divider]} />
//       <View
//         style={{
//           flexDirection: 'row',
//           flexWrap: 'wrap',
//         }}
//       >
//         {sort(data, 'CreatedDate')?.map((item) => {
//           const documentData = item?.CreatedDate?.split('T')[0]
//           const finalDocumentDate = documentData?.split('-').reverse().join('/')
//           return (
//             <DocumentAccordion
//               label={label}
//               data={data}
//               finalDocumentDate={finalDocumentDate}
//               index={index}
//             />
//           )
//         })}
//       </View>
//     </View>
//   )
// }

const DocumentAccordion = ({
  label,
  data,
  index,
  activeTab,
  style,
  showDate,
  height,
}) => {
  const { colors } = useTheme()
  const [isExpanded, setIsExpanded] = useState(
    activeTab === 1 ? (index === 0 ? true : false) : true,
  )
  const { t } = useTranslation()

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <View style={{ width: '100%' }}>
      <View style={styles.divider} />
      {activeTab === 1 ? (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            backgroundColor: colors.accent,
            borderRadius: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 30,
          }}
          onPress={toggleAccordion}
        >
          <Text
            variant="display2"
            color={colors.textPrimary}
            style={{ marginRight: 10, fontWeight: '700' }}
          >
            {label}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 20,
              alignItems: 'center',
            }}
          >
            <Text style={{ color: colors.textPrimary }}>
              {data.length !== 1
                ? `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENTS')})`
                : `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENT')})`}
            </Text>

            <Icon
              name={isExpanded ? 'ArrowUp' : 'ArrowDown'}
              color={'black'}
              width={24}
              height={24}
            />
          </View>
        </TouchableOpacity>
      ) : (
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: colors.accent,
            borderRadius: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 30,
          }}
        >
          <Text
            color={colors.textPrimary}
            style={{ marginRight: 10, fontWeight: 700, fontSize: 16 }}
          >
            {label.replaceAll('-', ' ')}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 20,
              alignItems: 'center',
            }}
          >
            <Text style={{ color: colors.textPrimary }}>
              {data.length !== 1
                ? `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENTS')})`
                : `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENT')})`}
            </Text>
          </View>
        </View>
      )}
      {isExpanded && (
        <View
          style={{
            marginVertical: 20,
            flexDirection: 'row',
            gap: 20,
            flexWrap: 'wrap',
          }}
        >
          {sort(data, 'CreatedDate')?.map((item) => {
            const documentData = item?.CreatedDate?.split('T')[0]
            const finalDocumentDate = documentData
              ?.split('-')
              .reverse()
              .join('/')
            return (
              <DocumentItem
                index={index}
                item={item}
                finalDocumentDate={finalDocumentDate}
                onPress={async () => {
                  const resp = await signedurl(item)
                  if (resp.FilePath) {
                    await handleNavigation({
                      url: resp.FilePath,
                      fileName: item?.Name,
                      translation: t,
                    })
                  }
                }}
                style={style}
                showDate={showDate}
                height={height}
              />
            )
          })}
        </View>
      )}
    </View>
  )
}

const AdmissionComponent = ({ data }) => {
  const { colors } = useTheme()
  const [showFullCondition, setShowFullCondition] = useState(false)

  const toggleCondition = () => {
    setShowFullCondition(!showFullCondition)
  }

  const renderConditionText = () => {
    const condition = data.Admissions_Condition__c
    if (showFullCondition) {
      return condition
    } else {
      return condition.length > 70 ? condition.slice(0, 95) + '...' : condition
    }
  }

  return (
    <ScrollView
      style={{ paddingBottom: 20 }}
      showsVerticalScrollIndicator={false}
    >
      <Text
        color={colors.textPrimary}
        style={{ fontWeight: 700, fontSize: 16 }}
      >
        Condition
      </Text>
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: showFullCondition ? 'flex-start' : 'center',
            paddingHorizontal: 20,
            paddingTop: 10,
            columnGap: 10,
          }}
        >
          {' '}
          <Text style={{ color: colors.textPrimary, fontWeight: 700 }}>
            &#8226;
          </Text>
          <Text
            numberOfLines={showFullCondition ? undefined : 1}
            style={{ fontSize: 14, lineHeight: 20 }}
          >
            {renderConditionText()}
          </Text>
        </View>

        <View>
          {!showFullCondition && data?.Admissions_Condition__c.length > 70 && (
            <TouchableOpacity
              onPress={toggleCondition}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#F5F5F5',
                paddingHorizontal: 38,
                paddingVertical: 3,
              }}
            >
              <Text
                style={{
                  color: colors.draftBlue,
                  paddingRight: 4,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                See more
              </Text>
            </TouchableOpacity>
          )}
          {showFullCondition && (
            <TouchableOpacity
              onPress={toggleCondition}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#F5F5F5',
                paddingHorizontal: 38,
                paddingVertical: 6,
              }}
            >
              <Text
                style={{
                  color: colors.draftBlue,
                  paddingRight: 4,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                See less
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  mainContainer: (tablet, width) => ({
    paddingVertical: 20,
    flexDirection: 'row',
    width: '100%',
    columnGap: 20,
  }),

  activeTab: {
    flexDirection: 'row',
  },
  unActiveTab: {
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  divider: {
    borderWidth: 0.2,
    borderColor: '#000000',
    borderStyle: 'solid',
    opacity: 0.2,
  },
  attachDocument: {
    flexDirection: 'row',
    padding: 25,
    alignItems: 'center',
    columnGap: 3,
    borderRadius: 16,
  },
  rightContainer: (width) => ({
    padding: 30,
    borderRadius: 13,
    background: 'transparent',
    borderWidth: 0.2,
    borderColor: '#000000',
    minWidth: width >= 1260 ? 400 : 300,
  }),
  rightSideTextContainer: {
    paddingBottom: 10,
    marginTop: 20,
    fontSize: 15,
    lineHeight: 21,
  },
  notes: {
    marginHorizontal: 20,
    marginBottom: 10,
    marginTop: 30,
    backgroundColor: '#FFF4E0',
    borderColor: '#D4691B',
    borderRadius: 5,
    borderWidth: 0.2,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  activeTabConatiner: {
    paddingHorizontal: 20,
    marginHorizontal: 20,
    borderRadius: 16,
    marginTop: 5,
    justifyContent: 'space-between',
  },
})

export default DesktopView
