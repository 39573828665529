export const addScript = (src, callback) => {
  const script = document.createElement('script')
  script.src = src
  script.async = true
  script.setAttribute('chat', 'true')
  script.setAttribute('widgetId', '48b47732-6c60-4434-a32e-9fd25b19e598')
  script.onload = callback
  document.body.appendChild(script)
}

export const initializeChatbotUser = (userProfile) => {
  const { firstName, lastName, email, externalId, properties } = userProfile

  const checkWidget = () => {
    if (window.fcWidget) {
      window.fcWidget.setExternalId(externalId)
      window.fcWidget.user.setFirstName(firstName)
      window.fcWidget.user.setLastName(lastName)
      window.fcWidget.user.setEmail(email)
      window.fcWidget.user.setProperties(properties)
      if (userProfile?.phoneNumber)
        window.fcWidget.user.setPhone(userProfile?.phoneNumber)
      if (userProfile?.appherotoken) {
        window.fwcrm.on('widget:loaded', () => {
          window.fcWidget.conversation.setConversationProperties({
            cf_appherotoken2: userProfile?.appherotoken,
          })
        })
        window.fcWidget.conversation.setBotVariables({
          appherotoken: userProfile?.appherotoken,
        })
      }
      console.log('User data set for Freshchat')
    } else {
      console.log('Waiting for fcWidget to load...')
      setTimeout(checkWidget, 1000) // Check again after 1000ms
    }
  }
  checkWidget()
}

export const userChatBotDestroy = async () => {
  if (window.fcWidget.isInitialized()) {
    window.fcWidget?.user?.isExists().then(
      (data) => {
        if (data.data && data.success) {
          console.log('User is available. Clearing the user first.')
          window.fcWidget.user.clear()
        } else {
          console.log('User is not available, destroying widget directly.')
          window.fcWidget.destroy()
        }
      },
      (error) => {
        console.log('Error fetching user', error)
      },
    )

    // Bind event listener to handle the user cleared event
    window.fcWidget.on('user:cleared', () => {
      console.log('User cleared, now destroying widget.')
      window.fcWidget.destroy()
    })

    // Bind event listener to handle the widget destroyed event
    window.fcWidget.on('widget:destroyed', () => {
      console.log('Widget destroyed')
      // Navigate to home page or any other logic after destroying the widget
      // navigation.navigate('Home'); // Replace 'Home' with your home route name
    })
  }
}
