import React, {
  useCallback,
  Suspense,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { DateFormat, ScreenLayout, SecureStore } from '@libs/utils'
import { Text } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import {
  JpegImage,
  JpgImage,
  MiscImage,
  PdfImage,
  PdfLogo,
  PhotoId,
  PngImage,
} from '@apphero/assets'
import { useTranslation } from 'react-i18next'
import {
  getDocumentType,
  getDocumentsByOpportunityId,
  getOpportunitiesById,
  updateReadState,
} from '../../api'
import { useUploadDocument } from '../../hooks/useUploadFile'
import { getRecentData, handleNavigation } from '../../utils/function'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const ViewApplicationDetails = (props) => {
  const isFocused = useIsFocused()
  const opportunityId = props?.route?.params?.id
  const [isFileUpload, setIsFileUpload] = useState()
  const dropDownRef = useRef()
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [informationList, setInformationList] = useState([])
  const [applicationDocuments, setApplicationDocuments] = useState()
  const [downloadApplicationData, setDownloadApplicationData] = useState()
  const [applicationLetterDocuments, setApplicationLetterDocuments] = useState()
  const { t, i18n } = useTranslation()
  const scrollViewRef = useRef(null)
  const childRef = useRef(null)
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const tabIsActive = props?.route?.params?.isActive
  const isActiveComment = props?.route?.params?.commentId

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const infoTabData = (finalData) => {
    const duration =
      finalData?.OpportunityLineItems?.records[0]?.Product2?.Duration__c

    if (!finalData?.isDirectSales) {
      return [
        {
          fieldName: finalData?.OpportunityLineItems?.records[0]?.Intake_Date__c
            ? DateFormat(
                finalData?.OpportunityLineItems?.records[0]?.Intake_Date__c.split(
                  'T',
                )[0],
              )
            : '-',
          displayName: t('APPLICATION_DETAIL.INTAKE'),
        },
        {
          fieldName: duration || '-',
          displayName: t('APPLICATION_DETAIL.DURATION'),
        },
        {
          fieldName:
            finalData?.OpportunityLineItems?.records[0]?.Location__c || '-',
          displayName: t('APPLICATION_DETAIL.LOCATION'),
        },
        {
          fieldName: DateFormat(finalData?.CreatedDate?.split('T')[0]) || '-',
          displayName: t('APPLICATION_DETAIL.CREATED_DATE'),
        },
        {
          fieldName: finalData?.ApplicationStatus?.status || '-',
          displayName: t('APPLICATION_DETAIL.STATUS'),
        },
        {
          fieldName: finalData?.AgentAccountName__c || '-',
          displayName: t('APPLICATION_DETAIL.AGENT_ACCOUNT'),
        },
        {
          fieldName: finalData?.Agent_Contact__r?.Name || '-',
          displayName: t('APPLICATION_DETAIL.AGENT_CONTACT'),
        },
        {
          fieldName: finalData?.Agent_Contact__r?.Email || '-',
          displayName: t('APPLICATION_DETAIL.AGENT_EMAIL'),
        },
        {
          fieldName: finalData?.Agent_Contact__r?.Phone || '-',
          displayName: t('APPLICATION_DETAIL.AGENT_PHONE'),
        },
      ]
    }
    return [
      {
        fieldName: finalData?.OpportunityLineItems?.records[0]?.Intake_Date__c
          ? DateFormat(
              finalData?.OpportunityLineItems?.records[0]?.Intake_Date__c.split(
                'T',
              )[0],
            )
          : '-',
        displayName: t('APPLICATION_DETAIL.INTAKE'),
      },
      {
        fieldName: duration || '-',
        displayName: t('APPLICATION_DETAIL.DURATION'),
      },
      {
        fieldName:
          finalData?.OpportunityLineItems?.records[0]?.Location__c || '-',
        displayName: t('APPLICATION_DETAIL.LOCATION'),
      },
      {
        fieldName: DateFormat(finalData?.CreatedDate?.split('T')[0]) || '-',
        displayName: t('APPLICATION_DETAIL.CREATED_DATE'),
      },
      {
        fieldName: finalData?.ApplicationStatus?.status || '-',
        displayName: t('APPLICATION_DETAIL.STATUS'),
      },
      {
        fieldName: finalData?.OwnerName__c || '-',
        displayName: t('APPLICATION_DETAIL.ADVISOR'),
      },
      {
        fieldName: finalData?.OwnerEmail__c || '-',
        displayName: t('APPLICATION_DETAIL.ADVISOR_EMAIL'),
      },
      {
        fieldName: finalData?.Owner?.Phone || '-',
        displayName: t('APPLICATION_DETAIL.ADVISOR_PHONE'),
      },
      {
        fieldName: finalData?.Owner?.Appointment_Booking_Link__c,
        displayName: t('APPLICATION_DETAIL.MEETING_LINK'),
        field: 'MeetLink',
      },
    ]
  }

  const fetchOpportunities = async () => {
    let userProfile = await SecureStore.getItemAsync('userProfile')
    userProfile = JSON.parse(userProfile)

    const data = await getOpportunitiesById({
      opportunityId,
      email: userProfile?.email,
    })
    const finalData = data?.response?.[0]

    setInformationList(infoTabData(finalData))

    return finalData
  }

  const admissionsNotesTabData = [
    {
      displayText: t('APPLICATION_DETAIL.COMMENTS'),
    },
    {
      displayText: t('APPLICATION_DETAIL.CONDITIONS'),
    },
  ]

  const { data: opportunities, isFetching: isOpportunitiesFetching } = useQuery(
    {
      queryKey: [`getOpportunitiesById-${opportunityId}`],
      queryFn: fetchOpportunities,
      enabled: isFocused && !!opportunityId,
      initialData: [],
    },
  )

  const { data: documentType } = useQuery({
    queryKey: ['getDocumentsType'],
    queryFn: async () => fetchDocumentTypes(),
    initialData: [],
    enabled: isFocused && !!opportunities.BusinessUnitFilter__c,
  })

  const getFileTypeImage = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return PdfImage
      case 'png':
        return PngImage
      case 'jpg':
        return JpgImage
      case 'jpeg':
        return JpegImage
      default:
        return MiscImage
    }
  }
  const fetchDocumentTypes = async () => {
    const documentType = await getDocumentType({
      brandName: opportunities.BusinessUnitFilter__c,
    })
    const documentList = documentType.records.map((document) => ({
      label: document.DocumentType__c,
    }))
    return documentList
  }

  const fetchOpportunityDocument = async () => {
    const documentsResponse = await getDocumentsByOpportunityId({
      opportunityId: opportunities?.Id,
    })
    const documentsFinalData = documentsResponse?.response

    if (documentsFinalData?.length > 0) {
      const convertedData = {}
      const convertedInfo = {}
      const applicationLetter = []
      const applicationDocument = []
      let hasOffer = false
      documentsFinalData.forEach((item) => {
        const documentType = item.DocumentType__c
        if (!convertedData[documentType] && !item?.AdmissionLetter) {
          convertedData[documentType] = []
        }
        if (!convertedInfo[documentType] && item?.AdmissionLetter) {
          convertedInfo[documentType] = []
        }
        if (documentType === 'Application form') {
          applicationDocument.push(item)
        }
        const documentCopyItem = item
        documentCopyItem.placeholderImage = getFileTypeImage(item?.Name)
        if (item?.AdmissionLetter) {
          if (documentCopyItem?.OfferLetter) {
            hasOffer = true
          }
          convertedInfo[documentType].push(documentCopyItem)
          applicationLetter.push(documentCopyItem)
        } else {
          convertedData[documentType].push(documentCopyItem)
        }
      })

      const uploadDocuments = getRecentData({ itemList: applicationLetter })
      const recentApplicationDocuments = getRecentData({
        itemList: applicationDocument,
      })
      setApplicationLetterDocuments({
        documents: convertedInfo,
        hasOffer,
        uploadDocuments,
      })
      setDownloadApplicationData(recentApplicationDocuments)
      setApplicationDocuments(convertedData)
    }
    return documentsFinalData
  }

  const { isFetching: isDocumentFetching } = useQuery({
    queryKey: [`getDocumentsByOpportunityId-${opportunities?.Id}`],
    queryFn: fetchOpportunityDocument,
    enabled: isFocused && !!opportunities?.Id,
    initialData: [],
  })

  const { mutation } = useUploadDocument()

  const handleFileUpload = async (properties) => {
    setIsFileUpload(true)
    await mutation.mutateAsync({
      opportunities,
      ...properties,
    })
    setIsFileUpload(false)
  }

  const toggleDropdown = () => {
    dropDownRef?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
      setDropdownTop(py + 50)
      setDropdownLeft(_px)
      setDropDownWidth(_w)
    })
  }

  const handleMoreIcon = async ({ selectedItem }) => {
    if (selectedItem?.type === 'DownloadApplication') {
      await handleNavigation({
        url: downloadApplicationData?.FilePath,
        fileName: downloadApplicationData?.Name,
        translation: t,
      })
    }
  }

  const handleScrollComment = () => {
    if (childRef.current) {
      childRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const [tabData, setTabData] = useState([
    {
      displayText: t('APPLICATION_DETAIL.INFORMATION'),
      iconName: 'DocumentText',
      isActive: true,
    },
    {
      displayText: t('APPLICATION_DETAIL.DOCUMENTS_LETTER'),
      iconName: 'DocumentDownload',
      isActive: false,
    },
    {
      displayText: t('APPLICATION_DETAIL.OFFERS'),
      iconName: 'Mail',
      isActive: false,
    },
    {
      displayText: t('APPLICATION_DETAIL.ADMISSIONS_COMMENTS'),
      iconName: 'Mail',
      isActive: false,
    },
  ])

  useEffect(() => {
    setTabData([
      {
        displayText: t('APPLICATION_DETAIL.INFORMATION'),
        iconName: 'DocumentText',
        isActive: tabIsActive ? tabIsActive === 'INFORMATION' : true,
      },
      {
        displayText: t('APPLICATION_DETAIL.DOCUMENTS_LETTER'),
        iconName: 'DocumentDownload',
        isActive: tabIsActive === 'DOCUMENTS_LETTER' || false,
      },
      {
        displayText: t('APPLICATION_DETAIL.OFFERS'),
        iconName: 'Mail',
        isActive: tabIsActive === 'OFFERS' || false,
      },
      {
        displayText: t('APPLICATION_DETAIL.ADMISSIONS_COMMENTS'),
        iconName: 'Mail',
        isActive: tabIsActive === 'ADMISSIONS_COMMENTS' || false,
      },
    ])

    setInformationList(infoTabData(opportunities))
  }, [i18n.language, opportunities, tabIsActive])

  const moreIconDropDownDetails = [
    {
      label: t('DROP_DOWN.DOWNLOAD_APPLICATION'),
      type: 'DownloadApplication',
    },
  ]

  const handleUpdateComment = async (data, queryClient, isMarkasRead) => {
    if (!isMarkasRead) {
      data.ids = []
      if (data?.id) {
        data.isRead = true
        data.ids = [data.id]
        delete data.content, delete data.date, delete data.title, delete data.id
      }
    }

    await updateReadState(data)

    await queryClient.setQueryData(
      [`getOpportunitiesById-${data.whatId}`],
      (prevData) => {
        if (prevData && prevData.commentsObj) {
          return {
            ...prevData,
            commentsObj: updateCommentsObj(
              prevData.commentsObj,
              data.ids.map((id) => ({ id, isRead: true })),
            ),
          }
        }

        return prevData
      },
    )
  }

  const updateCommentsObj = (prevCommentsObj, updatedComments) => {
    return prevCommentsObj.map((comment) => {
      const updatedComment = updatedComments.find((c) => c.id === comment.id)
      if (updatedComment) {
        return {
          ...comment,
          isRead: true,
        }
      }
      return comment
    })
  }

  const viewProps = {
    canShowMenu: !!downloadApplicationData?.FilePath,
    brandLogoUrl,
    admissionsNotesTabData,
    applicationDocuments,
    applicationLetterDocuments,
    documentType,
    dropdownLeft,
    dropDownRef,
    dropdownTop,
    dropdownWidth,
    informationList,
    isDocumentFetching,
    isFileUpload,
    handleUpdateComment,
    isLoading: isOpportunitiesFetching,
    modalVisible,
    moreIconDropDownDetails,
    opportunities,
    tabData,
    setModalVisible,
    setTabData,
    handleFileUpload,
    handleMoreIcon,
    toggleDropdown,
    handleScrollComment,
    scrollViewRef,
    childRef,
    isActiveComment,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ViewApplicationDetails
