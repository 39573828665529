import React, { useCallback, Suspense, useState } from 'react'
import { ScreenLayout } from '@libs/utils'
import { Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import { userProfile } from '../../utils/atom'
import { useAtom } from 'jotai'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getNotificationByEmail, updateNotificationStatus } from '../../graphql'
import NotificationLoader from '@libs/components/src/Loader/NotificationLoader'
import useNotifications from '../../hooks/useNotifications'

const Notification = () => {
  const navigation = useNavigation()
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )
  const [userprofile] = useAtom(userProfile)

  const {
    notifications,
    isLoading,
    isError,
    loadMoreNotifications,
    handleUpdateNotification,
    notificationLoading,
    offset,
    totalNotifications,
    notificationCount,
    handleEventNavigation,
  } = useNotifications()

  if (isLoading) {
    return <NotificationLoader />
  }

  if (isError) {
    return navigation.navigate('my-application')
  }

  const viewProps = {
    navigation,
    inAppNotifications: notifications,
    handleUpdateNotification,
    loadMoreNotifications,
    isLoading,
    handleEventNavigation,
    notificationLoading,
    offset,
    total: totalNotifications,
  }

  return (
    <Suspense fallback={<Text>Loading...</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Notification
