import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Image,
} from 'react-native'
import React from 'react'
import { spacing, useTheme } from '@libs/theme'

import { useTranslation } from 'react-i18next'
import { Button, Loader, Text } from '@libs/components'
import { Icon } from '@app-hero/native-icons'

const DesktopView = (props) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const {
    brandLogoUrl,
    institutionData,
    isOpen,
    isProgramFetchNextPage,
    renderItem,
    onHover,
    program,
    programFetchNextPage,
    programHasNextPage,
    showBrandName,
    setShowBrandName,
    setOnHover,
    activeTab,
    setActiveTab,
    isLoading,
    ListEmptyComponent,
  } = props

  return (
    <View
      style={[styles.container, { shadowOpacity: onHover ? 0.5 : 1 }]}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <View style={styles.header}>
        <View style={styles.headerRight}>
          {!showBrandName ? (
            <Image
              source={`${brandLogoUrl}/${institutionData.BusinessUnitFilter__c?.replaceAll(
                ' ',
                '_',
              )}.png`}
              style={{
                height: 75,
                width: 150,
              }}
              alt={institutionData?.BusinessUnitFilter__c}
              resizeMode="contain"
              onError={(error) => {
                setShowBrandName(true)
              }}
            />
          ) : (
            <Text variant="display2" color={colors.neutral}>
              {institutionData?.BusinessUnitFilter__c}
            </Text>
          )}
          <View style={{ flex: 0.5, marginLeft: 40 }}>
            <Text
              variant="heading4"
              color={activeTab || onHover ? colors.primary : colors.neutral}
            >
              {institutionData?.brandFullName}
            </Text>
            <Text variant="display3" color={colors.text}>
              {`${institutionData?.total} ${t('ACCORDION.PROGRAMMES')}`}
            </Text>
          </View>
        </View>

        <TouchableOpacity
          onPress={() => {
            !isOpen ? setActiveTab(institutionData) : setActiveTab({})
          }}
        >
          <Icon
            name={isOpen ? 'ArrowUp' : 'ArrowDown'}
            width={20}
            height={20}
          />
        </TouchableOpacity>
      </View>
      {isOpen && (
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <View style={styles.body}>
            {isLoading && program?.length === 0 ? (
              <Loader visible={isLoading} />
            ) : null}
            <FlatList
              data={program}
              renderItem={renderItem}
              keyExtractor={(item) => item.Id}
              style={styles.body}
              numColumns={2}
              contentContainerStyle={{ justifyContent: 'center' }}
              ListEmptyComponent={!isLoading ? ListEmptyComponent : null}
            />
          </View>
          {programHasNextPage && (
            <View style={styles.nextPageIndicator}>
              {isLoading && isProgramFetchNextPage ? (
                <Loader visible={isLoading} style={{ paddingVertical: 8 }} />
              ) : (
                <Button
                  label={t('DASHBOARD.SHOW_MORE')}
                  leftIcon={<Icon name="Refresh" />}
                  buttonColor={colors.primaryVariant}
                  labelColors={colors.primary}
                  buttonStyle={{
                    alignSelf: 'center',
                  }}
                  onPress={() => programFetchNextPage()}
                />
              )}
            </View>
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    shadowColor: 'rgba(3, 30, 125, 0.05)',
    shadowOffset: { width: 0, height: 2 },
    elevation: 5,
    shadowOpacity: 1,
    shadowRadius: 10,
    marginBottom: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: spacing.spacing5,
    paddingHorizontal: spacing.spacing6,
    borderRadius: 6,
    height: 78,
    backgroundColor: 'white',
  },
  headerRight: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  body: {
    borderRadius: 6,
    flexWrap: 'wrap',
    flex: 1,
    width: '100%',
    minHeight: 100,
    backfaceVisibility: 'red',
  },
  nextPageIndicator: {
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginBottom: 30,
    marginTop: 10,
  },
})

export default DesktopView
