import { StyleSheet, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Icon } from '@app-hero/native-icons'
import { spacing, useTheme } from '@libs/theme'
import Text from '../Text/Text'

const CheckBox = ({
  field,
  filtersApplied,
  handleCheck = () => {},
  itemIndex,
  label,
  labelColor,
  labelStyle,
  programId,
  renderLabel,
  selectedProgrammes = [],
  style = {},
  value,
  checkboxColor,
}) => {
  const [isChecked, setIsChecked] = useState()
  useEffect(() => {
    if (filtersApplied) {
      const filedName = field?.charAt(0).toUpperCase() + field?.slice(1)
      if (
        (field && filtersApplied?.[filedName]?.values?.includes(value)) ||
        selectedProgrammes?.includes(programId)
      ) {
        setIsChecked(true)
      } else {
        setIsChecked(false)
      }
    } else if (selectedProgrammes?.includes(programId)) {
      setIsChecked(true)
    } else {
      setIsChecked(value)
    }
  }, [filtersApplied, programId, field, selectedProgrammes])

  const { colors } = useTheme()
  return (
    <View style={[styles(colors).checkBoxContainer, style]} key={itemIndex}>
      <TouchableOpacity
        onPress={() => {
          setIsChecked(!isChecked)
          handleCheck(isChecked, field, label, value)
        }}
      >
        <View
          style={[
            styles(colors).checkBox,
            {
              backgroundColor: isChecked
                ? colors.primary
                : checkboxColor || 'transparent',
            },
          ]}
        >
          {isChecked && <Icon name="Check" color={colors.white} />}
        </View>
      </TouchableOpacity>
      {renderLabel ? (
        renderLabel
      ) : (
        <Text
          variant="display4"
          color={labelColor}
          style={[styles(colors).labelStyle, labelStyle]}
        >
          {label}
        </Text>
      )}
    </View>
  )
}

const styles = (props) =>
  StyleSheet.create({
    checkBox: {
      width: 20,
      height: 20,
      backgroundColor: props.white,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: props.fieldBorder,
      borderRadius: 1,
      alignItems: 'center',
      borderRadius: 4,
    },
    checkBoxContainer: {
      flexDirection: 'row',
      paddingVertical: spacing.spacing3,
    },
    labelStyle: {
      marginLeft: spacing.spacing4,
    },
  })
export default CheckBox
