import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, CheckBox, Link, Text } from '@libs/components'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { SecureStore } from '@libs/utils'
import {
  gusUrl,
  ourNetwork,
  privacyPolicyUrl,
  termsAndConditionsUrl,
} from '../../utils/constants'
import { handleNavigation } from '../../utils/function'
import { isWeb } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'

const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [policyConfirmation, setPolicyConfirmation] = useState({
    canApply: false,
    canSendNotification: false,
  })
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const childRef = useRef(null)
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      if (Platform.OS === 'web') {
        window.addEventListener('keypress', (event) => {
          if (event.code === 'Enter') {
            if (
              policyConfirmation.canApply ||
              policyConfirmation.canSendNotification
            ) {
              handleSignUpNavigation()
            }
          }
        })
      }
    })()
  }, [isFocused, policyConfirmation])

  const handleScrollToEnd = () => {
    if (childRef.current) {
      childRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  const handleCheck = (isChecked, field) => {
    handleScrollToEnd()
    setPolicyConfirmation((previousData) => ({
      ...previousData,
      [field]: !isChecked,
    }))
  }

  const handleSignUpNavigation = async () => {
    await SecureStore.setItemAsync(
      'consentAccepted',
      policyConfirmation.canApply && policyConfirmation.canSendNotification,
    )
    navigation.navigate('signUp')
  }

  const findPaddingHorizontal = (pixel) => {
    if (width >= 1536 && width <= 1790) {
      return 80
    } else if (width >= 1791 && width <= 1915) {
      return 160
    } else if (width >= 1916 && width <= 2050) {
      return 220
    } else if (width >= 2051 && width <= 2200) {
      return 360
    }

    if (pixel === 2) {
      return 30
    } else if (pixel.toFixed(1) == 2.2) {
      // return 80
      return 24
    } else if (pixel === 2.5) {
      return '3%'
    } else {
      return 24
    }
  }

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{
        flexDirection: 'column',
        alignItems: isDesktop && 'center',
        justifyContent: 'center',
        marginTop: !isDesktop ? '6vh' : '10vh',
        paddingLeft: isDesktop ? '16%' : '10%',
        paddingRight: isDesktop ? '16%' : '10%',
      }}
      showsVerticalScrollIndicator={false}
      ref={childRef}
    >
      <Text
        color={colors.cardTitleColor}
        variant="display4"
        style={{
          marginTop: window.devicePixelRatio === 2 ? 15 : '2%',
          marginBottom: 22,
          fontSize: 24,
          fontWeight: 700,
          letterSpacing: 0.2,
          textAlign: isDesktop ? 'center' : 'left',
        }}
      >
        {t('AUTH.GET_STARTED')}
      </Text>
      <View>
        <Text
          variant="display4"
          color={colors.cardTitleColor}
          style={{
            fontWeight: 700,
            marginTop: 10,
            marginBottom: 4,
            lineHeight: 22.3,
          }}
        >
          {t('AUTH.PRIVACY_POLICY')}
        </Text>
        <Text variant="display4" color={colors.authPrimary} style={{}}>
          {t('PRIVACY_POLICY.FIRST_PARAGRAPH_1')}{' '}
          <LinkText
            text={t('PRIVACY_POLICY.FIRST_PARAGRAPH_LINK_TEXT')}
            onClick={() =>
              handleNavigation({ url: gusUrl, canValidate: false })
            }
          />
          {t('PRIVACY_POLICY.FIRST_PARAGRAPH_2')}
        </Text>
        <Text
          variant="display4"
          color={colors.authPrimary}
          style={{ marginBottom: 25 }}
        >
          {t('PRIVACY_POLICY.SECOND_PARAGRAPH_1')}{' '}
          <LinkText
            text={t('PRIVACY_POLICY.SECOND_PARAGRAPH_LINK_TEXT')}
            onClick={() =>
              handleNavigation({ url: privacyPolicyUrl, canValidate: false })
            }
          />
          {t('PRIVACY_POLICY.SECOND_PARAGRAPH_2')}
        </Text>
      </View>
      <View style={{ marginLeft: -12 }}>
        <CheckBox
          renderLabel={
            <Text
              variant="display4"
              color={colors.authPrimary}
              style={{ marginLeft: 12, maxWidth: 500 }}
            >
              {t('PRIVACY_POLICY.CHECK_BOX_1_TEXT')}{' '}
              <LinkText
                text={t('PRIVACY_POLICY.CHECK_BOX_1_LINK_TEXT')}
                onClick={() =>
                  handleNavigation({
                    url: termsAndConditionsUrl,
                    canValidate: false,
                  })
                }
              />
              .
            </Text>
          }
          value={policyConfirmation.canApply}
          handleCheck={handleCheck}
          isActive={policyConfirmation.canApply}
          field="canApply"
          style={{ paddingHorizontal: 12 }}
        />
        <CheckBox
          renderLabel={
            <Text
              variant="display4"
              color={colors.authPrimary}
              style={{ marginLeft: 12, maxWidth: 480 }}
            >
              {t('PRIVACY_POLICY.CHECK_BOX_2_TEXT')}{' '}
              <LinkText
                text={t('PRIVACY_POLICY.CHECK_BOX_2_LINK_TEXT')}
                onClick={() =>
                  handleNavigation({ url: ourNetwork, canValidate: false })
                }
              />
              .
            </Text>
          }
          value={policyConfirmation.canSendNotification}
          handleCheck={handleCheck}
          isActive={policyConfirmation.canSendNotification}
          field="canSendNotification"
          style={{ paddingHorizontal: 12 }}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'center',
          marginVertical: 20,
          width: '100%',
        }}
      >
        <Button
          label={t('AUTH.REGISTER')}
          buttonColor={colors.primary}
          labelColors={colors.white}
          buttonStyle={{ width: '100%', borderRadius: 12, height: 56 }}
          labelStyle={{}}
          onPress={() => handleSignUpNavigation()}
          disable={
            !policyConfirmation.canApply ||
            !policyConfirmation.canSendNotification
          }
        />
      </View>

      <View style={styles.footer}>
        <Text variant="display4">{t('LINK.HAVE_ACCOUNT')} </Text>
        <Link
          label={t('BUTTON.SIGNIN')}
          labelVariant="display4"
          labelColor={colors.primary}
          handlePress={() => navigation.navigate('login')}
          labelStyle={{ fontWeight: 700 }}
        />
      </View>
    </ScrollView>
  )
}

const LinkText = ({ text = '', onClick = () => {} }) => {
  const { colors } = useTheme()
  return (
    <TouchableOpacity onPress={() => onClick()}>
      <Text
        variant="display4"
        color={colors.primary}
        style={{ textDecoration: 'underline' }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}

export default PrivacyPolicy

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
