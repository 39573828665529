export const GET_NOTIFICATIONS_QUERY = `
query getInAppNotificationByEmail($email: String!, $filters: NotificationFilterInput, $limit: Int, $offset: Int) {
  getInAppNotificationByEmail(email: $email, filters: $filters, limit: $limit, offset: $offset) {
    total
    unreadCount
    items {
      createdAt
      email
      event
      message
      messageDetails {
        messageId
        opportunityId
        taskId
      }
      readStatus
      type
      updatedAt
    }
  }
}
`

export const UPDATE_NOTIFICATION_STATUS = `
mutation UpdateNotificationStatus($input: InAppNotificationReadStatusUpdateInput!) {
  updateNotificationStatus(input: $input) {
    messageId
    readStatus
    email
    createdAt
  }
}
`

export const SUBSCRIPTION_QUERY = `
subscription OnNewNotification($email: String) {
    onNewNotification(email: $email) {
    createdAt
    email
    event
    message
    readStatus
    updatedAt
    type
    messageDetails {
      messageId
      opportunityId
      taskId
    }
  }
}
`
