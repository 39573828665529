import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  useWindowDimensions,
  ImageBackground,
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import { BannerBG1, BannerBG2, BannerBG3, WelcomeHome } from '@apphero/assets'
import { Image } from 'react-native-web'
import { isWeb } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'
import { useAtom } from 'jotai'
import { getRandomImage } from '@libs/utils'
import { brandDetailsAtom } from '../../utils/atom'

const WelcomeCard = ({ name }) => {
  const isDesktop = isWeb(useWindowDimensions().width)
  const [brandDetails] = useAtom(brandDetailsAtom)
  const { colors } = useTheme()
  const slideImages = [BannerBG1, BannerBG2, BannerBG3]

  if (brandDetails?.brand) {
    return (
      <View
        style={[
          styles.mainContainer,
          {
            paddingVertical: isDesktop ? 20 : 30,
            backgroundColor: colors.backgroundNeutral,
            flex: isDesktop ? 1 : '',
            flexDirection: isDesktop ? 'row' : '',
          },
        ]}
      >
        <View
          style={[
            styles.contentContainer,
            { flexDirection: isDesktop ? 'row' : 'column', width: '90%' },
          ]}
        >
          <View style={!isDesktop && { width: '100%' }}>
            <Text style={styles.userContainer}>Welcome,</Text>
            <Text style={[styles.userContainer, { fontWeight: 700 }]}>
              {name ? `${name}!` : ''}
            </Text>
          </View>
          <Text
            style={{
              color: 'white',
              fontSize: '16px',
              lineHeight: '24px',
              flexWrap: 'wrap',
              width: isDesktop ? '55%' : '100%',
              paddingTop: isDesktop ? '' : 12,
            }}
          >
            Step into a world of opportunities and elevate your career with the
            top programmes offered by{' '}
            <Text style={{ color: colors.brandPrimary, fontWeight: 700 }}>
              {brandDetails?.brandName}!
            </Text>
          </Text>
        </View>

        {isDesktop && (
          <ImageBackground
            style={{
              height: '100%',
              width: '20%',
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 50,
            }}
            source={getRandomImage(slideImages)}
            imageStyle={{
              flex: 1,
              borderRadius: 20,
              width: '100%',
            }}
          >
            <LinearGradient
              colors={[colors.backgroundNeutral, 'transparent']}
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                right: 0,
                bottom: 0,
                zIndex: 60,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              locations={[0.2, 0.5]}
            />
          </ImageBackground>
        )}
      </View>
    )
  }

  return (
    <LinearGradient
      colors={[colors.draftBlue, '#162447']}
      style={[styles.mainContainer, { paddingVertical: isDesktop ? 20 : 30 }]}
      start={{ x: 0, y: 1 }}
      end={{ x: 0, y: 1 }}
    >
      <View
        style={[
          styles.contentContainer,
          { flexDirection: isDesktop ? 'row' : 'column' },
        ]}
      >
        <View style={!isDesktop && { width: '100%' }}>
          <Text style={styles.userContainer}>Welcome,</Text>
          <Text style={[styles.userContainer, { fontWeight: 700 }]}>
            {name ? `${name}!` : ''}
          </Text>
        </View>
        <Text
          style={{
            color: 'white',
            fontSize: '16px',
            lineHeight: '24px',
            flexWrap: 'wrap',
            width: isDesktop ? '55%' : '101%',
            paddingTop: isDesktop ? '' : 12,
          }}
        >
          Step into a world of opportunities and elevate your career with the
          top programmes offered by AppHero!
        </Text>
      </View>

      <Image
        source={WelcomeHome}
        style={{
          width: 150,
          height: 100,
          position: 'absolute',
          right: isDesktop ? 80 : -30,
          bottom: isDesktop ? -10 : '',
          top: isDesktop ? '' : -25,
        }}
      />
    </LinearGradient>
  )
}
export default WelcomeCard

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 16,
    position: 'relative',
    paddingVertical: 20,
  },
  userContainer: {
    color: 'white',
    fontSize: 28,
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingHorizontal: 30,
    gap: '2%',
  },
})
