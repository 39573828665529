export const removeScript = (scriptToRemove) => {
  const allScripts = document.getElementsByTagName('script')
  for (let i = allScripts.length - 1; i >= 0; i--) {
    if (
      allScripts[i] &&
      allScripts[i].getAttribute('src') !== null &&
      allScripts[i].getAttribute('src').indexOf(`${scriptToRemove}`) !== -1
    ) {
      allScripts[i].parentNode.removeChild(allScripts[i])
    }
  }
}
