import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { styles } from './styles'
import Text from '../Text/Text'
import { Image } from 'react-native'
import { LinkedInLogo } from '@apphero/assets'
import { useTheme } from '@libs/theme'

const Button = (props) => {
  const { colors } = useTheme()
  const [onHover, setOnHover] = useState(false)

  const {
    appearance = 'filled',
    buttonStyle,
    buttonColor = colors.primaryContainer,
    disable,
    leftIcon,
    labelVariant = 'display3',
    rightIcon,
    label,
    labelStyle,
    labelColors = colors.onNeutral,
    onPress = () => {},
    hoverColor,
    imageIcon,
  } = props

  return (
    <TouchableOpacity
      onPress={() => onPress()}
      style={StyleSheet.flatten([
        styles.buttonContainer,
        { backgroundColor: onHover && hoverColor ? hoverColor : buttonColor },
        getButtonStyle(appearance, buttonColor, disable),
        buttonStyle,
      ])}
      disabled={disable}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      {leftIcon}
      <View style={{ flex: 1, alignItems: 'center' }}>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          {imageIcon}
          <Text
            variant={labelVariant}
            style={[
              styles.label,
              {
                color: onHover && hoverColor ? colors.white : labelColors,
                marginLeft: leftIcon ? 10 : 0,
                marginRight: rightIcon ? 10 : 0,
              },
              labelStyle,
            ]}
          >
            {label}
          </Text>
        </View>
      </View>

      {rightIcon}
    </TouchableOpacity>
  )
}

const getButtonStyle = (appearance, buttonVariant, disable) => {
  const buttonStyle = []

  if (appearance === 'outline') {
    buttonStyle.push({
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: buttonVariant,
    })
  }

  if (disable) {
    buttonStyle.push({
      opacity: 0.5,
      activeOpacity: 1,
    })
  }

  return buttonStyle
}

export default Button
