import {
  LayoutAnimation,
  TouchableOpacity,
  View,
  StyleSheet,
  ScrollView,
} from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { Text } from '@libs/components'
import { updateReadState } from '../../api'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { DateforComments } from '@libs/utils'
import { sort } from '../../utils/sorting'
import { useTheme } from '@libs/theme'

const AccordionItem = ({
  children,
  title,
  date,
  item,
  recentComment,
  queryClient,
  handleUpdateComment,
}) => {
  const [showFullCondition, setShowFullCondition] = useState(true)
  const { colors } = useTheme()

  const toggleCondition = () => {
    setShowFullCondition(!showFullCondition)
  }

  useEffect(() => {
    setShowFullCondition(!recentComment)
  }, [recentComment])

  const renderConditionText = () => {
    const condition = children

    if (recentComment && showFullCondition) {
      return !showFullCondition
        ? React.Children.toArray(children)[0].props.children?.length > 0
          ? React.Children.toArray(children)[0]
              .props.children?.split('\n')[0]
              .slice(0, 70) + '...'
          : children
        : children
    }

    if (showFullCondition) {
      return condition
    } else {
      return React.Children.toArray(children)[0].props.children?.length > 30
        ? React.Children.toArray(children)[0]
            .props.children?.split('\n')[0]
            .slice(0, 50) + '...'
        : children
    }
  }

  return (
    <View style={[styles.accordContainer]}>
      {title && (
        <View style={styles.accordHeader}>
          <Text
            style={
              recentComment
                ? { fontSize: 14, fontWeight: 700 }
                : { fontSize: 14, fontWeight: 400 }
            }
          >
            {date ? DateforComments(date) : ''}
            {' - '} Admissions Team
          </Text>
          {recentComment ? (
            <Text
              style={{
                color: colors.onAlert,
                fontWeight: 900,
              }}
            >
              &#8226;
            </Text>
          ) : null}
        </View>
      )}
      <Text
        style={[
          styles.contentStyle,
          recentComment
            ? { fontSize: 14, fontWeight: 600 }
            : { fontSize: 14, fontWeight: 400 },
        ]}
        numberOfLines={(!showFullCondition || recentComment) && 1}
      >
        {renderConditionText()}
      </Text>
      <View>
        {!recentComment && showFullCondition ? (
          <TouchableOpacity
            onPress={toggleCondition}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 6,
            }}
          >
            <Text
              style={{
                color: colors.draftBlue,
                paddingRight: 4,
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              See less
            </Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={
              recentComment
                ? () => {
                    setShowFullCondition(true)
                    updateComment(
                      item,
                      queryClient,
                      handleUpdateComment,
                      false,
                      setShowFullCondition,
                    )
                  }
                : toggleCondition
            }
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 3,
            }}
          >
            <Text
              style={{
                color: colors.draftBlue,
                paddingRight: 4,
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              See more
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

async function updateComment(
  item,
  queryClient,
  handleUpdateComment,
  isMarkasRead,
  setShowFullCondition,
) {
  setShowFullCondition(false)
  await handleUpdateComment(item, queryClient, isMarkasRead)
}

const Accordion = ({ data, handleUpdateComment, isActiveComment }) => {
  const queryClient = useQueryClient()

  const { colors } = useTheme()

  const scrollViewRef = useRef(null)
  const activeCommentRef = useRef(null)

  useEffect(() => {
    let hasScrolled = false // Flag to track whether scroll operation has been performed
    if (
      isActiveComment &&
      scrollViewRef.current &&
      activeCommentRef.current &&
      !hasScrolled
    ) {
      activeCommentRef.current.measureLayout(
        scrollViewRef.current,
        (x, y) => {
          scrollViewRef.current.scrollTo({ y, animated: true })
          hasScrolled = true // Update flag after scroll operation
        },
        (error) => console.error(error),
      )
    }
  }, [isActiveComment])

  return (
    <ScrollView
      ref={scrollViewRef}
      contentContainerStyle={{ maxHeight: '100vh' }}
    >
      {sort(data, 'date').map((item, index) => (
        <View
          key={index}
          style={[
            styles.accordionItem(colors),
            !item.isRead && styles.accordionReadItem,
          ]}
          ref={item?.id === isActiveComment ? activeCommentRef : null}
        >
          <AccordionItem
            title={item?.title}
            date={item?.date}
            recentComment={!item.isRead}
            handleUpdateComment={handleUpdateComment}
            queryClient={queryClient}
            item={item}
            style={{ color: '#162447', fontSize: 14, fontWeight: 600 }}
          >
            {item.content}
          </AccordionItem>
        </View>
      ))}
    </ScrollView>
  )
}
const styles = StyleSheet.create({
  accordContainer: {
    backgroundColor: 'transparent',
    borderRadius: 12,
    width: '100%',
  },

  accordHeader: {
    flex: 1,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  accordTitle: {
    fontSize: 14,
    letterSpacing: '0.5%',
  },
  accordBody: {
    maxWidth: '100%',
  },
  accordionItem: (colors) => ({
    borderWidth: 0.4,
    borderRadius: 16,
    marginTop: 20,
    padding: 20,
    borderColor: colors.borderPrimary,
  }),
  accordionReadItem: {
    borderRadius: 16,
    marginTop: 20,
    padding: 20,
    backgroundColor: 'white',
  },
  contentStyle: {
    paddingTop: 16,
    paddingBottom: 10,
  },
})

export default Accordion
