/* eslint-disable no-nested-ternary */
import { View, StyleSheet, ScrollView, useWindowDimensions } from 'react-native'
import React from 'react'
import { ShimmerPlaceholder, Text } from '@libs/components'

import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { isTablet } from '@libs/utils/src/screenLayout'
import { Filter, OfferCard, OpportunitiesCard } from '../../components'
import EmptyContainer from '../../components/container/EmptyContainer'
import { sort } from '../../utils/sorting'
import WelcomeCard from '../../components/welcome/welcome'

const DesktopView = ({
  activeTab,
  data,
  brandLogoUrl,
  filterData,
  isLoading,
  setActiveTab,
  userName,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const tablet = isTablet(width)

  return (
    <ScrollView style={{ backgroundColor: colors.backgroundPrimary }}>
      <View style={styles.welcomeCard(colors, width)}>
        {isLoading ? (
          <ShimmerPlaceholder
            style={{
              width: '100%',
              height: 110,
              borderRadius: spacing.spacing5,
            }}
          />
        ) : (
          <WelcomeCard name={userName} />
        )}
      </View>
      <View
        style={[
          styles.mainContainer(width),
          {
            justifyContent: isLoading ? 'center' : '',
            backgroundColor: colors.backgroundPrimary,
            minHeight: '73vh',
          },
        ]}
      >
        <>
          <Filter
            filterHeading={t('HEADER.MY_APPLICATION')}
            filterData={filterData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <View style={styles.cardContainer}>
            <ScrollView
              style={{
                flex: 1,
                paddingVertical: 40,
                paddingHorizontal: 48,
              }}
              contentContainerStyle={{ flexGrow: 1 }}
              showsVerticalScrollIndicator={false}
            >
              {/* {offeredCards?.length > 0 &&
            filterData[activeTab]?.fieldName === 'Offer' ? (
              <OfferCard data={offeredCards} />
            ) : null} */}
              {filterData[activeTab]?.fieldName === 'AgentDraft' ? (
                <View
                  style={[
                    styles.agentDraft,
                    { backgroundColor: colors.textPrimaryVariant },
                  ]}
                >
                  <Icon name="FeaturedIcon" height={34} width={34} />
                  <View
                    style={{ marginLeft: 15, flex: 'wrap', maxWidth: '90%' }}
                  >
                    <Text variant="display4" color={colors.onNeutral}>
                      {t('MY_APPLICATION.AGENT_DRAFT_ALERT')}
                    </Text>
                  </View>
                </View>
              ) : null}
              {isLoading ? (
                <SkeletonLoaderGrid />
              ) : data[filterData[activeTab]?.fieldName]?.length > 0 ? (
                tablet ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 24,
                      paddingLeft: width < 1070 ? 0 : 10,
                    }}
                  >
                    {sort(
                      data[filterData[activeTab].fieldName],
                      'CreatedDate',
                    )?.map((item) => (
                      <OpportunitiesCard
                        data={item}
                        brandLogo={`${brandLogoUrl}/${item.BusinessUnitFilter__c?.replaceAll(
                          ' ',
                          '_',
                        )}.png`}
                        disable={
                          filterData[activeTab]?.fieldName === 'AgentDraft'
                        }
                        style={{
                          width: width < 1070 ? '47%' : '48%',
                          backgroundColor: '#F4F5FB',
                        }}
                        width={150}
                        height={75}
                      />
                    ))}
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 24,
                      paddingLeft: width < 1070 ? 0 : 10,
                    }}
                  >
                    {sort(
                      data[filterData[activeTab].fieldName],
                      'CreatedDate',
                    )?.map((item) => (
                      <OpportunitiesCard
                        data={item}
                        brandLogo={`${brandLogoUrl}/${item.BusinessUnitFilter__c?.replaceAll(
                          ' ',
                          '_',
                        )}.png`}
                        disable={
                          filterData[activeTab]?.fieldName === 'AgentDraft'
                        }
                        style={{
                          width: width < 1070 ? '47%' : '48%',
                          backgroundColor: '#F4F5FB',
                        }}
                        width={150}
                        height={75}
                      />
                    ))}
                  </View>
                )
              ) : (
                <EmptyContainer />
              )}
            </ScrollView>
          </View>
        </>
      </View>
    </ScrollView>
  )
}

const SkeletonLoaderGrid = () => (
  <View style={styles.loaderGrid}>
    {[1, 2, 3, 4].map(() => (
      <ShimmerPlaceholder
        style={{
          width: '48%',
          height: 420,
          borderRadius: spacing.spacing5,
        }}
      />
    ))}
  </View>
)

const findPaddingHorizontal = (width) => {
  let paddingHorizontal
  if (width >= 1024 && width <= 1240) {
    paddingHorizontal = 30
  } else if (width >= 1241 && width <= 1535) {
    paddingHorizontal = 90
  } else if (width >= 1536 && width <= 1790) {
    paddingHorizontal = 220
  } else if (width >= 1791 && width <= 1915) {
    paddingHorizontal = 280
  } else if (width >= 1916 && width <= 2050) {
    paddingHorizontal = 360
  } else if (width >= 2051 && width <= 2200) {
    paddingHorizontal = 430
  } else {
    paddingHorizontal = 445
  }
  return paddingHorizontal
}

const styles = StyleSheet.create({
  mainContainer: (width) => ({
    flex: 1,
    paddingVertical: 30,
    paddingHorizontal: findPaddingHorizontal(width),
    flexDirection: 'row',
    backgroundColor: '#fff',
    columnGap: 51,
  }),
  loaderGrid: {
    flexDirection: 'row',
    marginTop: 10,
    flexWrap: 'wrap',
    gap: 20,
  },
  cardContainer: {
    flex: 1,
    borderRadius: 16,
    backgroundColor: 'white',
    marginBottom: 20,
  },
  agentDraft: {
    padding: 10,
    flexDirection: 'row',
    flex: 'wrap',
    borderRadius: 15,
    marginBottom: 20,
  },
  welcomeCard: (colors, width) => ({
    paddingHorizontal: findPaddingHorizontal(width),
    paddingTop: 30,
    overflow: 'visible',
    zIndex: 1,
    backgroundColor: colors.backgroundPrimary,
  }),
})

export default DesktopView
