import React, { useEffect, useRef, useState } from 'react'
import {
  Animated,
  FlatList,
  Image,
  Modal,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  useWindowDimensions,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { Icon } from '@app-hero/native-icons'
import Text from '../Text/Text'
import { styles } from './styles'
import CheckBox from '../CheckBox/CheckBox'
import { countryCodes } from '@libs/utils'
import { isMobile } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'

const DropDown = (props) => {
  const {
    hasNoBorder = false,
    placeHolderColor,
    disable,
    dialCode,
    label,
    labelColor,
    field,
    filtersApplied,
    items = [],
    itemIndex,
    onPress = () => {},
    hideLabel,
    position,
    isOutLine,
    isCountryCode = false,
    value = {},
    toggleDropdown,
    setFiltersApplied,
    style,
    dropdownWidth,
    dropdownHeight = 115,
    handleCheck,
    labelStyle,
    iconColor,
    iconSize,
    hasNoBorderStyle,
  } = props

  const DropdownButton = useRef()
  const isFocused = useIsFocused()

  const [showDropDown, setShowDropDown] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const dropDownAnim = useRef(new Animated.Value(0)).current
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)

  useEffect(() => {
    if (showDropDown) {
      Animated.timing(dropDownAnim, {
        toValue: dropdownHeight,
        duration: 100,
        useNativeDriver: true,
      }).start()
    }
  }, [showDropDown])

  const handleDropDownClose = () => {
    Animated.timing(dropDownAnim, {
      toValue: 0,
      duration: 100,
      useNativeDriver: true,
    }).start(() => {
      setShowDropDown(false)
    })
  }

  const { colors } = useTheme()
  const getDropDownStyle = () => {
    if (!hideLabel && !isOutLine) {
      return styles.dropDownContainer
    }
    if (isOutLine) {
      return styles.dropDownOutLineContainer
    }
    return ''
  }

  useEffect(() => {
    if (!isFocused) return

    const selectedItem = Array.isArray(items)
      ? items?.filter((filterItem) => filterItem.isSelected === true)
      : []
    if (selectedItem?.length > 0) setSelectedOption(selectedItem[0])
    else if (label) {
      setSelectedOption({ label: label, isPlaceholder: true })
    } else {
      if (isCountryCode) {
        if (Object.keys(value).length <= 0) {
          const selectedCountryCode = countryCodes.filter(
            (item) => item.dial_code === dialCode,
          )

          const selectedCountry = {
            label:
              selectedCountryCode?.length > 0
                ? `${selectedCountryCode[0]?.flag} ${selectedCountryCode[0]?.dial_code}`
                : `${items[0]?.flag} ${items[0]?.dial_code}`,
          }
          setSelectedOption(selectedCountry)
        }
      } else {
        setSelectedOption(items[0])
      }
    }
  }, [isFocused, dialCode, label])

  useEffect(() => {
    if (!isFocused) return

    if (Object.keys(value).length > 0) {
      setSelectedOption(value)
    }
  }, [isFocused, value])

  const getWidth = () => {
    if (isOutLine || isCountryCode) return ''
    else 129
  }

  let languageWidth
  let languageHeight

  if (hideLabel && !mobile) {
    languageWidth = 40
    languageHeight = 40
  } else if (hideLabel && mobile) {
    languageWidth = 30
    languageHeight = 30
  } else {
    languageWidth = 20
    languageHeight = 11
  }

  return (
    <TouchableWithoutFeedback
      style={{ flex: 1 }}
      onPress={() => handleDropDownClose()}
    >
      <View
        ref={DropdownButton}
        style={[
          { position: 'relative' },
          !hideLabel
            ? {
                margin: isOutLine || isCountryCode || hasNoBorderStyle ? 0 : 10,
                maxWidth: getWidth(),
                paddingBottom: hasNoBorderStyle ? 16 : '',
              }
            : { alignItems: 'center', flex: 1 },
          style,
        ]}
      >
        <TouchableOpacity
          onPress={
            !disable
              ? () => {
                  setShowDropDown(!showDropDown)
                  toggleDropdown(showDropDown, DropdownButton)
                }
              : null
          }
          style={[
            getDropDownStyle(),
            {
              opacity: disable ? 0.8 : 1,
            },
            hasNoBorder
              ? {
                  borderRadius: 0,
                  backgroundColor:
                    hasNoBorderStyle?.backgroundColor ||
                    colors.primaryBackground,
                  borderWidth: 0,
                  borderRadius: hasNoBorderStyle?.borderRadius,
                  paddingHorizontal: hasNoBorderStyle?.paddingHorizontal,
                }
              : {
                  borderColor: placeHolderColor
                    ? placeHolderColor
                    : colors.primaryPlaceHolder,
                },
          ]}
          disabled={disable}
        >
          {selectedOption?.image && (
            <Image
              source={selectedOption?.image}
              style={{
                height: languageHeight,
                width: languageWidth,
                borderRadius: hideLabel ? 20 : 0,
              }}
            />
          )}
          {selectedOption?.icon && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Icon
                name={selectedOption.icon}
                height={20}
                width={20}
                color={iconColor}
              />
              <Text
                variant={isOutLine ? 'bodyBold3' : 'display4'}
                color={
                  selectedOption?.isPlaceholder
                    ? placeHolderColor || colors.primaryPlaceHolder
                    : labelColor || colors.onNeutral
                }
                style={[
                  { marginRight: 6, marginLeft: isCountryCode ? 0 : 6 },
                  selectedOption?.isPlaceholder ? { fontWeight: 400 } : {},
                  labelStyle,
                ]}
              >
                {selectedOption?.label || selectedOption?.Label}
              </Text>
            </View>
          )}

          {!hideLabel && !selectedOption?.icon && (
            <Text
              variant={isOutLine ? 'bodyBold3' : 'display4'}
              color={
                selectedOption?.isPlaceholder
                  ? placeHolderColor || colors.primaryPlaceHolder
                  : labelColor || colors.onNeutral
              }
              style={[
                { marginRight: 6, marginLeft: isCountryCode ? 0 : 6 },
                selectedOption?.isPlaceholder ? { fontWeight: 400 } : {},
              ]}
            >
              {selectedOption?.label || selectedOption?.Label}
            </Text>
          )}
          {!hideLabel && (
            <Icon
              name="ArrowDown"
              height={iconSize || 16}
              width={iconSize || 16}
              style={{ opacity: disable ? 0.5 : 1 }}
              color={labelColor || placeHolderColor || colors.onNeutral}
            />
          )}
        </TouchableOpacity>
        {showDropDown ? (
          <Modal
            transparent
            visible={showDropDown}
            onBackdropPress={() => handleDropDownClose()}
            onRequestClose={() => {
              handleDropDownClose()
            }}
          >
            <TouchableWithoutFeedback
              style={{ flex: 1 }}
              onPress={() => handleDropDownClose()}
            >
              <View
                style={[
                  styles.dropDownList,
                  position,
                  {
                    width: dropdownWidth || '',
                    maxHeight: 240,
                  },
                ]}
              >
                <Animated.ScrollView
                  style={{
                    height: dropDownAnim,
                  }}
                >
                  <FlatList
                    data={items}
                    renderItem={({ item, index }) => (
                      <DropDownItem
                        itemIndex={itemIndex}
                        item={item}
                        index={index}
                        isCountryCode={isCountryCode}
                        field={field}
                        filtersApplied={filtersApplied}
                        setFiltersApplied={setFiltersApplied}
                        setSelectedOption={setSelectedOption}
                        handleDropDownClose={handleDropDownClose}
                        onPress={onPress}
                        handleCheck={handleCheck}
                        iconColor={iconColor ? iconColor : ''}
                      />
                    )}
                    keyExtractor={(_, index) => index}
                  />
                </Animated.ScrollView>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
        ) : null}
      </View>
    </TouchableWithoutFeedback>
  )
}

const DropDownItem = ({
  index,
  itemIndex,
  item,
  isCountryCode,
  field,
  filtersApplied,
  setSelectedOption,
  onPress,
  handleCheck,
  handleDropDownClose,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { colors } = useTheme()

  const handleOptionSelected = (selectedOption, i) => {
    let selectedCountry
    if (isCountryCode) {
      if (selectedOption?.flag) {
        selectedCountry = {
          label: `${selectedOption.flag} ${selectedOption.dial_code}`,
        }
      } else {
        selectedCountry = { label: `${selectedOption.Label}` }
      }
      setSelectedOption(selectedCountry)
      onPress(selectedOption, i)
    } else {
      setSelectedOption(selectedOption)
      onPress(selectedOption, i)
    }

    handleDropDownClose()
  }

  return (
    <TouchableOpacity
      key={
        item.dial_code || item.value || item.name || item.label || item.Label
      }
      style={[
        styles.dropDownListContainer,
        { backgroundColor: isHovered ? colors.primaryVariant : '' },
      ]}
      onPress={() => handleOptionSelected(item, index)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {field ? (
        <CheckBox
          label={item.name}
          value={item.value}
          itemIndex={itemIndex}
          handleCheck={handleCheck}
          isActive={filtersApplied[field]}
          filtersApplied={filtersApplied}
          field={field}
          index={index}
        />
      ) : item?.image ? (
        <View style={styles.item}>
          <Image
            source={item.image}
            style={{ height: 12, width: 20, marginRight: 10 }}
            resizeMode="contain"
          />
          <Text variant="display3" color={colors.onNeutral}>
            {item.label || item.Label || item.name}
          </Text>
        </View>
      ) : item?.icon ? (
        <View style={styles.item}>
          <Icon name={item.icon} height={20} width={20} />
          <Text
            style={{ marginLeft: 8 }}
            variant="display3"
            color={colors.onNeutral}
          >
            {item.label || item.Label || item.name}
          </Text>
        </View>
      ) : (
        <View style={styles.item}>
          <Text variant="display3" color={colors.onNeutral}>
            {isCountryCode && item.flag
              ? `${item.flag} ${item.dial_code}`
              : item.label || item.Label || item.name}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  )
}

export default DropDown
