import { useMutation, useQueryClient } from '@tanstack/react-query'
import { uploadFile } from '../api'

export const useUploadDocument = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation(async (data) => {
    const payload = {
      base64File: data?.file,
      documentType: data?.documentType,
      fileName: data?.name,
      applicationFormId: data?.opportunities?.ApplicationFormId__c,
      opportunityId: data?.opportunities?.Id,
    }

    const response = await uploadFile(payload)
    await queryClient.refetchQueries([
      `getDocumentsByOpportunityId-${data?.opportunities?.Id}`,
    ])
    return response
  }, {})
  return { mutation }
}
