import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  DefaultTheme,
  DarkTheme as NavigationDarkTheme,
} from '@react-navigation/native'
import { useAtom } from 'jotai'
import { DarkTheme as DarkThemeColors } from './src/darkTheme'
import { LightTheme as LightThemeColors } from './src/lightTheme'
import { typography } from './src/typography'
import { spacing } from './src/spacing'
import { brandDetailsAtom } from '../../packages/shared/src/utils/atom'

const customLightTheme = {
  ...DefaultTheme,
  ...LightThemeColors,
  colors: {
    ...DefaultTheme.colors,
    ...LightThemeColors.colors,
  },
  spacing,
}

const customDarkTheme = {
  ...NavigationDarkTheme,
  ...DarkThemeColors,
  colors: {
    ...NavigationDarkTheme.colors,
    ...DarkThemeColors.colors,
  },
  spacing,
}

const ThemeContext = createContext({
  theme: customLightTheme,
  setTheme: () => {},
  updateColors: () => {},
})

const getThemeColor = (theme) => {
  if (theme === 'dark') {
    return customDarkTheme
  }
  if (theme === 'light') {
    return customLightTheme
  }
  return customDarkTheme
}

const ThemeProvider = ({ children }) => {
  const currentTheme = getThemeColor('light')
  const [theme, setTheme] = useState(currentTheme)
  const [brandDetails] = useAtom(brandDetailsAtom)

  const updateColors = (newColors) => {
    setTheme((prevTheme) => ({
      ...prevTheme,
      colors: {
        ...prevTheme.colors,
        ...newColors,
      },
    }))
  }

  useEffect(() => {
    ;(async () => {
      const storedColors = brandDetails.colors
      if (storedColors && Object.keys(storedColors).length > 0) {
        // console.log(' The theme updated ')
        setTheme((prevTheme) => ({
          ...prevTheme,
          colors: {
            ...prevTheme.colors,
            ...storedColors,
          },
        }))
      }
    })()
  }, [brandDetails])

  return (
    <ThemeContext.Provider value={{ ...theme, setTheme, updateColors }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)

export { ThemeProvider, ThemeContext, typography, spacing }
