import React, { useEffect, useState } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { Auth } from 'aws-amplify'
import { useNavigation, useRoute } from '@react-navigation/native'

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const navigation = useNavigation()
  const route = useRoute()

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser()
        setIsAuthenticated(true)
      } catch {
        setIsAuthenticated(false)
        navigation.navigate('login', { redirect: route.path })
      }
    }

    checkAuthStatus()
  }, [navigation, isAuthenticated, route])

  if (isAuthenticated === null) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" />
      </View>
    )
  }

  return isAuthenticated ? children : null
}

export default ProtectedRoute
