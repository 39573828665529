import React, { useCallback, Suspense, useState, useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ScreenLayout, SecureStore } from '@libs/utils'
import { useAtom } from 'jotai'
import { Text } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Auth } from 'aws-amplify'
import { Loader } from '@libs/components'
import DesktopView from './DesktopView'
import { getBrandTheme, getOpportunities } from '../../api'
import { brandDetailsAtom, userProfile } from '../../utils/atom'
import MobileView from './MobileView'

const MyApplication = (props) => {
  const isFocused = useIsFocused()
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [userprofile, setUserDetails] = useAtom(userProfile)
  const [activeTab, setActiveTab] = useState(0)
  const [offeredCards, setOfferedCards] = useState([])
  const [data, setData] = useState({
    AllData: [],
    AdmissionsReview: [],
    Offer: [],
    Draft: [],
    AgentDraft: [],
  })
  const { t } = useTranslation()
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const [brandDetails, setBrandDetails] = useAtom(brandDetailsAtom)
  const [userDetails] = useAtom(userProfile)
  const navigation = useNavigation()
  const queryClient = useQueryClient()
  const allOpportunity = queryClient.getQueryData(['getOpportunities'])

  const { setInitialParams } = props

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      if (!userprofile?.accessToken || !userprofile?.email) {
        const currentUser = await Auth.currentAuthenticatedUser().catch(
          (error) => console.log({ error }),
        )
        const userData = currentUser?.attributes

        const userDetail = {
          email: userData?.email || '',
          firstName: userData['custom:first_name'] || '',
          lastName: userData['custom:last_name'] || '',
          userID: userData?.sub || '',
          accessToken: currentUser?.signInUserSession?.accessToken?.jwtToken,
        }
        setUserDetails(userDetail)
      }
    })()
  }, [isFocused, userprofile])

  const fetchBrandTheme = async () => {
    const user = await Auth.currentAuthenticatedUser().catch((error) => {})
    if (!props?.cobrandId) return
    const res = await getBrandTheme({ id: props?.cobrandId })

    if (Object.keys(user || {}).length > 0 && userDetails?.email !== '') {
      if (res?.email && userDetails?.email !== res?.email) {
        setBrandDetails(res)
        Auth.signOut()
        setInitialParams({})
        await SecureStore.setItemAsync('consentAccepted', false)
        if (!res.emailStatus) {
          navigation.navigate('signUp')
        } else if (res.emailStatus) {
          navigation.navigate('login')
        }
      }
    }
    return res
  }

  const { data: brandThemeData, isFetching: isBrandThemeLoading } = useQuery({
    queryKey: ['getBrandTheme', props?.cobrandId],
    queryFn: fetchBrandTheme,
    enabled: isFocused && !!props?.cobrandId && !!allOpportunity,
    onSuccess: async (data) => {
      await SecureStore.setItemAsync(
        'tempUser',
        JSON.stringify({ email: data?.email }),
      )
    },
  })

  useEffect(() => {
    if (allOpportunity && allOpportunity.length > 0) {
      const branded = allOpportunity?.some(
        (item) => item.BusinessUnitFilter__c !== brandDetails.brand,
      )
      if (branded) {
        setBrandDetails({
          colors: {},
          uspDetails: [],
          brand: '',
          brandName: 'AppHero',
          emailStatus: null,
        })
      }
    }
  }, [allOpportunity])

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const fetchAllOpportunities = async () => {
    let response = await getOpportunities({ email: userprofile?.email })
    const branded = response.response.some(
      (item) => item.BusinessUnitFilter__c !== brandDetails.brand,
    )
    if (branded) {
      setBrandDetails({
        colors: {},
        uspDetails: [],
        brand: '',
        brandName: 'AppHero',
        emailStatus: null,
      })
    }
    if (!response) {
      response = await getOpportunities({ email: userprofile?.email })
    }

    if (response?.response) {
      const allData = response?.response

      const admissionsReview = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Admission Review',
      )
      const offer = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Offers',
      )
      const draft = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Drafts',
      )

      const visaPending = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Visa / Funding',
      )

      const accepted = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Accepted',
      )
      const enrolled = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Enrolment',
      )
      const rejected = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Rejected/Withdrawn',
      )
      setData({
        ...data,
        AllData: allData,
        AdmissionsReview: admissionsReview,
        Offer: offer,
        Draft: draft,
        VisaPending: visaPending,
        Accepted: accepted,
        Enrolled: enrolled,
        Rejected: rejected,
      })
      setOfferedCards(offer)
    }
    return response?.response
  }

  const { isFetching: isOpportunitiesFetching } = useQuery({
    queryKey: ['getOpportunities'],
    queryFn: fetchAllOpportunities,
    enabled: isFocused && !!userprofile?.email,
    initialData: [],
    onSuccess: (res) => {
      const branded = res?.some(
        (item) => item.BusinessUnitFilter__c !== brandDetails.brand,
      )
      if (branded) {
        setBrandDetails({
          colors: {},
          uspDetails: [],
          brand: '',
          brandName: 'AppHero',
          emailStatus: null,
        })
      }
    },
  })
  const toggleDropdown = (visible, ref) => {
    if (visible) {
      return
    }
    if (!visible) {
      ref?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
        setDropdownTop(py + 50)
        setDropdownLeft(_px)
        setDropDownWidth(_w)
      })
    }
  }
  const onChangeDropdown = (item, index) => {
    setActiveTab(index)
  }
  const filterData = [
    {
      fieldName: 'AllData',
      label: t('MY_APPLICATION.ALL_APPLICATION'),
      icon: 'ClipboardCheck',
      count: data?.AllData?.length || 0,
    },
    {
      fieldName: 'Draft',
      label: t('MY_APPLICATION.DRAFT'),
      icon: 'DocumentText',
      count: data?.Draft?.length || 0,
    },
    {
      fieldName: 'AdmissionsReview',
      label: t('MY_APPLICATION.ADMISSIONS_REVIEW'),
      icon: 'Clock',
      count: data?.AdmissionsReview?.length || 0,
    },
    {
      fieldName: 'Offer',
      label: t('MY_APPLICATION.OFFERS'),
      icon: 'Star',
      count: data?.Offer?.length || 0,
    },
    {
      fieldName: 'VisaPending',
      label: t('MY_APPLICATION.VISA_PENDING'),
      icon: 'CreditCardIcon',
      count: data?.VisaPending?.length || 0,
    },
    {
      fieldName: 'Accepted',
      label: t('MY_APPLICATION.ACCEPTED'),
      icon: 'UserCheckIcon',
      count: data?.Accepted?.length || 0,
    },
    {
      fieldName: 'Enrolled',
      label: t('MY_APPLICATION.ENROLLED'),
      icon: 'DocumentText',
      count: data?.Enrolled?.length || 0,
    },
    {
      fieldName: 'Rejected',
      label: t('MY_APPLICATION.REJECTED'),
      icon: 'XCircle',
      count: data?.Rejected?.length || 0,
    },
  ]

  const userName = userprofile.firstName + ' ' + userprofile.lastName
  const viewProps = {
    activeTab,
    brandLogoUrl,
    data,
    filterData,
    isLoading: isOpportunitiesFetching,
    offeredCards,
    setActiveTab,
    toggleDropdown,
    dropdownTop,
    dropdownLeft,
    dropdownWidth,
    onChangeDropdown,
    userprofile,
    userName,
  }
  if (isBrandThemeLoading) return <Loader />

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default MyApplication
