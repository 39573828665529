import { Text } from '@libs/components'
import { ScreenLayout } from '@libs/utils'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useIsFocused } from '@react-navigation/native'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { cartItemsCount, userProfile } from '../../utils/atom'
import DesktopView from './DesktopView'
import { useDeleteBasketItem } from '../../hooks/useDeleteBasketItem'
import { useQuery } from "@tanstack/react-query"
import { getBasketItems, getStudentDetail } from "../../api"

const ApplicationBasket = () => {
  const [basketData, setBasketData] = useState([])
  const [subHeadingCount, setSubHeadingCount] = useState(0)
  const isFocused = useIsFocused()
  const [userprofile, setUserDetails] = useAtom(userProfile)
  const [deletingCartItemId, setDeletingCartItemId] = useState(-1)
  const [, setCartItemCount] = useAtom(cartItemsCount)
  const { t } = useTranslation()


  const getBasketDetail = async () => {
    let basketResponse = []
    if (userprofile?.email) {
      basketResponse = await getBasketItems({ email: userprofile?.email })
      let userDetailsCopy = { ...userprofile }
      userDetailsCopy['bucketId'] = basketResponse?.[0]?.bucketId || ''
      setUserDetails(userDetailsCopy)
    }
    return basketResponse
  }

  const { data: studentDetails, isFetching: isStudentDetailFetching } = useQuery({
    queryKey: ['getStudentDetailAPI'],
    queryFn: async () => await getStudentDetail(userprofile?.email),
    enabled: isFocused && !!userprofile?.email,
    initialData: []
  })

  // fetch basket Items
  const { data: basketItems, isFetching: isBasketItemFetching } = useQuery({
    queryKey: [`getBasketItems-${userprofile?.email}`],
    queryFn: async () => await getBasketDetail(),
    initialData: [],
  })




  useEffect(() => {
    if (!isFocused) return
      ; (async () => {
        const basketItem = [
          {
            imageURl:
              'https://www.ue-germany.com/media/1512/gus-logo__gallery.png',
            progress: studentDetails.progressPercentage,
            title: t('APPLICATION_BASKET.TITLE'),
            description1: t(
              'APPLICATION_BASKET.STUDENT_DETAIL_CARD_DESCRIPTION1',
            ),
            description2: t(
              'APPLICATION_BASKET.STUDENT_DETAIL_CARD_DESCRIPTION2',
            ),
            routeName: 'personal-information',
          },
        ]
        if (basketItems?.length > 0) {
          basketItems.map((cartItem) => {
            if (cartItem.progress === 100 && subHeadingCount !== 2) {
              setSubHeadingCount(2)
            }
            basketItem.push({
              ...cartItem,
              progress: 0,
              description1: t('APPLICATION_BASKET.APPLICATION_CARD_DESCRIPTION'),
            })
          })
        }
        setBasketData(basketItem)
      })()
  }, [isFocused, basketItems, studentDetails])

  useEffect(() => {
    if (!isFocused) return
    (() => {
      const progress = studentDetails.progressPercentage
      if (progress === 100) {
        setSubHeadingCount(1)
      }
    })()
  }, [isFocused, studentDetails])

  useEffect(() => {
    if (!isFocused) return

    if (basketData?.length > 0) {
      setCartItemCount(basketData?.length - 1)
    }

  }, [isFocused, basketData])

  const { mutation: deleteMutation } = useDeleteBasketItem()

  // delete Cart
  const deleteCartItem = async ({ externalId }) => {
    setDeletingCartItemId(externalId)
    await deleteMutation.mutateAsync({
      email: userprofile?.email,
      externalId,
    })
    setDeletingCartItemId(-1)
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, DesktopView, DesktopView),
    [],
  )
  const viewProps = { basketData, isLoading: isBasketItemFetching || isStudentDetailFetching, subHeadingCount, deletingCartItemId, deleteCartItem }
  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ApplicationBasket
