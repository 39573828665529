import {
  LayoutAnimation,
  TouchableOpacity,
  View,
  StyleSheet,
  ScrollView,
} from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { Text } from '@libs/components'
import { DateforComments } from '@libs/utils'
import { colors } from '@libs/theme/src/tokens'
import { updateReadState } from '../../api'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { sort } from '../../utils/sorting'
import { useTheme } from '@libs/theme'

const AccordionItem = ({
  children,
  item,
  queryClient,
  handleUpdateComment,
  style = {},
}) => {
  const { colors } = useTheme()

  const [isExpanded, setIsExpanded] = useState(item?.isRead)

  useEffect(() => {
    setIsExpanded(item?.isRead)
  }, [item?.isRead])

  const handleContentExpansion = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <View style={[styles.accordContainer]}>
      {item?.title && (
        <TouchableOpacity
          style={styles.accordHeader}
          onPress={
            !item?.isRead
              ? () => {
                  updateComment(
                    item,
                    queryClient,
                    handleUpdateComment,
                    false,
                    handleContentExpansion,
                  )
                }
              : handleContentExpansion
          }
        >
          <Text style={{ fontSize: 14, fontWeight: 700 }}>
            {!item?.isRead ? (
              <Text
                style={{
                  color: colors.onAlert,
                  fontWeight: 700,
                  width: 8,
                  height: 8,
                }}
              >
                &#8226;{' '}
              </Text>
            ) : null}
            Admissions Team
          </Text>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 24,
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: colors.textPrimary,
                opacity: 0.5,
              }}
            >
              {item?.date ? DateforComments(item?.date.split('T')[0]) : ''}
            </Text>
            <Icon name={isExpanded ? 'ArrowUp' : 'ArrowDown'} />
          </View>
        </TouchableOpacity>
      )}
      <View style={{ paddingTop: 10 }}>
        {isExpanded ? (
          <Text style={[style]}>{children}</Text>
        ) : (
          <Text numberOfLines={1}>
            {React.Children.toArray(children)[0].props.children?.length > 30
              ? React.Children.toArray(children)[0]
                  .props.children?.split('\n')[0]
                  .slice(0, 70) + '...'
              : children}
          </Text>
        )}
      </View>
    </View>
  )
}

async function updateComment(
  item,
  queryClient,
  handleUpdateComment,
  isMarkasRead,
  handleContentExpansion,
) {
  if (handleContentExpansion) {
    handleContentExpansion()
  }
  await handleUpdateComment(item, queryClient, isMarkasRead)
}

const Accordion = ({ data, handleUpdateComment, isActiveComment }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { colors } = useTheme()

  const scrollViewRef = useRef(null)
  const activeCommentRef = useRef(null)

  useEffect(() => {
    let hasScrolled = false // Flag to track whether scroll operation has been performed

    if (
      isActiveComment &&
      scrollViewRef.current &&
      activeCommentRef.current &&
      !hasScrolled
    ) {
      activeCommentRef.current.measureLayout(
        scrollViewRef.current,
        (x, y) => {
          scrollViewRef.current.scrollTo({ y, animated: true })
          hasScrolled = true // Update flag after scroll operation
        },
        (error) => console.error(error),
      )
    }
  }, [isActiveComment])

  const handleMarkasRead = async () => {
    const unreadComments = data.filter((comment) => !comment.isRead)
    if (unreadComments.length > 0) {
      const payload = {
        whatId: unreadComments[0].whatId,
        ids: unreadComments.map((comment) => comment.id),
        isRead: true,
      }
      await updateComment(payload, queryClient, handleUpdateComment, true)
    }
  }

  return (
    <>
      <TouchableOpacity
        style={{
          marginLeft: 'auto',
          paddingHorizontal: 10,
          paddingTop: 10,
        }}
        onPress={handleMarkasRead}
      >
        <Text style={{ color: colors.draftBlue }}>
          {t('ACCORDION.MARK_ALL_READ')}
        </Text>
      </TouchableOpacity>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={{ maxHeight: '100vh' }}
      >
        {sort(data, 'date')?.map((item, index) => (
          <View
            key={index}
            style={[
              styles.accordionItem(colors),
              !item.isRead && styles.accordionReadItem,
            ]}
            ref={item?.id === isActiveComment ? activeCommentRef : null}
          >
            <AccordionItem
              handleUpdateComment={handleUpdateComment}
              queryClient={queryClient}
              item={item}
              style={{
                color: colors.textPrimary,
                fontSize: 14,
                fontWeight: 400,
              }}
              isActive={item?.id === isActiveComment}
            >
              {item.content}
            </AccordionItem>
          </View>
        ))}
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  accordContainer: {
    backgroundColor: 'transparent',
    borderRadius: 12,
    width: '100%',
  },

  accordHeader: {
    flex: 1,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  accordTitle: {
    fontSize: 14,
    letterSpacing: '0.5%',
  },
  accordBody: {
    maxWidth: '100%',
  },
  accordionItem: (colors) => ({
    borderWidth: 0.4,
    borderRadius: 16,
    marginTop: 20,
    padding: 20,
    borderColor: colors.borderPrimary,
  }),
  accordionReadItem: {
    borderRadius: 16,
    marginTop: 20,
    padding: 20,
    backgroundColor: 'white',
  },
})

export default Accordion
