import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 50,
    padding: 14,
  },

  label: {
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
  },
})
