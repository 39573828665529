import { TextInput as Input, View } from 'react-native'
import React from 'react'
import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'
import Row from '../Row/Row'
import { styles } from './styles'

const TextInput = (props) => {
  const { colors } = useTheme()
  const {
    disableFieldColor = '',
    editable = true,
    eyeIcon,
    inputFieldStyle,
    inputRef,
    limit,
    onBlur = () => {},
    onChangeText = () => {},
    onFocus = () => {},
    password,
    placeholder = '',
    placeholderColor = '',
    required,
    secureTextEntry,
    style,
    value,
    iconName,
    iconSize,
  } = props

  const handleInputOnFocus = async () => {
    onFocus()
  }
  const handleInputOutFocus = async () => {
    onBlur()
  }

  return (
    <Row
      style={[
        styles.inputRow,
        {
          backgroundColor: editable
            ? 'transparent'
            : disableFieldColor || colors.background,
        },
        style,
      ]}
    >
      {iconName && (
        <View style={{ width: 24 }}>
          <Icon
            name={iconName}
            height={iconSize || 16}
            width={iconSize || 16}
          />
        </View>
      )}
      <Input
        autoCorrect={false}
        onChangeText={(text) => onChangeText(text)}
        onFocus={handleInputOnFocus}
        onBlur={handleInputOutFocus}
        placeholder={placeholder}
        placeholderTextColor={placeholderColor || colors.primaryPlaceHolder}
        ref={inputRef}
        required={required}
        secureTextEntry={secureTextEntry}
        style={[styles.inputField, inputFieldStyle]}
        value={value}
        editable={editable}
        maxLength={limit || ''}
      />
      {password && <View style={{ justifyContent: 'center' }}>{eyeIcon}</View>}
    </Row>
  )
}

export default TextInput
