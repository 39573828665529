import { StyleSheet, TouchableOpacity, View, Animated } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { useIsFocused } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Icon } from '@app-hero/native-icons'
import { Text, Button, Loader, Badge } from '@libs/components'
import { useAtom } from 'jotai'
import { Image } from 'react-native'
import { Easing } from 'react-native'
import { selectedProgramIds, userProfile } from '../../utils/atom'
import { useQueryClient } from '@tanstack/react-query'
import { useTheme } from '@libs/theme'

const ProgramCard = ({
  brandLogo,
  cardLine = true,
  data,
  isCompareProgram,
  isCompareProgrammesIdUpdate,
  style,
  accordion,
  handlePrimaryButton = () => {},
  handleSecondaryButton = () => {},
  updateCartItems = async () => {},
  updateCompareProgrammesIds = () => {},
  onClose = async () => {},
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [onHover, setOnHover] = useState(false)
  const queryClient = useQueryClient()
  const [userprofile] = useAtom(userProfile)
  const basketItems = queryClient.getQueryData([
    `getBasketItems-${userprofile?.email}`,
  ])
  const [showBrandName, setShowBrandName] = useState(false)
  const [programIds, setSelectedProgramID] = useAtom(selectedProgramIds)
  const [isProgramRemoving, setIsProgramRemoving] = useState(false)
  const [isAnimationFinished, setIsAnimationFinished] = useState(false)
  const [isBasketLoading, setIsBasketLoading] = useState(false)
  const [showBadge, setShowBadge] = useState(false)
  const isFocused = useIsFocused()
  const fontAnimation = useRef(new Animated.Value(18)).current
  const paddingAnimation = useRef(new Animated.Value(30)).current
  const fadeAnim = useRef(new Animated.Value(0)).current
  const fadeAnim2 = useRef(new Animated.Value(1)).current
  const imageAnim = useRef(new Animated.Value(0)).current
  const scaleValue = useRef(new Animated.Value(1.2)).current

  const animate = () => {
    fadeAnim2.setValue(1)

    Animated.timing(fadeAnim2, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      setIsAnimationFinished(true)
      Animated.timing(scaleValue, {
        toValue: 1,
        duration: 500,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start()
      Animated.timing(imageAnim, {
        toValue: 0.5,
        duration: 500,
        useNativeDriver: true,
      }).start()
      Animated.timing(fontAnimation, {
        toValue: 16,
        duration: 500,
        useNativeDriver: true,
      }).start()
      Animated.timing(paddingAnimation, {
        toValue: 20,
        duration: 500,
        useNativeDriver: true,
      }).start()
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start()
    })
  }

  useEffect(() => {
    if (onHover && !isCompareProgram) {
      animate()
    } else {
      scaleValue.setValue(1.2)
      fadeAnim.setValue(0)
      fadeAnim2.setValue(1)
      fontAnimation.setValue(18)
      paddingAnimation.setValue(30)
      setIsAnimationFinished(false)
    }
  }, [onHover])

  useEffect(() => {
    if (!isFocused) return
    ;(() => {
      const filteredData = basketItems?.filter(
        (item) =>
          item?.programmeId === data?.id || item?.programmeId === data?.Id,
      )

      if (filteredData?.length > 0) {
        setShowBadge(true)
      } else {
        setShowBadge(false)
      }
    })()
  }, [isFocused, data, basketItems])

  const handleUpdateCart = async (params) => {
    setIsBasketLoading(true)
    await updateCartItems(params)
    setIsBasketLoading(false)
  }

  const handleClose = async (data) => {
    setIsProgramRemoving(true)
    await onClose(data)
    setIsProgramRemoving(false)
  }

  return (
    <View
      style={[
        styles({ colors }).card,
        style,
        {
          shadowColor: 'rgba(3, 30, 125, 0.05)',
          shadowOffset: { width: 0, height: 2 },

          elevation: 5,
          shadowOpacity: 1,
          shadowRadius: onHover ? 10 : 2,
          zIndex: isCompareProgram ? 20000 : '',
        },
      ]}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      key={data?.id}
    >
      {cardLine && <View style={styles({ colors }).line} />}

      <View style={[styles({ colors }).cardContainer]}>
        {!accordion && (
          <View style={styles({ colors }).cardHeader}>
            {!showBrandName ? (
              <Animated.View style={{ transform: [{ scale: scaleValue }] }}>
                <Image
                  source={brandLogo}
                  style={{
                    height: 50,
                    width: 100,
                  }}
                  alt={data?.brand}
                  resizeMode="contain"
                  onError={(error) => {
                    setShowBrandName(true)
                  }}
                />
              </Animated.View>
            ) : (
              <Animated.Text
                variant="display2"
                color={colors.neutral}
                style={{
                  fontSize: fontAnimation,
                }}
              >
                {data?.brandFullName}
              </Animated.Text>
            )}
            {showBadge ? <Badge theme="green">Added</Badge> : null}
            {isCompareProgram ? (
              isProgramRemoving ? (
                <Loader size={12} hasFlexValue={false} />
              ) : (
                <TouchableOpacity
                  style={{
                    flexDirection: 'column',
                    backgroundColor: '#ECF2FC',
                    borderRadius: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 24,
                    width: 24,
                  }}
                  onPress={() => handleClose(data)}
                >
                  <Icon name="Close" height={12} width={12} color="#9E9E9E" />
                </TouchableOpacity>
              )
            ) : null}
          </View>
        )}
        <Animated.Text
          variant="heading5"
          color={colors.neutral}
          style={{
            paddingTop: accordion ? 0 : 20,
            paddingBottom: paddingAnimation,
            fontWeight: 700,
            fontSize: fontAnimation,
          }}
        >
          {data?.name || data?.Name}
        </Animated.Text>
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flex: 1,
          }}
        >
          {renderComponent({
            accordion,
            colors: colors,
            data,
            fadeAnim,
            fadeAnim2,
            isAnimationFinished,
            isBasketLoading,
            isCompareProgram,
            isCompareProgrammesIdUpdate,
            onHover,
            programIds,
            translation: t,
            handlePrimaryButton,
            handleSecondaryButton,
            setSelectedProgramID,
            handleUpdateCart,
            updateCompareProgrammesIds,
          })}
        </View>
      </View>
    </View>
  )
}

const getDurationValue = (minDuration, maxDuration) => {
  if (minDuration === maxDuration) {
    return minDuration || 0
  }
  return `${minDuration || 0} - ${maxDuration || 0}`
}

const renderComponent = ({
  accordion,
  colors,
  data,
  fadeAnim,
  fadeAnim2,
  isAnimationFinished,
  isCompareProgram,
  isCompareProgrammesIdUpdate,
  onHover,
  isBasketLoading,
  translation,
  handlePrimaryButton,
  handleUpdateCart,
  updateCompareProgrammesIds,
}) => {
  const groupButton = accordion
    ? styles({ colors }).accordionButtonsGroup
    : styles({ colors }).buttonsGroup
  if (isCompareProgram) {
    return isBasketLoading ? (
      <Loader size={20} />
    ) : (
      <Button
        label={translation('BUTTON.ADD_TO_APPLICATION')}
        buttonStyle={[
          styles({ colors }).button,
          {
            alignSelf: 'flex-start',
          },
        ]}
        buttonColor={colors.primaryVariant}
        onPress={() => {
          handleUpdateCart({ programDetails: data })
        }}
      />
    )
  }
  if (onHover && isAnimationFinished) {
    return (
      <Animated.View
        style={[
          styles({ colors, accordion }).buttonContainer,
          {
            opacity: fadeAnim,
            transform: [
              {
                translateY: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [50, 0],
                }),
              },
            ],
          },
        ]}
      >
        <View style={groupButton}>
          <Button
            label={translation('BUTTON.MORE_INFORMATION')}
            buttonStyle={[
              styles({ colors }).button,
              {
                width: accordion ? '47%' : '100%',
                marginBottom: 10,
              },
            ]}
            buttonColor={colors.primaryVariant}
            hoverColor={colors.onHoverColor}
            onPress={() => {
              handlePrimaryButton(data?.id, data?.brand)
            }}
          />
          {isCompareProgrammesIdUpdate ? (
            <Loader size={20} style={{ marginBottom: 10 }} />
          ) : (
            <Button
              label={translation('BUTTON.ADD_TO_COMPARE')}
              buttonStyle={[
                styles({ colors }).button,
                {
                  width: accordion ? '47%' : '100%',
                  marginBottom: 10,
                },
              ]}
              buttonColor={colors.primary}
              labelColors={colors.white}
              onPress={() => {
                const mappedId = `${data?.brand}_${data?.id}`
                updateCompareProgrammesIds({
                  programId: mappedId,
                  type: 'update',
                })
              }}
            />
          )}
          {isBasketLoading ? (
            <Loader size={20} />
          ) : (
            <Button
              label={translation('BUTTON.ADD_TO_APPLICATION')}
              buttonStyle={[
                styles({ colors }).button,
                {
                  width: accordion ? '47%' : '100%',
                  marginBottom: accordion ? 0 : 10,
                },
              ]}
              buttonColor={colors.buttonVariant1}
              onPress={() => handleUpdateCart({ programDetails: data })}
            />
          )}
        </View>
      </Animated.View>
    )
  }
  return (
    <Animated.View
      style={[styles({ colors }).detailsContainer, { opacity: fadeAnim2 }]}
    >
      <View style={styles({ colors }).row}>
        <Icon name="LocationMarker" height={20} width={20} />
        <Text variant="display4" style={styles({ colors }).content}>
          {data?.locations?.map((location) => location)?.join(',') || '--'}
        </Text>
      </View>
      <View style={[styles({ colors }).row, { marginVertical: 8 }]}>
        <Icon name="Clock" height={20} width={20} />
        <Text variant="display4" style={styles({ colors }).content}>
          {data?.minDuration === null && data?.maxDuration === null
            ? '--'
            : `${getDurationValue(
                data?.minDuration,
                data?.maxDuration,
              )} ${translation('OPPORTUNITIES_CARD.DURATION')}`}
        </Text>
      </View>
      <View style={styles({ colors }).row}>
        <Icon name="Database" height={20} width={20} />
        <Text variant="display4" style={styles({ colors }).content}>
          {data.price.length == 0
            ? '--'
            : `${data?.price || 0} (${translation(
                'OPPORTUNITIES_CARD.ESTIMATED_TUITION',
              )})`}
        </Text>
      </View>
      {isBasketLoading ? <Loader size={20} style={{ marginTop: 20 }} /> : null}
    </Animated.View>
  )
}

const styles = (props) =>
  StyleSheet.create({
    buttonContainer: {
      flexDirection: props?.accordion ? 'row' : 'column',
    },
    card: {
      flexDirection: 'row',
      backgroundColor: props?.colors.white,
      margin: 20,
      borderRadius: 10,
      overflow: 'hidden',
      position: 'relative',
    },
    cardContainer: {
      paddingHorizontal: 30,
      paddingVertical: 20,
      flex: 1,
    },
    cardHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 35,
      alignItems: 'center',
    },
    checkBox: {
      width: 22,
      height: 22,
      backgroundColor: props?.colors.fieldBorder,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: props?.colors.fieldBorder,
      borderRadius: 1,
      alignItems: 'center',
    },
    detailsContainer: {
      paddingBottom: 20,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    button: {
      paddingVertical: 14,
    },
    line: {
      height: '100%',
      width: 4,
      backgroundColor: props?.colors.primary,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    accordionButtonsGroup: {
      justifyContent: 'space-between',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '100%',
      paddingBottom: 3,
    },
    buttonsGroup: {
      flexDirection: 'column',
      flex: 1,
    },
    content: {
      marginLeft: 10,
    },
  })

export default ProgramCard
