import {
  View,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import {
  Button,
  Loader,
  PasswordInput,
  Text,
  TextInput,
} from '@libs/components'
import { Icon } from '@app-hero/native-icons'

import { isMobile, isTablet } from '@libs/utils/src/screenLayout'
import { CustomAuth } from '@libs/utils'

import OTPTextView from '@twotalltotems/react-native-otp-input'
import { useAtom } from 'jotai'
import { useTheme } from '@libs/theme'
import { isWeb } from '@libs/utils/src/screenLayout'
import { useUpdateProfile } from '../../hooks/useUpdateProfile'
import { addScript, initializeChatbotUser } from '../../utils/addScript'
import {
  OTPVerification,
  ResetPasswordAtom,
  errorMessageAtom,
  signUpDetailsAtom,
  alertBoxAtom,
  userProfile,
  isChatbotVisible,
} from '../../utils/atom'
import { FW_CHAT_BOT_URL } from '../../utils/constants'

import { colors } from '@libs/theme/src/tokens'

const Otp = ({ isLoading, setIsLoading, handleSignUp }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [forgotPasswordCredential, setForgotPasswordCredential] =
    useAtom(ResetPasswordAtom)
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom)
  const [showOTPVerification, setshowOTPVerification] = useAtom(OTPVerification)
  const [signUpDetails, setSignupDetails] = useAtom(signUpDetailsAtom)

  const [hasOTPSent, setHasOTPSent] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [alertBoxDetails, setAlertBoxDetails] = useAtom(alertBoxAtom)
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const tablet = isTablet(width)
  const [seconds, setSeconds] = useState(59)
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const route = useRoute()
  const { mutation } = useUpdateProfile()
  const routeName = route?.name
  const isDesktop = isWeb(width)
  const [canShowChatbot, setCanShowChatbot] = useAtom(isChatbotVisible)

  const atIndex = signUpDetails?.email?.indexOf('@')
  const repeatCount = atIndex > 0 ? atIndex - 1 : 1

  const username =
    signUpDetails?.email?.substring(0, 1) +
    '*'.repeat(repeatCount) +
    signUpDetails?.email?.substring(atIndex)

  // cta enable variable
  const [isCTAEnable, setIsCTAEnable] = useState(false)
  const [isContinueEnable, setIsContinueEnable] = useState(false)
  const [, setUserDetails] = useAtom(userProfile)

  const [passwordErrorMessage, setPasswordErrorMessage] = useState({
    error: '',
    errorKey: '',
  })
  const initialValue = {
    country: '',
    countryCode: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: {
      dialCode: '',
      mobileNumber: '',
    },
    password: '',
    repeatPassword: '',
  }

  // useEffect(() => {
  //   if (!isFocused) return
  //   return () => {
  //     window.removeEventListener('keypress')
  //     const initialValue = {
  //       country: '',
  //       countryCode: '',
  //       firstName: '',
  //       lastName: '',
  //       email: '',
  //       mobile: {
  //         dialCode: '',
  //         mobileNumber: '',
  //       },
  //       password: '',
  //       repeatPassword: '',
  //     }
  //     setSignupDetails(initialValue)
  //     setPasswordErrorMessage({
  //       error: '',
  //       errorKey: '',
  //     })
  //     setErrorMessage('')
  //   }
  // }, [isFocused])

  // Adjust useEffect to only handle the countdown
  useEffect(() => {
    console.log('details', signUpDetails)
    let interval
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((sec) => sec - 1)
      }, 1000)
    } else {
      clearInterval(interval)
      setButtonDisabled(false) // Enable the button when countdown is done
    }
    return () => clearInterval(interval)
  }, [seconds])

  // useEffect(() => {
  //   if (showOTPVerification === false) {
  //     setForgotPasswordCredential((prev) => ({
  //       ...prev,
  //       newPassword: '',
  //       confirmPassword: '',
  //     }))
  //   }
  // }, [showOTPVerification])
  useEffect(() => {
    if (canShowChatbot) {
      console.log('inside condition')
      addScript(FW_CHAT_BOT_URL, () => {
        initializeChatbotUser({
          email: userProfile?.email,
          firstName: userProfile?.firstName,
          lastName: userProfile?.lastName,
          externalId: userProfile?.email,
          phoneNumber: userProfile?.phoneNumber,
          appherotoken: userProfile?.accessToken,
          properties: {
            plan: 'Pro',
            status: 'Active',
          },
        })
      })
    }
  }, [canShowChatbot])

  useEffect(() => {
    if (seconds === 0) {
      setButtonDisabled(false)
    }
  }, [seconds])

  const handleVerifyButtonClick = async () => {
    await handleConfirmSignUp()
    setSeconds(59)
    setButtonDisabled(true)
  }
  const handleResendClick = async () => {
    setSeconds(59)
    setButtonDisabled(true)
    await handleSignUp(false)
  }

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const emailPattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidEmail = emailPattern.test(forgotPasswordCredential?.email)
      const isValidPassword =
        forgotPasswordCredential.newPassword?.length > 0 &&
        forgotPasswordCredential.confirmPassword?.length > 0
      const code = forgotPasswordCredential?.code?.length === 6

      if (code) setIsCTAEnable(true)
      else setIsCTAEnable(false)
      if (isValidEmail) setIsContinueEnable(true)
      else setIsContinueEnable(false)

      if (
        forgotPasswordCredential.newPassword !==
          forgotPasswordCredential.confirmPassword &&
        forgotPasswordCredential.confirmPassword?.length > 0
      ) {
        setPasswordErrorMessage({
          error: t('ERROR_MESSAGE.PASSWORD_NOT_MATCH'),
          errorKey: 'ERROR_MESSAGE.PASSWORD_NOT_MATCH',
        })
        if (code && isValidPassword) {
          setIsCTAEnable(false)
        }
      }
      if (
        forgotPasswordCredential.newPassword ===
        forgotPasswordCredential.confirmPassword
      ) {
        setPasswordErrorMessage({
          error: '',
          errorKey: '',
        })
        if (code && isValidPassword) {
          setIsCTAEnable(true)
        }
      }
    })()
  }, [isFocused, forgotPasswordCredential])

  const handleConfirmSignUp = async () => {
    setIsLoading(true)
    const userData = await CustomAuth.confirmSignUp(
      signUpDetails.email.toLowerCase(),
      forgotPasswordCredential?.code,
      signUpDetails?.password,
    )
    console.log('userData', userData)

    if (userData?.message) {
      if (userData?.name === 'CodeMismatchException') {
        console.log('Error occured CodeMismatchException:', userData?.message)
        setErrorMessage(
          'The verification code you entered is incorrect. Please try again.',
        )
      } else if (userData?.name === 'ExpiredCodeException') {
        console.log('Error occured :', userData?.message)
        setErrorMessage(
          'The verification code you entered is incorrect. Please try again.',
        )
      } else if (userData?.name) {
        console.log('Error occured :', userData?.message)
        setErrorMessage(
          'The verification code you entered is incorrect. Please try again.',
        )
      } else {
        navigation.navigate('signUp', { invalidUser: true })
        setAlertBoxDetails({
          visible: true,
          message: t('ALERT.INVALID_EMAIL'),
        })
      }
      setIsLoading(false)
      return
    }
    if (userData && !userData?.message) {
      setUserDetails((prev) => ({ ...prev, ...userData }))
      await mutation.mutateAsync({
        updatedProfile: signUpDetails,
        isInitial: true,
      })
      setCanShowChatbot(true)
      if (userData?.canApply) {
        navigation.navigate('path-finder')
      } else {
        navigation.navigate('my-application')
      }
      setIsLoading(false)
    }
  }

  // const handleGoBack = () => {
  //   navigation.navigate('login')
  //   setErrorMessage('')
  // }

  return (
    <View style={[styles.loginContainer(mobile, tablet, isDesktop)]}>
      <View
        style={{
          minHeight: '40vh',
          marginTop: mobile || tablet ? 0 : 30,
          width: '100%',
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'baseline',
          }}
        >
          {/* {(mobile || tablet) && (
            <View style={{ flex: 0.1, justifyContent: 'flex-start' }}>
              {routeName !== 'privacyPolicy' && routeName !== 'login' && (
                <TouchableOpacity
                  onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.navigate('login')
                  }}
                >
                  <View style={styles.leftHeader}>
                    <Icon name="ArrowNarrowLeft" color={colors.neutral} />
                  </View>
                </TouchableOpacity>
              )}
            </View>
          )} */}
          <View style={{ flex: mobile || tablet ? 0.9 : 1 }}>
            <Text
              color={colors.neutral}
              variant="heading3"
              style={{
                textAlign: mobile || tablet ? 'start' : 'center',
                fontWeight: 700,
                marginTop: mobile || tablet ? 0 : 60,
                marginBottom: 20,
                borderRadius: 12,
              }}
            >
              {t('AUTH.VALIDATE_EMAIL')}
            </Text>
            <View style={{ paddingTop: 12, paddingBottom: 24 }}>
              <Text
                color="#64748B"
                style={{
                  textAlign: mobile || tablet ? 'start' : 'center',
                  fontSize: 14,
                }}
              >
                {`${t('AUTH.VALIDATE_EMAIL_DESC')} `}
                <Text
                  color="#64748B"
                  style={{
                    textAlign: mobile || tablet ? 'start' : 'center',
                    fontSize: 14,
                  }}
                >
                  {username}.
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <View>
          <OTPTextView
            onCodeChanged={(text) => {
              if (text.length <= 6) {
                setForgotPasswordCredential((prevData) => ({
                  ...prevData,
                  code: text,
                }))
              }
            }}
            inputCount={6}
            tintColor={colors.primary}
            keyboardAppearance="dark"
            placeholderCharacter="-"
            codeInputFieldStyle={styles.OtpFields(mobile, tablet)}
            codeInputHighlightStyle={styles.OtpFields(mobile, tablet)}
          />
        </View>
        {Boolean(errorMessage) && (
          <Text
            variant="display3"
            color={colors.onAlert}
            style={{ marginTop: 16 }}
          >
            {errorMessage}
          </Text>
        )}
        {/* forgot enter otp email section */}

        <View style={[styles.SignUpFields, {}]}>
          <View
            style={[
              {
                marginTop: mobile || tablet ? 24 : 30,
                width: '100%',
              },
            ]}
          >
            <Button
              label={t('BUTTON.VERIFY')}
              buttonColor={colors.primary}
              labelColors={colors.white}
              onPress={() => {
                // handleSubmitPassword()
                handleVerifyButtonClick()
              }}
              disable={!isCTAEnable}
              buttonStyle={{ width: '100%', borderRadius: 12, height: 56 }}
              labelStyle={{ fontWeight: 700 }}
            />
          </View>
        </View>
        {/* otp resend button section */}
        {/* <View style={{ padding: 20, alignItems: 'center', paddingBottom: 0 }}>
          <TouchableOpacity onPress={() => handleResendClick()}>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ margin: '0.5rem', fontSize: 14 }}>
                Did not recevie an email?
              </Text>
              <View>
                <Text
                  variant="display4"
                  color={colors.primary}
                  style={{
                    fontWeight: 800,
                    margin: '0.5rem',
                  }}
                >
                  Resend OTP
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        </View> */}
        <View style={{ padding: 20, alignItems: 'center', paddingBottom: 0 }}>
          <View>
            <TouchableOpacity
              onPress={() => (isButtonDisabled ? null : handleResendClick())}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text
                  variant="display4"
                  color={colors.primary}
                  style={{
                    fontWeight: 800,
                    opacity: isButtonDisabled ? 0.5 : 0.8,
                    margin: '0.5rem',
                  }}
                >
                  Resend Verification Code
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <Text style={{ margin: '0.5rem', fontSize: 14 }}>
            Time remaining 00:{String(seconds).padStart(2, '0')} seconds
          </Text>
        </View>
      </View>

      <View
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          marginBottom: 20,
          // marginTop: 100,
        }}
      >
        <View
          style={{
            alignSelf: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: mobile ? 0 : 0,
          }}
        >
          <Text style={{ fontSize: 14 }}>{t('AUTH.ALREADY_HAVE_ACCOUNT')}</Text>
          <TouchableOpacity
            onPress={() => {
              setErrorMessage('')
              setSignupDetails(initialValue)
              navigation.navigate('login')
            }}
          >
            <Text
              style={{
                color: colors.draftBlue,
                fontWeight: 700,
                letterSpacing: 0.2,
                fontSize: 14,
              }}
            >
              {' '}
              {t('AUTH.SIGN_IN')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

const ButtonContainer = ({
  disable,
  isLoading,
  showButton,
  handleButton = () => {},
  isContinueEnable,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  if (showButton) {
    return (
      <Button
        label={t('BUTTON.CONTINUE')}
        buttonColor={colors.draftBlue}
        buttonStyle={{ borderRadius: 12, height: 56 }}
        labelStyle={{ color: colors.white, fontWeight: 700, fontSize: 16 }}
        onPress={() => handleButton()}
        disable={!isContinueEnable}
      />
    )
  }
  if (isLoading) {
    return <Loader size={20} />
  }
  return (
    <Button
      label={t('AUTH.RESET_PASSWORD_BUTTON')}
      buttonColor={colors.draftBlue}
      onPress={() => handleButton()}
      disable={!disable}
      buttonStyle={{ borderRadius: 12, height: 56 }}
      labelStyle={{ color: colors.white, fontSize: 16, fontWeight: 700 }}
    />
  )
}

const styles = StyleSheet.create({
  authLinks: {
    alignSelf: 'center',
  },
  buttonsContainer: {
    width: '100%',
    marginVertical: 32,
    marginTop: 20,
  },
  loginContainer: (mobile, tablet, isDesktop) => {
    return {
      flexDirection: 'column',
      alignItems: isDesktop && 'center',
      justifyContent: 'center',

      marginTop: !isDesktop ? '6vh' : '10vh',

      paddingLeft: isDesktop ? '16%' : '10%',
      paddingRight: isDesktop ? '16%' : '10%',
      rowGap: mobile || tablet ? 20 : 25,
      height: '80vh',
    }
  },
  loginFields: {
    width: '100%',
  },
  OtpFields: (mobile, tablet) => {
    return {
      borderWidth: 1,
      // width: mobile || tablet ? '16.6%' : 45,
      height: 45,
      borderRadius: 12,
      border: '0.1px solid gray',
      color: colors.primary,
      fontWeight: 700,
    }
  },
})

export default Otp
