import { Linking, Platform } from 'react-native'

export const handleNavigation = ({ url }) => {
  if (Platform.OS === 'web') {
    window.open(url, '_blank')
  } else {
    Linking.openURL(url).catch((err) =>
      console.error('Error opening URL: ', err),
    )
  }
}

export const toggleDropdown = ({
  ref,
  setDropdownTop,
  setDropdownRight,
  setDropDownWidth,
}) => {
  ref?.current?.measure((_fx, _fy, _w, h, _px, py) => {
    setDropdownTop(py + 50)
    setDropdownRight(_px - 10)
    setDropDownWidth(_w)
  })
}
