import { France, Germany, UnitedStates } from '@apphero/assets'

export const languageDropDown = [
  {
    label: 'English',
    image: UnitedStates,
    languageCode: 'en',
  },
  {
    label: 'French',
    image: France,
    languageCode: 'fr',
  },
  {
    label: 'German',
    image: Germany,
    languageCode: 'de',
  },
]

export const menuItems = [
  // {
  //   label: 'View Profile',
  //   action: 'student-details',
  //   param: true,
  // },
  {
    label: 'personal information',
    action: 'profile',
    param: false,
  },
  {
    label: 'Change Password',
    action: 'change-password',
    param: false,
  },
]

export const profileDrawerMenu = [
  {
    label: 'PROFILE INFORMATION',
    action: 'profile',
    icon: 'User',
  },
  {
    label: 'NOTIFICATIONS',
    param: false,
    icon: 'NotificationBell',
    action: 'notification',
  },
  {
    label: 'CHANGE PASSWORD',
    action: 'change-password',
    param: false,
    icon: 'PasswordLock',
  },
  {
    label: 'LOGOUT',
    action: 'Logout',
    param: false,
    icon: 'Logout',
  },
]
export const profileDrawerTabs = [
  {
    label: 'MY APPLICATIONS',
    action: 'my-application',
    isTab: true,
  },
  {
    label: 'EXPLORE PATHWAYS',
    action: 'path-finder',
    isTab: true,
  },
]

export const profileDrawerTabsWithoutPathWay = [
  {
    label: 'MY APPLICATIONS',
    action: 'my-application',
    isTab: true,
  },
]
export const MobileMenuItems = [
  {
    label: 'PERSONAL INFORMATION',
    action: 'profile',
    param: false,
  },
  {
    label: 'CHANGE PASSWORD',
    action: 'change-password',
    param: false,
  },
  {
    label: 'LOGOUT',
    action: 'Logout',
  },
]

export const loadingMessage = {
  login: { label: 'Signing In...' },
  forgotPassword: {
    label: 'Resetting password...',
    subText:
      'Redirecting you to login page. Please login with your new credentials!',
  },
  setPassword: {
    label: 'Account creation in progress',
  },
  Otp: { label: 'Signing Up...' },
}
