import React from 'react'
import { Image, StyleSheet, View, useWindowDimensions } from 'react-native'
import { AppHeroLogo, AppHeroLogoLite } from '@apphero/assets'
import { Icon } from '@app-hero/native-icons'
import LottieView from 'react-native-web-lottie'
import Text from '../Text/Text'
import LinearGradient from 'react-native-linear-gradient'
import { isWeb } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'

const AuthLoader = (props) => {
  const { style, hasFlexValue = true, label, subText } = props
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)
  return isDesktop ? (
    <View
      style={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors?.primaryBackground,
        },
        hasFlexValue ? { flex: 1 } : {},
        style,
      ]}
    >
      <View style={styles.leftHeader}>
        <Image
          source={AppHeroLogo}
          style={{ height: 38, width: 146 }}
          resizeMode="contain"
        />
      </View>
      <View
        style={[
          styles.cardContainer(colors),
          {
            maxHeight: '30rem',
            width: '30rem',
          },
        ]}
      >
        <Icon
          name="AuthLoading"
          style={{
            height: 152,
            width: 132,
            marginTop: '2.5rem',
          }}
        />
        <Text variant="heading3" style={{ marginTop: '1.5rem' }}>
          {label}
        </Text>
        {subText && (
          <Text
            variant="display4"
            style={{
              marginLeft: 30,
              marginRight: 30,
              marginTop: 12,
              textAlign: 'center',
            }}
          >
            {subText}
          </Text>
        )}
        <View style={styles.animationContainer}>
          <LottieView
            source={require('./loadingAnimation.json')}
            autoPlay
            loop
            speed={0.8}
          />
        </View>
      </View>
    </View>
  ) : (
    <LinearGradient
      style={[
        {
          justifyContent: 'center',
          backgroundColor: colors?.primaryBackground,
          paddingLeft: 20,
          paddingRight: 20,

          minHeight: '100vh',
          paddingBottom: 80,
        },
        style,
      ]}
      start={{ x: 0, y: 0 }}
      end={{ x: -1, y: -1 }}
      colors={['#2563EB', '#18181B']}
      locations={[0.2, 1.3]}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          paddingVertical: 32,
        }}
      >
        <Image source={AppHeroLogoLite} style={{ height: 28, width: 110 }} />
      </View>
      <View
        style={[
          styles.cardContainer(colors),
          { width: 327, marginHorizontal: 'auto' },
        ]}
      >
        <Icon
          name="AuthLoading"
          style={{
            height: 152,
            width: 132,
            marginTop: 92,
          }}
        />
        <Text
          variant="heading3"
          style={{ paddingTop: 32, paddingBottom: 24, width: '70%' }}
          numberOfLines={2}
          textAlign="center"
        >
          {label}
        </Text>
        {subText && (
          <Text
            variant="display4"
            style={{ width: '70%', marginTop: 12 }}
            numberOfLines={2}
            textAlign="center"
          >
            {subText}
          </Text>
        )}
        <View style={styles.animationContainer}>
          <LottieView
            source={require('./loadingAnimation.json')}
            autoPlay
            loop
            speed={0.8}
          />
        </View>
      </View>
    </LinearGradient>
  )
}
const styles = StyleSheet.create({
  leftHeader: {
    position: 'absolute',
    zIndex: 20,
    top: '1.25rem',
    left: '5.625rem',
  },
  animationContainer: {
    height: 126,
    width: 126,
    margin: '2rem',
    padding: '1rem',
  },
  cardContainer: (color) => ({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color?.card,
    borderRadius: 16,
  }),
})
export default AuthLoader
