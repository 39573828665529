import { View, StyleSheet, Modal, TouchableOpacity, Image } from 'react-native'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DropDown, FilePicker, Loader, Text } from '@libs/components'

import { Icon } from '@app-hero/native-icons'
import {
  PhotoId,
  PdfImage,
  PngImage,
  JpegImage,
  JpgImage,
  MiscImage,
} from '@apphero/assets'
import { useTheme } from '@libs/theme'

const FileUploadModal = ({
  titleVariant = 'heading3',
  title = '',
  visible = false,
  toggleDropdown = () => {},
  handleSave = async () => {},
  handleClose = () => {},
  dropDownRef,
  dropDownPosition = {},
  dropdownWidth = '',
  documentType = [],
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [file, setFile] = useState({})
  const [error, setError] = useState()
  const [selectedDocumentType, setSelectedDocumentType] = useState({})
  const [isFileSaving, setIsFileSaving] = useState()

  const handleSaveDocument = async () => {
    setIsFileSaving(true)
    await handleSave({
      ...file,
      documentType: selectedDocumentType?.label,
    })
    setIsFileSaving(false)
    toast.show(`${file.name} uploaded successfully!`, {
      type: 'success',
      successColor: colors.primary,
    })
    handleClose()
    setSelectedDocumentType({})
    setFile({})
  }

  const canCTADisable = () =>
    Object.keys(selectedDocumentType).length === 0 ||
    Object.keys(file).length === 0 ||
    !!error
  const getFileTypeImage = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return PdfImage
      case 'png':
        return PngImage
      case 'jpg':
        return JpgImage
      case 'jpeg':
        return JpegImage
      default:
        return MiscImage
    }
  }

  return (
    <Modal
      visible={visible}
      transparent
      onBackdropPress={() => {
        handleClose()
        setSelectedDocumentType({})
      }}
      onRequestClose={() => {
        handleClose()
        setSelectedDocumentType({})
      }}
      style={{ flex: 1 }}
    >
      <View style={styles.container}>
        <View style={styles.card}>
          <View style={styles.header}>
            <TouchableOpacity
              style={{ position: 'absolute', right: 0 }}
              onPress={() => {
                handleClose()
                setSelectedDocumentType({})
              }}
            >
              <Icon name="Close" height={23} width={23} />
            </TouchableOpacity>
            <Text
              style={{ paddingVertical: 20, fontSize: 24, fontWight: 300 }}
              placeHolder={'Text'}
            >
              {title}
            </Text>
          </View>
          <View>
            <Text variant="display4" style={{ paddingHorizontal: 10 }}>
              {t('FILE_UPLOAD.DOCUMENT_TYPE')}{' '}
              <Text variant="display4" color={colors.onAlert}>
                *
              </Text>
            </Text>
            <View ref={dropDownRef}>
              <DropDown
                items={documentType}
                toggleDropdown={toggleDropdown}
                position={dropDownPosition}
                dropdownWidth={dropdownWidth}
                onPress={(selectedOption) =>
                  setSelectedDocumentType(selectedOption)
                }
                label={t('DROP_DOWN.LABEL_SELECT')}
                dropdownHeight={300}
              />
            </View>
          </View>
          <View>
            <Text
              variant="display4"
              style={{
                flexWrap: 'wrap',
                flex: 1,
                paddingHorizontal: 10,
              }}
            >
              {t('FILE_UPLOAD.ATTACH_DOCUMENT')}{' '}
              <Text variant="display4" color={colors.onAlert}>
                *
              </Text>
            </Text>
            <View
              style={{
                marginHorizontal: 10,

                marginTop: 15,
                backgroundColor: '#FFF4E0',
                borderColor: '#D4691B',
                borderRadius: 5,
                borderWidth: 0.2,
                flexDirection: 'row',
                alignItems: 'center',
                padding: 10,
              }}
            >
              <Icon
                name="AlertCircle"
                height={12}
                width={12}
                color={'rgba(212, 105, 27, 1)'}
                style={{ paddingHorizontal: 10 }}
              />
              <Text
                style={{
                  color: '#D4691B',
                  fontSize: 12,
                }}
              >
                <b>{t('APPLICATION_DETAIL.NOTE')}</b> Maximum file upload size
                is 5MB.
              </Text>
            </View>
            {Object.keys(file).length > 0 ? (
              <View style={{ paddingHorizontal: 20 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderColor: colors.primaryBackground,
                    borderWidth: 2,
                    padding: 20,
                    marginVertical: 20,
                    borderRadius: 15,
                    flexWrap: 'wrap',
                  }}
                >
                  <View
                    style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}
                  >
                    <Image
                      source={getFileTypeImage(file.name)}
                      style={{ height: 15, width: 15 }}
                    />
                    <Text
                      variant="body2"
                      style={{ marginHorizontal: 10, maxWidth: '70%' }}
                    >
                      {file.name}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => {
                      setFile({})
                      setError('')
                    }}
                  >
                    <Icon name="Close" height={15} width={15} />
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <FilePicker
                style={{ marginHorizontal: 10 }}
                handleFileSelect={({ fileJson, error, file }) => {
                  if (error) {
                    setError(error)
                  }
                  setFile({ ...fileJson, file })
                }}
              />
            )}
          </View>
          {error ? (
            <Text
              variant="display4"
              color={colors.onAlert}
              style={{ marginBottom: 20 }}
            >
              {error}
            </Text>
          ) : null}
          <View style={styles.buttonContainer}>
            {isFileSaving ? (
              <Loader size={20} />
            ) : (
              <>
                <Button
                  label={t('BUTTON.SAVE')}
                  buttonColor={colors.primary}
                  onPress={() => handleSaveDocument()}
                  buttonStyle={{ marginRight: 20, width: 110 }}
                  disable={canCTADisable()}
                  labelColors="#FFFF"
                />
                <Button
                  label={t('BUTTON.CANCEL')}
                  buttonColor="#B6CFF3"
                  onPress={() => {
                    handleClose()
                    setSelectedDocumentType({})
                    setFile({})
                    setError('')
                  }}
                  appearance="outline"
                  labelColors={'#0B5CD7'}
                  buttonStyle={{ width: 110 }}
                />
              </>
            )}
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000000CC',
    width: '100%',
    paddingHorizontal: 20,
  },
  card: {
    alignSelf: 'center',
    backgroundColor: '#ffffff',
    paddingLeft: 30,
    paddingBottom: 30,
    paddingTop: 15,
    paddingRight: 15,
    marginTop: '10%',
    maxWidth: 502,
    width: '100%',
    borderRadius: 15,
  },
  header: {
    paddingTop: 20,
    position: 'relative',
  },
  buttonContainer: {
    flexDirection: 'row',
  },
})

export default FileUploadModal
